import { $api } from "@shared/api/api";

const route = "/api/production/release/item";

export const getReleaseItems = (
    page: number,
    perPage: number,
    group?: string,
    filters?: TFilterValue[]
) => {
    const params: any = {
        page,
        perPage,
        filter:
            filters && filters?.length > 0
                ? {
                      condition_type: "AND",
                      conditions: filters,
                  }
                : undefined,
    };
    if (group) {
        params["group_by"] = group;
        return $api.get<TResponseListWithPage<TReleaseItemGrouped>>(`${route}/groups`, {
            params,
        });
    }
    return $api.get<TResponseListWithPage<TReleaseItem>>(route, {
        params,
    });
};
