import { entityModel } from "@entities/Portal/Entity";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEntityModifyModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const editingEntity = useSelector(entityModel.selectEditingEntity);
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    useEffect(() => {
        if (editingEntity) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [editingEntity]);
    const onClose = () => {
        setIsOpen(false);
        dispatch(entityModel.setEditingEntity(null));
    };

    return {
        editingEntity,
        isOpen,
        windowHeight,
        windowWidth,
        setIsOpen,
        onClose,
    };
};
