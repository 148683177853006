import { Button, Space } from "antd";
import { useTMActions } from "../model";
import TMActionsQtyModal from "./TMActionsQtyModal";

interface ITMActions {}

const TMActions = ({}: ITMActions) => {
    const {
        isFilled,
        nextLoading,
        isLast,
        stopLoading,
        pauseResumeLoading,
        isPaused,
        isOpenQtyModal,
        needPrinter,
        printerIsConnected,
        onClickNextWithCheckMultiple,
        onClickNextModal,
        onClickStop,
        onClickPauseResume,
        onCloseQtyModal,
    } = useTMActions();

    return (
        <>
            <Space>
                <Button loading={stopLoading} onClick={onClickStop} type="primary" danger>
                    Остановить
                </Button>
                <Button
                    loading={pauseResumeLoading}
                    onClick={onClickPauseResume}
                    type="primary"
                >
                    {isPaused ? "Продолжить" : "Пауза"}
                </Button>
                <Button
                    loading={nextLoading}
                    disabled={
                        !isFilled || isPaused || (needPrinter && !printerIsConnected)
                    }
                    onClick={onClickNextWithCheckMultiple}
                    type="primary"
                >
                    {isLast ? "Завершить" : "Далее"}
                </Button>
            </Space>
            <TMActionsQtyModal
                loading={nextLoading}
                isOpen={isOpenQtyModal}
                onApprove={onClickNextModal}
                onCancel={onCloseQtyModal}
            />
        </>
    );
};

export default TMActions;
