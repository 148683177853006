import Barcode from "react-barcode";
import { useCode } from "./UseCode";
import { QRCode } from "antd";
import "./Code.scss";
type TQrCode = {
    codeType: "qrcode";
    bgColor: string;
    color: string;
    value: string;
    size: number;
};
type TBarCode = {
    codeType: "barcode";
    renderer?: "canvas"; //| "img" | "svg" | undefined;
    height: number;
    width: number;
    fontSize: number;
    value: string;
};

type ICode = {
    codeType: "barcode" | "qrcode";
    showValue?: boolean;
} & (TQrCode | TBarCode);
const Code = (props: ICode) => {
    const { download } = useCode(props.codeType, props.value);

    return (
        <div className="code-item" id={`${props.codeType}-${props.value}`}>
            <div onClick={download}>
                {props.codeType === "barcode" ? (
                    <Barcode {...props} displayValue={false} />
                ) : (
                    <>
                        <QRCode {...props} />
                    </>
                )}
            </div>
            {props.showValue && (
                <span
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    {props.value}
                </span>
            )}
        </div>
    );
};

export default Code;
