export const parseErpLocationData = (
    patterns: TDrawerPattern[],
    windowLocation: string
) => {
    const pattern = patterns.find(
        (pattern) => windowLocation.search(pattern.pattern) !== -1
    );
    if (!pattern) return null;
    const index = windowLocation.search(pattern.pattern);
    if (index === -1) return null;
    const erpLocation = windowLocation.slice(index);
    const splittedLocation = erpLocation.split("/").filter((item) => item !== "");
    const id = Number(splittedLocation[pattern.idIndex]);
    const tableName = pattern?.tableIndex && splittedLocation[pattern?.tableIndex];
    const name = splittedLocation[pattern.nameIndex];
    return {
        id: Number.isNaN(id) ? null : Number(id),
        name: !name ? null : tableName ? `${tableName}/${name}` : name,
        location: erpLocation,
        pattern: pattern.pattern,
    };
};

export const getDrawerManagerItem = (
    patterns: TDrawerPattern[],
    windowLocation: string
): TDrawerManagerItemPrepared | null => {
    const queryIndex = windowLocation.indexOf("?");
    const query = queryIndex !== -1 ? windowLocation.slice(queryIndex + 1) : "";
    const clearWindowLocation = windowLocation.replace(query, "");

    const erpLocationData = parseErpLocationData(patterns, clearWindowLocation);
    if (!erpLocationData || !erpLocationData.id || !erpLocationData.name) return null;

    return {
        location: erpLocationData.location,
        id: erpLocationData?.id,
        name: erpLocationData?.name,
        pattern: erpLocationData.pattern,
        isOpen: true,
    };
};

export const parseOriginalLocation = (
    patterns: TDrawerPattern[],
    windowLocation: string
) => {
    const queryIndex = windowLocation.indexOf("?");
    const query = queryIndex !== -1 ? windowLocation.slice(queryIndex + 1) : "";
    const clearWindowLocation = windowLocation.replace(query, "");
    const erpLocationData = parseErpLocationData(patterns, clearWindowLocation);
    if (!erpLocationData || !erpLocationData.id) return windowLocation;
    return `${erpLocationData?.location.replace(`/${erpLocationData.id}`, "")}${query}`;
};

export const isHigherItem = () => {};
export const getItemLevel = () => {};
export const generateLocation = (item: TDrawerManagerItem) => {
    return `${item.pattern}/${item.name !== "" ? `${item.name}/` : ""}${item.id ?? "create"}`;
};
