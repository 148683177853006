import { useHeaderLayout } from "@shared/ui/Header";
import { BizProcGroups } from "@widgets/Portal/BizProcWidgets/BizProcGroups";
import { BizProcFilters } from "@widgets/Portal/Filters/BizProcFilters";
import { useBizProcsPage } from "./model";

const BizProcsPage = () => {
    useHeaderLayout({
        title: "Бизнес-процессы",
    });
    useBizProcsPage();
    return (
        <div className="biz-procs">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: 10 }}>
                    <BizProcFilters />
                </div>
                <BizProcGroups />
            </div>
        </div>
    );
};

export default BizProcsPage;
