import { useTimer } from "@shared/hooks";
import { useEffect } from "react";

export const useTechMapSpentTime = (spentTime: number, isPaused?: boolean) => {
    const { time, isActive, setTimeValue, pauseTimer, startTimer } = useTimer(0);
    useEffect(() => {
        setTimeValue(Math.floor(spentTime));
    }, [spentTime]);
    useEffect(() => {
        if (isPaused) {
            pauseTimer();
        } else {
            startTimer();
        }
    }, [isPaused]);
    return { time };
};
