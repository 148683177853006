import { bizProcModel } from "@entities/Portal/BizProc";
import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { datatypeModel } from "@entities/Portal/Datatype";
import { entityModel } from "@entities/Portal/Entity";
import { variableModel } from "@entities/Portal/Variables";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { excludedDataTypes } from "../constants";

export const useCreateUpdateVariable = (
    editingVariable: TVariable | null,
    onSuccess?: (variable: TVariable, isCreating?: boolean) => void
) => {
    const [isCalculatedValue, setIsCalculatedValue] = useState<boolean>(
        !!editingVariable?.is_calculated || !!editingVariable?.is_precalculated
    );
    const dispatch = useDispatch<AppDispatch>();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [variable, setVariable] = useState<TVariable | null>(null);
    const [error, setError] = useState<TValidationError>({});
    const datatypes = useSelector(datatypeModel.selectDatatypes);
    const { loadEntities, entities } = entityModel.useLoadEntities(false);
    const { LoadBizProcs, bizProcs } = bizProcModel.useLoadBizProcs(false);
    const { loadBizProcTemplates, bizProcTemplates } =
        bizProcTemplateModel.useLoadBizProcTemplates(undefined, false);
    const calculateOnce = !variable?.is_calculated && variable?.is_precalculated;
    const loadItemsByType = (type: TVariableType) => {
        if (type === "entity") {
            loadEntities();
        }
        if (type === "biz_proc_template") {
            loadBizProcTemplates();
        }
        if (type === "biz_proc") {
            LoadBizProcs();
        }
    };
    useEffect(() => {
        setVariable(editingVariable);
        if (editingVariable) {
            onSelectParentType(editingVariable.type);
            setIsOpen(true);
            loadItemsByType(editingVariable.type);
        } else {
            setIsOpen(false);
        }
    }, [editingVariable]);

    const onChangeVariableValue = (value: any) => {
        if (variable)
            setVariable({
                ...variable,
                value: value,
            });
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (variable)
            setVariable({
                ...variable,
                [event.target.name]: event.target.value,
            });
    };

    const onChangeType = (datatype: string) => {
        if (variable) {
            setVariable({
                ...variable,
                datatype: datatype,
                related_entity_uuid: null,
                value: null,
                key_label: null,
                key_value: null,
                is_precalculated: false,
                is_calculated: false,
                is_multiple: false,
            });
        }
    };

    const onClose = () => {
        setIsOpen(false);
        setError({});
        dispatch(variableModel.setEditingVariable(null));
    };
    const onOk = () => {
        setError({});
        if (variable) {
            setLoading(true);
            dispatch(
                variable.is_creating
                    ? variableModel.createVariableThunk(variable)
                    : variableModel.updateVariableThunk(variable)
            )
                .then(parseFormResponse)
                .then((data: TResponse<TVariable>) => {
                    onSuccess && onSuccess(variable, variable.is_creating);
                    onClose();
                })
                .catch((error: TValidationError) => {
                    console.log("error", error);
                    setError(error);
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Ошибка",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const onSelectParentType = (type: TVariableType) => {
        loadItemsByType(type);
        if (variable) {
            setVariable({
                ...variable,
                value: variable.is_calculated ? null : variable.value,
                type: type,
            });
        }
    };

    const onChangeIsCalculatedValueCheckbox = (event: CheckboxChangeEvent) => {
        const isCalculatedValue = event.target.checked;
        setIsCalculatedValue(event.target.checked);
        if (variable) {
            setVariable({
                ...variable,
                value: variable.is_multiple ? (isCalculatedValue ? [null] : []) : null,
                is_calculated: false,
                is_precalculated: isCalculatedValue,
            });
        }
    };

    const onSelectParentId = (value: number | string | null, option: any) => {
        const item = option;
        setIsCalculatedValue(false);
        if (variable) {
            setVariable({
                ...variable,
                type: variable.type,
                value: null,
                parent_identifier: item.value,
                parent_identifier_label: item.label,
                parent: item.option,
            });
        }
    };
    const onSelectIsCalculated = () => {
        if (variable) {
            setVariable({
                ...variable,
                is_calculated: true,
                is_precalculated: false,
            });
        }
    };
    const onSelectIsPrecalculated = () => {
        if (variable) {
            setVariable({
                ...variable,
                is_precalculated: true,
                is_calculated: false,
            });
        }
    };

    const onChangeIsMultiple = (event: CheckboxChangeEvent) => {
        const is_multiple = event.target.checked;
        if (variable) {
            setVariable({
                ...variable,
                is_multiple,
                value: is_multiple
                    ? variable.value
                        ? [variable.value]
                        : isCalculatedValue
                          ? [null]
                          : []
                    : variable.value[0] ?? null,
            });
        }
    };
    const onAddValueItem = () => {
        if (variable) {
            setVariable({ ...variable, value: [...variable.value, null] });
        }
    };
    const getItemsByType = (parentType: TVariableType) => {
        if (parentType === "biz_proc") return bizProcs;
        if (parentType === "entity") return entities;
        if (parentType === "biz_proc_template") return bizProcTemplates;
        return [];
    };
    const options = (variable?.type ? getItemsByType(variable.type) : []).map((item) => {
        return { label: item.name, value: item.id, item };
    });
    const types = datatypes
        .filter((item) => !excludedDataTypes.includes(item.datatype))
        .map((item) => {
            return { label: item.name, value: item.datatype, item };
        });
    return {
        isCalculatedValue,
        calculateOnce,
        onClose,
        onChangeName,
        onOk,
        onSelectParentType,
        onSelectParentId,
        onChangeType,
        setVariable,
        onChangeVariableValue,
        onSelectIsCalculated,
        onSelectIsPrecalculated,
        onChangeIsCalculatedValueCheckbox,
        onChangeIsMultiple,
        onAddValueItem,
        types,
        options,
        loading,
        error,
        isOpen,
        variable,
    };
};
