import { CalculatedValueItem } from "@entities/Portal/CalculatedValues/ui";
import { useCalculatedValueListItem } from "../model";

interface ICalculatedValueNormalItem {
    calculatedValue: TCalculatedValue;
}
const CalculatedValueNormalItem = ({ calculatedValue }: ICalculatedValueNormalItem) => {
    const { onDelete, deleteLoading } = useCalculatedValueListItem(calculatedValue);

    return (
        <div style={{ minWidth: 200 }}>
            <CalculatedValueItem
                calculatedValue={calculatedValue}
                onDelete={onDelete}
                deleteLoading={deleteLoading}
            />
        </div>
    );
};

export default CalculatedValueNormalItem;
