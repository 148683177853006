export const TECH_ROLES_TABLE = "roles";
export const LOCATION_TMT_OPERATION_PATTERN = "/tech-map-editor";
export const LOCATION_TMT_OPERATION_ID_INDEX = 3;
export const LOCATION_TMT_OPERATION_NAME_INDEX = 1;

export const techMapTemplateMock: TTechMapTemplateCreating = {
    name: "Технологическая карта",
    is_multiple: false,
    is_skippable: false,
    executor_roles: [],
    reviewer_roles: [],
    results: [],
};
export const tmtTypesOptions = [
    {
        value: "increment",
        label: "Множественный результат",
    },
    {
        value: "create",
        label: "Одиночный результат",
    },
];
