export const getNewChangedUserRoles = (
    oldChangedUserRoles: TUserRole[],
    newUserRoleWithUpdatedPriority: TUserRole
) => {
    let newChangedUserRoles: TUserRole[] = oldChangedUserRoles;
    const foundedChangedUserRoles = newChangedUserRoles.find(
        (userRole) => userRole.id === newUserRoleWithUpdatedPriority.id
    );
    if (foundedChangedUserRoles) {
        if (
            foundedChangedUserRoles.oldPriorityValue ===
            newUserRoleWithUpdatedPriority.priority
        ) {
            newChangedUserRoles = newChangedUserRoles.filter(
                (item) => item.id !== newUserRoleWithUpdatedPriority.id
            );
        } else {
            newChangedUserRoles = newChangedUserRoles.map((item) =>
                item.id === foundedChangedUserRoles.id
                    ? newUserRoleWithUpdatedPriority
                    : item
            );
        }
    } else {
        newChangedUserRoles = [...newChangedUserRoles, newUserRoleWithUpdatedPriority];
    }
    return newChangedUserRoles;
};

export const getSortedUserRolesByPriorityWithOldPriorityValue = (
    userRoles: TUserRole[]
) =>
    [...userRoles]
        .sort(
            (userRolePrev, userRoleNext) => userRoleNext.priority - userRolePrev.priority
        )
        .map((userRole) => ({ ...userRole, oldPriorityValue: userRole.priority }));
