import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { useState } from "react";
import { resizeObserverOptions } from "../constants";

export const useContainerWidthResize = (
    element: HTMLElement | null,
    condition?: boolean
) => {
    const [containerWidth, setContainerWidth] = useState<number>();
    const onResize = (entries: ResizeObserverEntry[]) => {
        const [entry] = entries;
        if (entry && condition) {
            setContainerWidth(entry.contentRect.width);
        }
    };
    useResizeObserver(element, resizeObserverOptions, onResize);
    return { containerWidth };
};
