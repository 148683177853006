import { contrastColor } from "@shared/lib/colors";
import "./FormReadValue.scss";
import { CSSProperties } from "react";
import { Tooltip } from "antd";
import { useDebounceTooltipOpenEffect } from "@shared/hooks/DebounceTooltipOpenEffect";

interface IFormReadValue {
    value: any;
    backgroundColor?: string;
    customClassName?: string;
    readValueTextStyle?: CSSProperties;
    isReduceValue?: boolean;
    onClick?: (e: any) => void;
}

const FormReadValue = ({
    value,
    customClassName,
    readValueTextStyle,
    backgroundColor,
    isReduceValue,
    onClick,
}: IFormReadValue) => {
    const maxLengthChars = 255;
    const maxTooltipLengthChars = 500;
    const { tooltipInnerStyle } = useDebounceTooltipOpenEffect(
        value?.length,
        maxLengthChars,
        isReduceValue
    );

    if (isReduceValue && value?.length > maxLengthChars) {
        return (
            <Tooltip
                color="white"
                zIndex={900}
                overlayInnerStyle={tooltipInnerStyle}
                title={
                    value?.length > maxTooltipLengthChars
                        ? value.slice(0, maxTooltipLengthChars) + "..."
                        : value
                }
                trigger="click"
            >
                <div
                    onClick={onClick}
                    className={`reduced-text-value ${onClick ? "link" : ""}`}
                >
                    {value.slice(0, maxLengthChars)}...
                </div>
            </Tooltip>
        );
    }

    return (
        <div
            onClick={onClick}
            className={`${readValueTextStyle ? "" : "form-read-value"} ${customClassName ?? ""} ${onClick ? "link" : ""}`}
            style={{
                ...(backgroundColor
                    ? {
                          backgroundColor: backgroundColor,
                          padding: "3px 7px",
                          borderRadius: 9,
                          color: contrastColor(backgroundColor),
                      }
                    : {}),
                ...(readValueTextStyle ?? {}),
            }}
        >
            {value ?? "-"}
        </div>
    );
};

export default FormReadValue;
