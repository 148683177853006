import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Tooltip } from "antd";
import { useEditQueryActionHeaders } from "../model";
import { ICommonPropsEditQueryActionInterface } from "../model/types";
import EditQueryActionParamsOrHeadersItem from "./EditQueryActionParamsOrHeadersItem";

interface IEditQueryActionHeaders extends ICommonPropsEditQueryActionInterface {
    headers: Record<string, string>[];
}

const EditQueryActionHeaders = ({
    onChange,
    headers,
    itemId,
}: IEditQueryActionHeaders) => {
    const {
        isDisabledAddButton,
        onAdd,
        headersKeyValue,
        onChangeQueryParamsKeyValue,
        onDelete,
    } = useEditQueryActionHeaders(headers, onChange);

    return (
        <FormItem
            label={
                <Flex gap={4}>
                    <Button
                        icon={<PlusOutlined />}
                        type="link"
                        disabled={isDisabledAddButton}
                        onClick={onAdd}
                        style={{
                            minHeight: 0,
                            height: "fit-content",
                            padding: 0,
                            position: "sticky",
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        Добавить заголовок
                    </Button>
                    <Tooltip
                        color="white"
                        overlayInnerStyle={{ color: "#838383" }}
                        title={"Некорректные заголовки при сохранении будут удалены"}
                    >
                        <InfoCircleOutlined style={{ color: "var(--warning)" }} />
                    </Tooltip>
                </Flex>
            }
        >
            <Flex
                gap={8}
                vertical
                style={{
                    padding: 8,
                    maxHeight: 300,
                    overflow: "auto",
                    scrollbarGutter: "stable both-edges",
                }}
            >
                {headersKeyValue.map(({ key, value }, index, { length }) => (
                    <EditQueryActionParamsOrHeadersItem
                        key={length - 1 - index}
                        index={index}
                        onChangeQueryParamsKeyValue={onChangeQueryParamsKeyValue}
                        itemId={itemId}
                        onDelete={onDelete}
                        paramKey={key}
                        paramValue={value}
                        keyPlaceholder="Заголовок"
                        ValuePlaceholder="Значение"
                    />
                ))}
            </Flex>
        </FormItem>
    );
};

export default EditQueryActionHeaders;
