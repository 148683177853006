import { EntitySelect } from "@entities/Portal/Entity";
import { TechMapTmlResultTypeSelect } from "@entities/Production/TechMapTemplate";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { Input } from "antd";
import { useTechMapTemplateResultItem } from "../model";
import "./TechMapTemplateResultItem.scss";
import TMTResultItemEntityRow from "./TMTResultItemEntityRow";
interface ITechMapTemplateResultItem {
    allowMultiple?: boolean;

    item: TTechMapTemplateResult | TTechMapTemplateResultCreating;
    onChange: (item: TTechMapTemplateResult | TTechMapTemplateResultCreating) => void;
}

const TechMapTemplateResultItem = ({
    allowMultiple,
    item,
    onChange,
}: ITechMapTemplateResultItem) => {
    const { onChangeQuantity, onChangeType, onChangeEntityRowId, onChangeEntityUuid } =
        useTechMapTemplateResultItem(item, onChange);
    // type: TTechMapTemplateResultType;
    // entity_row_id: number;
    return (
        <div className="tmt-result-item">
            <IndentContainer>
                <FormItem label={<>Тип</>}>
                    <TechMapTmlResultTypeSelect
                        value={item.type}
                        onChange={onChangeType}
                    />
                </FormItem>
            </IndentContainer>
            <IndentContainer>
                <FormItem label={<>Компонент</>}>
                    <EntitySelect
                        value={item.entity_uuid}
                        onChangeSelect={onChangeEntityUuid}
                    />
                </FormItem>
            </IndentContainer>

            {item.type === "increment" ? (
                <>
                    <IndentContainer>
                        <FormItem label={<>Запись</>}>
                            {item.entity_uuid ? (
                                <TMTResultItemEntityRow
                                    entityUuid={item.entity_uuid}
                                    entityRowId={item.entity_row_id}
                                    onChangeEntityRowId={onChangeEntityRowId}
                                />
                            ) : (
                                <>-</>
                            )}
                        </FormItem>
                    </IndentContainer>
                    <IndentContainer>
                        <FormItem label={<>Количество выпускаемых единиц за раз</>}>
                            <Input value={item.quantity} onChange={onChangeQuantity} />
                        </FormItem>
                    </IndentContainer>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default TechMapTemplateResultItem;
