import { Select } from "antd";
import { options } from "../constants";

interface ISelectReleaseItemsGroups {
    groups: string[];
    setGroups: (groups: string[]) => void;
}

const SelectReleaseItemsGroups = ({ groups, setGroups }: ISelectReleaseItemsGroups) => {
    const onChange = (value: string[]) => {
        setGroups(value);
    };

    return (
        <Select
            style={{ width: "100%", maxWidth: 300 }}
            options={options}
            mode="multiple"
            value={groups}
            onChange={onChange}
            placeholder="Группировка"
        />
    );
};

export default SelectReleaseItemsGroups;
