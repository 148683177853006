import { Image } from "antd";
import { MouseEvent } from "react";

interface IFileUploadModal {
    setPreviewImage: (item: string) => void;
    previewOpen: boolean;
    setPreviewOpen: (item: boolean) => void;
    handleCancel: () => void;
    previewImage: string;
    previewTitle: string;
}

const FileUploadModal = ({
    setPreviewImage,
    previewOpen,
    setPreviewOpen,
    handleCancel,
    previewImage,
    previewTitle,
}: IFileUploadModal) => {
    const preventEvent = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div onClick={(e) => {}}>
            <Image
                wrapperStyle={{ display: "none", pointerEvents: "none" }}
                src={previewImage}
                preview={{
                    visible: previewOpen,
                    destroyOnClose: true,
                    toolbarRender: (original) => (
                        <div
                            onClick={preventEvent}
                            style={{ background: "rgb(50,50,50,1)", borderRadius: 100 }}
                            onDoubleClick={preventEvent}
                        >
                            {original}
                        </div>
                    ),
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
            />
        </div>
    );
};

export default FileUploadModal;
