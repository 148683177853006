import { releaseItemApi } from "@entities/Production/ReleaseItem";
import { useEffect, useState } from "react";

export const useReleaseItemsTable = (
    level: number,
    groups?: string[],
    additionalFilters?: any
) => {
    const [releaseItems, setReleaseItems] = useState<TReleaseItem[]>([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const currentGroup = groups?.[level];

    const loadReleaseItems = (page: number, perPage: number) => {
        setLoading(true);
        releaseItemApi
            .getReleaseItems(page, perPage, currentGroup, additionalFilters)
            .then((response) => {
                setReleaseItems(response.data.data.data);
                setLastPage(response.data.data.last_page);
                setPage(response.data.data.current_page);
                console.log("response", response.data.data);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setReleaseItems([]);
        loadReleaseItems(1, perPage);
    }, [currentGroup, additionalFilters]);

    const onShowSizeChange = (current: number, size: number) => {
        setPage(current);
        setPerPage(size);
        loadReleaseItems(current, size);
    };

    const onSelectRows = (selectedRows: any[]) => {
        console.log("selectedRows", selectedRows);
    };

    return {
        loading,
        page,
        perPage,
        lastPage,
        currentGroup,
        releaseItems,
        setReleaseItems,
        onShowSizeChange,
        onSelectRows,
    };
};
