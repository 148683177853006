import { Button, Popconfirm } from "antd";

export const getPublishColumn = (
    onPublish: (ids: number[]) => void,
    publishIds: number[]
) => {
    return {
        title: "",
        dataIndex: "id",
        render: (value: number, record: any) => {
            if (record.is_sent || record.posted) return <></>;
            return (
                <div onClick={(e) => e.stopPropagation()}>
                    <Button
                        loading={publishIds.includes(value)}
                        onClick={() => onPublish([value])}
                        type="link"
                    >
                        Отправить в 1С
                    </Button>
                </div>
            );
        },
    };
};
