import { useRef, useState } from "react";

export const useTimer = (initialSeconds: number) => {
    const [isActive, setIsActive] = useState(false);
    const [time, setTime] = useState(initialSeconds);
    const timerRef = useRef<ReturnType<typeof setInterval> | null>();
    const startTimer = () => {
        if (!isActive) {
            setIsActive(true);
            if (timerRef.current) clearInterval(timerRef.current);
            timerRef.current = setInterval(() => {
                setTime((prev) => prev + 1);
            }, 1000);
        }
    };
    const pauseTimer = () => {
        if (timerRef.current) {
            setIsActive(false);
            clearInterval(timerRef.current);
        }
    };
    const setTimeValue = (value: number) => {
        setTime(value);
    };
    return {
        time,
        isActive,
        setTimeValue,
        pauseTimer,
        startTimer,
    };
};
