import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Select } from "antd";
import { useFieldSelect } from "./useFieldSelect";

interface IFieldSelect {
    fields: TBizProcTemplateField[];
    onClickCreateVariable?: () => void;
    onChangeFields: (item: TBizProcTemplateField[]) => void;
    selectedFields: any[];
    disabled?: boolean;
    placeholder?: string;
    selectOnly?: boolean;
    allowClear?: boolean;
    maxTagCount?: number;
}

const FieldSelect = ({
    placeholder,
    fields,
    onClickCreateVariable,
    onChangeFields,
    selectedFields,
    disabled,
    selectOnly,
    allowClear,
    maxTagCount,
}: IFieldSelect) => {
    const { onButtonClick, fieldOptions, selectRef, onChange, onBlur, onFocus } =
        useFieldSelect(fields, onChangeFields);

    const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        onClickCreateVariable && onClickCreateVariable();
    };

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <Select
                maxTagCount={maxTagCount}
                allowClear={allowClear}
                placeholder={placeholder}
                disabled={disabled}
                onBlur={onBlur}
                onFocus={onFocus}
                ref={selectRef}
                onChange={onChange}
                style={{ width: "100%", marginRight: selectOnly ? undefined : 10 }}
                mode="multiple"
                filterOption={(input: any, option) => {
                    return option?.label?.includes(input) === true;
                }}
                value={selectedFields.map(
                    (field) => `${field.variable_type}-${field.key}`
                )}
                options={fieldOptions}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        {onClickCreateVariable && (
                            <>
                                <Divider style={{ margin: 0 }} />

                                <Button
                                    disabled={disabled}
                                    type="text"
                                    style={{ height: 60, width: "100%" }}
                                    icon={<PlusOutlined />}
                                    onClick={onClick}
                                >
                                    Создать переменную
                                </Button>
                            </>
                        )}
                    </>
                )}
            />
            {!selectOnly ? (
                <Button disabled={disabled} onClick={onButtonClick}>
                    Добавить поле
                </Button>
            ) : undefined}
        </div>
    );
};

export default FieldSelect;
