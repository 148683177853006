import { useEffect } from "react";

export const useBizProcsPage = () => {
    const keydownHandler = (event: KeyboardEvent) => {
        const filterFields = document.querySelector(".filter-fields");
        const input = (filterFields?.querySelector(".ant-input") ??
            filterFields?.querySelector(
                ".ant-select-selection-search-input"
            )) as HTMLInputElement;
        if (event.ctrlKey && event.code === "KeyF") {
            event.preventDefault();
            //@ts-ignore
            input?.focus();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", keydownHandler);
        return () => {
            document.removeEventListener("keydown", keydownHandler);
        };
    }, []);
    return {
        keydownHandler,
    };
};
