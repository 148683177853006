import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { reorderStages } from "../lib";
import { stageBuilder } from "./StageBuilder";

export interface IStage {
    stages: TStageWithCategory;
    allStagesFlatArray: TStage[];
    gettingAllStagesLoading: boolean;
    getLoading: number[];
    updateLoading: number[];
    createLoading: number[];
    deleteLoading: number[];
}

const initialState: IStage = {
    gettingAllStagesLoading: false,
    stages: {},
    allStagesFlatArray: [],
    getLoading: [],
    updateLoading: [],
    createLoading: [],
    deleteLoading: [],
};

export const stageSlice = createSlice({
    name: "StageSlice",
    initialState,
    reducers: {
        setStages: (state, action: PayloadAction<TStageWithCategory>) => {
            state.stages = action.payload;
        },
        setCategoryStages: (
            state,
            action: PayloadAction<{
                categoryId: number;
                stages: TStage[];
            }>
        ) => {
            state.stages[action.payload.categoryId] = reorderStages(
                action.payload.stages
            );
        },
    },
    extraReducers: (builder) => {
        stageBuilder(builder);
    },
});

export const { setStages, setCategoryStages } = stageSlice.actions;
export default stageSlice.reducer;
