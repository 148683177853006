import { Select } from "antd";
import { useSelector } from "react-redux";
import { selectTechMapTemplate } from "../model";
import "./TMTResultSelect.scss";
import TMTResultSelectOption from "./TMTResultSelectOption";
interface ITMTResultSelect {
    onlyRead?: boolean;
    value: number | null;
    onChange: (value: number) => void;
}

const TMTResultSelect = ({ onlyRead, value, onChange }: ITMTResultSelect) => {
    const tmt = useSelector(selectTechMapTemplate);
    const results = tmt?.results ?? [];
    const options = (
        results.filter((result) => {
            return "id" in result && result.entity_uuid;
        }) as TTechMapTemplateResult[]
    ).map((result) => {
        const entityUuid = result.entity_uuid;
        const entityRowId = result.entity_row_id;

        return {
            label: (
                <TMTResultSelectOption
                    onlyDefaultReadRender
                    entityRowId={entityRowId}
                    entityUuid={entityUuid}
                />
            ),
            value: result.id,
            item: result,
        };
    });

    const onChangeValue = (
        value: number,
        option: any | TOptionWithItem<TTechMapTemplateResult>
    ) => {
        onChange(value);
    };
    return (
        <Select
            style={{ width: "100%" }}
            disabled={onlyRead}
            onChange={onChangeValue}
            value={value}
            optionRender={(oriOption) => {
                const item = oriOption.data.item;
                const entityUuid = item.entity_uuid;
                const entityRowId = item.entity_row_id;
                return (
                    <div className="tmtr-select-item">
                        <TMTResultSelectOption
                            onlyDefaultReadRender
                            entityRowId={entityRowId}
                            entityUuid={entityUuid}
                        />
                        {/* <Button
                            size="small"
                            type="link"
                            className="tmtr-select-item__add"
                        >
                            Выбрать
                        </Button> */}
                    </div>
                );
            }}
            options={options}
        />
    );
};

export default TMTResultSelect;
