import { stageModel } from "@entities/Portal/Stage";
import { useDispatch } from "react-redux";

export const useStagesList = (entityId: number, categoryId: number) => {
    const { loading, stages } = stageModel.useStagesLoad(entityId, categoryId);

    const dispatch = useDispatch<AppDispatch>();
    const startStage = stages.find((stage) => stage.prev_stage_uuid === null);
    const additionalStages = stages.filter(
        (stage) => stage.prev_stage_uuid !== null && stage.type === "in_progress"
    );

    const successfulStages = stages.filter(
        (stage) => stage.prev_stage_uuid !== null && stage.type === "successful"
    );
    const unsuccessfulStages = stages.filter(
        (stage) => stage.prev_stage_uuid !== null && stage.type === "unsuccessful"
    );
    const onChangeItems = (items: TStage[], moveIndex: number, targetIndex: number) => {
        // const newItemIndex = items.findIndex(item=> item.id === items[targetIndex].id);
        let prevStage = stages[0];
        dispatch(
            stageModel.setCategoryStages({
                categoryId,
                stages: [
                    startStage!,
                    ...items.map((stage) => {
                        const newStage = {
                            ...stage,
                            prev_stage_uuid: prevStage.uuid,
                        } as TStage;
                        prevStage = stage;
                        return newStage;
                    }),
                    ...stages.filter(
                        (stage) =>
                            stage.prev_stage_uuid !== null && stage.type !== "in_progress"
                    ),
                ],
            })
        );
        dispatch(
            stageModel.updateStagePrevStageUuidThunk({
                stage: {
                    ...items[targetIndex],
                    prev_stage_uuid:
                        targetIndex > 0
                            ? items[targetIndex - 1].uuid
                            : startStage?.uuid ?? null,
                },
                entityId,
                categoryId,
            })
        );
    };
    return {
        loading,
        stages,
        startStage,
        additionalStages,
        successfulStages,
        unsuccessfulStages,
        onChangeItems,
    };
};
