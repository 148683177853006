import {
    TechMapTemplateListItem,
    TechMapTemplateListItemSkeleton,
} from "@entities/Production/TechMapTemplate";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { ITechMapTemplatesFilters } from "@widgets/Production/TechMapTemplatesFilters/TechMapTemplatesFilters";
import { Flex, Spin } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTechMapTemplateList } from "../model";
import TechMapTemplateCreate from "./TechMapTemplateCreate";

interface ITechMapTemplateList {
    filtersWidget: (props: ITechMapTemplatesFilters) => React.ReactNode;
}

//TODO: переделать фильтры, передавать запрос для шаблонов пропсом
//Все состояния хранить внутри фильтров

const TechMapTemplateList = ({ filtersWidget }: ITechMapTemplateList) => {
    const {
        onChange,
        resetFilters,
        submitFilters,
        filters,
        onDelete,
        techMapTemplates,
        loading,
        loadTechMapTemplatesWithPagination,
        isPaginationLoading,
        paginationIsEnded,
    } = useTechMapTemplateList();
    const [isScrollToBottom, setIsScrollToBottom] = useState<boolean>(false);
    const containerOfTMT = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isPaginationLoading && isScrollToBottom) {
            if (containerOfTMT.current)
                containerOfTMT.current!.scrollTop = containerOfTMT.current!.scrollHeight;
            setIsScrollToBottom(false);
        }
    }, [isPaginationLoading]);

    return (
        <Flex vertical style={{ height: "100%", overflow: "hidden" }}>
            <IndentContainer>
                <TechMapTemplateCreate />
            </IndentContainer>
            {filtersWidget({
                onChange,
                resetFilters,
                submitFilters,
                filters,
            })}
            {loading ? (
                <TechMapTemplateListItemSkeleton count={4} />
            ) : (
                <div
                    style={{ flex: "1 1 100%", overflow: "auto" }}
                    ref={containerOfTMT}
                    onScroll={(event) => {
                        setIsScrollToBottom(true);
                        //@ts-ignore
                        const { scrollHeight, scrollTop, clientHeight } =
                            event.nativeEvent.target;
                        const isBottomReached =
                            scrollHeight - Math.round(scrollTop) === clientHeight;
                        if (isBottomReached && !paginationIsEnded) {
                            loadTechMapTemplatesWithPagination(
                                techMapTemplates[techMapTemplates.length - 1].id
                            );
                        }
                    }}
                >
                    {techMapTemplates.map((template) => {
                        return (
                            <Fragment key={template.id}>
                                <TechMapTemplateListItem
                                    onSubmitDelete={onDelete}
                                    techMapTemplate={template}
                                />
                            </Fragment>
                        );
                    })}
                    {isPaginationLoading && (
                        <div style={{ width: 800 }} className="column-spinner">
                            <Spin />
                        </div>
                    )}
                </div>
            )}
        </Flex>
    );
};

export default TechMapTemplateList;
