import {
    EXTERNAL_LINK_VALIDATION_REGEXP,
    PORTAL_URL_VALIDATION_REGEXP,
    VALID_EXTERNAL_LINK_WITH_VARIABLES,
} from "@shared/constants";

export const checkIsValidExternalUrl = (url: string) => {
    return EXTERNAL_LINK_VALIDATION_REGEXP.test(url);
};
export const checkIsValidExternalUrlWithVariables = (url: string) => {
    return VALID_EXTERNAL_LINK_WITH_VARIABLES.test(url);
};
export const checkIsValidPortalUrl = (url: string) => {
    return PORTAL_URL_VALIDATION_REGEXP.test(url);
};
