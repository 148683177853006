import { techMapModel } from "@entities/Production/TechMap";
import { Button } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TMTableApproveModal from "./TMTableApproveModal";

interface ITMTableApprove {
    techMap: TTechMapStarted;
    levelKey?: string | number;
}

const TMTableApprove = ({ techMap, levelKey }: ITMTableApprove) => {
    const [isOpen, setIsOpen] = useState(false);

    const approveLoading = useSelector((state: RootState) =>
        techMapModel.selectIsApproveLoading(state, techMap.id)
    );
    const rejectLoading = useSelector((state: RootState) =>
        techMapModel.selectIsRejectLoading(state, techMap.id)
    );
    const dispatch = useDispatch<AppDispatch>();

    const onApprove = () => {
        setIsOpen(true);
        // dispatch(
        //     techMapModel.approveTmThunk({
        //         tmtId: techMap.id,
        //         decision: "approve",
        //         level: levelKey,
        //     })
        // );
    };

    const onCancel = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Button
                loading={approveLoading}
                disabled={rejectLoading}
                style={{ margin: 0, padding: 0, minHeight: 10 }}
                type="link"
                size="small"
                onClick={onApprove}
            >
                Согласовать
            </Button>
            <TMTableApproveModal
                techMap={techMap}
                isOpen={isOpen}
                levelKey={levelKey}
                onCancel={onCancel}
            />
        </>
    );
};

export default TMTableApprove;
