import {
    getNewChangedUserRoles,
    getSortedUserRolesByPriorityWithOldPriorityValue,
} from "./lib";
import { capabilitiesRolesModel } from "@entities/Portal/CapabilitiesRoles";
import { setUserRoles } from "@entities/Portal/CapabilitiesRoles/model";
import { userModel } from "@entities/Portal/User";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { useDebounceEffect } from "@shared/hooks";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useUserRoles = (
    userRolesEntityId: number | undefined,
    setRole: (item: TUserRole) => void
) => {
    const user = useSelector(userModel.selectUser);
    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
    const dispatch = useDispatch<AppDispatch>();
    capabilitiesRolesModel.useUserRolesWS(user, userRolesEntityId);
    const { userRoles, loading, error } =
        capabilitiesRolesModel.useLoadUserRoles(userRolesEntityId);
    const sortedUserRolesByPriorityWithOldPriorityValue =
        getSortedUserRolesByPriorityWithOldPriorityValue(userRoles);
    const [changedUserRoles, setChangedUserRoles] = useState<TUserRole[]>([]);
    const [copyOfUserRoles, setCopyOfUserRoles] = useState<TUserRole[]>([...userRoles]);
    const userRolesListRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    useEffect(() => {
        if (error) {
            notificationEmit({ type: "error", title: "Ошибка загрузки ролей" });
        }
    }, [error]);

    useEffect(() => {
        if (isOpenSidebar) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpenSidebar]);

    const onDelete = (item: TUserRole) => {
        userRolesEntityId &&
            dispatch(
                capabilitiesRolesModel.deleteUserRoleThunk({
                    entityId: userRolesEntityId,
                    entityRowId: item.id,
                })
            )
                .then(parseFormResponse)
                .then(() => {
                    notificationEmit({
                        type: "success",
                        title: "Успешно",
                        description: `Роль ${item.name} успешно удалена`,
                    });
                })
                .catch((e: any) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: `Не удалось удалить роль ${item.name}`,
                    });
                });
    };

    const onClickUserRole = (item: TUserRole) => {
        localStorage.setItem("capabilities_editor_role_id", String(item.id));
        setIsOpenSidebar(false);
        setRole(item);
    };
    const closeSideBar = () => setIsOpenSidebar(false);
    const handleClickOutside = (event: any) => {
        if (
            userRolesListRef.current &&
            !userRolesListRef.current.contains(event.target) &&
            !document.querySelector(".ant-drawer-mask")?.contains(event.target) &&
            !document.querySelector(".ant-drawer-wrapper-body")?.contains(event.target)
        ) {
            closeSideBar();
        }
    };

    const onDragEndUserRoleFunction = (
        items: TUserRole[],
        moveIndex: number,
        targetIndex: number
    ) => {
        let newUserRoleWithUpdatedPriority: TUserRole | undefined;
        if (moveIndex > targetIndex) {
            newUserRoleWithUpdatedPriority = {
                ...items[targetIndex],
                priority: items[targetIndex + 1].priority + 1,
            };
        } else if (moveIndex < targetIndex) {
            newUserRoleWithUpdatedPriority = {
                ...items[targetIndex],
                priority: items[targetIndex - 1].priority - 1,
            };
        } else {
            return;
        }
        setCopyOfUserRoles(items);
        const newItems = items.map((item) =>
            item.id === newUserRoleWithUpdatedPriority?.id
                ? newUserRoleWithUpdatedPriority
                : item
        );
        dispatch(capabilitiesRolesModel.setUserRoles(newItems));

        const newChangedUserRoles = getNewChangedUserRoles(
            changedUserRoles,
            newUserRoleWithUpdatedPriority
        );
        setChangedUserRoles(newChangedUserRoles);
    };

    useDebounceEffect(
        () => {
            if (changedUserRoles.length > 0 && userRolesEntityId && !isDragging) {
                updateUserRoles(userRolesEntityId, changedUserRoles);
            }
        },
        () => {},
        [changedUserRoles, isDragging],
        1000
    );
    const updateUserRoles = (
        userRolesEntityId: number,
        newUserRolesWithUpdatedPriority: TUserRole[]
    ) => {
        dispatch(
            capabilitiesRolesModel.updateUserRolesThunk({
                entityId: userRolesEntityId,
                newUserRoles: newUserRolesWithUpdatedPriority,
            })
        )
            .then(parseFormResponse)
            .catch((e: any) => {
                dispatch(setUserRoles(copyOfUserRoles));
            })
            .finally(() => setChangedUserRoles([]));
    };

    return {
        setIsDragging,
        userRolesListRef,
        user,
        error,
        isOpenSidebar,
        setIsOpenSidebar,
        userRoles: sortedUserRolesByPriorityWithOldPriorityValue,
        loading,
        onDelete,
        onClickUserRole,
        onDragEndUserRoleFunction,
    };
};
