import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import EditTemplateBar from "../EditTemplateBar";

interface IQueryActionSuccessBranch {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}
const QueryActionSuccessBranch = ({
    onDeleteItem,
    templateItem,
}: IQueryActionSuccessBranch) => {
    return (
        <CardWithHead
            backgroundColor="var(--success)"
            noColor
            title={
                <EditTemplateBar
                    textColor="white"
                    title={templateItem?.name ?? "Успешно"}
                />
            }
        />
    );
};

export default QueryActionSuccessBranch;
