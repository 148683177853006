import { TMTResultSelectOption } from "@entities/Production/TechMapTemplate";

interface ITMTOCodesReadableItem {
    code: TMTOCodeTemplateItem;
    result?: TTechMapTemplateResult | TTechMapTemplateResultCreating;
}

const TMTOCodesReadableItem = ({ code, result }: ITMTOCodesReadableItem) => {
    if (!code.technical_map_template_result_id) {
        return <>Не указан</>;
    }
    if (!result) return <>Не найден</>;
    return (
        <TMTResultSelectOption
            entityUuid={result.entity_uuid}
            entityRowId={result.entity_row_id}
        />
    );
};

export default TMTOCodesReadableItem;
