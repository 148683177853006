import { Bar } from "@shared/ui/Bar";
import { useStartedTMBar } from "../model";

interface IStartedTMBar {}

const StartedTMBar = ({}: IStartedTMBar) => {
    const { options, loading, activeItemsIds, loadingItemsIds, onClickItem } =
        useStartedTMBar();

    return (
        <Bar
            label="Запущенные процессы"
            onClick={onClickItem}
            loading={loading}
            activeValues={activeItemsIds}
            loadingValues={loadingItemsIds}
            options={options}
        />
    );
};

export default StartedTMBar;
