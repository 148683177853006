import { userModel } from "@entities/Portal/User";
import { techMapConstant, techMapModel } from "@entities/Production/TechMap";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPreparedFilters } from "../lib";
import { openDrawer } from "@shared/ui/DrawerManager";

export const useTMTable = (
    level: number,
    parentKey?: string | number,
    additionalFilters?: TTechMapFilters
) => {
    const groupLevels = useSelector(techMapModel.selectGroupLevels);
    const filters = useSelector(techMapModel.selectTechMapFilters);
    const levelKey =
        groupLevels.length > 0 ? `${parentKey ?? ""}${groupLevels[level] ?? ""}` : 0;
    const dispatch = useDispatch<AppDispatch>();
    const userId = useSelector(userModel.selectUserId);
    const isAdmin = useSelector(userModel.selectUserIsAdmin);
    const { defferedCallback } = useDefferedCallback(800);

    const currentPage =
        useSelector((state: RootState) =>
            techMapModel.selectTmsCurrentPage(state, levelKey)
        ) ?? 1;
    const lastPage =
        useSelector((state: RootState) =>
            techMapModel.selectTmsLastPage(state, levelKey)
        ) ?? 1;
    const perPage =
        useSelector((state: RootState) =>
            techMapModel.selectTmsPerPage(state, levelKey)
        ) ?? 50;
    const techMaps =
        useSelector((state: RootState) =>
            techMapModel.selectTmsPaginated(state, levelKey)
        ) ?? [];
    const loading =
        useSelector((state: RootState) =>
            techMapModel.selectTmsLoading(state, levelKey)
        ) ?? false;
    const isGrouped = groupLevels?.[level] !== undefined;

    const canReviewSome = useMemo(() => {
        if (isGrouped) return false;

        return (techMaps as TTechMapStarted[]).some(
            (tm) =>
                tm.status === "waiting" &&
                (isAdmin ||
                    tm?.template.reviewer_roles.find((user) => user.id === userId))
        );
    }, [techMaps, isGrouped]);

    const initLevelTable = () => {
        dispatch(
            techMapModel.setTechMapsPaginated({
                level: levelKey,
                TMsPaginated: {
                    lastPage: 1,
                    currentPage: 1,
                    perPage: 50,
                    loading: loading,
                    techMaps: [],
                },
            })
        );
    };
    useEffect(() => {
        initLevelTable();
        return initLevelTable;
    }, []);

    useEffect(() => {
        dispatch(
            techMapModel.setTechMapsPaginatedLoadingState({
                level: levelKey,
                state: true,
            })
        );

        const preparedFilters = getPreparedFilters(filters, additionalFilters);

        defferedCallback(() =>
            dispatch(techMapModel.loadTechMapsPaginated(level, levelKey, preparedFilters))
        );
    }, [currentPage, perPage, levelKey, filters]);

    const onShowSizeChange = (current: number, size: number) => {
        dispatch(
            techMapModel.updateTechMapsPaginated({
                level: levelKey,
                TMsPaginated: {
                    currentPage: size !== perPage ? 1 : current,
                    perPage: size,
                },
            })
        );
    };

    const onClickRow = (tm: TTechMapStarted) => {
        openDrawer({
            id: tm.id,
            name: "",
            pattern: techMapConstant.LOCATION_TM_PATTERN,
        });
    };
    return {
        isGrouped,
        groupLevel: groupLevels?.[level],
        currentPage,
        canReviewSome,
        lastPage,
        levelKey,
        perPage,
        loading,
        techMaps,
        onShowSizeChange,
        onClickRow,
    };
};
