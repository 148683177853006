import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { getTechMaps } from "../../api";
import {
    selectGroupLevels,
    selectTmsCurrentPage,
    selectTmsPerPage,
} from "../TechMapSelectors";
import {
    setTechMapsPaginatedLoadingState,
    updateTechMapsPaginated,
} from "../TechMapSlice";

export const loadTechMapsPaginated =
    (
        level: number,
        levelKey: number | string,
        filters?: TSingleCondition | TMultipleCondition
    ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(
            setTechMapsPaginatedLoadingState({
                level: levelKey,
                state: true,
            })
        );
        try {
            const currentPage = selectTmsCurrentPage(getState(), levelKey) ?? 1;
            const perPage = selectTmsPerPage(getState(), levelKey) ?? 50;
            const groupLevels = selectGroupLevels(getState());

            const response = await getTechMaps({
                per_page: perPage,
                page: currentPage,
                group_by: groupLevels?.[level],
                filter: filters,
            });
            setTimeout(() => {
                dispatch(
                    updateTechMapsPaginated({
                        level: levelKey,
                        TMsPaginated: {
                            lastPage: response.data.data.last_page,
                            techMaps: response.data.data.data,
                        },
                    })
                );
            });
        } catch (err) {
            notificationEmit({
                type: "error",
                title: "Не удалось загрузить информацию",
            });
        }
        dispatch(
            setTechMapsPaginatedLoadingState({
                level: levelKey,
                state: false,
            })
        );
    };
