import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDragAndDropList } from "./model";
import { ReactNode } from "react";
import { getItemStyle, getListStyle } from "./lib";
import { CSSProperties } from "styled-components";

interface IDragAndDropList<T> {
    items: T[];
    keyName: keyof T;
    direction?: "horizontal" | "vertical";
    disabled?: boolean;
    customDndListStyle?: CSSProperties;
    className?: string;
    onChangeItems: (items: T[], moveIndex: number, targetIndex: number) => void;
    draggableItem: (
        dragHandleProps: any,
        item: T,
        index: number,
        isDragging?: boolean,
        currentIndex?: number | null,
        sourceIndex?: number | null
    ) => ReactNode;
}

const DragAndDropList = <T extends object>({
    items,
    keyName,
    disabled,
    customDndListStyle,
    direction,
    className,
    draggableItem,
    onChangeItems,
}: IDragAndDropList<T>) => {
    const { currentIndex, sourceIndex, onDragEnd, defineLocationPropsOfDraggingItem } =
        useDragAndDropList({
            onChangeItems,
            items,
        });
    return (
        <DragDropContext
            onDragEnd={(endData) => {
                defineLocationPropsOfDraggingItem(endData);
                onDragEnd(endData);
            }}
            onDragUpdate={(update) => defineLocationPropsOfDraggingItem(update)}
            onDragStart={(startData) =>
                defineLocationPropsOfDraggingItem({
                    ...startData,
                    destination: { index: startData.source.index },
                })
            }
        >
            <Droppable droppableId="droppable" direction={direction}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={className}
                        style={
                            customDndListStyle ?? getListStyle(snapshot.isDraggingOver)
                        }
                    >
                        {items.map((item, index) => {
                            return (
                                <Draggable
                                    isDragDisabled={disabled}
                                    key={item[keyName]?.toString() as string}
                                    draggableId={item[keyName]?.toString() as string}
                                    index={index}
                                >
                                    {(provided, snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                {draggableItem(
                                                    provided.dragHandleProps,
                                                    item,
                                                    index,
                                                    snapshot.isDragging,
                                                    currentIndex,
                                                    sourceIndex
                                                )}
                                            </div>
                                        );
                                    }}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDropList;
