import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import {
    createTechMapTemplateOperation,
    getTechMapTemplateOperation,
    getTechMapTemplateOperations,
    updateMassTechMapTemplateOperations,
} from "../../api/api-tmt-operation";
import { setTechMapTmplOperations } from "../TechMapTemplateSlice";
import { selectOperations } from "./TechMapTemplateOperationSelectors";

export const loadTechMapTemplateOperationsThunk = createAsyncThunk(
    "TechMapTemplateOperationSlice/loadTechMapTemplateOperationsThunk",
    async (
        props: { techMapTemplateId: number; params?: TParams },
        { rejectWithValue }
    ) => {
        try {
            const response = await getTechMapTemplateOperations(
                props.techMapTemplateId,
                props.params
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadTechMapTemplateOperationThunk = createAsyncThunk(
    "TechMapTemplateOperationSlice/loadTechMapTemplateOperationThunk",
    async (
        { techMapTemplateOperationId }: { techMapTemplateOperationId: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await getTechMapTemplateOperation(
                techMapTemplateOperationId
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

// export const updateTechMapTemplateOperationThunk = createAsyncThunk(
//     "TechMapTemplateOperationSlice/updateTechMapTemplateOperationThunk",
//     async (
//         props: { techMapTemplateOperation: TTechMapTemplateOperation },
//         { rejectWithValue }
//     ) => {
//         try {
//             const response = await updateTechMapTemplateOperation(
//                 props.techMapTemplateOperation
//             );
//             return response.data;
//         } catch (error: any) {
//             return rejectWithValue({
//                 data: error?.response?.data,
//                 status: error?.response?.status,
//             });
//         }
//     }
// );
export const createTechMapTemplateOperationThunk = createAsyncThunk(
    "TechMapTemplateOperationSlice/createTechMapTemplateOperationThunk",
    async (
        props: {
            techMapTemplateId: number;
            techMapTemplateOperation: TTechMapTemplateOperationCreating;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await createTechMapTemplateOperation(
                props.techMapTemplateId,
                props.techMapTemplateOperation
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

// export const deleteTechMapTemplateOperationThunk = createAsyncThunk(
//     "TechMapTemplateOperationSlice/deleteTechMapTemplateOperationThunk",
//     async (props: { id: number }, { rejectWithValue }) => {
//         try {
//             const response = await deleteTechMapTemplateOperation(props.id);
//             return response.data;
//         } catch (error: any) {
//             return rejectWithValue({
//                 data: error?.response?.data,
//                 status: error?.response?.status,
//             });
//         }
//     }
// );

export const updateOperation =
    (
        newOperations: (TTechMapTemplateOperation | TTechMapTemplateOperationCreating)[],
        tmtId: number
    ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const response = await updateMassTechMapTemplateOperations(
                tmtId,
                newOperations
            );

            dispatch(setTechMapTmplOperations(response.data.data));
        } catch (e) {
            console.error(e);
            notificationEmit({
                type: "error",
                title: "Не удалось обновить операции",
            });
            throw e;
        }
    };
export const deleteTechMapTemplateOperationThunk =
    (
        deletedOperation: TTechMapTemplateOperation | TTechMapTemplateOperationCreating,
        tmtId: number
    ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const operations = selectOperations(getState());
        const newOperations = operations
            .filter((operation) => operation.uuid !== deletedOperation.uuid)
            .map((operation, index, fullArr) => {
                return {
                    ...operation,
                    parent_uuid: index > 0 ? fullArr?.[index - 1].uuid : null,
                };
            });
        await dispatch(updateOperation(newOperations, tmtId));
    };
export const updateTechMapTemplateOperationsThunk =
    (
        editingOperation: TTechMapTemplateOperation | TTechMapTemplateOperationCreating,
        tmtId: number
    ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const operations = selectOperations(getState());
        const isCreatedOperation = operations.find(
            (operation) => operation.uuid === editingOperation?.uuid
        );

        const newOperations = isCreatedOperation
            ? operations.map((operation) =>
                  operation.uuid === editingOperation?.uuid ? editingOperation : operation
              )
            : [
                  ...operations,
                  {
                      ...editingOperation,
                      parent_uuid:
                          operations.length > 0
                              ? operations[operations.length - 1].uuid
                              : null,
                  },
              ];

        await dispatch(updateOperation(newOperations, tmtId));
    };
let ref: any = null;
export const reorderTechMapTemplateOperationsThunk =
    (
        operations: (TTechMapTemplateOperation | TTechMapTemplateOperationCreating)[],
        tmtId: number
    ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const originalOperations = selectOperations(getState());

        const newOperations = operations.map((operation, index, fullArr) => {
            return {
                ...operation,
                parent_uuid: index > 0 ? fullArr?.[index - 1].uuid : null,
            };
        });
        try {
            dispatch(setTechMapTmplOperations(newOperations));
            if (ref) clearTimeout(ref);
            ref = setTimeout(() => {
                dispatch(updateOperation(newOperations, tmtId));
            }, 600);
        } catch (e) {
            dispatch(setTechMapTmplOperations(originalOperations));
        }
    };
