import { UserActionListItem, bizProcApi, bizProcModel } from "@entities/Portal/BizProc";
import { Button, Popconfirm } from "antd";
import { useState } from "react";

import { useDispatch } from "react-redux";
interface IBizProcUserAction {
    action: TBizProcAction;
}

const BizProcUserAction = ({ action }: IBizProcUserAction) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const dispatch = useDispatch();

    const onClick = () => {
        if (action.is_form) {
            dispatch(bizProcModel.setTargetForm(action));
        } else {
            dispatch(bizProcModel.setTargetAgreement(action));
        }
    };

    const onDeleteBizProc = () => {
        setDeleteLoading(true);
        bizProcApi
            .deleteBizProc(action.biz_proc_id)
            .then(() => {
                dispatch(bizProcModel.deleteUserAction(action));
            })
            .catch(() => {})
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    return (
        <UserActionListItem
            action={action}
            footer={
                <>
                    {action.status === "executing" &&
                        action.action === "InitialAction" && (
                            <Popconfirm
                                title="Завершить бизнес-процесс?"
                                okText="Завершить"
                                cancelButtonProps={{
                                    size: "small",
                                    type: "link",
                                }}
                                okButtonProps={{
                                    size: "small",
                                    type: "link",
                                    danger: true,
                                }}
                                onConfirm={onDeleteBizProc}
                                cancelText="Отмена"
                            >
                                <Button danger type="link" loading={deleteLoading}>
                                    Завершить
                                </Button>
                            </Popconfirm>
                        )}
                    <Button
                        type={action.status === "executing" ? "link" : "text"}
                        onClick={onClick}
                    >
                        {action.is_form
                            ? action.status === "executing"
                                ? "Заполнить"
                                : "Подробнее"
                            : action.status === "executing"
                              ? `Рассмотреть`
                              : "Подробнее"}
                    </Button>
                </>
            }
        />
    );
};

export default BizProcUserAction;
