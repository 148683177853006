import { useState } from "react";
import { TEditQueryActionValue } from "./types";
export const useEditQueryActionHeaders = (
    headers: Record<string, string>[],
    onChange: (
        name: keyof TEditQueryActionValue,
        value: string | null | Record<string, string>[]
    ) => void
) => {
    const [headersKeyValue, setHeadersKeyValue] =
        useState<Record<string, string>[]>(headers);
    const lastHeader = headersKeyValue[0] ?? { key: "", value: "" };
    const isEmptyLastHeader =
        lastHeader.key.length === 0 || lastHeader.value.length === 0;
    const isDisabledAddButton = headersKeyValue.length !== 0 && isEmptyLastHeader;
    const onDelete = (index: number) => {
        const newHeaders = headersKeyValue.filter((_, idx) => index !== idx);
        setHeadersKeyValue(newHeaders);
    };
    const onChangeQueryParamsKeyValue = (
        name: string,
        newValue: string,
        newIndex: number
    ) => {
        const newHeaders = headersKeyValue!.map((item, index) => {
            if (index === newIndex) {
                return {
                    ...item,
                    [name]: newValue,
                };
            }
            return item;
        });
        setHeadersKeyValue(newHeaders);
        onChange("headers", newHeaders);
    };

    const onAdd = () => {
        setHeadersKeyValue([{ key: "", value: "" }, ...headersKeyValue]);
    };

    return {
        isDisabledAddButton,
        onAdd,
        headersKeyValue,
        onChangeQueryParamsKeyValue,
        onDelete,
    };
};
