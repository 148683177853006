import { useEffect } from "react";
import { getEntityRow } from "../api";

export const useEntityRowsWS = (
    entityId: number | undefined,
    user: TUser,
    onCreate: (entityRow: any, props: TEntityRowWS) => void,
    onUpdate: (entityRow: any, props: TEntityRowWS) => void,
    onDelete: (entityRowId: number, props: TEntityRowWS) => void
) => {
    useEffect(() => {
        if (!entityId) return;
        window.Echo.private(`User.${user.id}.EntityRow.${entityId}`)
            .listen(".entityRow.create", (props: TEntityRowWS) => {
                getEntityRow(props.entityRowId, props.entityId)
                    .then((response) => {
                        const entityRow = response.data.data;
                        onCreate(entityRow, props);
                    })
                    .catch((e) => {
                        console.error(".entityRow.create", e);
                    });

                // dispatch(addEntityRow(entityRow));
            })
            .listen(".entityRow.update", (props: TEntityRowWS) => {
                getEntityRow(props.entityRowId, props.entityId)
                    .then((response) => {
                        const entityRow = response.data.data;
                        onUpdate(entityRow, props);
                    })
                    .catch((e) => {
                        console.error(".entityRow.create", e);
                    });
                // dispatch(updateEntityRow(entityRow));
            })
            .listen(".entityRow.delete", (props: TEntityRowWS) => {
                onDelete(props.entityRowId, props);
            });

        return () => {
            window.Echo.leave(`User.${user.id}.EntityRow.${entityId}`);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, user.id]);
};
