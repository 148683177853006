import { parseErrorMessageByKey } from "@shared/api/ParseResponse";
import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Checkbox, Divider, Flex, Input, Space } from "antd";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import { useEntityFieldUpdatingForm } from "../model";
import CreateUpdateDefaultValue from "./CreateUpdateDefaultValue";
import "./EntityFieldUpdatingForm.scss";
interface IEntityFieldUpdatingForm {
    onCancel?: () => void;
    onSuccessSave?: () => void;
    datatypeDependingInputs?: <T>(props: {
        editingField: T;
        error?: TValidationError;
        setEditingField: (editingField: T) => void;
    }) => ReactNode;
}

const MotionFlex = motion(Flex);

const EntityFieldUpdatingForm = ({
    onCancel,
    onSuccessSave,
    datatypeDependingInputs,
}: IEntityFieldUpdatingForm) => {
    const {
        error,
        editingEntityField,
        loading,
        setEditingEntityField,
        onSave,
        onChangeInput,
        onChangeCalculation,
        onChangeCreateUpdateValue,
        onChangeIsCreateUpdateCheckbox,
        onChangeUnwritable,
        onChangeIsName,
        onChangeIsDesc,
    } = useEntityFieldUpdatingForm(onSuccessSave);
    return (
        <Flex vertical className="entity-field-updating-form" justify="space-between">
            <Flex style={{ flex: "1 1 100%", height: "calc(100% - 68px)" }} vertical>
                <Flex>
                    <MotionFlex
                        variants={{
                            open: { transitionEnd: { flexWrap: "wrap" } },
                            closed: {
                                transitionEnd: { flexWrap: "nowrap" },
                            },
                        }}
                        className="entity-field-updating-form__title"
                        gap={4}
                        justify="center"
                        style={{ flex: "1 0 320px", minWidth: 320 }}
                    >
                        <span
                            style={{ textWrap: "nowrap" }}
                        >{`${editingEntityField?.id ? "Редактирование" : "Создание"} поля`}</span>
                        <span
                            style={{ textWrap: "nowrap" }}
                            className="entity-field-updating-form__title-name"
                        >
                            {editingEntityField?.name}
                        </span>
                    </MotionFlex>
                </Flex>
                <Flex
                    vertical
                    gap={8}
                    className="entity-field-updating-form__editing-entity-field-form-container"
                >
                    <FormItem label="Тип данных">
                        <>
                            <Input
                                name="datatype"
                                disabled
                                value={editingEntityField?.datatype}
                            />
                            <div className="entity-field-updating-form__datatype-description">
                                {editingEntityField?.field_datatype?.description}
                            </div>
                        </>
                    </FormItem>
                    <FormItem label="Наименование">
                        <>
                            <Input
                                name="name"
                                status={error.data?.["name"] ? "error" : undefined}
                                value={editingEntityField?.name}
                                onChange={onChangeInput}
                            />
                            <ErrorText text={parseErrorMessageByKey(error, "name")} />
                        </>
                    </FormItem>
                    <FormItem label="Ключ">
                        <>
                            <Input
                                name="key"
                                value={editingEntityField?.key}
                                status={error.data?.["key"] ? "error" : undefined}
                                onChange={onChangeInput}
                                disabled={editingEntityField?.is_system}
                            />
                            <ErrorText text={parseErrorMessageByKey(error, "key")} />
                        </>
                    </FormItem>
                    <Flex
                        vertical
                        gap={2}
                        className="entity-field-updating-form__options"
                    >
                        <FormItem>
                            <Checkbox
                                onChange={onChangeIsName}
                                checked={editingEntityField?.is_name}
                            >
                                Используется для заголовка (Имя)
                            </Checkbox>
                        </FormItem>

                        <FormItem>
                            <Checkbox
                                onChange={onChangeIsDesc}
                                checked={editingEntityField?.is_description}
                            >
                                Используется для описания
                            </Checkbox>
                        </FormItem>
                        <Divider style={{ margin: 8 }} />
                        <FormItem>
                            <Checkbox
                                disabled={editingEntityField?.is_system}
                                onChange={onChangeUnwritable}
                                checked={editingEntityField?.is_unwritable}
                            >
                                Только чтение
                            </Checkbox>
                        </FormItem>
                        {datatypeDependingInputs &&
                            editingEntityField &&
                            datatypeDependingInputs({
                                error,
                                editingField: editingEntityField,
                                setEditingField: setEditingEntityField,
                            })}
                        <Divider style={{ margin: 8 }} />
                        {editingEntityField && (
                            <CreateUpdateDefaultValue
                                setEditingEntityField={setEditingEntityField}
                                editingEntityField={editingEntityField}
                                onChangeCalculation={onChangeCalculation}
                                onChangeCreateUpdateValue={onChangeCreateUpdateValue}
                                onChangeIsCreateUpdateCheckbox={
                                    onChangeIsCreateUpdateCheckbox
                                }
                            />
                        )}
                    </Flex>
                </Flex>
            </Flex>

            <Flex style={{ padding: 16, flex: "0 1 48px" }} justify="end">
                <Space>
                    {onCancel && <Button onClick={onCancel}>Отмена</Button>}
                    <Button loading={loading} type="primary" onClick={onSave}>
                        Сохранить
                    </Button>
                </Space>
            </Flex>
        </Flex>
    );
};

export default EntityFieldUpdatingForm;
