import { $api } from "@shared/api/api";

const route = "/api/production/release";

export const getReleases = (
    page: number,
    perPage: number,
    group?: string,
    filters?: TFilterValue[]
) => {
    const params: any = {
        page,
        perPage,
        filter:
            filters && filters?.length > 0
                ? {
                      condition_type: "AND",
                      conditions: filters,
                  }
                : undefined,
    };
    if (group) {
        params["group_by"] = group;
        return $api.get<TResponseListWithPage<TReleaseGrouped>>(`${route}/groups`, {
            params,
        });
    }
    return $api.get<TResponseListWithPage<TRelease>>(route, {
        params,
    });
};

export const createReleaseByItemsIds = (
    itemsIds: number[],
    warehouse_ref: string,
    organization_ref: string,
    comment: string
) => {
    return $api.post<any>(route, {
        warehouse_ref: warehouse_ref,
        organization_ref: organization_ref,
        item_ids: itemsIds,
        comment,
    });
};

export const deleteRelease = (id: number) => {
    return $api.delete(`${route}/${id}`);
};
export const publishReleases = (ids: number[]) => {
    return $api.post(`${route}/publish`, {
        ids,
    });
};

export const createReleaseByFilter = (
    filter: TFilterValue[],
    warehouse_ref: string,
    organization_ref: string,
    comment: string
) => {
    return $api.post<any>(`${route}/by-filter`, {
        warehouse_ref: warehouse_ref,
        organization_ref: organization_ref,
        comment,
        filter: {
            conditioin_type: "AND",
            conditions: filter,
        },
    });
};
