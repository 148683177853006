import { UserAccess } from "@entities/Portal/User";
import { ImportButton } from "@features/Portal/Action/Import";
import { EntitiesList } from "@features/Portal/Entity/EntitiesList";
import { EntityCreateButton } from "@features/Portal/Entity/EntityCreateButton";
import { GenerateQRCode, GenerateQRCodeButton } from "@features/Portal/GenerateQRCode";
import { EmptyPage } from "@shared/EmptyPage";
import { CardError } from "@shared/ui/Cards/CardError";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { EntityModifyModal } from "@widgets/Portal/Entity/CreateUpdateEntity";
import { EntityFieldsWidget } from "@widgets/Portal/Entity/EntityFieldList";
import { EntityFilters } from "@widgets/Portal/Filters/EntityFilters";
import { Space, Typography } from "antd";
import { useEntitiesPageList } from "./model";

const EntitiesPageList = () => {
    const {
        entities,
        isLoading,
        referenceEntities,
        guidebookEntities,
        mainEntities,
        error,
    } = useEntitiesPageList();
    return (
        <>
            <UserAccess.AdminAccess>
                <IndentContainer>
                    <Space>
                        {entities.length > 0 && <EntityCreateButton />}
                        <ImportButton />
                    </Space>
                </IndentContainer>
            </UserAccess.AdminAccess>
            <IndentContainer>
                <EntityFilters />
            </IndentContainer>
            <EntityModifyModal
                entityFieldsAddingWidget={(entity, entityId) => (
                    <EntityFieldsWidget
                        isCreatingEntity
                        entity={entity}
                        entityId={entityId}
                    />
                )}
            />
            {mainEntities.length === 0 &&
                referenceEntities.length === 0 &&
                !isLoading &&
                !error && (
                    <EmptyPage
                        text="Сущности не найдены"
                        button={
                            <UserAccess.AdminAccess>
                                <EntityCreateButton isEmptyPage={true} />
                            </UserAccess.AdminAccess>
                        }
                    />
                )}
            {!isLoading && error && (
                <CardError message={error?.message} status={error?.status} />
            )}
            {mainEntities.length > 0 && !error && (
                <IndentContainer>
                    <EntitiesList
                        entities={mainEntities}
                        additionalActions={(entity: TEntity) => (
                            <>
                                <UserAccess.AdminAccess>
                                    <GenerateQRCodeButton entity={entity} />
                                </UserAccess.AdminAccess>
                            </>
                        )}
                    />
                </IndentContainer>
            )}
            {referenceEntities.length > 0 && !error && (
                <>
                    <Typography.Title level={4}>
                        Вспомогательные сущности
                    </Typography.Title>
                    <EntitiesList
                        entities={referenceEntities}
                        additionalActions={(entity: TEntity) => (
                            <>
                                <UserAccess.AdminAccess>
                                    <GenerateQRCodeButton entity={entity} />
                                </UserAccess.AdminAccess>
                            </>
                        )}
                    />
                </>
            )}
            {guidebookEntities.length > 0 && !error && (
                <>
                    <Typography.Title level={4}>Справочные сущности</Typography.Title>
                    <EntitiesList
                        entities={guidebookEntities}
                        additionalActions={(entity: TEntity) => (
                            <>
                                <UserAccess.AdminAccess>
                                    <GenerateQRCodeButton entity={entity} />
                                </UserAccess.AdminAccess>
                            </>
                        )}
                    />
                </>
            )}
            <GenerateQRCode />
        </>
    );
};

export default EntitiesPageList;
