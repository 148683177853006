import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { techMapTemplateBuilder } from "./TechMapBuilder";
import { getGroupLevelFilterKey } from "../lib";

export interface ITechMap {
    executableTmts: TTechMapTemplateExecuble[];
    getExecutableLoading: boolean;
    startedTm: TTechMapStarted | null;
    startedTms: TTechMapStarted[];
    startedOperation: TTechMapOperationFull | null;
    startingLoading: number | null;
    getStartedTmsLoading: boolean;
    getStartedOperationLoading: boolean;
    nextOperationLoading: boolean;
    attachItemLoading: boolean;
    stopLoading: boolean;
    pauseResumeLoading: boolean;
    detachItemLoading: number[];
    techMaps: TTechMapStarted[];
    printerIsConnected: boolean;
    techMapsPaginated: {
        [level: string | number]: TTechMapPaginated;
    };
    filters: TTechMapFilters;
    groupLevels: string[];
    techMapsForReview: TTechMapStarted[];
    getTMsLoading: boolean;
    getStartedTmLoading: boolean;
    approveTmLoading: { [id: number]: TDecision };
}

const initialState: ITechMap = {
    executableTmts: [],
    printerIsConnected: false,
    getExecutableLoading: false,
    techMaps: [],
    startedTms: [],
    groupLevels: [],
    techMapsForReview: [],
    filters: {},
    detachItemLoading: [],
    getStartedTmsLoading: false,
    techMapsPaginated: {},
    getStartedOperationLoading: false,
    stopLoading: false,
    pauseResumeLoading: false,
    startingLoading: null,
    nextOperationLoading: false,
    attachItemLoading: false,
    getTMsLoading: false,
    getStartedTmLoading: false,
    startedOperation: null,
    startedTm: null,
    approveTmLoading: {},
};

const getLevel = (groupLevels: (string | number)[], tm: TTechMapStarted) => {
    if (groupLevels.length === 0) return 0;
    const levelKey = groupLevels.reduce((prev, curr, index) => {
        return `${prev}${curr}${(tm as any)?.[getGroupLevelFilterKey(curr)]}`;
    }, "");
    return levelKey;
};
export const techMapSlice = createSlice({
    name: "TechMapSlice",
    initialState,
    reducers: {
        setExecutableTmts: (state, action: PayloadAction<TTechMapTemplateExecuble[]>) => {
            state.executableTmts = action.payload;
        },

        addTMPaginated: (state, action: PayloadAction<TTechMapStarted>) => {
            const level = getLevel(state.groupLevels, action.payload);

            if (state.techMapsPaginated?.[level]) {
                const tms = state.techMapsPaginated[level].techMaps as TTechMapStarted[];
                tms.push(action.payload);
                state.techMapsPaginated[level].techMaps = tms;
            }
        },

        updateTMPaginated: (state, action: PayloadAction<TTechMapStarted>) => {
            const level = getLevel(state.groupLevels, action.payload);
            if (state.techMapsPaginated?.[level]) {
                const tms = (
                    state.techMapsPaginated[level].techMaps as TTechMapStarted[]
                ).map((tm) => {
                    return tm.id === action.payload.id ? action.payload : tm;
                });
                state.techMapsPaginated[level].techMaps = tms;
            }
        },
        deleteTMPaginated: (state, action: PayloadAction<TTechMapStarted>) => {
            const level = getLevel(state.groupLevels, action.payload);
            if (state.techMapsPaginated?.[level]) {
                const tms = (
                    state.techMapsPaginated[level].techMaps as TTechMapStarted[]
                ).filter((tm) => {
                    return tm.id !== action.payload.id;
                });
                state.techMapsPaginated[level].techMaps = tms;
            }
        },
        setGetStartedTmsLoading: (state, action: PayloadAction<boolean>) => {
            state.getStartedTmsLoading = action.payload;
        },
        setGetStartedTmLoading: (state, action: PayloadAction<boolean>) => {
            state.getStartedTmLoading = action.payload;
        },
        setGetStartedOperationLoading: (state, action: PayloadAction<boolean>) => {
            state.getStartedOperationLoading = action.payload;
        },
        setStartedTm: (state, action: PayloadAction<TTechMapStarted | null>) => {
            state.startedTm = action.payload;
        },
        setStartedTms: (state, action: PayloadAction<TTechMapStarted[]>) => {
            state.startedTms = action.payload;
        },
        setTechMaps: (state, action: PayloadAction<TTechMapStarted[]>) => {
            state.techMaps = action.payload;
        },
        setPrinterIsConnected: (state, action: PayloadAction<boolean>) => {
            state.printerIsConnected = action.payload;
        },
        setFilters: (state, action: PayloadAction<TTechMapFilters>) => {
            state.filters = action.payload;
        },
        setTechMapsPaginated: (
            state,
            action: PayloadAction<{
                level: string | number;
                TMsPaginated: TTechMapPaginated;
            }>
        ) => {
            state.techMapsPaginated[action.payload.level] = action.payload.TMsPaginated;
        },
        updateTechMapsPaginated: (
            state,
            action: PayloadAction<{
                level: string | number;
                TMsPaginated: NotRequired<TTechMapPaginated>;
            }>
        ) => {
            state.techMapsPaginated[action.payload.level] = {
                ...state.techMapsPaginated[action.payload.level],
                ...action.payload.TMsPaginated,
            };
        },
        setTechMapsPaginatedLoadingState: (
            state,
            action: PayloadAction<{
                level: string | number;
                state: boolean;
            }>
        ) => {
            if (state.techMapsPaginated[action.payload.level]) {
                state.techMapsPaginated[action.payload.level].loading =
                    action.payload.state;
            }
        },
        setTechMapsForReview: (state, action: PayloadAction<TTechMapStarted[]>) => {
            state.techMapsForReview = action.payload;
        },
        setGetTMsLoading: (state, action: PayloadAction<boolean>) => {
            state.getTMsLoading = action.payload;
        },
        setGroupLevels: (state, action: PayloadAction<string[]>) => {
            state.groupLevels = action.payload;
        },
        setStartedOperation: (
            state,
            action: PayloadAction<TTechMapOperationFull | null>
        ) => {
            state.startedOperation = action.payload;
        },
    },
    extraReducers: (builder) => {
        techMapTemplateBuilder(builder);
    },
});

export const {
    setExecutableTmts,
    setStartedTm,
    setTechMaps,
    setFilters,
    setGetStartedTmsLoading,
    setGroupLevels,
    setStartedOperation,
    setTechMapsPaginatedLoadingState,
    setStartedTms,
    setGetTMsLoading,
    setGetStartedTmLoading,
    setTechMapsForReview,
    setGetStartedOperationLoading,
    setTechMapsPaginated,
    updateTechMapsPaginated,
    addTMPaginated,
    updateTMPaginated,
    deleteTMPaginated,
    setPrinterIsConnected,
} = techMapSlice.actions;
export default techMapSlice.reducer;
