import PDFIcon from "./PDFIcon";
import PPTIcon from "./PPTIcon";
import XLSIcon from "./XLSIcon";
import DOCIcon from "./DOCIcon";
import { getFileExtenstion } from "../lib";
import { FileUnknownOutlined } from "@ant-design/icons";
import { UploadFile } from "antd";

const FileIconRender = ({ file }: { file: UploadFile<any> }) => {
    const fileExt = getFileExtenstion(file.name);
    return fileExt === "pdf" ? (
        <PDFIcon />
    ) : ["ppt", "pptx"].includes(fileExt) ? (
        <PPTIcon />
    ) : ["xls", "xlsx"].includes(fileExt) ? (
        <XLSIcon />
    ) : ["doc", "docx"].includes(fileExt) ? (
        <DOCIcon />
    ) : (
        <FileUnknownOutlined />
    );
};

export default FileIconRender;
