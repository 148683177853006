import { EntitySelect } from "@entities/Portal/Entity";
import { Checkbox, Input } from "antd";
import { useTechMapTmplMainInfo } from "../model";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { memo } from "react";

interface ITechMapTmplMainInfo {
    name: string;
    isMultiple: boolean;
    isSkippable: boolean;
    onChange: (key: keyof TTechMapTemplate, value: any) => void;
}

const TechMapTmplMainInfo = ({
    name,
    isMultiple,
    isSkippable,
    onChange,
}: ITechMapTmplMainInfo) => {
    const { onChangeName, onChangeIsMultiple, onChangeIsSkippable } =
        useTechMapTmplMainInfo(onChange);

    return (
        <>
            <IndentContainer>
                <>Название</>
                <Input value={name} onChange={onChangeName} />
            </IndentContainer>

            <IndentContainer>
                <Checkbox checked={isMultiple} onChange={onChangeIsMultiple}>
                    Возможно завершение с множественным результатом
                </Checkbox>
            </IndentContainer>
            <IndentContainer>
                <Checkbox checked={isSkippable} onChange={onChangeIsSkippable}>
                    Возможно "принудительное" завершение
                </Checkbox>
            </IndentContainer>
        </>
    );
};

export default memo(TechMapTmplMainInfo);
