import { Button, Flex, Select } from "antd";
import { useEntityRowsActionsChangeStage } from "../model";

interface IEntityRowsActionsChangeStage {
    entityId: number;
    selectedRows: any[];
    onSuccess?: () => void;
    smallVersion?: boolean;
}

const EntityRowsActionsChangeStage = ({
    entityId,
    selectedRows,
    onSuccess,
    smallVersion,
}: IEntityRowsActionsChangeStage) => {
    const { stage, disabled, loading, onSubmit, options, loadingSelect, onChange } =
        useEntityRowsActionsChangeStage(entityId, selectedRows, onSuccess);
    return (
        <>
            {!disabled && (
                <Flex style={{ flex: "1 0 50%" }}>
                    <Select
                        filterOption={(input: any, option) => {
                            return (
                                option?.label
                                    ?.toLowerCase()
                                    ?.includes(input.toLowerCase()) === true
                            );
                        }}
                        size="small"
                        loading={loadingSelect}
                        style={{ width: "100%" }}
                        options={options}
                        popupMatchSelectWidth={false}
                        maxTagTextLength={8}
                        maxTagCount={1}
                        popupClassName="change-stage-popup"
                        allowClear
                        onChange={onChange}
                        showSearch
                        placeholder="Перевод в стадию"
                        value={stage}
                    />
                </Flex>
            )}
            <Flex style={{ flex: "0 0 40%" }}>
                <Button
                    type="link"
                    onClick={onSubmit}
                    disabled={disabled}
                    loading={loading}
                    size="small"
                    style={{ minHeight: 0, padding: 0, margin: 0 }}
                >
                    Перевести
                </Button>
            </Flex>
        </>
    );
};

export default EntityRowsActionsChangeStage;
