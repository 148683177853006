export const isOptionalPrompt = `Если правило обязательное, то отсутствие ключа в ответе переведет бизнес-процесс в ветку "Ошибка". Отсутствие ключа в одном из правил не блокирует выполнение парсинга.`;
export const methodsOptions = [
    { value: "get", label: "GET" },
    { value: "post", label: "POST" },
    { value: "put", label: "PUT" },
    { value: "delete", label: "DELETE" },
];

export const maxCountOfRows = 30;
export const minCountOfRows = 2;
