import { useState } from "react";

export const useReleaseItems = () => {
    const [groups, setGroups] = useState<string[]>([]);
    const [filters, setFilters] = useState<TFilterValue[]>([
        {
            value: "null",
            condition: "null",
            key: "production_release_id",
        },
    ]);
    return {
        groups,
        filters,
        setFilters,
        setGroups,
    };
};
