import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { FormItem } from "@shared/ui/Form";
import { Input, InputNumber } from "antd";
import UserSelectWithCalc from "../UserSelectWithCalc";
import { ChangeEvent, EventHandler } from "react";

interface IEditDeviceMessageAction {
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditDeviceMessageAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditDeviceMessageAction) => {
    const body = templateItem.parameters?.body;

    const onChangeInputs = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                [e.target.name]: e.target.value,
            },
        });
    };

    return (
        <>
            <FormItem label="Сообщение">
                <Input.TextArea
                    disabled={disabled}
                    value={body}
                    onChange={onChangeInputs}
                    name="body"
                />
            </FormItem>
        </>
    );
};

export default EditDeviceMessageAction;
