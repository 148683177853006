import { checkIsValidExternalUrlWithVariables } from "@shared/lib/isValidUrl";
import { CSSProperties, useRef } from "react";
import { TEditQueryActionValue } from "./types";
import { VALUE_WITH_VARIABLES_CHECK_REGEX } from "@shared/constants";
import { getUrlRouteAndQueryParamString } from "../lib";

export const useEditQueryActionHeader = (
    url: string | undefined,
    onChange: (
        name: keyof TEditQueryActionValue,
        value: string | null | Record<string, string>[]
    ) => void
) => {
    const onChageMethod = (value: string) => {
        onChange("method", value);
    };
    const firstRender = useRef(true);
    const [visibleRoute] = getUrlRouteAndQueryParamString(url ?? "");
    const isWithVariable = VALUE_WITH_VARIABLES_CHECK_REGEX.test(visibleRoute);

    const onChangeUrl = (name: string, value: string) => {
        firstRender.current = false;
        onChange(name as keyof TEditQueryActionValue, value);
    };

    const isValidUrl = isWithVariable
        ? true
        : checkIsValidExternalUrlWithVariables(visibleRoute);

    const commonCustomStyle: CSSProperties = {
        bottom: -8,
        left: 4,
        textWrap: "nowrap",
    };

    return {
        firstRender,
        isValidUrl,
        onChageMethod,
        onChangeUrl,
        commonCustomStyle,
        visibleRoute,
    };
};
