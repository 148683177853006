import { entityModel } from "@entities/Portal/Entity";
import { useEntityRowCodeParse } from "@features/Portal/EntityRow/EntityRowCodeParse";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useEntitiesPageList = () => {
    const { entities, isLoading } = entityModel.useLoadEntities(true);
    const referenceEntities = entities.filter((entity) => entity.is_reference);
    const guidebookEntities = entities.filter((entity) => entity.is_guidebook);
    const mainEntities = entities.filter(
        (entity) => !entity.is_reference && !entity.is_guidebook
    );
    const keydownHandler = (event: KeyboardEvent) => {
        const filterFields = document.querySelector(".filter-fields");
        const input = (filterFields?.querySelector(".ant-input") ??
            filterFields?.querySelector(
                ".ant-select-selection-search-input"
            )) as HTMLInputElement;
        if (event.ctrlKey && event.code === "KeyF") {
            event.preventDefault();
            input?.focus();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", keydownHandler);
        return () => {
            document.removeEventListener("keydown", keydownHandler);
        };
    }, []);
    const error = useSelector(entityModel.selectError);
    useEntityRowCodeParse();

    return {
        entities,
        isLoading,
        referenceEntities,
        guidebookEntities,
        mainEntities,
        error,
    };
};
