import { DeleteOutlined } from "@ant-design/icons";
import { FormInputItem } from "@entities/Portal/Datatype";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Input, Select } from "antd";

interface ISingleConditionFormItem {
    isLoading: boolean;
    depthLvl: number;
    parentIndex: number;
    parentCondition: any;
    field: TEntityField | undefined;
    onChangeEntityField: (_: string, { option }: any) => void;
    entityFieldsOptions: {
        label: string;
        value: string;
        option: TEntityField;
    }[];
    onChangeConditionItem: (
        condition: any,
        name: string,
        value: string,
        indexOfEditingCondition: number
    ) => void;
    comparsionTypesOptions:
        | {
              label: string;
              value: string;
          }[]
        | undefined;
    onDeleteCondition: () => void;
}

const SingleConditionFormItem = ({
    isLoading,
    parentIndex,
    parentCondition,
    onChangeEntityField,
    depthLvl,
    entityFieldsOptions,
    onChangeConditionItem,
    comparsionTypesOptions,
    field,
    onDeleteCondition,
}: ISingleConditionFormItem) => {
    return (
        <div
            style={{
                display: "list-item",
                padding: 8,
                marginLeft: (depthLvl - 1) * 8,
            }}
        >
            <Flex justify="space-between" align="center">
                <Flex style={{ width: "100%" }} gap={8}>
                    <FormItem label="Поле" style={{ width: "30%" }}>
                        <Select
                            value={parentCondition.key}
                            onChange={onChangeEntityField}
                            options={entityFieldsOptions}
                            loading={isLoading}
                            style={{ width: "100%" }}
                        />
                    </FormItem>
                    <Flex justify="space-between" style={{ width: "60%" }} gap={8}>
                        <FormItem label="Сравнение" style={{ width: "40%" }}>
                            <Select
                                onChange={(value) => {
                                    onChangeConditionItem(
                                        parentCondition,
                                        "condition",
                                        value,
                                        parentIndex
                                    );
                                }}
                                loading={isLoading}
                                placeholder={!parentCondition.key && "Выберите поле"}
                                options={comparsionTypesOptions}
                                disabled={!parentCondition.key}
                                value={parentCondition.condition}
                                style={{ width: "100%" }}
                            />
                        </FormItem>
                        <FormItem style={{ width: "60%" }} label="Значение">
                            {field ? (
                                <FormInputItem
                                    additionalParameters={field?.additional_parameters}
                                    field={field}
                                    value={parentCondition.value}
                                    onChange={(value) =>
                                        onChangeConditionItem(
                                            parentCondition,
                                            "value",
                                            value,
                                            parentIndex
                                        )
                                    }
                                    datatype={field.datatype}
                                />
                            ) : (
                                <Input placeholder="Выберите поле" disabled={true} />
                            )}
                        </FormItem>
                    </Flex>
                </Flex>
                <Button
                    icon={<DeleteOutlined />}
                    onClick={onDeleteCondition}
                    type="link"
                    danger
                />
            </Flex>
        </div>
    );
};

export default SingleConditionFormItem;
