import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import {
    loadBizProcTemplatesThunk,
    createBizProcEntityTemplateItemThunk,
    deleteBizProcEntityTemplateItemThunk,
    loadBizProcTemplateItemThunk,
    loadBizProcEntityTemplatesThunk,
    updateBizProcEntityTemplateItemThunk,
} from "./BizProcTemplateThunk";

export interface IBizProcTemplateItemSlice {
    bizProcEntityTemplates: TBizProcTemplateItem[];
    editingTemplateItem: TBizProcTemplateItem | null;
    bizProcTemplates: TBizProcTemplateItem[];
    isCopyingMovingMode: boolean;
}

const initialState: IBizProcTemplateItemSlice = {
    bizProcEntityTemplates: [],
    editingTemplateItem: null,
    bizProcTemplates: [],
    isCopyingMovingMode: false,
};

export const bizProcTemplateSlice = createSlice({
    name: "BizProcTemplateItemSlice",
    initialState,
    reducers: {
        setBizProcEntityTemplates: (
            state,
            action: PayloadAction<TBizProcTemplateItem[]>
        ) => {
            state.bizProcEntityTemplates = action.payload;
        },
        setEditingBizProcTemplateItem: (
            state,
            action: PayloadAction<TBizProcTemplateItem>
        ) => {
            state.editingTemplateItem = action.payload;
        },
        updateBizProcEntityTemplateItem: (
            state,
            action: PayloadAction<TBizProcTemplateItem>
        ) => {
            state.bizProcEntityTemplates = updateArrByItemId(
                state.bizProcEntityTemplates,
                action.payload
            );
        },
        setIsCopyingMovingMode: (state, action: PayloadAction<boolean>) => {
            state.isCopyingMovingMode = action.payload;
        },
        deleteBizProcEntityTemplateItem: (
            state,
            action: PayloadAction<TBizProcTemplateItem>
        ) => {
            state.bizProcEntityTemplates = state.bizProcEntityTemplates.filter(
                (item) => item.id !== action.payload.id
            );
        },
    },
    extraReducers: (builder) => {
        loadBuilder(builder);
        createBuilder(builder);
        updateBuilder(builder);
        deleteBuilder(builder);
        loadOneBuilder(builder);
    },
});

const loadBuilder = (builder: ActionReducerMapBuilder<IBizProcTemplateItemSlice>) => {
    builder.addCase(loadBizProcEntityTemplatesThunk.fulfilled, (state, action) => {
        state.bizProcEntityTemplates = action.payload.data;
    });
    builder.addCase(loadBizProcTemplatesThunk.fulfilled, (state, action) => {
        state.bizProcTemplates = action.payload.data;
    });
};
const createBuilder = (builder: ActionReducerMapBuilder<IBizProcTemplateItemSlice>) => {
    builder.addCase(createBizProcEntityTemplateItemThunk.fulfilled, (state, action) => {
        state.bizProcEntityTemplates.unshift(action.payload.data);
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<IBizProcTemplateItemSlice>) => {
    builder.addCase(updateBizProcEntityTemplateItemThunk.fulfilled, (state, action) => {
        state.bizProcEntityTemplates = updateArrByItemId(
            state.bizProcEntityTemplates,
            action.payload.data
        );
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IBizProcTemplateItemSlice>) => {
    builder.addCase(deleteBizProcEntityTemplateItemThunk.fulfilled, (state, action) => {
        state.bizProcEntityTemplates = state.bizProcEntityTemplates.filter(
            (item) => item.id !== action.payload.data.id
        );
    });
};
const loadOneBuilder = (builder: ActionReducerMapBuilder<IBizProcTemplateItemSlice>) => {
    builder.addCase(loadBizProcTemplateItemThunk.fulfilled, (state, action) => {
        state.editingTemplateItem = action.payload.data;
    });
};
export const {
    setBizProcEntityTemplates,
    updateBizProcEntityTemplateItem,
    deleteBizProcEntityTemplateItem,
    setEditingBizProcTemplateItem,
    setIsCopyingMovingMode,
} = bizProcTemplateSlice.actions;
export default bizProcTemplateSlice.reducer;
