import { bizProcApi, bizProcModel } from "@entities/Portal/BizProc";
import { entityApi } from "@entities/Portal/Entity";
import { userModel } from "@entities/Portal/User";
import { PrimarySecondButton } from "@shared/ui/Buttons/PrimarySecondButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ITMPBizProcButton {
    column: TPatternColumn;
    entityRow: any;
    entityId: number;
}

const TMPBizProcButton = ({ column, entityRow, entityId }: ITMPBizProcButton) => {
    const [loading, setLoading] = useState(false);
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        setLoading(true);
        e.stopPropagation();
        const params = JSON.parse(column.type_value);
        (column.type === "start_biz_proc"
            ? bizProcApi.startBizProc(params.bizProcTemplateId, entityRow.id)
            : entityApi.startChildBizProc(
                  entityId,
                  entityRow.id,
                  params.entityFieldKey,
                  params.bizProcTemplateId
              )
        )
            .then((response) => {
                const bizProcTemplateItem = response.data.data;
                if (
                    !bizProcTemplateItem.start_biz_proc_action ||
                    !bizProcTemplateItem.start_biz_proc_action.collected_parameters
                        ?.users_ids
                )
                    return;
                const action = bizProcTemplateItem.start_biz_proc_action;
                const usersIds =
                    bizProcTemplateItem.start_biz_proc_action.collected_parameters
                        ?.users_ids;

                if (
                    ((action.action === "InitialAction" &&
                        action.parameters?.triggerType === "form") ||
                        action.action === "FormAction") &&
                    usersIds.includes(user.id)
                )
                    dispatch(bizProcModel.setTargetForm(action));
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <PrimarySecondButton
                onClick={onClick}
                loading={loading}
                type="link"
                className="TMPBizProcButton"
            >
                {column.label}
            </PrimarySecondButton>
        </>
    );
};

export default TMPBizProcButton;
