import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Tooltip } from "antd";
import { useEditQueryActionResultParse } from "../model";
import { TParseRule } from "../model/types";
import EditQueryActionResultParseItem from "./EditQueryActionResultParseItem";

interface IEditQueryActionResultParse {
    bizProcFields: TBizProcTemplateField[];
    parseRules: TParseRule[];
    onChangeParseRules: (item: TParseRule[]) => void;
    itemId: number;
}

const EditQueryActionResultParse = ({
    bizProcFields,
    parseRules,
    onChangeParseRules,
    itemId,
}: IEditQueryActionResultParse) => {
    const { onChangeParseRulesCommonProps, onAdd, onDelete } =
        useEditQueryActionResultParse(parseRules, onChangeParseRules);
    return (
        <FormItem label="Правила парсинга ответа">
            <Flex
                vertical
                align="start"
                style={{
                    height: "fit-content",
                }}
            >
                <Flex gap={8}>
                    <Button
                        icon={<PlusOutlined />}
                        type="link"
                        onClick={onAdd}
                        style={{
                            minHeight: 0,
                            height: "fit-content",
                            padding: 0,
                            position: "sticky",
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        Добавить правило
                    </Button>
                    <Tooltip
                        color="white"
                        overlayInnerStyle={{ color: "#838383" }}
                        title={
                            <span>
                                Правила с некорректными и пустыми полями при сохранении
                                будут удалены
                            </span>
                        }
                    >
                        <InfoCircleOutlined style={{ color: "var(--warning)" }} />
                    </Tooltip>
                </Flex>
                <Flex
                    gap={24}
                    wrap="wrap"
                    className="result-parse-items-container"
                    style={{
                        width: "100%",
                        maxHeight: 400,
                        padding: "8px 8px 0 0",
                        overflow: "auto",
                        scrollbarGutter: "stable both-edges",
                    }}
                >
                    {parseRules.map((parseRule, index, { length }) => (
                        <EditQueryActionResultParseItem
                            parseRules={parseRules.filter((_, idx) => idx !== index)}
                            bizProcFields={bizProcFields}
                            key={index}
                            index={index}
                            parseRule={parseRule}
                            onChangeParseRulesCommonProps={onChangeParseRulesCommonProps}
                            onDelete={onDelete}
                            itemId={itemId}
                        />
                    ))}
                </Flex>
            </Flex>
        </FormItem>
    );
};

export default EditQueryActionResultParse;
