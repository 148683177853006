import { checkIsValidExternalUrl } from "@shared/lib/isValidUrl";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { TimeInput } from "@shared/ui/TimeInput";
import { Button, Checkbox, Flex, Input, Space } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ReactNode, useState } from "react";
import BizProcEditAction from "./BizProcEditAction";
import { getPreparedEditQueryAction } from "./EditActions/EditQueryAction/lib";

interface IBizProcItemEditForm {
    templateItem: TBizProcTemplateAction;
    additionalNode?: ReactNode;
    bizProcTemplate: TBizProcTemplateItem;
    disabled?: boolean;
    onSubmit: (templateItem: TBizProcTemplateAction) => void;
    onCancel: () => void;
}

const BizProcItemEditForm = ({
    templateItem,
    disabled,
    additionalNode,
    bizProcTemplate,
    onSubmit,
    onCancel,
}: IBizProcItemEditForm) => {
    const [item, setItem] = useState<TBizProcTemplateAction>(templateItem);

    const updateItemParameters = (parameters: TBizProcParameters) => {
        setItem({
            ...item,
            parameters: {
                ...(item.parameters ?? {}),
                ...parameters,
            },
        });
    };
    const onOk = () => {
        if (templateItem.action === "QueryAction") {
            if (
                (item.parameters.url && item.parameters.url.length === 0) ||
                (item.parameters?.method && !item.parameters.method) ||
                !checkIsValidExternalUrl(item.parameters.url)
            ) {
                notificationEmit({ type: "error", title: "Ошибка валидации" });
                return;
            }
            const preparedTemplateItem = getPreparedEditQueryAction(item);
            console.log("preparedTemplateItem", preparedTemplateItem);
            onSubmit(preparedTemplateItem);
        } else onSubmit(item);
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setItem({
            ...item,
            name: value,
        });
    };

    const onChangeTime = (time: number) => {
        setItem({
            ...item,
            deadline_delta: time,
        });
    };
    const onChangeTimeCheckbox = (event: CheckboxChangeEvent): void => {
        setItem({
            ...item,
            deadline_delta_enabled: event.target.checked,
        });
    };
    const onChangeHideFromHistory = (event: CheckboxChangeEvent): void => {
        setItem({
            ...item,
            hide_from_history: event.target.checked,
        });
    };

    return (
        <Flex
            vertical
            onDragStartCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onDragEndCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {additionalNode}
            <IndentContainer type={["marginBottom"]}>
                <div>
                    <div>Имя</div>
                    <Input
                        value={item.name}
                        onChange={onChangeName}
                        disabled={disabled}
                    />
                </div>
            </IndentContainer>

            <BizProcEditAction
                disabled={disabled}
                bizProcTemplate={bizProcTemplate}
                templateItem={item}
                setItem={setItem}
                updateItemParameters={updateItemParameters}
            />
            {templateItem.action !== "DelayAction" && (
                <FormItem label="Время выполнения">
                    <>
                        <TimeInput
                            disabled={
                                disabled || !Boolean(item.deadline_delta_enabled ?? false)
                            }
                            onChangeValue={onChangeTime}
                            value={item.deadline_delta}
                        />

                        <Checkbox
                            onChange={onChangeTimeCheckbox}
                            checked={Boolean(item.deadline_delta_enabled ?? false)}
                            disabled={disabled}
                        >
                            С ограничением на время выполнения
                        </Checkbox>
                    </>
                </FormItem>
            )}
            <FormItem>
                <Checkbox
                    disabled={disabled}
                    style={{ marginTop: 8 }}
                    onChange={onChangeHideFromHistory}
                    checked={Boolean(item.hide_from_history ?? false)}
                >
                    Скрыть из истории бизнес-процесса
                </Checkbox>
            </FormItem>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <Button onClick={onCancel}>Закрыть</Button>
                    <Button onClick={onOk} type="primary">
                        Сохранить
                    </Button>
                </Space>
            </div>
        </Flex>
    );
};

export default BizProcItemEditForm;
