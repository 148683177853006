import { LockOutlined } from "@ant-design/icons";
import { UserProfileInfo, userModel } from "@entities/Portal/User";
import { QrCodeList } from "@features/Portal/QrCodeList";
import { SignOutButton } from "@features/Portal/SignOutButton";
import { SelectUserTimezone } from "@features/Portal/User/SelectUserTimezone";
import { UserPassword } from "@features/Portal/User/UserPassword";
import { UserTelegramLinkButton } from "@features/Portal/User/UserTelegramLink";
import TelegramIcon from "@shared/assets/TelegramIcon";
import { useHeaderLayout } from "@shared/ui/Header";
import { Card } from "antd";
import { useSelector } from "react-redux";
import "./UserPage.scss";
const UserPage = () => {
    useHeaderLayout({
        title: "Личный профиль",
    });
    const user = useSelector(userModel.selectUser);
    return (
        <div className="user-page">
            <Card
                bordered={false}
                style={{
                    width: "100%",
                    maxWidth: 450,
                }}
            >
                <UserProfileInfo
                    user={user}
                    additionalUserData={[
                        {
                            label: "Временная зона",
                            value: (
                                <div style={{ marginTop: 10, width: "100%" }}>
                                    <SelectUserTimezone
                                        userId={user.id}
                                        timezoneId={user.timezone_id}
                                    />
                                </div>
                            ),
                        },
                        {
                            label: "Телеграм",
                            value: <UserTelegramLinkButton onlyReadTg={false} />,
                            icon: (style) => <TelegramIcon style={style} />,
                        },
                        {
                            label: "Пароль",
                            value: <UserPassword userId={user.id} />,
                            icon: (style) => <LockOutlined style={style} />,
                        },
                    ]}
                    actions={
                        <SignOutButton
                            size={window.innerWidth <= 768 ? 16 : undefined}
                            color="red"
                        />
                    }
                />
            </Card>
            <Card
                className="user-page__qr-code-list"
                title="QR-Коды"
                bordered={false}
                style={{ width: "100%", minHeight: "100%" }}
                bodyStyle={{ height: "calc(100% - 48px)" }}
            >
                <QrCodeList userId={user.id} />
            </Card>
        </div>
    );
};

export default UserPage;
