import { entityModel } from "@entities/Portal/Entity";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useEntityRowObserverDelete = (item: TEntityRowObserver) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const onDelete = () => {
        setLoading(true);
        dispatch(entityModel.deleteEntityRowObserverThunk(item.id))
            .then(parseFormResponse)
            .catch((error: any) => {
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return {
        onDelete,
        loading,
    };
};
