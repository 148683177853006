import { entityRowModel } from "@entities/Portal/EntityRow";
import { userModel } from "@entities/Portal/User";
import { useDispatch, useSelector } from "react-redux";

export const useEntityRowsSchedulerWS = (entityId: number) => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();
    const onCreate = (entityRow: any) => {
        dispatch(entityRowModel.addEntityRow(entityRow));
    };
    const onUpdate = (entityRow: any) => {
        dispatch(entityRowModel.updateEntityRow(entityRow));
    };
    const onDelete = (entityRowId: number) => {
        dispatch(entityRowModel.deleteEntityRow(entityRowId));
    };

    entityRowModel.useEntityRowsWS(entityId, user, onCreate, onUpdate, onDelete);
};
