import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { UserShortProfile, userModel } from "@entities/Portal/User";
import { Header } from "@shared/ui/Header";
import { useHeader } from "@shared/ui/Header/model";
import { AlertNotification } from "@shared/ui/NotificationAndMessage";
import { Sidebar } from "@widgets/Portal/Sidebar";
import { useSidebar } from "@widgets/Portal/Sidebar/model";
import { UserProfileDrawer } from "@widgets/Portal/UserProfileWidget";
import { Button, Layout, Menu } from "antd";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface ISiteLayout {
    children: ReactNode;
}
const SiteLayout = ({ children }: ISiteLayout) => {
    const windowWidth = window.innerWidth;
    const [collapsedMenu, setCollapsedMenu] = useState(false);
    const [collapsedMenuMobile, setCollapsedMenuMobile] = useState(false);
    const currentURL = useLocation();
    const isBizProc = currentURL.pathname.includes("bizproc-editor");
    const user = useSelector(userModel.selectUser);
    const { menuItems, badges } = useSidebar(collapsedMenu);
    const { layout } = useHeader();
    const clickCollapseButton = () => setCollapsedMenu(!collapsedMenu);
    const clickCollapseMobileButton = () => setCollapsedMenuMobile(!collapsedMenuMobile);
    return (
        <>
            <Layout className="main-layout">
                <Layout className="main-inner-layout">
                    <Sidebar
                        setCollapsedMenu={setCollapsedMenu}
                        collapsed={collapsedMenu}
                    />
                    {windowWidth <= 1150 && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <Button
                                    type="text"
                                    onClick={clickCollapseMobileButton}
                                    size="large"
                                    style={{
                                        marginBottom: 10,
                                        marginTop: 10,
                                        marginLeft: 10,
                                    }}
                                >
                                    {collapsedMenuMobile ? (
                                        <CloseOutlined />
                                    ) : (
                                        <MenuOutlined />
                                    )}
                                </Button>
                                {collapsedMenuMobile && (
                                    <Menu
                                        theme="light"
                                        style={{
                                            position: "absolute",
                                            width: "80%",
                                            zIndex: 9999,
                                            background: "#f3f3f3",
                                            overflow: "auto",
                                            maxHeight: "80%",
                                            marginLeft: 15,
                                            borderRadius: 10,
                                        }}
                                        mode="inline"
                                        items={menuItems}
                                        onClick={() =>
                                            setCollapsedMenuMobile(!collapsedMenuMobile)
                                        }
                                    />
                                )}
                            </div>
                            {layout.title}
                            <UserShortProfile
                                user={user}
                                badges={badges}
                                showNotificationBell={true}
                            />
                        </div>
                    )}
                    <Layout className="site-layout">
                        {windowWidth > 1150 && (
                            <Header
                                collapse={collapsedMenu}
                                preNode={
                                    <Button
                                        type="text"
                                        icon={
                                            collapsedMenu ? (
                                                <MenuOutlined />
                                            ) : (
                                                <MenuOutlined />
                                            )
                                        }
                                        onClick={clickCollapseButton}
                                        style={{
                                            marginLeft: 10,
                                            fontSize: "16px",
                                            minWidth: 64,
                                            height: 64,
                                        }}
                                    />
                                }
                                postNode={
                                    <div style={{ marginRight: 20 }}>
                                        <UserShortProfile
                                            user={user}
                                            badges={badges}
                                            showNotificationBell={true}
                                        />
                                    </div>
                                }
                            />
                        )}
                        <Layout.Content
                            className="site-content-layout"
                            style={{
                                padding: `15px`,
                                paddingTop: "15px",
                                paddingLeft: !collapsedMenu || windowWidth > 415 ? 16 : 0,
                                overflow: !collapsedMenu && !isBizProc ? "auto" : "",
                            }}
                        >
                            {children}
                        </Layout.Content>
                    </Layout>
                </Layout>
                <AlertNotification />
            </Layout>
            <UserProfileDrawer />
        </>
    );
};

export default SiteLayout;
