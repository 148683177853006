import { releaseApi } from "@entities/Production/Release";
import { useEffect, useState } from "react";

export const useReleasesTable = (
    level: number,
    groups?: TReleaseGroup[],
    additionalFilters?: any
) => {
    const [releases, setReleases] = useState<TRelease[]>([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [deletingIds, setDeletingIds] = useState<number[]>([]);
    const [publishIds, setPublishIds] = useState<number[]>([]);
    const currentGroup = groups?.[level];

    const loadReleases = (page: number, perPage: number) => {
        setLoading(true);
        releaseApi
            .getReleases(page, perPage, currentGroup, additionalFilters)
            .then((response) => {
                setReleases(response.data.data.data);
                setLastPage(response.data.data.last_page);
                setPage(response.data.data.current_page);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setReleases([]);
        loadReleases(1, perPage);
    }, [currentGroup, additionalFilters]);

    const onShowSizeChange = (current: number, size: number) => {
        setPage(current);
        setPerPage(size);
        loadReleases(current, size);
    };

    const onSelectRows = (selectedRows: any[]) => {
        console.log("selectedRows", selectedRows);
    };

    const onDelete = (id: number) => {
        setDeletingIds((prev) => [...prev, id]);
        releaseApi
            .deleteRelease(id)
            .then(() => {
                setReleases(releases.filter((release) => release.id !== id));
            })
            .catch(() => {})
            .finally(() => {
                setDeletingIds((prev) => prev.filter((item) => item !== id));
            });
    };
    const onPublish = (ids: number[]) => {
        setPublishIds((prev) => [...prev, ...ids]);
        releaseApi
            .publishReleases(ids)
            .then(() => {
                setReleases(
                    releases.map((release) =>
                        ids.includes(release.id)
                            ? {
                                  ...release,
                                  posted: 1,
                              }
                            : release
                    )
                );
            })
            .catch(() => {})
            .finally(() => {
                setPublishIds((prev) => prev.filter((item) => !ids.includes(item)));
            });
    };

    return {
        loading,
        page,
        perPage,
        lastPage,
        currentGroup,
        releases,
        deletingIds,
        publishIds,
        setReleases,
        onPublish,
        onShowSizeChange,
        onSelectRows,
        onDelete,
    };
};
