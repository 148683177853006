import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useTMNecessaryItems } from "../model";
import TMNecessaryItem from "./TMNecessaryItem";
import "./TMNecessaryItems.scss";
interface ITMNecessaryItems {}

const TMNecessaryItems = ({}: ITMNecessaryItems) => {
    const { operationItems, templateItems } = useTMNecessaryItems();
    return (
        <>
            {templateItems.length > 0 ? (
                <>
                    <span className="tmn-items__title">
                        Необходимые компоненты для выполнения операции
                    </span>
                    <div
                        style={{
                            height: "100%",
                            overflowY: "scroll",
                        }}
                    >
                        {templateItems.map((templateItem) => {
                            const operationItem = operationItems.find(
                                (oItem) =>
                                    oItem.tm_template_necessary_item_uuid ===
                                    templateItem.uuid
                            );
                            return (
                                <IndentContainer key={templateItem.id}>
                                    <TMNecessaryItem
                                        templateItem={templateItem}
                                        operationItem={operationItem}
                                    />
                                </IndentContainer>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div style={{ height: "100%" }}>
                    Компоненты для выполнения операции не требуются
                </div>
            )}
        </>
    );
};

export default TMNecessaryItems;
