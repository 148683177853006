import { useSelector } from "react-redux";
import { selectNavigationRoutes } from "./NavigationRouteSelectors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNavigationRoutesThunk } from "./NavigationRouteThunk";

export const useLoadNavigationRoutes = () => {
    const navigationRoutes = useSelector(selectNavigationRoutes);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getNavigationRoutesThunk());
    }, [dispatch]);

    return {
        navigationRoutes,
    };
};
