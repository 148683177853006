import { $api } from "@shared/api/api";
const route = "/api/real_device";

export const getDevices = () => {
    return $api.get<TResponseList<TDevice>>(route);
};
export const writeCard = (deviceId: number, userId: number) => {
    return $api.post<TResponse<null>>(`${route}/${deviceId}/write`, {
        userId: userId,
    });
};
export const updateDevice = (device: TDevice) => {
    return $api.put<TResponse<TDevice>>(`${route}/${device.id}`, device);
};
