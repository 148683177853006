export const getPreparedFilters = (
    filters: TTechMapFilters,
    additionalFilters?: TTechMapFilters
): TMultipleCondition | undefined => {
    const settedFilters = ["user_id", "technical_map_template_uuid", "status"].reduce(
        (prev, current) => {
            return {
                ...prev,
                [current]: [
                    ...(filters[current as keyof TTechMapFilters] ?? []),
                    ...(additionalFilters?.[current as keyof TTechMapFilters] ?? []),
                ],
            };
        },
        {} as TTechMapFilters
    );

    const conditions = Object.keys(settedFilters)
        .filter((key) => {
            const filterValue = settedFilters[key as keyof TTechMapFilters];
            return filterValue?.length && filterValue.length > 0;
        })
        .map((key) => {
            return {
                value: `(${settedFilters[key as keyof TTechMapFilters]?.map((item) => `'${item}'`).join(",")})`,
                key,
                condition: "in",
            };
        });
    if (conditions.length === 0) return undefined;
    return {
        condition_type: "AND",
        conditions: conditions,
    };
};
