import { DeleteOutlined } from "@ant-design/icons";
import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { ErrorText } from "@shared/ui/Text";
import { Button, Flex } from "antd";
import { useRef } from "react";

interface IEditQueryActionParamsOrHeadersItem {
    index: number;
    onChangeQueryParamsKeyValue: (
        name: string,
        newValue: string,
        newIndex: number
    ) => void;
    itemId: number;
    onDelete: (index: number) => void;
    paramKey: string;
    paramValue: string;
    keyPlaceholder: string;
    ValuePlaceholder: string;
}

const EditQueryActionParamsOrHeadersItem = ({
    index,
    onChangeQueryParamsKeyValue,
    itemId,
    onDelete,
    paramKey,
    paramValue,
    keyPlaceholder,
    ValuePlaceholder,
}: IEditQueryActionParamsOrHeadersItem) => {
    const onDelete_ = () => onDelete(index);
    const keyChanged = useRef(false);
    const valueChanged = useRef(false);

    return (
        <Flex vertical key={`${index}`} style={{ paddingBottom: 8 }}>
            <Flex gap={8} align="center">
                <Flex style={{ flex: "1 1 30%" }}>
                    <InputWithVariables
                        name={`key`}
                        width={300}
                        value={paramKey}
                        isTextArea={false}
                        onChange={(name, value) => {
                            keyChanged.current = true;
                            onChangeQueryParamsKeyValue(name, value, index);
                        }}
                        itemId={itemId}
                        placeholder={keyPlaceholder}
                    />
                </Flex>
                =
                <Flex style={{ flex: "1 1 65%" }}>
                    <InputWithVariables
                        name={`value`}
                        isTextArea={false}
                        value={paramValue}
                        onChange={(name, value) => {
                            valueChanged.current = true;
                            onChangeQueryParamsKeyValue(name, value, index);
                        }}
                        itemId={itemId}
                        placeholder={ValuePlaceholder}
                    />
                </Flex>
                <Flex style={{ flex: "1 1 fit-content" }}>
                    <Button
                        icon={<DeleteOutlined />}
                        type="link"
                        onClick={onDelete_}
                        danger
                    />
                </Flex>
            </Flex>
            <Flex style={{ position: "relative" }}>
                {keyChanged.current &&
                valueChanged.current &&
                (paramKey.length === 0 || paramValue.length === 0) ? (
                    <ErrorText
                        customStyle={{ bottom: -16, left: 4 }}
                        absolute
                        text="Некорректный параметр"
                    />
                ) : undefined}
            </Flex>
        </Flex>
    );
};

export default EditQueryActionParamsOrHeadersItem;
