import { DevicesList } from "@features/DevicesList";

interface IDevicesPage {}

const DevicesPage = ({}: IDevicesPage) => {
    return (
        <div
            style={{
                display: "flex",
                borderRadius: 8,
                backgroundColor: "white",
                height: "100%",
                width: "100%",
            }}
        >
            <DevicesList />
        </div>
    );
};

export default DevicesPage;
