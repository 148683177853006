import { useEstimationValues } from "../model";
import { Button, Empty, Flex, Spin } from "antd";
import EstimationValuesList from "./EstimationValuesList";
import EstimationValuesTitle from "./EstimationValuesTitle";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
interface IEstimationValues {
    entityId: number;
}
// TODO remove estimation redux store. add props word
const EstimationValues = ({ entityId }: IEstimationValues) => {
    const { estimation, applyingLoading, resetTemplate, reloadTemplate } =
        useEstimationValues(entityId);
    if (!estimation) return <></>;
    return (
        <div style={{ width: "100%" }}>
            <Spin spinning={applyingLoading}>
                <Flex justify="space-between" align="center">
                    <div style={{ marginLeft: 8 }}>
                        <EstimationValuesTitle name={estimation.name} />
                    </div>
                    <div>
                        <Button
                            onClick={reloadTemplate}
                            type="text"
                            icon={<ReloadOutlined />}
                        />
                        <Button
                            onClick={resetTemplate}
                            type="text"
                            icon={<CloseOutlined />}
                        />
                    </div>
                </Flex>
                <Flex
                    gap={16}
                    wrap="wrap"
                    justify={estimation.values?.length === 0 ? "center" : undefined}
                >
                    {estimation.values?.length > 0 ? (
                        <EstimationValuesList
                            entityId={entityId}
                            templateValues={estimation.values}
                        />
                    ) : (
                        <>
                            {!applyingLoading && (
                                <div>
                                    {`Шаблон "${estimation.name}" не имеет выбранных полей`}
                                </div>
                            )}
                        </>
                    )}
                </Flex>
            </Spin>
        </div>
    );
};

export default EstimationValues;
