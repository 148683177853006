import { AgreementListItem } from "@entities/Portal/BizProc";
import { EmptyPage } from "@shared/EmptyPage";

import { ListAnimation } from "@shared/animations";
import { CardError } from "@shared/ui/Cards/CardError";
import { useBizProcAgreementWS } from "../BizProcWebsocket";
import "./BizProcAgreementsList.scss";
import BizProcAgreementsSkeletonList from "./BizProcAgreementsSkeletonList";
import { useBizProcAgreementsList } from "./model";

interface BizProcAgreementsListInterface {
    isMyAgreements?: boolean;
}

const BizProcAgreementsList = ({ isMyAgreements }: BizProcAgreementsListInterface) => {
    useBizProcAgreementWS(isMyAgreements);
    const {
        bizProcAgreements,
        isMounted,
        isAgreementsLoading,
        bizProcAgreementLoadingError,
        onClick,
    } = useBizProcAgreementsList(isMyAgreements);

    if (bizProcAgreements.length > 0) {
        return (
            <>
                <div>
                    {bizProcAgreements.map((bizProc) => {
                        return (
                            <div
                                key={`agreementsList-${isMyAgreements ? "my" : ""}-${bizProc.id}`}
                            >
                                <div className="agreement-header">{bizProc.name}</div>
                                <ListAnimation
                                    layoutId={`agreementsList-${
                                        isMyAgreements ? "my" : ""
                                    }-${bizProc.id}`}
                                    key={`agreementsList-${isMyAgreements ? "my" : ""}`}
                                    isMounted={isMounted}
                                    classNameList="agreements-list"
                                    classNameItem="agreements-list__item"
                                    items={bizProc.biz_proc_actions}
                                    itemRenderItem={(item: TBizProcAction) => (
                                        <AgreementListItem
                                            isMyAgreements={isMyAgreements}
                                            onClick={onClick}
                                            agreement={item}
                                        />
                                    )}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
    if (isAgreementsLoading) {
        return <BizProcAgreementsSkeletonList />;
    }
    if (bizProcAgreementLoadingError) {
        return (
            <CardError
                message={bizProcAgreementLoadingError?.error?.message}
                status={bizProcAgreementLoadingError?.payload?.status}
            />
        );
    }
    return <EmptyPage text="Согласования отсутствуют" />;
};

export default BizProcAgreementsList;
