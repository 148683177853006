import { LoadingOutlined } from "@ant-design/icons";
import { BizProcHistoryActionItem } from "@entities/Portal/BizProc";
import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import { UserDateWithTimezone } from "@entities/Portal/User";

const BizProcTimelineItemChildren = ({ action }: { action: TBizProcAction }) => {
    const getClassNameByStatus = (status: TBizProcStatus) => {
        switch (status) {
            case "done":
                return "bizproc-action-title--done";
            case "approve":
                return "bizproc-action-title--success";
            case "reject":
                return "bizproc-action-title--danger";
            case "executing":
                return "bizproc-action-title--pending";
            default:
                return "";
        }
    };

    return (
        <CardWithHead
            key={`card${action.id}`}
            title={
                <div>
                    <span style={{ textAlign: "center" }}>{action.name} </span>
                    <span style={{ marginLeft: 10 }}>
                        {action.status === "executing" && <LoadingOutlined />}
                    </span>
                </div>
            }
            withDivider={true}
            noColor={true}
            titleClassName={`bizproc-action__title ${getClassNameByStatus(action.status)}`}
            childrenClassName="bizproc-action__body"
        >
            <BizProcHistoryActionItem action={action} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <UserDateWithTimezone
                    dateStyle={{ fontSize: 12 }}
                    date={action.updated_at}
                />
            </div>
        </CardWithHead>
    );
};

export default BizProcTimelineItemChildren;
