import { TableProps } from "antd";
import dayjs from "dayjs";
import ReleaseItemsSendButton from "./ReleaseItemsSendButton";
import { groupFilterKey } from "../constants";

const getReleaseColumn = (currentGroup?: "nomenclature" | "release") => {
    return {
        title: "Выпуск",
        render: (_: any, record: any) => {
            if (currentGroup === "release" && record.objectValue !== null) return "-";
            if (record.production_release_id)
                return record.production_release.is_sent === 0
                    ? "Ожидает синхронизации"
                    : "Выпущен";

            return (
                <div onClick={(e) => e.stopPropagation()}>
                    <ReleaseItemsSendButton
                        releasedItems={
                            currentGroup
                                ? {
                                      type: "filter",
                                      filter: [
                                          {
                                              condition: "=",
                                              value: record.value,
                                              key: groupFilterKey[currentGroup],
                                          },
                                      ],
                                  }
                                : {
                                      type: "ids",
                                      items_ids: [record.id],
                                  }
                        }
                    />
                </div>
            );
        },
    };
};
export const getColumns = (
    currentGroup?: "nomenclature" | "release"
): TableProps<any>["columns"] => {
    if (currentGroup === "nomenclature")
        return [
            {
                title: "Наименование",
                dataIndex: "name",
            },
            {
                title: "Количество",
                dataIndex: "count",
            },
            getReleaseColumn(currentGroup),
        ];
    if (currentGroup === "release")
        return [
            {
                title: "Наименование",
                dataIndex: "name",
                render: (_: any, record) => {
                    if (record.objectValue) {
                        return `Выпуск №${record.objectValue?.id}`;
                    }
                    return "Не выпущенно";
                },
            },
            {
                title: "Дата создания",
                dataIndex: "created_at",
                render: (_: any, record) => {
                    const value = record.objectValue?.created_at;
                    return value ? dayjs(value).format("HH:mm | DD.MM.YYYY") : "-";
                },
            },

            {
                title: "Количество",
                dataIndex: "count",
            },
            getReleaseColumn(currentGroup),
        ];

    return [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Номенклатура",
            render: (value: any, record: any) => {
                return record?.technical_map_result?.entity_row.name;
            },
        },
        {
            title: "ref номенклатуры",
            dataIndex: "nomenclature_ref",
        },
        {
            title: "Дата создания",
            dataIndex: "created_at",
            render: (value: any) => {
                return value ? dayjs(value).format("HH:mm | DD.MM.YYYY") : "-";
            },
        },
        getReleaseColumn(currentGroup),
    ];
};
