import { actionModel } from "@entities/Portal/Action";
import { ActionsList } from "@features/Portal/Action/ActionsList";
import { ActionCreateButton } from "@features/Portal/Action/CreateAction";
import { ExportButton } from "@features/Portal/Action/Export";
import { ImportButton } from "@features/Portal/Action/Import";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { Space } from "antd";

const ActionsPage = () => {
    useHeaderLayout({
        title: "Редактор блоков",
    });
    const { actions } = actionModel.useLoadActions(true);

    return (
        <div>
            <IndentContainer>
                <div style={{ display: "flex" }}>
                    <Space>
                        {actions.length > 0 && (
                            <>
                                <ActionCreateButton />
                                <ExportButton url="action/export" filename="actions" />
                            </>
                        )}
                        <ImportButton />
                    </Space>
                </div>
            </IndentContainer>
            <ActionsList />
        </div>
    );
};

export default ActionsPage;
