import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createEntityField,
    deleteEntityField,
    getEntityFields,
    updateEntityField,
} from "../api";

export const loadEntityFieldsThunk = createAsyncThunk(
    "EntityFieldSlice/loadEntityFieldFields",
    async (
        {
            entityId,
            params,
        }: {
            entityId?: number | undefined;
            params?: TParams;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await getEntityFields(entityId, params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateEntityFieldThunk = createAsyncThunk(
    "EntityFieldSlice/saveEntityField",
    async (entity: TEntityField, { rejectWithValue }) => {
        try {
            const response = await updateEntityField(entity);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);

export const createEntityFieldThunk = createAsyncThunk(
    "EntityFieldSlice/createEntityField",
    async (entity: TEntityField, { rejectWithValue }) => {
        try {
            const response = await createEntityField(entity);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const deleteEntityFieldThunk = createAsyncThunk(
    "EntityFieldSlice/deleteEntityField",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteEntityField(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
