import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface IEntitySelect {
    multiple?: boolean;
    disabled?: boolean;
    value: string | string[] | null;
    onChangeSelect: (
        value: any,
        item: { label: string; value: string; option: TEntity } | null
    ) => void;
    placeholder?: string;
    isDropdownWidthIsFitContent?: boolean;
    maxTagCount?: number;
}

const EntitySelect = ({
    multiple,
    disabled,
    placeholder,
    value,
    onChangeSelect,
    isDropdownWidthIsFitContent,
    maxTagCount,
}: IEntitySelect) => {
    return (
        <PaginatedSelect
            isDropdownWidthIsFitContent={isDropdownWidthIsFitContent}
            onlyRead={disabled}
            multiple={multiple}
            maxTagCount={maxTagCount}
            requestUrl={`${apiUrl}/api/entity?idKey=uuid`}
            value={value}
            placeholder={placeholder}
            storeName="entitytable"
            valueKey="uuid"
            labelKey="name"
            onChangeValue={onChangeSelect}
            initialOptions={[]}
        />
    );
};

export default EntitySelect;
