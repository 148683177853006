import { CSSProperties } from "react";
import "./TextStyles.scss";
interface IErrorTextProps {
    text: string | undefined;
    absolute?: boolean;
    customStyle?: CSSProperties;
}
const ErrorText = ({ text, absolute, customStyle }: IErrorTextProps) => {
    if (!text) return <></>;
    return (
        <div
            className="error-text"
            style={{
                ...(customStyle ? customStyle : {}),
                position: absolute ? "absolute" : undefined,
            }}
        >
            {text}
        </div>
    );
};

export default ErrorText;
