import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    LeftOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { Button } from "antd";
import { useSelector } from "react-redux";

interface IBizProcTemplateItemMoveButtons {
    index: number;
    isLastChildBranch?: boolean;
    onChangeBranchIndex: (index: number, indexChangeStep: 1 | -1) => void;
    onCopyBranchToNeighbourBranch: (index: number, indexMoveStep: 1 | -1) => void;
}

const BizProcTemplateItemMoveButtons = ({
    index,
    isLastChildBranch,
    onChangeBranchIndex,
    onCopyBranchToNeighbourBranch,
}: IBizProcTemplateItemMoveButtons) => {
    const isCopyingMovingMode = useSelector(
        bizProcTemplateModel.selectIsCopyingMovingMode
    );
    const preventDefaultAndStopPropagation = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onLeftArrowsClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        preventDefaultAndStopPropagation(e);
        isCopyingMovingMode
            ? onCopyBranchToNeighbourBranch(index, -1)
            : onChangeBranchIndex(index, -1);
    };
    const onRightArrowsClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        preventDefaultAndStopPropagation(e);
        isCopyingMovingMode
            ? onCopyBranchToNeighbourBranch(index, 1)
            : onChangeBranchIndex(index, 1);
    };

    return (
        <div style={{ position: "absolute", top: -25 }} onFocus={(e) => e.target.focus()}>
            <Button
                className="template__item--action-button"
                icon={
                    isCopyingMovingMode ? (
                        <DoubleLeftOutlined />
                    ) : index === 0 ? (
                        <></>
                    ) : (
                        <LeftOutlined />
                    )
                }
                onClick={onLeftArrowsClick}
                type="text"
            />
            <Button
                className="template__item--action-button"
                icon={
                    isCopyingMovingMode ? (
                        <DoubleRightOutlined />
                    ) : isLastChildBranch ? (
                        <></>
                    ) : (
                        <RightOutlined />
                    )
                }
                onClick={onRightArrowsClick}
                type="text"
            />
        </div>
    );
};

export default BizProcTemplateItemMoveButtons;
