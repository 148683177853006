import { PrinterOutlined } from "@ant-design/icons";
import { checkConnectionAndPrintCode } from "@shared/StickerPrinter/emits";
import { Button } from "antd";
import styled from "styled-components";
const StyledButton = styled(Button)`
    min-height: 0;
    padding: 0;
    margin: 0;
    z-index: 2;
    color: var(--primary);
    background: white;
    border: 1px solid rgba(5, 5, 5, 0.06);

    &:hover {
        background: var(--primary) !important;
        color: white;
        scale: 1.05;
    }
`;

interface IQrBarCodesPrintButton {
    value: string;
    type: TPrinterCodeItemType;
}

const QrBarCodesPrintButton = ({ value, type }: IQrBarCodesPrintButton) => {
    const printCodeClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        checkConnectionAndPrintCode(value, type);
    };

    return (
        <StyledButton
            type="primary"
            onClick={printCodeClick}
            icon={<PrinterOutlined />}
        />
    );
};

export default QrBarCodesPrintButton;
