import { AddEntityField } from "@features/Portal/Entity/AddEntityField";
import { EntityFieldItemList } from "@features/Portal/Entity/EntityFieldItemList";
import EntityFieldEditCreate from "@widgets/Portal/Entity/EntityFieldList/EntityFieldEditCreate";
import { EntityFieldsFilters } from "@widgets/Portal/Filters/EntityFieldsFIlters";
import { Flex, Typography } from "antd";
import "./EntityFieldsWidget.scss";
import { useSelector } from "react-redux";
import { datatypeModel } from "@entities/Portal/Datatype";

interface IEntityFieldsWidget {
    entity: TEntity;
    entityId: number;
    isCreatingEntity?: boolean;
}

const EntityFieldsWidget = ({
    entity,
    entityId,
    isCreatingEntity,
}: IEntityFieldsWidget) => {
    const datatypes = useSelector(datatypeModel.selectDatatypes);
    return (
        <Flex style={{ overflow: "hidden", height: "100%", width: "100%" }}>
            <Flex
                className="entity-fields-widget__entity-fields-list-container"
                flex={isCreatingEntity ? "1 1 100%" : "0 1 50%"}
                vertical
                style={{ padding: isCreatingEntity ? 0 : "0 16px 0 8px" }}
                gap={8}
            >
                <Typography.Title
                    level={5}
                    style={{ margin: 0, marginTop: 8, marginLeft: 16, flex: "0 1 24px" }}
                >
                    Настройка полей
                </Typography.Title>
                <Flex
                    gap={4}
                    align="center"
                    wrap="wrap"
                    className="entity-fields-widget__header-of-list"
                >
                    <Flex
                        className="add-entity-field-container"
                        style={{ flex: "1 1 45%" }}
                    >
                        <AddEntityField
                            datatypes={datatypes}
                            entityUuid={entity!.uuid}
                            entityId={entityId}
                        />
                    </Flex>
                    <Flex className="entity-fields-filters-container">
                        {entityId && (
                            <EntityFieldsFilters
                                entityId={entityId}
                                entityUuid={entity?.uuid}
                            />
                        )}
                    </Flex>
                </Flex>
                <Flex
                    className="entity-field-item-list-container"
                    style={{
                        flex: "0 1 100%",
                        overflow: "auto",
                        // overflowX: "hidden",
                        padding: isCreatingEntity ? "0.5%" : undefined,
                    }}
                >
                    <EntityFieldItemList
                        entityId={entityId}
                        view={isCreatingEntity ? "card" : "list"}
                        datatypes={datatypes}
                    />
                </Flex>
            </Flex>
            <Flex
                style={{
                    flex: "1 1 50%",
                    padding: 8,
                    paddingRight: 24,
                    position: "relative",
                }}
                className="create-edit-entity-field-form-container"
                justify="center"
            >
                <EntityFieldEditCreate isCreatingEntity={isCreatingEntity} />
            </Flex>
        </Flex>
    );
};

export default EntityFieldsWidget;
