import { getDateFormattedValue, getDatePickerFormat } from "@shared/lib/date";
import { DatePicker } from "antd";

interface IFilterDate {
    filters: TFilterValue[];
    setFilters: (filters: TFilterValue[]) => void;
}

const FilterDate = ({ filters, setFilters }: IFilterDate) => {
    const dateStart =
        filters.find((filter) => filter.key === "created_at" && filter.condition === ">=")
            ?.value ?? null;
    const dateEnd =
        filters.find((filter) => filter.key === "created_at" && filter.condition === "<=")
            ?.value ?? null;

    const onChangeBetween = (values: any, formatString: [string, string]) => {
        const newFilters = filters.filter((filter) => filter.key !== "created_at");
        if (values?.[0]) {
            newFilters.push({
                value: getDateFormattedValue(values[0]),
                condition: ">=",
                key: "created_at",
            });
        }
        if (values?.[1]) {
            newFilters.push({
                value: getDateFormattedValue(values[1]),
                condition: "<=",
                key: "created_at",
            });
        }

        setFilters(newFilters);
    };

    return (
        <DatePicker.RangePicker
            style={{ width: "100%", minWidth: 300 }}
            format={"DD.MM.YYYY"}
            showTime={false}
            value={[getDatePickerFormat(dateStart), getDatePickerFormat(dateEnd)]}
            picker="date"
            changeOnBlur
            allowEmpty={[true, true]}
            onChange={onChangeBetween}
        />
    );
};

export default FilterDate;
