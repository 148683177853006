import { qtyDatatypes } from "./constants";

export const compareResultById = (
    item: TTechMapTemplateResult | TTechMapTemplateResultCreating,
    originalItem: TTechMapTemplateResult | TTechMapTemplateResultCreating
) => {
    return "id" in item
        ? "id" in originalItem && originalItem.id === item.id
        : "creating" in originalItem && originalItem.creating === item.creating;
};

export const getEntityFieldsForQty = (fields: TEntityField[]) => {
    return fields
        .map((field) => ({
            item: field,
            label: field.name,
            value: field.key,
        }))
        .filter(
            (option) =>
                qtyDatatypes.includes(option.item.datatype) &&
                !option.item.is_system &&
                !option.item.is_unwritable
        );
};
