import { useEffect, useState } from "react";
import { selectExecutableTmts, selectGetExecutableLoading } from "./TechMapSelectors";
import { useDispatch, useSelector } from "react-redux";
import { loadExecutableTmtsThunk } from "./TechMapApiThunk";
import { getExecutableTmtsWithFilters } from "../api";
import { setExecutableTmts } from "./TechMapSlice";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { TFiltersTechMap } from "@widgets/Production/TechMapTemplatesFilters/types";
import { isNumber } from "lodash";

export const useLoadExecutableTmts = () => {
    const loading = useSelector(selectGetExecutableLoading);
    const executableTmts = useSelector(selectExecutableTmts);
    const dispatch = useDispatch<AppDispatch>();
    const [isPaginationLoading, setIsPaginationLoading] = useState<boolean>(false);
    const [paginationIsEnded, setPaginationIsEnded] = useState<boolean>(false);
    const [filters, setFilters] = useState<TFiltersTechMap>({
        name: null,
        entity_uuid: null,
        entity_row_id: null,
        is_active: undefined,
        executor_roles: null,
        reviewer_roles: null,
    });
    const perPage = 15;
    const executorRoles = filters.executor_roles;
    const reviewerRoles = filters.reviewer_roles;
    const resultExecutorRoles =
        executorRoles === null || executorRoles.length === 0 ? null : executorRoles;
    const resultReviewerRoles =
        reviewerRoles === null || reviewerRoles.length === 0 ? null : reviewerRoles;
    useEffect(() => {
        setPaginationIsEnded(false);
        let result = null;
        if (filters.entity_uuid) {
            result = {
                entity_uuid: filters.entity_uuid,
                entity_row_id: filters.entity_row_id,
            };
        }

        //TODO: вынести в компонент фильтров, прокидывать функцию запроса в компонент фильтров
        //Использовать функции высшего порядка (filters) => (templateId, result) => {}
        //повторяющаяся логика useTechMapTemplatesLoad
        const filter =
            filters.name || filters.is_active
                ? {
                      condition_type: "AND" as TConditionType,
                      conditions: [
                          ...(filters.name
                              ? [
                                    {
                                        key: "name",
                                        value: `%${filters.name}%`,
                                        condition: "like",
                                    },
                                ]
                              : []),
                          ...(isNumber(filters.is_active)
                              ? [
                                    {
                                        key: "is_active",
                                        value: filters.is_active,
                                        condition: "=",
                                    },
                                ]
                              : []),
                      ],
                  }
                : null;

        dispatch(
            loadExecutableTmtsThunk({
                result,
                filter,
                perPage,
                executor_roles: resultExecutorRoles,
                reviewer_roles: resultReviewerRoles,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters]);

    const loadTechMapTemplatesWithPagination = (lastTemplateId: number) => {
        setIsPaginationLoading(true);
        const newFilter = {
            condition_type: "AND" as TConditionType,
            conditions: [
                ...(filters.name
                    ? [
                          {
                              key: "name",
                              value: `%${filters.name}%`,
                              condition: "like",
                          },
                      ]
                    : []),
                ...(isNumber(filters.is_active)
                    ? [
                          {
                              key: "is_active",
                              value: filters.is_active,
                              condition: "=",
                          },
                      ]
                    : []),
                { key: "id", value: lastTemplateId, condition: ">" },
            ],
        };

        getExecutableTmtsWithFilters(
            newFilter,
            filters.entity_uuid
                ? {
                      entity_uuid: filters.entity_uuid,
                      entity_row_id: filters.entity_row_id,
                  }
                : undefined,
            resultExecutorRoles,
            resultReviewerRoles,
            perPage
        )
            .then((response) => {
                const {
                    data: {
                        data: { data },
                    },
                } = response;
                dispatch(setExecutableTmts([...executableTmts, ...data]));

                if (response.data.data.total < perPage) setPaginationIsEnded(true);
            })
            .catch((e) => {
                console.log(e);
                notificationEmit({ type: "error", title: "Ошибка загрузки тех. карт" });
            })
            .finally(() => setIsPaginationLoading(false));
    };

    return {
        executableTmts,
        loading,
        loadTechMapTemplatesWithPagination,
        paginationIsEnded,
        isPaginationLoading,
        filters,
        setFilters,
    };
};
