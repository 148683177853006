import { getUnixTimestamp } from "@shared/lib/date";
import { checkRequiredFields } from "@entities/Portal/Datatype";
import { useState } from "react";

interface IUseEntityInput {
    value: any;
    onChangeValue: (value: any) => void;
    onlyRead?: boolean;
    additionalParameters: TEntityAdditionalParameters;
    field: TEntityField | TVariable;
}

export const useEntityInput = ({
    value,
    onChangeValue,
    additionalParameters,
    onlyRead,
    field,
}: IUseEntityInput) => {
    const [viewMode, setViewMode] = useState<TEntityInputViewMode>(
        (localStorage.getItem(`${field.id}__${field.name}__entityViewMode`) as
            | "table"
            | "list") ?? "list"
    );
    const [open, setOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<null | object>(null);
    const [isOpenFullView, setIsOpenFullView] = useState(false);
    const [error, setError] = useState<any>({});
    const values = value ? (Array.isArray(value) ? value : [value]) : [];
    const fields = additionalParameters?.fields;
    const entityTableName = additionalParameters?.entityTableName;
    const countShowFormat = additionalParameters?.countShowFormat;
    const onCloseFillView = () => {
        setIsOpenFullView(false);
    };

    const onOpenFillView = () => {
        setIsOpenFullView(true);
    };

    const onSubmitValue = (updatedValue: any) => {
        const { isValid, errorFields } = checkRequiredFields(
            (fields ?? []).map((field) => ({
                ...field,
                value: updatedValue[field.key],
            }))
        );
        setError(errorFields);

        if (!isValid) return;
        if (field.is_multiple) {
            const isExist = value?.find((item: any) =>
                !item.id
                    ? updatedValue.tmp_id === item.tmp_id
                    : item.id === updatedValue.id
            );
            if (isExist) {
                onChangeValue(
                    value.map((item: any) =>
                        (
                            !item.id
                                ? updatedValue.tmp_id === item.tmp_id
                                : item.id === updatedValue.id
                        )
                            ? updatedValue
                            : item
                    )
                );
            } else {
                onChangeValue([updatedValue, ...(value ?? [])]);
            }
        } else {
            onChangeValue(updatedValue);
        }
        setOpen(false);
    };
    const onAdd = () => {
        setEditingItem({
            id: null,
            tmp_id: getUnixTimestamp(),
        });
        setOpen(true);
    };
    const onEdit = (item: any) => {
        setEditingItem(item);
        setOpen(true);
    };
    const onDelete = (deletedItem: any) => {
        if (field.is_multiple) {
            onChangeValue(
                value.filter((value: any) =>
                    !value.id
                        ? deletedItem.tmp_id !== value.tmp_id
                        : value.id !== deletedItem.id
                )
            );
        } else {
            onChangeValue(null);
        }
    };

    const onClose = () => {
        setOpen(false);
    };
    return {
        viewMode,
        fields,
        countShowFormat,
        error,
        isOpenFullView,
        open,
        editingItem,
        values,
        entityTableName,
        setViewMode,
        onOpenFillView,
        onSubmitValue,
        onCloseFillView,
        onClose,
        onDelete,
        onEdit,
        onAdd,
    };
};
