import ActionCard from "./ActionCard";

interface IDeviceMessageAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const DeviceMessageAction = ({ onDeleteItem, templateItem }: IDeviceMessageAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="#3DFF16"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Сообщение устройство"}
        ></ActionCard>

        // <CardWithHead
        //     noColor
        //     bordered
        //     title={<EditTemplateBar
        //         onDeleteItem={onDeleteItem}
        //         textColor="black"
        //         title={templateItem?.name ?? "Уведомление"} />}
        // />
    );
};

export default DeviceMessageAction;
