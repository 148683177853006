import { SignInForm } from "@features/Portal/Auth/SignInForm";
import { Button } from "antd";
import "./LoginPage.scss";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
    const navigate = useNavigate();

    const onRecoverClick = () => {
        navigate("/start-recover-password");
    };
    return (
        <div className="login-page">
            <div className="login-page__signin">
                <SignInForm />
            </div>
            <div className="login-page__remember">
                <Button onClick={onRecoverClick} type="link">
                    Восстановить пароль
                </Button>
            </div>
        </div>
    );
};

export default LoginPage;
