import { BizProcInfo, useBizProcInfo } from "@features/Portal/BizProc/BizProcInfo";
import {
    BizProcHistorySwitcher,
    BizProcHistoryList,
} from "@widgets/Portal/BizProcWidgets/BizProcHistory";
import { useHeaderLayout } from "@shared/ui/Header";
import "./BizProcInfoPage.scss";

import { BizProcFillForm } from "@features/Portal/BizProc/BizProcFillForm";
import { BizProcFillVariables } from "@features/Portal/BizProc/BizProcFillVariables";
import { useParams } from "react-router-dom";
const BizProcHistoryPage = () => {
    useHeaderLayout({
        title: `Информация о бизнес-процессе`,
    });
    const params = useParams();
    const bizprocId = +(params?.id ?? 0);
    const { loading, bizProc, isWithStartFormAction, setBizProc, showHidden, onChecked } =
        useBizProcInfo(bizprocId);

    return (
        <div className="bizproc-info">
            <div className="bizproc-info__fields">
                <BizProcInfo loading={loading} bizProc={bizProc} />
                {isWithStartFormAction ? (
                    <>
                        {bizProc && (
                            <BizProcFillForm bizProc={bizProc} setBizProc={setBizProc} />
                        )}
                    </>
                ) : (
                    <>
                        {bizProc && (
                            <BizProcFillVariables
                                bizProc={bizProc}
                                setBizProc={setBizProc}
                            />
                        )}
                    </>
                )}
            </div>

            {bizProc && bizProc?.actions && (
                <div className="biz-proc-history-block" style={{ width: 720 }}>
                    <BizProcHistorySwitcher
                        showHidden={showHidden}
                        onChecked={onChecked}
                    />
                    <BizProcHistoryList actions={bizProc.actions} />
                </div>
            )}
        </div>
    );
};

export default BizProcHistoryPage;
