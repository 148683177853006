import { useHeaderLayout } from "@shared/ui/Header";
import { LogViewerFrame } from "@widgets/Portal/LogViewerFrame";

const LogViewerPage = () => {
    useHeaderLayout({
        title: "Логи",
    });

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <LogViewerFrame />
        </div>
    );
};

export default LogViewerPage;
