export const pagginationCountPerPageOptions = [
    { value: 15 },
    { value: 30 },
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 500 },
];

export const isActiveSelectOptions = [
    { value: 1, label: "Активные" },
    { value: 0, label: "Неактивные" },
];
