import { entityModel } from "@entities/Portal/Entity";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";

interface IEntityRowObserversForm {
    onCancel?: () => void;
    entityUuid: string;
    bizProcTemplates: TBizProcTemplateItem[];
}

export const useEntityRowObserversForm = ({
    onCancel,
    entityUuid,
    bizProcTemplates,
}: IEntityRowObserversForm) => {
    const [bizProcTempalateUuid, setBizProcTemplateUuid] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState<TEntityRowObserverType>("on_create");
    const bizProcTemplateOptions = bizProcTemplates.map((template) => ({
        value: template.uuid,
        label: template.name,
    }));
    const dispatch = useDispatch<AppDispatch>();
    const onChangeBizProcTemplate = (value: string | null) => {
        setBizProcTemplateUuid(value);
    };
    const onChangeType = (value: string) => {
        setType(value as TEntityRowObserverType);
    };
    const onSubmit = () => {
        if (bizProcTempalateUuid) {
            setLoading(true);
            dispatch(
                entityModel.createEntityRowObserverThunk({
                    entity_uuid: entityUuid,
                    biz_proc_template_uuid: bizProcTempalateUuid,
                    type,
                })
            )
                .then(parseFormResponse)
                .then((data: TResponse<TVariable>) => {
                    onClose();
                })
                .catch((error: any) => {
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Ошибка",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onClose = () => {
        onCancel && onCancel();
    };
    return {
        bizProcTemplateOptions,
        bizProcTempalateUuid,
        type,
        loading,
        onChangeBizProcTemplate,
        onSubmit,
        onClose,
        onChangeType,
    };
};
