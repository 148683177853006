import {
    actionNotAllowAddAfter,
    actionsAllowChildren,
    bizProcRowChild,
} from "../constants";

export const isBizProcWithRowChild = (bizProcType: TBizProcActionType) => {
    return bizProcRowChild.includes(bizProcType);
};
export const isBizProcWithSelfChildren = (bizProcType: TBizProcActionType) => {
    return actionsAllowChildren.includes(bizProcType);
};
export const isBizProcNotAllowAddAfter = (bizProcType: TBizProcActionType) => {
    return actionNotAllowAddAfter.includes(bizProcType);
};
