import { viewPatternModel } from "@entities/Portal/ViewPattern";
import { Select, Space, Spin } from "antd";
import { useSelector } from "react-redux";

interface IViewPatternItemCreateSelectOption {
    option: {
        entityField: TEntityField;
        viewPatternItem: TViewPatternItem | undefined;
        value: number | string;
        label: string;
    };
    createLoading: { [key: string | number]: boolean };
    deleteLoading: { [key: string | number]: boolean };
}

const ViewPatternItemCreateSelectOption = ({
    option,
    createLoading,
    deleteLoading,
}: IViewPatternItemCreateSelectOption) => {
    const loading = createLoading[option.value] || deleteLoading[option.value];
    return (
        <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => {
                if (loading) e.stopPropagation();
            }}
        >
            <Space>
                <div>{option.label}</div>
                {loading && <Spin size="small" />}
            </Space>
        </div>
    );
};

export default ViewPatternItemCreateSelectOption;
