import { FormReadValue } from "@shared/ui/Form";
import { Input } from "antd";

interface INumberInput {
    value: number;
    onlyRead?: boolean;
    onChangeValue: (value: any) => void;
    disabled?: boolean;
}

const FloatInput = ({ value, onlyRead, onChangeValue, disabled }: INumberInput) => {
    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value?.replaceAll(",", ".");

        const reg = /^[+-]?\d+(\.\d+)?$/;

        if (
            reg.test(value) ||
            value === "-" ||
            /* eslint eqeqeq: "off" */
            value == "" ||
            /* eslint eqeqeq: "off" */
            (value?.[value.length - 1] == "." &&
                /* eslint eqeqeq: "off" */
                value.split("").filter((item) => item == ".").length <= 1)
        ) {
            onChangeValue(value);
        }

        // const value = event.target.value;

        // const reg = /^-?\d*(\.\d*)?$/;
        // if (!reg.test(value) && value != "") {
        //     return;
        // }

        // onChangeValue(
        //     value === "" ? (null as any as number) : value === "-" ? "-" : Number(value)
        // );
    };
    if (onlyRead) return <FormReadValue value={value ?? "-"} />;
    return (
        <Input disabled={onlyRead || disabled} onChange={onChange} value={value ?? ""} />
    );
};

export default FloatInput;
