import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectEntityFields,
    selectEntityRows,
    selectFilters,
    selectIsLoading,
    selectTextFilterValue,
} from "./EntityRowSelectors";
import { setEntityFields, setEntityRows } from "./EntityRowSlice";
import { getEntityRowsByDatesThunk } from "./EntityRowThunk";
import { getDateRange } from "@shared/lib/date";
import { getCondition, getConditionParameters, getValidParams } from "../lib";

export const useLoadEntityRowsByDates = (
    entityId: number,
    entityUuid: string,
    date: Date,
    dateEndKey: string | undefined,
    dateStartKey: string | undefined,
    eventLabel: string | undefined,
    eventDescription: string | undefined,
    viewMode: TScheduleViewMode
) => {
    const filters = useSelector(selectFilters);
    const textFilterValue = useSelector(selectTextFilterValue);
    const entityRows = useSelector(selectEntityRows);
    const entityFields = useSelector(selectEntityFields);
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        return () => {
            dispatch(setEntityRows([]));
            dispatch(setEntityFields([]));
        };
    }, [dispatch]);

    useEffect(() => {
        if (!dateStartKey) {
            dispatch(setEntityRows([]));
        } else {
            localStorage.setItem(
                `${entityUuid}_schedule`,
                JSON.stringify({
                    dateEndKey,
                    dateStartKey,
                    eventLabel,
                    eventDescription,
                })
            );
            const newDate = dayjs(dayjs(date).format("YYYY-MM-DD HH:mm"));
            const { startValue, endValue } = getDateRange(newDate, viewMode);
            const schedulerCondition = getCondition(
                dateEndKey,
                dateStartKey,
                startValue,
                endValue
            );
            if (schedulerCondition) {
                loadEntityRows(filters, schedulerCondition, textFilterValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filters,
        textFilterValue,
        dateEndKey,
        dateStartKey,
        eventLabel,
        date,
        viewMode,
        eventDescription,
    ]);

    const loadEntityRows = (
        filters: TFilterValue[],
        additionalCondition: TMultipleCondition | undefined,
        textFilter?: string | undefined | null
    ) => {
        const mainFiltersConditions = [...getValidParams([...(filters ?? [])])];
        const parameters = getConditionParameters(
            mainFiltersConditions,
            additionalCondition
        );
        dispatch(
            getEntityRowsByDatesThunk({
                id: entityId,
                filter: parameters,
                textFilter,
            })
        )
            .then(parseFormResponse)
            .then((response: any) => {
                dispatch(setEntityFields(response.data.entity_fields));
            })
            .catch((e: any) => {
                if (mainFiltersConditions.length > 0 && additionalCondition)
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description:
                            "Не удалось загрузить записи сущности на выбранную дату",
                    });
            });
    };
    return {
        isLoading,
        entityFields,
        entityRows,
        loadEntityRows,
    };
};
