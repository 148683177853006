import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import React from "react";
import { useSelector } from "react-redux";

interface IBizProcTemplateItemWrapper {
    templateItem: TBizProcTemplateAction;
    children: React.ReactNode;
    draggable: boolean;
    onDeleteTemplateItem: (item: TBizProcTemplateAction) => void;
    onDragStart: (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction
    ) => void;
    onDragEnd: (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction,
        deleteFunction: (item: TBizProcTemplateAction) => void,
        isCopyingMovingMode?: boolean
    ) => void;
}

const BizProcTemplateItemWrapper = ({
    templateItem,
    children,
    draggable,
    onDeleteTemplateItem,
    onDragEnd,
    onDragStart,
}: IBizProcTemplateItemWrapper) => {
    const isCopyingMovingMode = useSelector(
        bizProcTemplateModel.selectIsCopyingMovingMode
    );
    const onDragStart_ = (event: React.DragEvent<HTMLDivElement>) => {
        onDragStart(event, templateItem);
    };
    const onDragEnd_ = (event: React.DragEvent<HTMLDivElement>) => {
        onDragEnd(event, templateItem, onDeleteTemplateItem, isCopyingMovingMode);
    };
    return (
        <div
            id={`id-${templateItem.key}`}
            draggable={draggable}
            onDragStart={onDragStart_}
            onDragEnd={onDragEnd_}
        >
            {children}
        </div>
    );
};

export default BizProcTemplateItemWrapper;
