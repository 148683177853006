import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Flex, Modal } from "antd";
import React from "react";
import { useEntityModifyModal } from "../model/UseEntityModifyModal";
import CreateUpdateEntityForm from "./CreateUpdateEntityForm";

interface IEntityModifyModal {
    entityFieldsAddingWidget: (entity: TEntity, entityId: number) => React.ReactNode;
}

const EntityModifyModal = ({ entityFieldsAddingWidget }: IEntityModifyModal) => {
    const { editingEntity, isOpen, windowHeight, windowWidth, onClose } =
        useEntityModifyModal();
    return (
        <>
            <Modal
                title={`${editingEntity?.is_creating ? "Создание" : "Редактирование"} сущности`}
                open={isOpen}
                onCancel={onClose}
                destroyOnClose
                width={"90%"}
                footer={[]}
                style={{
                    top: windowHeight <= 700 ? "10%" : undefined,
                    padding: windowWidth <= 700 ? 0 : undefined,
                }}
            >
                <IndentContainer>
                    {editingEntity && (
                        <CreateUpdateEntityForm
                            onCancel={onClose}
                            onSave={onClose}
                            editingEntity={editingEntity}
                            entityId={editingEntity.id}
                        />
                    )}
                </IndentContainer>
                {/* <Typography.Title level={5} style={{ marginTop: 0 }}>
                    Настройка полей
                </Typography.Title>

                <IndentContainer>
                    <AddEntityField
                        datatypes={datatypes}
                        entityUuid={editingEntity?.uuid}
                        entityId={editingEntity?.id}
                    />
                </IndentContainer> */}
                <Flex
                    className="entity-fields-adding-widget-container"
                    style={{ width: "100%" }}
                >
                    {editingEntity &&
                        entityFieldsAddingWidget(editingEntity, editingEntity.id)}
                </Flex>
            </Modal>
        </>
    );
};

export default EntityModifyModal;
