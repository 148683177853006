import { Table, Tag } from "antd";
import { useDevicesList } from "../model";
import { deviceStateTag, deviceStatusTag } from "../constants";
import DeviceEditModal from "./DeviceEditModal";

interface IDevicesList {}

const DevicesList = ({}: IDevicesList) => {
    const { openedDevice, devices, isOpen, loading, onRowClick, onCloseOpenedDevice } =
        useDevicesList();

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Имя",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "MAC адрес",
            dataIndex: "mac_address",
            key: "mac_address",
        },
        {
            title: "Бизнес-процесс",
            dataIndex: "biz_proc_template_name",
            key: "biz_proc_template_name",
        },
        {
            title: "Состояние",
            dataIndex: "state",
            key: "state",
            render: (value: TDeviceState) => {
                return (
                    <Tag color={deviceStateTag?.[value]?.color ?? "red"}>
                        {deviceStateTag?.[value]?.label ?? value}
                    </Tag>
                );
            },
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (value: TDeviceStatus) => {
                return (
                    <Tag color={deviceStatusTag?.[value]?.color ?? "red"}>
                        {deviceStatusTag?.[value]?.label ?? value}
                    </Tag>
                );
            },
        },
    ];
    return (
        <>
            <Table
                onRow={(record) => {
                    return {
                        style: {
                            cursor: "pointer",
                        },
                        onClick: () => onRowClick(record),
                    };
                }}
                style={{
                    height: "100%",
                    width: "100%",
                }}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={devices}
            />
            <DeviceEditModal
                isOpen={isOpen}
                device={openedDevice}
                onClose={onCloseOpenedDevice}
            />
        </>
    );
};

export default DevicesList;
