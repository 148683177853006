export const options = [
    {
        label: "Склад",
        value: "warehouse",
    },
    {
        label: "Организация",
        value: "organization",
    },
    {
        label: "Пользователь",
        value: "user",
    },
];
