import { stageLib, stageModel } from "@entities/Portal/Stage";
import { useState } from "react";
import { getEndStage } from "../lib";
import { entityRowApi } from "@entities/Portal/EntityRow";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";

interface IUseStagesEntityRow {
    categoryId: number;
    entityId: number;
    onlyRead?: boolean;
    entityRow: any;
    onChangeEntityRow?: (entityRow: any) => void;
}

export const useStagesEntityRow = ({
    categoryId,
    entityId,
    entityRow,
    onlyRead,
    onChangeEntityRow,
}: IUseStagesEntityRow) => {
    const { loading, stages, notAllowed } = stageModel.useStagesLoadStatefull(
        categoryId,
        entityId
    );
    const [submitLoading, setSubmitLoading] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredStage, setHoveredStage] = useState<(TStage & { index: number }) | null>(
        null
    );

    const { defferedCallback } = useDefferedCallback(800);

    const currentStageId = entityRow?.["stage"];

    const orderedStages = stageLib.reorderStages(stages);

    const additionalStages = orderedStages.filter(
        (stage) => stage.type === "in_progress"
    );
    const finalStages = orderedStages.filter((stage) => stage.type !== "in_progress");

    const currentStageIndex =
        currentStageId !== undefined && currentStageId !== null
            ? orderedStages.findIndex((stage) => stage.id == Number(currentStageId))
            : null;
    const endStage = getEndStage(currentStageIndex, orderedStages, finalStages);

    const onEnter = (stage: TStage, index: number) => {
        setHoveredStage({
            ...stage,
            index,
        });
    };
    const onLeave = () => {
        setHoveredStage(null);
    };

    const onChangeStage = (stageId: number) => {
        setSubmitLoading(stageId);
        entityRowApi
            .saveEntityRow({
                entityId,
                entityRow: {
                    id: entityRow.id,
                    stage: stageId,
                },
            })
            .then(() => {
                onChangeEntityRow &&
                    onChangeEntityRow({
                        ...entityRow,
                        stage: stageId,
                    });
            })
            .catch(() => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось перевести в стадию!",
                });
            })
            .finally(() => {
                setSubmitLoading(null);
            });
    };

    const onClickStage = (stageId: number) => {
        if (submitLoading || onlyRead) return;
        if (stageId == currentStageId) return;
        setSubmitLoading(stageId);
        defferedCallback(() => onChangeStage(stageId));
    };
    const onClickEndStage = (stageId: number) => {
        if (submitLoading || onlyRead) return;
        if (
            stageId === 0 ||
            (finalStages.some((stage) => stage.id === stageId) && finalStages.length > 1)
        ) {
            setIsOpen(true);
            return;
        }
        onClickStage(stageId);
    };
    const onClose = () => {
        setIsOpen(false);
    };

    return {
        additionalStages,
        finalStages,
        stages: [...additionalStages, endStage],
        loading,
        notAllowed,
        currentStageIndex,
        currentStage:
            currentStageIndex !== null ? orderedStages[currentStageIndex] ?? null : null,
        hoveredIndex: hoveredStage ? hoveredStage.index : null,
        hoveredStage,
        submitLoading,
        isOpen,
        onEnter,
        onLeave,
        onClickStage,
        onClickEndStage,
        onClose,
    };
};
