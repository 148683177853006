import moment from "moment";
import dayjs, { OpUnitType, ManipulateType } from "dayjs";

export const getUnixTimestamp = () => {
    return moment().valueOf();
};

export const getTimezoneOffsetHours = () => {
    return new Date().getTimezoneOffset() / 60;
};

export const getDatePickerFormat = (date: string | undefined): dayjs.Dayjs | null => {
    return date ? dayjs(date, "YYYY-MM-DD") : null;
};
export const getDateHumanFormat = (date: string | undefined): string => {
    return date ? dayjs(date).format("D MMM YYYY") : "-";
};

export const getDateFormattedValue = (
    date: dayjs.Dayjs | null | undefined
): string | null => {
    return date ? date.format("YYYY-MM-DD") : null;
};

export const getDatetimePickerFormat = (
    date: string | undefined | null
): dayjs.Dayjs | null => {
    return date ? dayjs(date, "YYYY-MM-DD HH:mm") : null;
};
export const getDatetimeHumanFormat = (date: string | undefined): string => {
    return date ? dayjs(date).format("HH:mm D MMM YYYY") : "-";
};
export const getDatetimeFormattedValue = (
    date: dayjs.Dayjs | null | undefined
): string | null => {
    return date ? date.format("YYYY-MM-DD HH:mm") : null;
};
export const getTimestampPickerFormat = (
    date: string | undefined
): dayjs.Dayjs | null => {
    return date ? dayjs(date, "YYYY-MM-DD HH:mm").subtract(window.Offset, "hour") : null;
};
export const getTimestampFormattedValue = (
    date: dayjs.Dayjs | null | undefined
): string | null => {
    return date ? date.subtract(window.Offset, "hour").format("YYYY-MM-DD HH:mm") : null;
};

export const getDatetimePickerHumanFormat = (date: string | undefined): string | null => {
    return date ? dayjs(date).format("HH:mm D MMM YYYY") : null;
};
export const getTimestampPickerHumanFormat = (
    date: string | undefined
): string | null => {
    return date
        ? dayjs(date, "YYYY-MM-DD HH:mm")
              .add(window.Offset, "hour")
              .format("YYYY-MM-DD HH:mm")
        : null;
};

export const getTimestampToLocalTimePickerFormat = (
    date: string | undefined
): dayjs.Dayjs | null => {
    return date ? dayjs(date, "YYYY-MM-DD HH:mm").add(window.Offset, "hour") : null;
};

export const getTimePickerFormat = (time: string | undefined): dayjs.Dayjs | null => {
    return time ? dayjs(time, "HH:mm") : null;
};
export const getNormalizedTime = (time: string | null | undefined): any => {
    if (!time) {
        return "---";
    }
    const date = dayjs(time, "YYYY-MM-DD HH:mm").add(
        +(window.Offset ?? getTimezoneOffsetHours()),
        "hour"
    );
    const locale = navigator.language;
    const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric", // 2-digit
        month: "long", // numeric "2-digit", "narrow", "short" и "long"
        year: "numeric", // 2-digit
        // era: "long",
        weekday: "short", // "narrow", "short" и "long"
        // timeZoneName: "short",
        hour: "numeric",
        // hour12: true,
        minute: "numeric",
        // second: 'numeric'
    };
    const UserDate = new Intl.DateTimeFormat(locale, dateOptions);
    return UserDate.format(date.toDate());
};
export const getDateRange = (
    date: dayjs.Dayjs,
    rangeSize: ManipulateType | "agenda",
    action?: "subtract" | "add"
) => {
    let startValue: string | undefined;
    let endValue: string | undefined;

    if (!action) {
        startValue = dayjs(
            getStartOfCurrentDate(date, rangeSize === "agenda" ? "date" : rangeSize)
        )
            .utc(false)
            .format("YYYY-MM-DD HH:mm");
        endValue = dayjs(
            getEndOfCurrentDate(date, rangeSize === "agenda" ? "date" : rangeSize)
        )
            .utc(false)
            .format("YYYY-MM-DD HH:mm");
    } else if (rangeSize === "agenda") {
        startValue = dayjs(getStartOfCurrentDate(date, "date"))
            .utc(false)
            .format("YYYY-MM-DD HH:mm");
        endValue = dayjs(getEndOfCurrentDate(date, "date"))
            .utc(false)
            .format("YYYY-MM-DD HH:mm");
    } else {
        startValue = dayjs(getStartOfCurrentDate(date, rangeSize))
            .utc(false)
            .format("YYYY-MM-DD HH:mm");
        endValue = dayjs(getEndOfCurrentDate(date, rangeSize))
            .utc(false)
            .format("YYYY-MM-DD HH:mm");
    }

    return {
        startValue,
        endValue,
    };
};
export const getStartOfCurrentDate = (
    date: dayjs.Dayjs,
    startOfUnit: OpUnitType
): string => {
    return date.startOf(startOfUnit).toISOString();
};
export const getEndOfCurrentDate = (
    date: dayjs.Dayjs,
    startOfUnit: OpUnitType
): string => {
    return date.endOf(startOfUnit).toISOString();
};
