import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectUserAgreements } from "./BizProcSelectors";
import { usePostLoadAgreements } from "./UsePostLoadAgreements";
import { getBizProcsAgreements } from "../api";
import { setUserAgreements } from "./BizProcSlice";

export const useLoadUserAgreements = (preload?: boolean) => {
    const agreements = useSelector(selectUserAgreements);
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    usePostLoadAgreements(true);
    useEffect(() => {
        if (preload) {
            loadAgreements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preload]);

    const loadAgreements = () => {
        setLoading(true);
        getBizProcsAgreements({
            isMy: true,
            grouped: false,
            filter: {
                value: "executing",
                condition: "=",
                key: "status",
            },
        })
            .then((response) => {
                dispatch(setUserAgreements(response.data.data));
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };
    return {
        loading,
        agreements,
        loadAgreements,
    };
};
