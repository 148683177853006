import { useEffect, useState } from "react";
import { getOperation } from "../api";

interface IUseTMOperationItemCodes {
    operationId: number;
}

export const useLoadFullOperation = ({ operationId }: IUseTMOperationItemCodes) => {
    const [operation, setOperation] = useState<TTechMapOperationFull>();
    const [loading, setLoading] = useState(false);

    const load = () => {
        setLoading(true);
        getOperation(operationId)
            .then((response) => {
                setOperation(response.data.data);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operationId]);

    return {
        operation,
        loading,
    };
};
