import { createSelector } from "@reduxjs/toolkit";

export const selectExecutableTmts = (state: RootState): TTechMapTemplateExecuble[] =>
    state.techMap.executableTmts;
export const selectGetExecutableLoading = (state: RootState): boolean =>
    state.techMap.getExecutableLoading;
export const selectPauseResumeLoading = (state: RootState): boolean =>
    state.techMap.pauseResumeLoading;
export const selectIsStartingLoading = (state: RootState, tmtId: number): boolean =>
    state.techMap.startingLoading === tmtId;
export const selectPrinterIsConnected = (state: RootState): boolean =>
    state.techMap.printerIsConnected;
export const selectStopLoading = (state: RootState): boolean => state.techMap.stopLoading;
export const selectStartingLoading = (state: RootState): number | null =>
    state.techMap.startingLoading;
export const selectStartedTm = (state: RootState): TTechMapStarted | null =>
    state.techMap.startedTm;
export const selectStartedTms = (state: RootState): TTechMapStarted[] =>
    state.techMap.startedTms;
export const selectStartedOperation = (state: RootState): TTechMapOperationFull | null =>
    state.techMap.startedOperation;
export const selectGetStartedOperationLoading = (state: RootState): boolean =>
    state.techMap.getStartedOperationLoading;
export const selectGetStartedTmsLoading = (state: RootState): boolean =>
    state.techMap.getStartedTmsLoading;
export const selectGetStartedTmLoading = (state: RootState): boolean =>
    state.techMap.getStartedTmLoading;
export const selectGetTMsLoading = (state: RootState): boolean =>
    state.techMap.getTMsLoading;
export const selectTechMapFilters = (state: RootState): TTechMapFilters =>
    state.techMap.filters;
export const selectAttachItemLoading = (state: RootState): boolean =>
    state.techMap.attachItemLoading;
export const selectTechMapsForReview = (state: RootState): TTechMapStarted[] =>
    state.techMap.techMapsForReview;
export const selectTechMaps = (state: RootState): TTechMapStarted[] =>
    state.techMap.techMaps;
export const selectGroupLevels = (state: RootState): string[] =>
    state.techMap.groupLevels;
export const selectNextOperationLoading = (state: RootState): boolean =>
    state.techMap.nextOperationLoading;
export const selectIsStartedTmt = (state: RootState, tmtUuid: string): boolean =>
    state.techMap.startedTm?.technical_map_template_uuid === tmtUuid;
export const selectIsApproveLoading = (state: RootState, tmId: number): boolean =>
    state.techMap.approveTmLoading[tmId] === "approve";
export const selectIsPartlyRejectLoading = (state: RootState, tmId: number): boolean =>
    state.techMap.approveTmLoading[tmId] === "partly_reject";
export const selectIsRejectLoading = (state: RootState, tmId: number): boolean =>
    state.techMap.approveTmLoading[tmId] === "reject";
export const selectDetachItemLoading = (state: RootState, id: number): boolean =>
    state.techMap.detachItemLoading.includes(id);

export const selectTmsCurrentPage = (
    state: RootState,
    level: number | string
): number | undefined => state.techMap.techMapsPaginated[level]?.currentPage;
export const selectTmsLoading = (
    state: RootState,
    level: number | string
): boolean | undefined => state.techMap.techMapsPaginated[level]?.loading;
export const selectTmsPerPage = (
    state: RootState,
    level: number | string
): number | undefined => state.techMap.techMapsPaginated[level]?.perPage;
export const selectTmsLastPage = (
    state: RootState,
    level: number | string
): number | undefined => state.techMap.techMapsPaginated[level]?.lastPage;

export const selectTmsPaginated = createSelector(
    [
        (state: RootState) => state.techMap.techMapsPaginated,
        (_: any, level: number | string) => level,
    ],
    (
        techMapsPaginated: { [level: string | number]: TTechMapPaginated },
        level: number | string
    ) => techMapsPaginated?.[level]?.techMaps
);
