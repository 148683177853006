import { Collapse } from "antd";
import CreateUpdateDefaultValueCreateInputItem from "./CreateUpdateDefaultValueInputItem";
import { FormItem } from "@shared/ui/Form";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";

interface ICreateUpdateDefaultValueInput {
    editingEntityField: TEntityField;
    isMultiple?: boolean;
    name: "on_create_default" | "on_update_default";
    label: string;
    onChangeCalculation: (
        name: keyof TEntityField,
        isMultiple?: boolean,
        key?: number | string
    ) => void;
    onChangeCreateUpdateValue: (
        value: any,
        name: keyof TEntityField,
        isMultiple?: boolean,
        key?: number | string
    ) => void;
    onDelete: (key: number, name: "on_create_default" | "on_update_default") => void;
}

const CreateUpdateDefaultValueInput = ({
    editingEntityField,
    isMultiple,
    name,
    label,
    onChangeCalculation,
    onChangeCreateUpdateValue,
    onDelete,
}: ICreateUpdateDefaultValueInput) => {
    if (editingEntityField.datatype === "entity") return <></>;

    if (isMultiple)
        return (
            <IndentContainer values={4}>
                {/* @ts-ignore */}
                {!!editingEntityField[name] && editingEntityField[name].length > 0 && (
                    <Collapse
                        defaultActiveKey={name}
                        items={[
                            {
                                key: name,
                                label: label,
                                children: (
                                    <>
                                        {/* @ts-ignore */}
                                        {editingEntityField[name].map((item) => {
                                            return (
                                                <div key={`${item.id}`}>
                                                    <CreateUpdateDefaultValueCreateInputItem
                                                        editingEntityField={
                                                            editingEntityField
                                                        }
                                                        createUpdateDefaultValue={item}
                                                        name={name}
                                                        isMultiple={isMultiple}
                                                        onChangeCalculation={
                                                            onChangeCalculation
                                                        }
                                                        onChangeCreateUpdateValue={
                                                            onChangeCreateUpdateValue
                                                        }
                                                        onDelete={onDelete}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                ),
                            },
                        ]}
                    />
                )}
            </IndentContainer>
        );

    return (
        <>
            {editingEntityField[name] && (
                <FormItem label={isMultiple ? "" : label}>
                    <CreateUpdateDefaultValueCreateInputItem
                        editingEntityField={editingEntityField}
                        createUpdateDefaultValue={editingEntityField[name]!}
                        name={name}
                        isMultiple={isMultiple}
                        onChangeCalculation={onChangeCalculation}
                        onChangeCreateUpdateValue={onChangeCreateUpdateValue}
                    />
                </FormItem>
            )}
        </>
    );
};

export default CreateUpdateDefaultValueInput;
