import { EntitySelect } from "@entities/Portal/Entity";
import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { FormItem } from "@shared/ui/Form";
import { Button, Checkbox, Input, Select, Space } from "antd";
import {
    tmtoNecessaryItemTypesOptions,
    tmtoNItemNecessaryTypesOptions,
} from "../../constants";
import { useTMTONecessaryItemForm } from "../../model";
import { TMTONItemUseActions } from "../TMTONItemUseActions";
import "./TMTONecessaryItemForm.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
interface ITMTONecessaryItemForm {
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating;

    onCancel: () => void;
    onSubmit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
}

const TMTONecessaryItemForm = ({ item, onCancel, onSubmit }: ITMTONecessaryItemForm) => {
    const {
        formItem,
        onUseActionsOpen,
        onUseActionsOpenDelaied,
        toggleUseActionsOpenState,
        onChangeLabel,
        onChangeCount,
        onChangeType,
        onChangeNecessaryType,
        onChangeEntity,
        onChangeEntityRowId,
        onClickSubmit,
        onChangeUseActions,
        onToggleScannable,
    } = useTMTONecessaryItemForm(item, onSubmit);

    return (
        <div className="tmto-ni-form">
            <div className="tmto-ni-form__info">
                <div
                    style={{
                        width: 420,
                        marginRight: 15,
                    }}
                >
                    <FormItem label={"Название"}>
                        <Input value={formItem.label} onChange={onChangeLabel} />
                    </FormItem>

                    <FormItem label={"Тип"}>
                        <Select
                            onChange={onChangeType}
                            style={{ width: "100%" }}
                            value={formItem.type}
                            options={tmtoNecessaryItemTypesOptions}
                        />
                    </FormItem>
                    <FormItem label={"Необходимое количество"}>
                        <Input value={formItem.count} onChange={onChangeCount} />
                    </FormItem>
                    {formItem.type === "filling" ? (
                        <>
                            <FormItem label={"Необходимый компонент"}>
                                <EntitySelect
                                    value={formItem.related_entity_uuid}
                                    onChangeSelect={onChangeEntity}
                                />
                            </FormItem>
                            <FormItem label={"Конкретный компонент"}>
                                {formItem.related_entity_uuid ? (
                                    <SelectEntityRow
                                        byUuid
                                        entityInfo={formItem.related_entity_uuid}
                                        value={formItem.related_entity_row_id}
                                        onChange={onChangeEntityRowId}
                                    />
                                ) : (
                                    <>Необходимо выбрать сущность</>
                                )}
                            </FormItem>
                            {formItem.related_entity_uuid &&
                                formItem.related_entity_row_id && (
                                    <FormItem label={""}>
                                        <Checkbox
                                            onClick={onToggleScannable}
                                            checked={formItem.is_scannable}
                                        >
                                            Необходимо сканировать код
                                        </Checkbox>
                                    </FormItem>
                                )}
                            <FormItem label={"Тип необходимого компонента"}>
                                <Select
                                    onChange={onChangeNecessaryType}
                                    style={{ width: "100%" }}
                                    value={formItem.necessary_type}
                                    options={tmtoNItemNecessaryTypesOptions}
                                />
                            </FormItem>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        width: onUseActionsOpen ? 385 : 0,
                        transition: "all ease-in .1s",
                    }}
                >
                    {onUseActionsOpenDelaied && formItem.related_entity_uuid && (
                        <div className="tmto-ni-form__use-actions">
                            <TMTONItemUseActions
                                entityUuid={formItem.related_entity_uuid}
                                entityRowId={formItem.related_entity_row_id}
                                onChange={onChangeUseActions}
                                actions={formItem.on_use_actions}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                {formItem.type === "filling" && formItem.related_entity_uuid && (
                    <IndentContainer>
                        <Button
                            type="link"
                            icon={onUseActionsOpen ? <LeftOutlined /> : <RightOutlined />}
                            onClick={toggleUseActionsOpenState}
                        >
                            Действия по завершению
                        </Button>
                    </IndentContainer>
                )}
                <Space>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button onClick={onClickSubmit} type="primary">
                        Сохранить
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default TMTONecessaryItemForm;
