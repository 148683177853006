import {
    BizProcActionItem,
    bizProcLib,
    bizProcTemplateModel,
} from "@entities/Portal/BizProcTemplate";
import { bizProcRowChild } from "@entities/Portal/BizProcTemplate/constants";
import { useSelector } from "react-redux";
import { withFixedAction } from "../BizProcActionAddButton";
import { BizProcItemEditModal } from "../BizProcItemEditModal";
import {
    getArcherRelations,
    getParentNextRelation,
    getSingleDefaultRelationRule,
} from "../lib";
import { useBizProcUpdateAction } from "../model/UseBizProcUpdateAction";
import ArcherElement from "./ArcherElement";
import "./BizProcTemplate.scss";
import BizProcTemplateActionAddButtonContainer from "./BizProcTemplateActionAddButtonContainer";
import BizProcTemplateItemCopyPasteButtons from "./BizProcTemplateItemCopyPasteButtons";
import BizProcTemplateItemMoveButtons from "./BizProcTemplateItemMoveButtons";
import BizProcTemplateItemWrapper from "./BizProcTemplateItemWrapper";

interface IBizProcTemplateItem {
    templateItem: TBizProcTemplateAction;
    customEndItemKey?: string;
    index: number;
    isParentWithRowChild?: boolean;
    onChangeTemplateItem: (templateItem: TBizProcTemplateAction) => void;
    onAddTemplateItem: (
        templateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => void;
    onDeleteTemplateItem: (deletedTemplateItem: TBizProcTemplateAction) => void;
    templateGroupItems: TBizProcTemplateAction[];
    parentItem: TBizProcTemplateAction;
    parentParentItem?: TBizProcTemplateAction;
    disabled?: boolean;
    parentNextItem: TBizProcTemplateAction | undefined;
    isMovableWholeBranch?: boolean;
    isLastChildBranch?: boolean;
    onChangeBranchIndex: (index: number, indexChangeStep: 1 | -1) => void;
    onCopyBranchToNeighbourBranch: (index: number, indexMoveStep: 1 | -1) => void;
    onDrop: (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction,
        pasteCopiedBlockOfActions: (
            item: TBizProcTemplateAction,
            passParentKey?: boolean
        ) => void,
        isCopyingMovingMode: boolean,
        passParentKey?: boolean
    ) => void;
    onDragStart: (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction
    ) => void;
    onDragEnd: (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction,
        deleteFunction: (item: TBizProcTemplateAction) => void,
        isCopyingMovingMode?: boolean
    ) => void;
}

const BizProcTemplateItem = ({
    templateItem,
    isParentWithRowChild,
    index,
    parentParentItem,
    disabled,
    parentItem,
    customEndItemKey,
    templateGroupItems,
    parentNextItem,
    onAddTemplateItem,
    onDeleteTemplateItem,
    onChangeTemplateItem,
    isLastChildBranch,
    onChangeBranchIndex,
    onCopyBranchToNeighbourBranch,
    onDrop,
    onDragStart,
    onDragEnd,
}: IBizProcTemplateItem) => {
    const bizProcTemplate = useSelector(
        bizProcTemplateModel.selectEditingBizProcTemplateItem
    );
    const isBizProcWithRowChild = bizProcLib.isBizProcWithRowChild(templateItem.action);
    const isAllowSelfChild = bizProcLib.isBizProcWithSelfChildren(templateItem.action);
    const isBizProcNotAllowAddAfter = bizProcLib.isBizProcNotAllowAddAfter(
        templateItem.action
    );
    const isMovableWholeBranch = ["ParallelBranchAction", "IfElseActionBranch"].includes(
        templateItem.action
    );
    const isCopybaleBlock = !templateItem.action.toLowerCase().includes("branch");
    const {
        addTemplateItemAfter,
        editAction,
        onAddTemplateItemParent,
        onDeleteTemplateItem_,
        onDeleteTemplateItemParent,
        setIsEditing,
        onUpdateTemplateItem,
        isEditing,
        onChangeTemplateItem_,
        copyBranchValue,
        pasteCopiedBlockOfActions,
    } = useBizProcUpdateAction(
        isAllowSelfChild,
        templateItem,
        onChangeTemplateItem,
        onAddTemplateItem,
        onDeleteTemplateItem,
        parentItem
    );
    const isFixedAction = withFixedAction.includes(templateItem.action);
    const relation =
        !isBizProcWithRowChild &&
        isAllowSelfChild &&
        templateItem.children?.length === 0 &&
        parentItem.key !== "start"
            ? getSingleDefaultRelationRule(`self-${parentItem.key}`)
            : !bizProcLib.isBizProcWithSelfChildren(templateItem.action) &&
                bizProcLib.isBizProcWithSelfChildren(parentItem.action) &&
                !isBizProcWithRowChild &&
                parentParentItem &&
                parentItem.children?.length === index + 1 &&
                parentItem.key !== "start"
              ? getSingleDefaultRelationRule(`self-${parentParentItem.key}`)
              : getArcherRelations(
                    templateItem,
                    templateGroupItems,
                    parentNextItem,
                    customEndItemKey
                );
    return (
        <div className="template">
            <div className="template__item" onClick={editAction}>
                {isMovableWholeBranch && (
                    <BizProcTemplateItemMoveButtons
                        index={index}
                        isLastChildBranch={isLastChildBranch}
                        onChangeBranchIndex={onChangeBranchIndex}
                        onCopyBranchToNeighbourBranch={onCopyBranchToNeighbourBranch}
                    />
                )}
                {isCopybaleBlock && (
                    <BizProcTemplateItemCopyPasteButtons
                        type="copy"
                        templateItem={templateItem}
                        onDeleteTemplateItem={onDeleteTemplateItem}
                    />
                )}
                <ArcherElement
                    id={templateItem.key}
                    relations={getSingleDefaultRelationRule(`add-${templateItem.key}`)}
                >
                    <div style={{ width: 150 }}>
                        <BizProcActionItem
                            disabled={disabled}
                            action={templateItem.action}
                            onDeleteItem={onDeleteTemplateItem_}
                            templateItem={templateItem}
                        />
                    </div>
                </ArcherElement>
            </div>
            {
                <ArcherElement
                    id={`add-${templateItem.key}`}
                    relations={relation}
                    gridBreak={isBizProcWithRowChild ? "50%" : "95%"}
                >
                    <BizProcTemplateActionAddButtonContainer
                        onDrop={onDrop}
                        isBizProcNotAllowAddAfter={isBizProcNotAllowAddAfter}
                        isAllowSelfChild={isAllowSelfChild}
                        templateItem={templateItem}
                        parentItem={parentItem}
                        passParentKey={false}
                        onAdd={addTemplateItemAfter}
                        disabled={disabled}
                        isButtonForCreateOnlyParallelBranch={isBizProcWithRowChild}
                        pasteCopiedBlockOfActions={pasteCopiedBlockOfActions}
                        isFixedAction={isFixedAction}
                    />
                </ArcherElement>
            }
            <div
                style={{
                    display: "flex",
                    flexDirection: isBizProcWithRowChild ? "row" : "column",
                    width: "fit-content",
                    boxSizing: "border-box",
                }}
            >
                {templateItem.children.map((templateItemInner, innerIndex) => {
                    const onCopyBranchToNeighbourBranch = (
                        index: number,
                        indexMoveStep: 1 | -1
                    ) => {
                        if (bizProcRowChild.includes(templateItem.action)) {
                            copyBranchValue(templateItem, index, indexMoveStep);
                        }
                    };
                    const onChangeBranchIndex = (
                        index: number,
                        indexChangeStep: 1 | -1
                    ) => {
                        if (bizProcRowChild.includes(templateItem.action)) {
                            const newTemplate = { ...templateItem };
                            const oldBranch =
                                newTemplate.children[index + indexChangeStep];
                            newTemplate.children[index + indexChangeStep] =
                                newTemplate.children[index];
                            newTemplate.children[index] = oldBranch;
                            onChangeTemplateItem_(newTemplate);
                        }
                    };
                    return (
                        <BizProcTemplateItemWrapper
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                            templateItem={templateItemInner}
                            draggable={
                                !templateItemInner.action.toLowerCase().includes("branch")
                            }
                            onDeleteTemplateItem={
                                isAllowSelfChild
                                    ? onDeleteTemplateItemParent
                                    : onDeleteTemplateItem_
                            }
                            key={templateItemInner.key}
                            children={
                                <BizProcTemplateItem
                                    onDrop={onDrop}
                                    onDragStart={onDragStart}
                                    onDragEnd={onDragEnd}
                                    disabled={disabled}
                                    parentItem={templateItem}
                                    index={innerIndex}
                                    onAddTemplateItem={
                                        isAllowSelfChild
                                            ? onAddTemplateItemParent
                                            : onAddTemplateItem
                                    }
                                    onDeleteTemplateItem={
                                        isAllowSelfChild
                                            ? onDeleteTemplateItemParent
                                            : onDeleteTemplateItem_
                                    }
                                    onChangeTemplateItem={onChangeTemplateItem_}
                                    customEndItemKey={
                                        isBizProcWithRowChild
                                            ? `self-${templateItem.key}`
                                            : customEndItemKey
                                    }
                                    isParentWithRowChild={
                                        isBizProcWithRowChild
                                            ? true
                                            : isParentWithRowChild
                                    }
                                    parentNextItem={
                                        !isBizProcWithRowChild
                                            ? templateItem.children?.[innerIndex + 1] ??
                                              parentNextItem
                                            : parentNextItem
                                    }
                                    parentParentItem={parentItem}
                                    templateGroupItems={
                                        templateItem.children?.[0]
                                            ? templateItem.children
                                            : templateGroupItems
                                    }
                                    templateItem={templateItemInner}
                                    isLastChildBranch={
                                        innerIndex === templateItem.children.length - 1
                                    }
                                    onChangeBranchIndex={onChangeBranchIndex}
                                    onCopyBranchToNeighbourBranch={
                                        onCopyBranchToNeighbourBranch
                                    }
                                />
                            }
                        />
                    );
                })}
            </div>
            {isAllowSelfChild &&
                isBizProcWithRowChild &&
                templateItem.children?.length > 0 && (
                    <div style={{ marginTop: 80 }}>
                        <ArcherElement
                            id={`self-${templateItem.key}`}
                            relations={getParentNextRelation(
                                parentNextItem,
                                customEndItemKey
                            )}
                            gridBreak="50%"
                        >
                            <BizProcTemplateActionAddButtonContainer
                                onDrop={onDrop}
                                isAllowSelfChild={false}
                                templateItem={templateItem}
                                parentItem={parentItem}
                                onAdd={(newTemplateItem: TBizProcTemplateAction) => {
                                    onAddTemplateItem(templateItem, newTemplateItem);
                                }}
                                passParentKey={true}
                                disabled={disabled}
                                isButtonForCreateOnlyParallelBranch={false}
                                pasteCopiedBlockOfActions={pasteCopiedBlockOfActions}
                            />
                        </ArcherElement>
                    </div>
                )}
            <BizProcItemEditModal
                disabled={disabled}
                isOpen={isEditing}
                bizProcTemplate={bizProcTemplate}
                setIsOpen={setIsEditing}
                templateItem={templateItem}
                onSubmit={onUpdateTemplateItem}
            />
        </div>
    );
};

export default BizProcTemplateItem;
