import { techMapModel } from "@entities/Production/TechMap";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useTMNecessaryItemAttach = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [loading, setLoading] = useState(false);
    const operation = useSelector(techMapModel.selectStartedOperation);
    const [code, setCode] = useState("");

    const onChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
    };
    const onAttach = () => {
        if (operation?.id) {
            setLoading(true);
            dispatch(
                techMapModel.attachItemThunk({
                    oId: operation.id,
                    value: code,
                })
            ).finally(() => {
                setLoading(false);
            });
        }
    };
    return {
        code,
        loading,
        onAttach,
        onChangeCode,
    };
};
