import { Drawer, Result } from "antd";
import IFrameViewerDrawerHeader from "./IFrameViewerDrawerHeader";
import { useSelector } from "react-redux";
import { navigationRouteModel } from "@entities/Portal/NavigationRoute";

interface IIFrameViewerDrawer {
    routeKey: string;
    isOpen: boolean;
    onClose: () => void;
}

const IFrameViewerDrawer = ({ routeKey, isOpen, onClose }: IIFrameViewerDrawer) => {
    const routes = useSelector(navigationRouteModel.selectNavigationRoutes);
    const foundRoute = routes.find(({ key }) => key === routeKey);
    const url = foundRoute?.route;
    const name = foundRoute?.name;
    const error = !url;
    return (
        <Drawer
            destroyOnClose
            open={isOpen}
            rootStyle={{ padding: 0 }}
            title={<IFrameViewerDrawerHeader name={name} onClose={onClose} />}
            onClose={onClose}
            width={"100vw"}
            styles={{
                body: {
                    padding: 0,
                    overflow: "hidden",
                },
                header: {
                    padding: "0 0 0 16px",
                },
            }}
        >
            {error ? (
                <Result status="404" title="Не удалось перейти по указанной ссылке" />
            ) : (
                <iframe
                    sandbox="allow-scripts allow-forms allow-modals allow-same-origin allow-pointer-lock"
                    src={url}
                    width={"100%"}
                    height={"100%"}
                    style={{ border: "none" }}
                />
            )}
        </Drawer>
    );
};

export default IFrameViewerDrawer;
