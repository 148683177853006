import { entityFieldModel } from "@entities/Portal/EntityField";
import { getUnixTimestamp } from "@shared/lib/date";
import { useRef } from "react";
import { useDispatch } from "react-redux";

export const useAddEntityField = (
    entityUuid: string | undefined,
    entityId: number | undefined
) => {
    const selectRef = useRef<any>();
    const dispatch = useDispatch();

    const addEntityField = (value: string, option: any) => {
        const key = getUnixTimestamp();
        const newField: TEntityField & { is_new: boolean } = {
            id: key,
            is_new: true,
            is_unwritable: false,
            entity_uuid: entityUuid,
            entity_id: entityId,
            name: option.item.name,
            datatype: option.item.datatype,
            key: "key_" + key,
            view: false,
            through_field: false,
            variable_type: "entity" as "entity",
        };
        if (option.item.datatype === "childEntity") {
            newField.key_value = "id";
            newField.is_multiple = true;
        }
        dispatch(entityFieldModel.setEditingEntityField(newField));
        if (!entityUuid && !entityId) dispatch(entityFieldModel.addEntityField(newField));
    };
    const onButtonClick = () => {
        selectRef.current?.focus();
    };

    return {
        selectRef,
        addEntityField,
        onButtonClick,
    };
};
