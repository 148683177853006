import { CapabilitiesRolesDataController } from "@features/Portal/CapabilitiesRoles/CapabilitiesRolesDataController";
import { CapabilitiesRolesTable } from "@features/Portal/CapabilitiesRoles/CapabilitiesRolesRow";
import { Flex } from "antd";
import "./CapabilitiesRolesTableWithCreating.scss";
import CreateCapabilitiesRoles from "./CreateCapabilitiesRoles";
import { memo } from "react";

interface ICapabilitiesRolesTableWithCreating {
    entityCapabilitiesRolesId: number | undefined;
    role: TUserRole | undefined;
}

const CapabilitiesRolesTableWithCreating = ({
    entityCapabilitiesRolesId,
    role,
}: ICapabilitiesRolesTableWithCreating) => {
    return (
        <Flex vertical className="capabilities-roles-table-with-creating">
            <Flex vertical style={{ flex: 1 }}>
                <CapabilitiesRolesTable
                    createButton={
                        <CreateCapabilitiesRoles
                            entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                            role={role}
                        />
                    }
                    entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                    role={role}
                    footer={
                        <CapabilitiesRolesDataController
                            entityCapabilitiesRolesId={entityCapabilitiesRolesId!}
                        />
                    }
                />
            </Flex>
        </Flex>
    );
};

export default memo(
    CapabilitiesRolesTableWithCreating,
    (
        prevProps: ICapabilitiesRolesTableWithCreating,
        nextProps: ICapabilitiesRolesTableWithCreating
    ) => {
        return (
            prevProps.entityCapabilitiesRolesId === nextProps.entityCapabilitiesRolesId &&
            prevProps.role?.id === nextProps.role?.id
        );
    }
);
