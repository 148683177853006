import { Checkbox } from "antd";

interface IEditParallelExecutionAction {
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditParallelExecutionAction = ({
    templateItem,
    setItem,
}: IEditParallelExecutionAction) => {
    const onChangeIsWaitForAll = ({
        target: { checked },
    }: {
        target: { checked: boolean };
    }) => {
        setItem({ ...templateItem, parameters: { is_wait_for_all: checked } });
    };
    return (
        <div style={{ padding: "8px 0 16px 0" }}>
            <Checkbox
                onChange={onChangeIsWaitForAll}
                checked={!!templateItem.parameters.is_wait_for_all}
            >
                Ожидать выполнение всех веток
            </Checkbox>
        </div>
    );
};

export default EditParallelExecutionAction;
