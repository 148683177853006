import { FormInputItem } from "@entities/Portal/Datatype";
import { Descriptions } from "antd";
import { Fragment } from "react";
import "./EntityRowsTablePatternColumn.scss";
import TMPBizProcButton from "./TMPBizProcButton";
interface IEntityRowsTablePatternColumn {
    order: any;
    value: any;
    entityId: number;
    column: TPatternColumn;
    entityRow?: any;
}

const EntityRowsTablePatternColumn = ({
    value,
    order,
    entityId,
    column,
    entityRow,
}: IEntityRowsTablePatternColumn) => {
    if (column.type === "entity-field") {
        const entityField = column.entityField;
        if (!entityField) return <></>;
        return (
            <div
                className="entity-rows-table-pattern-column__value-container"
                style={{
                    minWidth:
                        entityField.datatype === "longText"
                            ? 240
                            : entityField.datatype === "boolean"
                              ? 80
                              : 160,
                    maxWidth:
                        entityField.datatype === "longText" ? "none !important" : 400,
                    width: "max-content",
                    height: "100%",
                }}
            >
                <FormInputItem
                    isReduceValue={true}
                    field={entityField}
                    entityRow={entityRow}
                    inputId={entityField.key}
                    additionalParameters={entityField.additional_parameters ?? {}} //item.additional_parameters
                    datatype={entityField.datatype as unknown as string}
                    value={value}
                    onlyRead={true}
                    onChange={() => {}}
                />
            </div>
        );
    } else if (column.type === "group") {
        return (
            <>
                {(column.children ?? []).map((column, index, arr) => {
                    if (
                        column.type === "start_child_biz_proc" ||
                        column.type === "start_biz_proc"
                    ) {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    // padding: 10, // TODO MAX
                                    width: "100%",
                                    alignItems: "center",
                                    // borderBottom:  // TODO MAX
                                    //     index < arr.length - 1
                                    //         ? "1px solid #f0f0f0"
                                    //         : undefined,
                                }}
                            >
                                <TMPBizProcButton
                                    column={column}
                                    entityId={entityId}
                                    entityRow={order}
                                />
                            </div>
                        );
                    }
                    const entityField = column.entityField;
                    if (!entityField) return <Fragment key={index}></Fragment>;

                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                minWidth: 300,
                                width: "100%",
                                maxWidth: 450,
                                // padding: 10,
                                marginBottom: 5,
                                // borderBottom: "1px solid #f0f0f0", // TODO MAX
                            }}
                        >
                            <Descriptions
                                className="entity-rows-table-pattern-column__descriptions"
                                size="small"
                            >
                                <Descriptions.Item label={column.label}>
                                    <FormInputItem
                                        isReduceValue={true}
                                        entityRow={order}
                                        field={entityField}
                                        inputId={entityField.key}
                                        additionalParameters={
                                            entityField.additional_parameters ?? {}
                                        } //item.additional_parameters
                                        datatype={
                                            entityField.datatype as unknown as string
                                        }
                                        value={order[entityField.key]}
                                        onlyRead={true}
                                        onChange={() => {}}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    );
                })}
            </>
        );
    } else if (
        column.type === "start_child_biz_proc" ||
        column.type === "start_biz_proc"
    ) {
        return (
            <>
                <TMPBizProcButton column={column} entityId={entityId} entityRow={order} />
            </>
        );
    }

    return <></>;
};

export default EntityRowsTablePatternColumn;
