import { DeleteOutlined } from "@ant-design/icons";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { Button, Checkbox, Divider, Flex, Popconfirm, Select, Tooltip } from "antd";
import { useEntityRowsActions } from "../model";
import EntityRowActionsCarousel from "./EntityRowActionsCarousel";
import "./EntityRowsActions.scss";
import EntityRowsActionsChangeStage from "./EntityRowsActionsChangeStage";
interface IEntityRowsActions {
    entityId: number;
    selectedRows: number[];
    levelKey?: number | string;
    onSelectRows: (rows: any[]) => void;
    showCategoryStageChange: boolean;
}

const EntityRowsActions = ({
    entityId,
    selectedRows,
    levelKey,
    onSelectRows,
    showCategoryStageChange,
}: IEntityRowsActions) => {
    const {
        entityFieldsOptions,
        allowDelete,
        allowUpdate,
        deleteLoading,
        isCalculateAdditionalEstimation,
        choosenEntityFieldsKeysForEstimation,
        onChooseEntityFieldsKeysForEstimation,
        onDelete,
        onChangeAdditionalCalculation,
    } = useEntityRowsActions(entityId, selectedRows, onSelectRows, levelKey);
    const windowWidth = useWindowWidth();

    return (
        <Flex style={{ flex: "1 1 auto", width: "100%" }}>
            {windowWidth && windowWidth < 900 ? (
                <EntityRowActionsCarousel
                    showCategoryStageChange={showCategoryStageChange}
                    selectedRows={selectedRows}
                    allowDelete={allowDelete}
                    onDelete={onDelete}
                    deleteLoading={deleteLoading}
                    onChangeAdditionalCalculation={onChangeAdditionalCalculation}
                    isCalculateAdditionalEstimation={isCalculateAdditionalEstimation}
                    choosenEntityFieldsKeysForEstimation={
                        choosenEntityFieldsKeysForEstimation
                    }
                    entityFieldsOptions={entityFieldsOptions}
                    onChooseEntityFieldsKeysForEstimation={
                        onChooseEntityFieldsKeysForEstimation
                    }
                    allowUpdate={allowUpdate}
                    entityId={entityId}
                    onSelectRows={onSelectRows}
                />
            ) : (
                <Flex className="rows-actions" gap={8}>
                    <Flex
                        align="center"
                        justify="space-between"
                        style={{ flex: "1 1 30%" }}
                    >
                        <div className="rows-actions__count">{`Выделено: ${selectedRows.length}`}</div>
                        {allowDelete && (
                            <div>
                                <Popconfirm
                                    title="Удалить выбранные записи?"
                                    okText="Удалить"
                                    okButtonProps={{
                                        danger: true,
                                    }}
                                    onConfirm={onDelete}
                                    cancelText="Отмена"
                                >
                                    <Button
                                        disabled={selectedRows.length === 0}
                                        danger
                                        loading={deleteLoading}
                                        type="link"
                                        icon={<DeleteOutlined />}
                                    >
                                        Удалить
                                    </Button>
                                </Popconfirm>
                            </div>
                        )}
                        <Divider type="vertical" />
                    </Flex>
                    <Flex align="center" style={{ flex: "1 1 30%" }} gap={4} wrap="wrap">
                        <Flex style={{ flex: "0 0 40%" }}>
                            <Tooltip title="Суммировать выбранные строки по полям">
                                <Checkbox
                                    style={{ width: "fit-content" }}
                                    onChange={onChangeAdditionalCalculation}
                                    checked={isCalculateAdditionalEstimation}
                                >
                                    <span style={{ textWrap: "nowrap", fontSize: 14 }}>
                                        Суммировать
                                    </span>
                                </Checkbox>
                            </Tooltip>
                        </Flex>
                        {isCalculateAdditionalEstimation && (
                            <Flex style={{ flex: "1 0 50%" }}>
                                <Select
                                    size="small"
                                    placeholder="Поля для суммирования"
                                    mode="multiple"
                                    filterOption={(input: any, option) => {
                                        return option?.label?.includes(input) === true;
                                    }}
                                    allowClear
                                    value={choosenEntityFieldsKeysForEstimation}
                                    options={entityFieldsOptions}
                                    style={{ width: "100%" }}
                                    maxTagTextLength={8}
                                    maxTagCount={1}
                                    onChange={onChooseEntityFieldsKeysForEstimation}
                                />
                            </Flex>
                        )}
                    </Flex>

                    {showCategoryStageChange && allowUpdate ? (
                        <>
                            <Divider type="vertical" />
                            <Flex
                                style={{ flex: "1 1 30%" }}
                                wrap="wrap-reverse"
                                align="center"
                                gap={4}
                            >
                                <EntityRowsActionsChangeStage
                                    entityId={entityId}
                                    selectedRows={selectedRows}
                                    onSuccess={() => {
                                        onSelectRows([]);
                                    }}
                                />
                            </Flex>
                        </>
                    ) : undefined}
                </Flex>
            )}
        </Flex>
    );
};

export default EntityRowsActions;
