import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { techMapModel } from "@entities/Production/TechMap";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { Checkbox, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ITMTableApproveModal {
    techMap: TTechMapStarted;
    levelKey?: string | number;
    isOpen: boolean;
    onCancel: () => void;
}

const TMTableApproveModal = ({
    techMap,
    isOpen,
    levelKey,
    onCancel,
}: ITMTableApproveModal) => {
    const results = techMap.results;
    const approveLoading = useSelector((state: RootState) =>
        techMapModel.selectIsApproveLoading(state, techMap.id)
    );

    const [releaseItems, setReleaseItems] = useState<number[]>(
        results.length === 1 ? [results[0].id] : []
    );

    const dispatch = useDispatch<AppDispatch>();

    const onApprove = () => {
        dispatch(
            techMapModel.approveTmThunk({
                tmtId: techMap.id,
                decision: "approve",
                level: levelKey,
                releaseItemsIds: releaseItems,
            })
        );
    };

    return (
        <Modal
            title={"Согласование результатов"}
            onCancel={onCancel}
            open={isOpen}
            confirmLoading={approveLoading}
            onOk={onApprove}
            okText={"Согласовать"}
        >
            {results.map((result) => {
                const release = releaseItems.find((item) => item === result.id);

                const onChangeRelease = () => {
                    if (release) {
                        setReleaseItems((items) =>
                            items.filter((item) => item !== release)
                        );
                    } else {
                        setReleaseItems((items) => {
                            return [...items, result.id];
                        });
                    }
                };

                return (
                    <IndentContainer key={result.id}>
                        <div
                            style={{
                                padding: 15,
                                borderRadius: 8,
                                border: "1px solid #dfdfdfaa",
                            }}
                        >
                            <FormItem label="Результат">
                                {result.template_result.entity_uuid &&
                                result.entity_row_id ? (
                                    <SelectEntityRow
                                        byUuid
                                        onlyRead
                                        entityInfo={result.template_result.entity_uuid}
                                        value={result.entity_row_id}
                                        onChange={() => {}}
                                    />
                                ) : (
                                    <>Не указан</>
                                )}
                            </FormItem>

                            <div>
                                <div onClick={onChangeRelease}>
                                    <Checkbox checked={!!release}>
                                        Использовать для выпуска
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                    </IndentContainer>
                );
            })}
        </Modal>
    );
};

export default TMTableApproveModal;
