import { UserAccess, UserDateWithTimezone, userModel } from "@entities/Portal/User";
import { getNormalizedTime } from "@shared/lib/date";
import { TextWithLabel } from "@shared/ui/Text";
import { Spin } from "antd";
import "./BizProcInfo.scss";
import BizProcInfoActions from "./BizProcInfoActions/BizProcInfoActions";
import { useSelector } from "react-redux";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
interface BizProcInfoProps {
    loading: boolean;
    bizProc: TBizProc | null;
}
const BizProcInfo = ({ loading, bizProc }: BizProcInfoProps) => {
    if (loading) {
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        );
    }

    if (bizProc) {
        const bizProcStatus =
            bizProc.status === "executing"
                ? "В процессе"
                : bizProc.status === "waiting"
                  ? "Ожидает заполнения формы"
                  : "Завершен";
        return (
            <div className="bizproc__information">
                <div
                    className="bizproc__more-actions"
                    style={{ position: "absolute", right: 16 }}
                >
                    {/* <BizProcItemActions
						bizProc={bizProc}
						redirectAfterDelete={() => {
							navigate('/biz-procs');
						}}
						placement="bottomRight"
					/> */}
                    <UserAccess.CreatorAccess bizProc={bizProc}>
                        <BizProcInfoActions bizProc={bizProc} />
                    </UserAccess.CreatorAccess>
                </div>
                <TextWithLabel text={bizProcStatus} label="Статус выполнения" />
                <IndentContainer>
                    <UserDateWithTimezone
                        date={bizProc["created_at"]}
                        label="Дата и время запуска"
                    />
                </IndentContainer>
                <IndentContainer>
                    <UserDateWithTimezone
                        date={bizProc["updated_at"]}
                        label="Дата и время запуска"
                    />
                </IndentContainer>

                {bizProc["completed_at"] && (
                    <UserDateWithTimezone
                        date={bizProc["completed_at"]}
                        label="Дата и время запуска"
                    />
                )}
            </div>
        );
    } else return <>Отсутствует описание</>;
};

export default BizProcInfo;
