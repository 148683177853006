import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Checkbox, Flex, Tooltip } from "antd";
import { isOptionalPrompt } from "../constants";
import { useEditQueryActionResultParseItem } from "../model";
import { TParseRule, TTargetOfParseRule } from "../model/types";
import EditQueryActionVariableType from "./EditQueryActionVariableType";

interface IEditQueryActionResultParseItem {
    parseRules: TParseRule[];
    bizProcFields: TBizProcTemplateField[];
    parseRule: TParseRule;
    onChangeParseRulesCommonProps: (
        name: keyof TParseRule,
        value: string | TTargetOfParseRule[] | boolean | null,
        index: number
    ) => void;
    onDelete: (index: number) => void;
    index: number;
    itemId: number;
}

const EditQueryActionResultParseItem = ({
    parseRules,
    bizProcFields,
    parseRule,
    onChangeParseRulesCommonProps,
    onDelete,
    index,
    itemId,
}: IEditQueryActionResultParseItem) => {
    const {
        firstRender,
        onDelete_,
        isValidKey,
        isValidParseRule,
        entityFieldKeys,
        variableIds,
        onChangeTargets,
        onChangeKey,
    } = useEditQueryActionResultParseItem(
        parseRule,
        parseRules,
        onChangeParseRulesCommonProps,
        onDelete,
        index
    );
    return (
        <Flex
            gap={"8px 8px"}
            wrap="wrap"
            key={`${index}`}
            style={{
                borderLeft: `4px solid ${!isValidParseRule ? "var(--warning-400)" : "var(--primary-400)"}`,
                paddingLeft: 6,
                flex: "0 1 30%",
            }}
        >
            <Flex
                style={{
                    flex: "1 1 30%",
                    paddingBottom: 8,
                }}
                vertical
            >
                <InputWithVariables
                    name={`key`}
                    value={parseRule.key ?? ""}
                    isTextArea={false}
                    onChange={onChangeKey}
                    itemId={itemId}
                    placeholder="Ключ ответа"
                />
                <Flex
                    style={{
                        position: "relative",
                        height: "fit-content",
                    }}
                >
                    {!firstRender && !isValidKey ? (
                        <ErrorText
                            absolute
                            customStyle={{ bottom: -14, left: 4 }}
                            text="Ключ некорректен"
                        />
                    ) : undefined}
                </Flex>
            </Flex>

            <FormItem label="Целевая переменная">
                <EditQueryActionVariableType
                    bizProcFields={bizProcFields}
                    entityFieldKeys={entityFieldKeys}
                    variableIds={variableIds}
                    onChange={onChangeTargets}
                />
            </FormItem>
            <Flex
                justify="space-between"
                gap={8}
                align="center"
                style={{ flex: "1 1 100%" }}
            >
                <Checkbox
                    onChange={({ target: { checked } }) =>
                        onChangeParseRulesCommonProps("is_optional", !checked, index)
                    }
                    checked={!parseRule.is_optional}
                >
                    <Flex gap={8}>
                        Обязательное правило
                        <Tooltip
                            color="white"
                            overlayInnerStyle={{ color: "#838383" }}
                            title={<span>{isOptionalPrompt}</span>}
                        >
                            <InfoCircleOutlined style={{ color: "var(--warning)" }} />
                        </Tooltip>
                    </Flex>
                </Checkbox>
                <Button
                    danger
                    style={{
                        minHeight: 0,
                        height: "fit-content",
                    }}
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={onDelete_}
                />
            </Flex>
        </Flex>
    );
};

export default EditQueryActionResultParseItem;
