import { EntityFieldUpdatingForm } from "@features/Portal/Entity/EntityFieldUpdatingForm";
import { FieldOptionEditor } from "@features/Portal/FieldOption/FieldOptionEditor";
import { DatatypeDependingInputs } from "@features/Portal/FieldsTypes/DatatypeDependingInputs";
import { Modal } from "antd";
import { motion } from "framer-motion";
import { entityFieldCreateUpdateFormVariants } from "./constants";
import "./EntityFieldEditCreate.scss";
import { useEntityFieldEditCreate } from "./model";

interface IEntityFieldEditCreate {
    isWithAnimation?: boolean;
    isCreatingEntity?: boolean;
}

const EntityFieldEditCreate = ({
    isWithAnimation,
    isCreatingEntity,
}: IEntityFieldEditCreate) => {
    const { editingEntityField, onClose } = useEntityFieldEditCreate();
    const windowWidth = window.innerWidth;
    const isOpen = !!editingEntityField;
    return (
        <>
            {!isCreatingEntity && windowWidth > 1480 ? (
                <motion.div
                    style={{}}
                    exit={isWithAnimation ? { width: 0 } : {}}
                    className="editing-entity-field-container"
                    initial={"closed"}
                    animate={isOpen ? "open" : "closed"}
                    variants={entityFieldCreateUpdateFormVariants}
                >
                    {editingEntityField && (
                        <EntityFieldUpdatingForm
                            datatypeDependingInputs={(props) => (
                                <DatatypeDependingInputs
                                    editingField={props.editingField as TEntityField}
                                    setEditingField={
                                        props.setEditingField as (
                                            entityField: TEntityField | TVariable
                                        ) => void
                                    }
                                    error={props.error}
                                    optionEditor={(
                                        fieldId: number,
                                        type: TFieldOptionType
                                    ) => (
                                        <FieldOptionEditor
                                            fieldId={fieldId}
                                            type={type}
                                        />
                                    )}
                                />
                            )}
                            onSuccessSave={onClose}
                            onCancel={onClose}
                        />
                    )}
                </motion.div>
            ) : (
                <Modal
                    open={editingEntityField !== null}
                    onCancel={onClose}
                    style={{ top: 16 }}
                    wrapClassName="modal-editing-entity-field"
                    styles={{
                        mask: {
                            padding: 0,
                        },
                        body: {
                            padding: 0,
                        },
                    }}
                    footer={[]}
                    destroyOnClose
                >
                    {editingEntityField && (
                        <EntityFieldUpdatingForm
                            datatypeDependingInputs={(props) => (
                                <DatatypeDependingInputs
                                    editingField={props.editingField as TEntityField}
                                    setEditingField={
                                        props.setEditingField as (
                                            entityField: TEntityField | TVariable
                                        ) => void
                                    }
                                    error={props.error}
                                    optionEditor={(
                                        fieldId: number,
                                        type: TFieldOptionType
                                    ) => (
                                        <FieldOptionEditor
                                            fieldId={fieldId}
                                            type={type}
                                        />
                                    )}
                                />
                            )}
                            onSuccessSave={onClose}
                            onCancel={onClose}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

export default EntityFieldEditCreate;
