import { Button, Result } from "antd";

const FallbackComponent = ({
    error,
    resetErrorBoundary,
}: {
    error: any;
    resetErrorBoundary: () => void;
}) => {
    return (
        <Result
            status={"error"}
            title={`Ошибка! ${error?.name}: ${error?.message}`}
            extra={<Button onClick={resetErrorBoundary}>Сбросить ошибку</Button>}
        />
    );
};

export default FallbackComponent;
