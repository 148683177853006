import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ITechMapTemplate } from "../TechMapTemplateSlice";
import {
    createTechMapTemplateThunk,
    deleteTechMapTemplateThunk,
    loadTechMapTemplatesThunk,
    loadTechMapTemplateThunk,
    updateTechMapTemplateThunk,
} from "./TechMapTemplateThunk";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { updateArrByItemId } from "@shared/lib/object-helper";

const techMapTemplatesLoadBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(loadTechMapTemplatesThunk.fulfilled, (state, action) => {
        state.techMapTemplates = action.payload.data;

        state.getLoading = false;
    });
    builder.addCase(loadTechMapTemplatesThunk.pending, (state, action) => {
        state.getLoading = true;
    });
    builder.addCase(loadTechMapTemplatesThunk.rejected, (state, rejectedValue) => {
        state.getLoading = false;
        notificationEmit({
            title: "Не удалось загрузить элемент",
            type: "error",
        });
    });
};
const techMapTemplateLoadBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(loadTechMapTemplateThunk.fulfilled, (state, action) => {
        const id = action.meta.arg.techMapTemplateId;
        state.techMapTemplate = action.payload.data;
        state.getOneLoading = state.getOneLoading.filter((item) => item !== id);
    });
    builder.addCase(loadTechMapTemplateThunk.pending, (state, action) => {
        state.getOneLoading.push(action.meta.arg.techMapTemplateId);
    });
    builder.addCase(loadTechMapTemplateThunk.rejected, (state, action) => {
        const id = action.meta.arg.techMapTemplateId;
        state.getOneLoading = state.getOneLoading.filter((item) => item !== id);
        notificationEmit({
            title: "Не удалось загрузить элемент",
            type: "error",
        });
    });
};

const techMapTemplateCreateBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(createTechMapTemplateThunk.fulfilled, (state, action) => {
        state.techMapTemplates = [action.payload.data, ...state.techMapTemplates];
        state.createLoading = false;
    });
    builder.addCase(createTechMapTemplateThunk.pending, (state, action) => {
        state.createLoading = true;
    });
    builder.addCase(createTechMapTemplateThunk.rejected, (state, rejectedValue) => {
        state.createLoading = false;
        notificationEmit({
            title: "Не удалось создать элемент",
            type: "error",
        });
    });
};

const techMapTemplateUpdateBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(updateTechMapTemplateThunk.fulfilled, (state, action) => {
        const id = action.meta.arg.techMapTemplate.id;
        state.techMapTemplates = updateArrByItemId(
            state.techMapTemplates,
            action.payload.data
        );
        if (
            state.techMapTemplate &&
            "id" in state.techMapTemplate &&
            state.techMapTemplate.id === id
        )
            state.techMapTemplate = {
                ...state.techMapTemplate,
                ...action.payload.data,
            };
        state.updateLoading = state.updateLoading.filter((item) => item !== id);
    });
    builder.addCase(updateTechMapTemplateThunk.pending, (state, action) => {
        // state.updateLoading.push(action.meta.arg.techMapTemplate.id);
    });
    builder.addCase(updateTechMapTemplateThunk.rejected, (state, rejectedValue) => {
        state.updateLoading = state.updateLoading.filter(
            (item) => item !== rejectedValue.meta.arg.techMapTemplate.id
        );
        notificationEmit({
            title: "Не удалось обновить элемент",
            type: "error",
        });
    });
};

const techMapTemplateDeleteBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(deleteTechMapTemplateThunk.fulfilled, (state, action) => {
        const id = action.meta.arg.id;
        state.techMapTemplates = state.techMapTemplates.filter((item) => item.id !== id);

        state.deleteLoading = state.deleteLoading.filter((item) => item !== id);

        if (
            state.techMapTemplate &&
            "id" in state.techMapTemplate &&
            state.techMapTemplate.id === id
        )
            state.techMapTemplate = null;
    });
    builder.addCase(deleteTechMapTemplateThunk.pending, (state, action) => {
        state.deleteLoading.push(action.meta.arg.id);
    });
    builder.addCase(deleteTechMapTemplateThunk.rejected, (state, rejectedValue) => {
        state.deleteLoading = state.deleteLoading.filter(
            (item) => item !== rejectedValue.meta.arg.id
        );
        notificationEmit({
            title: "Не удалось удалить элемент",
            type: "error",
        });
    });
};

export const techMapTemplateBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    techMapTemplatesLoadBuilder(builder);
    techMapTemplateLoadBuilder(builder);
    techMapTemplateCreateBuilder(builder);
    techMapTemplateUpdateBuilder(builder);
    techMapTemplateDeleteBuilder(builder);
};
