import { $api } from "@shared/api/api";
const route = "/api/production/tm-template";

export const getTechMapTemplates = (params?: TParams) => {
    return $api.get<TResponseList<TTechMapTemplate>>(route, { params: params });
};

export const getTechMapTemplatesWithFilters = (
    filter: TFilterValue | TMultipleCondition | undefined,
    result: { entity_uuid: string; entity_row_id: number | null } | undefined,
    executor_roles: number[] | null,
    reviewer_roles: number[] | null,
    per_page: number
) => {
    return $api.post(`${route}/filter`, {
        result,
        filter,
        executor_roles,
        reviewer_roles,
        per_page,
    });
};

export const getTechMapTemplate = (techMapTemplateId: number) => {
    return $api.get<TResponse<TTechMapTemplateFull>>(`${route}/${techMapTemplateId}`);
};
export const updateTechMapTemplate = (techMapTemplate: TTechMapTemplateReq) => {
    return $api.put<TResponse<TTechMapTemplate>>(
        `${route}/${techMapTemplate.id}`,
        techMapTemplate
    );
};
export const createTechMapTemplate = (techMapTemplate: TTechMapTemplateReq) => {
    return $api.post<TResponse<TTechMapTemplate>>(route, techMapTemplate);
};
export const deleteTechMapTemplate = (id: number) => {
    return $api.delete<TResponse<null>>(`${route}/${id}`);
};
