import { entityFieldConstants } from "@entities/Portal/EntityField";
import { entityRowConstants } from "@entities/Portal/EntityRow";
import { navigationConstants } from "@entities/Portal/NavigationRoute";
import { techMapConstant } from "@entities/Production/TechMap";
import { techMapTemplateConstant } from "@entities/Production/TechMapTemplate";

export const patterns = [
    {
        pattern: entityRowConstants.ERP_LOCATION_PATTERN,
        nameIndex: entityRowConstants.LOCATION_ENTITY_TABLE_INDEX,
        idIndex: entityRowConstants.LOCATION_ENTITY_ROW_ID_INDEX,
    },
    {
        pattern: techMapTemplateConstant.LOCATION_TMT_OPERATION_PATTERN,
        nameIndex: techMapTemplateConstant.LOCATION_TMT_OPERATION_NAME_INDEX,
        idIndex: techMapTemplateConstant.LOCATION_TMT_OPERATION_ID_INDEX,
    },
    {
        pattern: entityFieldConstants.LOCATION_FILE_VIEWER_PATTERN,
        nameIndex: entityFieldConstants.LOCATION_FILE_VIEWER_FILE_NAME_INDEX,
        idIndex: entityFieldConstants.LOCATION_FILE_VIEWER_ID_INDEX,
        tableIndex: entityFieldConstants.LOCATION_FILE_VIEWER_ENTITY_TABLE_INDEX,
    },
    {
        pattern: techMapConstant.LOCATION_TM_PATTERN,
        nameIndex: techMapConstant.LOCATION_TM_NAME_INDEX,
        idIndex: techMapConstant.LOCATION_TM_ID_INDEX,
    },
    {
        pattern: navigationConstants.LOCATION_IFRAME_PATTERN,
        nameIndex: navigationConstants.LOCATION_IFRAME_NAME_INDEX,
        idIndex: navigationConstants.LOCATION_IFRAME_ID_INDEX,
    },
];
