export const entityFieldCreateUpdateFormVariants = {
    open: (action: () => void) => ({
        width: "100%",
        transition: {
            type: "linear",
            duration: 0.15,
        },
        transitionEnd: {
            overflow: "auto",
        },
    }),
    closed: {
        width: 0,
        left: 0,
        overflow: "hidden",
        transition: {
            type: "linear",
            duration: 0.15,
        },
    },
};
