import { VALID_PARSING_OBJECTS_KEY_WITH_VARIABLES } from "@shared/constants";

export const updateArrByItemId = (fullArray: any, newItem: any) => {
    return fullArray.map((item: any) => {
        if (item?.id === newItem?.id) return newItem;
        return item;
    });
};

export const compareObjects = (obj1: any, obj2: any) => {
    if (typeof obj1 !== typeof obj2) {
        return false;
    }

    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
        if (Array.isArray(obj1) !== Array.isArray(obj2)) {
            return false;
        }

        if (Array.isArray(obj1)) {
            if (obj1.length !== obj2.length) {
                return false;
            }
            obj1 = obj1.slice().sort();
            obj2 = obj2.slice().sort();
            for (let i = 0; i < obj1.length; i++) {
                if (!compareObjects(obj1[i], obj2[i])) {
                    return false;
                }
            }
            return true;
        } else {
            const keys1 = Object.keys(obj1).sort();
            const keys2 = Object.keys(obj2).sort();

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (let i = 0; i < keys1.length; i++) {
                if (
                    keys1[i] !== keys2[i] ||
                    !compareObjects(obj1[keys1[i]], obj2[keys2[i]])
                ) {
                    return false;
                }
            }
            return true;
        }
    }
    return obj1 === obj2;
};

export const checkIsValidParsingKeyWithVariables = (value: string | null) =>
    VALID_PARSING_OBJECTS_KEY_WITH_VARIABLES.test(value ?? "");
