import { estimationModel } from "@entities/Portal/Estimations";
import { useDispatch, useSelector } from "react-redux";

export const useEstimationTemplateEditModal = () => {
    const dispatch = useDispatch();
    const creatingEditingEstimation = useSelector(
        estimationModel.selectCreatingEditingEstimation
    );
    const isOpen = creatingEditingEstimation != null;

    const closeModal = () => {
        dispatch(estimationModel.setCreatingEditingEstimation(null));
    };

    return {
        creatingEditingEstimation,
        isOpen,
        closeModal,
    };
};
