import { TParseRule, TTargetOfParseRule } from "../model/types";

export const useEditQueryActionResultParse = (
    parseRules: TParseRule[],
    onChangeParseRules: (item: TParseRule[]) => void
) => {
    const onChangeParseRulesCommonProps = (
        name: keyof TParseRule,
        value: string | TTargetOfParseRule[] | boolean | null,
        index: number
    ) => {
        onChangeParseRules(
            parseRules.map((item, idx) => {
                if (idx === index) {
                    return { ...item, [name]: value };
                }
                return item;
            })
        );
    };
    const onAdd = () => {
        onChangeParseRules([
            ...parseRules,
            {
                key: null,
                is_optional: true,
                targets: [],
            },
        ]);
    };

    const onDelete = (index: number) =>
        onChangeParseRules(parseRules.filter((item, idx) => idx !== index));
    return { onChangeParseRulesCommonProps, onAdd, onDelete };
};
