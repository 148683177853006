import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCreateBizProcTemplateItem } from "../model";

interface ICreateBizProcTemplateItemButton {
    entityId: number;
    categoryUuid: string;
    entityUuid: string;
}

const CreateBizProcTemplateItemButton = ({
    entityId,
    entityUuid,
    categoryUuid,
}: ICreateBizProcTemplateItemButton) => {
    const { onAdd, loading } = useCreateBizProcTemplateItem(
        entityId,
        entityUuid,
        categoryUuid
    );

    return (
        <Button type="link" onClick={onAdd} loading={loading} icon={<PlusOutlined />}>
            Создать шаблон
        </Button>
    );
};

export default CreateBizProcTemplateItemButton;
