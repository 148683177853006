import { emitter } from "@shared/emitter";
import { useEffect, useRef, useState } from "react";
import { CHECK_AND_PRINT_EMIT } from "../constants";
import { printCode } from "../emits";

export const useCheckConnectionModal = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [printerIsConnected, setPrinterIsConnected] = useState(false);

    const onChangeConnectedState = (state: boolean) => {
        setPrinterIsConnected(state);
    };
    const savedCode = useRef<string>("");
    const savedType = useRef<TPrinterCodeItemType>("qrCode");
    const checkAndPrint = (code: string, type: TPrinterCodeItemType) => {
        savedCode.current = code;
        savedType.current = type;
        if (!printerIsConnected) setIsOpen(true);
        else printCode(code, type);
    };

    const onCloseModal = () => {
        setIsOpen(false);
        savedCode.current = "";
        savedType.current = "qrCode";
    };

    const onOk = () => {
        if (savedCode.current && savedType.current) {
            printCode(savedCode.current, savedType.current);
        }
        onCloseModal();
    };

    useEffect(() => {
        emitter.on(CHECK_AND_PRINT_EMIT, checkAndPrint);
        return () => {
            emitter.removeListener(CHECK_AND_PRINT_EMIT, checkAndPrint);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printerIsConnected]);

    return {
        printerIsConnected,
        isOpen,
        onCloseModal,
        onChangeConnectedState,
        onOk,
    };
};
