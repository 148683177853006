import { useEffect, useState } from "react";
import { getTechMap } from "../api";

interface IUseTMOperationItemCodes {
    tmId: number;
}

export const useLoadFullTechMap = ({ tmId }: IUseTMOperationItemCodes) => {
    const [techMap, setTechMap] = useState<TTechMapStarted>();
    const [loading, setLoading] = useState(false);

    const load = () => {
        setLoading(true);
        getTechMap(tmId)
            .then((response) => {
                setTechMap(response.data.data);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tmId]);

    return {
        techMap,
        loading,
    };
};
