import { PlusOutlined } from "@ant-design/icons";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { openDrawer } from "@shared/ui/DrawerManager";
import { Button } from "antd";
import EntityListView from "./EntityListView";
import EntityTableView from "./EntityTableView";
import EntityViewMode from "./EntityViewMode";
import { entityRowConstants } from "@entities/Portal/EntityRow";

interface IEntityView {
    entityTableName?: string;
    isModal?: boolean;
    viewMode: TEntityInputViewMode;
    onlyRead?: boolean;
    fields: (TBizProcTemplateActionField & TEntityFieldWithValue)[];
    values: any[];
    entityField: TEntityField | TVariable;
    additionalAction?: React.ReactNode;
    setMode: (item: TEntityInputViewMode) => void;
    onEdit: (item: TBizProcFormField) => void;
    onDelete: (item: TBizProcFormField) => void;
    onAdd: () => void;
}

const EntityView = ({
    entityTableName,
    isModal,
    viewMode,
    onlyRead,
    fields,
    values,
    entityField,
    additionalAction,
    setMode,
    onEdit,
    onDelete,
    onAdd,
}: IEntityView) => {
    const tmpValuesOrdered = [...values].reverse();
    const orderedFields = fields.toSorted((a: TEntityField, b: TEntityField) => {
        return a.order! - b.order!;
    });

    const onClick = (id: any) => {
        openDrawer({
            id: id,
            name: entityTableName!,
            pattern: entityRowConstants.ERP_LOCATION_PATTERN,
        });
    };

    return (
        <div style={{ marginTop: 8 }}>
            {!isModal && (
                <FlexContainer
                    type="row-end"
                    customStyle={{ flexWrap: "wrap", marginBottom: 5 }}
                >
                    {tmpValuesOrdered.length > 0 && (
                        <>
                            <EntityViewMode
                                id={entityField.id}
                                name={entityField.name}
                                mode={viewMode}
                                setMode={setMode}
                            />
                            {additionalAction && additionalAction}
                        </>
                    )}
                    {!onlyRead && !(values.length === 1 && !entityField.is_multiple) && (
                        <Button type="primary" onClick={onAdd} icon={<PlusOutlined />}>
                            Добавить
                        </Button>
                    )}
                </FlexContainer>
            )}
            {isModal || viewMode === "table" ? (
                <EntityTableView
                    onlyRead={onlyRead}
                    fields={orderedFields}
                    dataSource={tmpValuesOrdered}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            ) : (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <EntityListView
                        onlyRead={onlyRead}
                        fields={orderedFields}
                        entityRowsOrIds={tmpValuesOrdered}
                        onEdit={onEdit}
                        onClick={onClick}
                        onDelete={onDelete}
                    />
                </div>
            )}
        </div>
    );
};

export default EntityView;
