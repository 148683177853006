import { PAPER_SIZE } from "../constants";

export class StickerPrinter {
    paperSize = PAPER_SIZE.PAPER_65x45;

    constructor(paperSize: PAPER_SIZE) {
        this.paperSize = paperSize;
    }

    async connect() {}
    async print(text: string) {}
    async printQrCode(code: string) {}
    async printBarCode(code: string) {}
}
