import { Flex } from "antd";
import { Bookmark } from "../DrawerManager";
import { officeExtensions } from "../FileUpload/constants";
import { getFileExtenstion } from "../FileUpload/lib";
import { Office365Viewer } from "../Office365Viewer";
import { PDFViewer } from "../PDFViewer";
import "./FileViewer.scss";

const FileViewer = ({
    linkToFile,
    closeDrawer,
}: {
    linkToFile: string;
    closeDrawer: () => void;
}) => {
    const fileExt = getFileExtenstion(linkToFile);
    return (
        <Flex className="file-viewer">
            <Bookmark onClose={closeDrawer}>
                <></>
            </Bookmark>
            {fileExt === "pdf" && <PDFViewer linkToPdfFile={linkToFile} />}
            {officeExtensions.includes(fileExt) && (
                <Office365Viewer linkToFile={linkToFile} />
            )}
        </Flex>
    );
};

export default FileViewer;
