import { techMapConstant, techMapModel } from "@entities/Production/TechMap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMFilters = () => {
    const filters = useSelector(techMapModel.selectTechMapFilters);
    const dispatch = useDispatch();
    const templates = filters.technical_map_template_uuid;
    const status = filters.status;
    const usersIds = filters.user_id;
    const statusOptions = Object.keys(techMapConstant.tmStatusNames).map((key) => ({
        label: techMapConstant.tmStatusNames[
            key as keyof typeof techMapConstant.tmStatusNames
        ],
        value: key,
    }));
    const onChangeUsersIds = (value: number[] | null) => {
        dispatch(
            techMapModel.setFilters({
                ...filters,
                user_id: value,
            })
        );
    };
    const onChangeStatuses = (value: TTechMapStatus[] | null) => {
        dispatch(
            techMapModel.setFilters({
                ...filters,
                status: value,
            })
        );
    };

    return {
        templates,
        status,
        usersIds,
        statusOptions,
        onChangeUsersIds,
        onChangeStatuses,
    };
};
