import { SearchOutlined } from "@ant-design/icons";
import { FormInputItem } from "@entities/Portal/Datatype";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Select } from "antd";
import DeleteEntityFieldInput from "./DeleteEntityFieldInput";
import { useEntityRowsFiltersForm } from "../model";
import {
    getFormInputItemAdditionalParameters,
    getFormInputItemFieldsArray,
    getFormInputItemValue,
} from "../lib";

interface IEntityRowsFiltersForm {
    entityFields: TEntityField[];
    isVisible: boolean;
    topCoordinateOfModal?: number;
    visibleInputs: (TBizProcFormField & {
        condition?: any;
    })[];
    setCurrentFilters: (filters: TFilterValue[]) => void;
    openConfiguringModal: () => void;
    setDefaultFilters: () => void;
    resetFilters: () => void;
    searchByFilters: () => void;
    deleteFilterInput: (event: any) => void;
}

const EntityRowsFiltersForm = ({
    entityFields,
    isVisible,
    topCoordinateOfModal,
    visibleInputs,
    setCurrentFilters,
    openConfiguringModal,
    setDefaultFilters,
    resetFilters,
    searchByFilters,
    deleteFilterInput,
}: IEntityRowsFiltersForm) => {
    const {
        isDisabledCurrentCategorySelect,
        currentCategory,
        onChangeFormFieldCondition,
        onChangeFormFieldValue,
    } = useEntityRowsFiltersForm(visibleInputs, setCurrentFilters);

    return (
        <div
            className="entity-value-filter__entity-fields-inputs"
            style={{
                flexDirection: "column",
                display: isVisible ? "flex" : "none",
                top: topCoordinateOfModal ? topCoordinateOfModal + 8 : 0,
                left: 0,
            }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            {visibleInputs.map((filterValueItem, index) => {
                return (
                    <Flex
                        gap={8}
                        key={index}
                        className="entity-value-filter__entity-fields-inputs-item"
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-end",
                            position: "relative",
                            flexWrap: "wrap",
                            marginTop: 8,
                        }}
                    >
                        <FormItem
                            style={{
                                flex: "1 1 120px",
                                width: "unset",
                            }}
                            labelStyle={{ wordBreak: "break-all" }}
                            label={
                                <Flex>
                                    {filterValueItem.name}
                                    <DeleteEntityFieldInput
                                        value={filterValueItem.key}
                                        action={deleteFilterInput}
                                    />
                                </Flex>
                            }
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Select
                                    onChange={(value) =>
                                        onChangeFormFieldCondition(
                                            value,
                                            filterValueItem.unique_id
                                        )
                                    }
                                    value={filterValueItem?.condition}
                                    disabled={false}
                                    placeholder="Условия сравнения"
                                    options={filterValueItem.comparison_types}
                                    size="middle"
                                    style={{
                                        boxSizing: "content-box",
                                        width: "100%",
                                    }}
                                />
                            </div>
                        </FormItem>
                        <FormItem style={{ flex: "4 1 120px", width: "unset" }}>
                            <>
                                {!(
                                    filterValueItem.condition === "null" ||
                                    filterValueItem?.condition === "not_null"
                                ) && (
                                    <FormInputItem
                                        field={filterValueItem}
                                        inputId={filterValueItem.unique_id}
                                        additionalParameters={getFormInputItemAdditionalParameters(
                                            filterValueItem
                                        )}
                                        datatype={
                                            filterValueItem.datatype as unknown as string
                                        }
                                        fieldsArray={getFormInputItemFieldsArray(
                                            filterValueItem,
                                            isDisabledCurrentCategorySelect,
                                            currentCategory,
                                            visibleInputs,
                                            entityFields
                                        )}
                                        value={getFormInputItemValue(
                                            filterValueItem,
                                            isDisabledCurrentCategorySelect
                                        )}
                                        onlyRead={false}
                                        key={index}
                                        onChange={onChangeFormFieldValue}
                                    />
                                )}
                            </>
                        </FormItem>
                    </Flex>
                );
            })}

            <Flex wrap="wrap" gap={8}>
                <Button onClick={openConfiguringModal} style={{ padding: 0 }} type="link">
                    Добавить поле
                </Button>
                <Button
                    onClick={setDefaultFilters}
                    type="link"
                    style={{ color: "gray", padding: 0 }}
                >
                    Вернуть поля по умолчанию
                </Button>
            </Flex>
            <Flex gap={8} className="entity-value-filter__entity-fields-filters-actions">
                <Button style={{ width: 100 }} onClick={resetFilters}>
                    Сбросить
                </Button>
                <Button
                    style={{ width: 100 }}
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={searchByFilters}
                >
                    Найти
                </Button>
            </Flex>
        </div>
    );
};

export default EntityRowsFiltersForm;
