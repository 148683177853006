import { userApi, userModel } from "@entities/Portal/User";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useUserTimezone = (userId: number, initialTimezoneId?: string | null) => {
    const { loading, defferedCallback } = useDefferedCallback(1000);
    const [timezone, setTimezone] = useState(initialTimezoneId);
    const dispatch = useDispatch();

    const updateTimezone = (timezone: string) => {
        return userApi
            .updateUserTimezone(userId, timezone)
            .then((response) => {
                dispatch(userModel.setUserTimezone(response.data.data.timezone));
            })
            .catch(() => {
                setTimezone(initialTimezoneId);
                notificationEmit({
                    type: "error",
                    title: "Не удалось обновить временную зону",
                });
            });
    };
    const onChangeTimezone = (value: string, _: any, extrInfo: any) => {
        setTimezone(value);
        defferedCallback(() => updateTimezone(value));
    };
    return {
        timezone,
        loading,
        onChangeTimezone,
    };
};
