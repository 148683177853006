import { techMapTemplateLib } from "@entities/Production/TechMapTemplate";
import { compareResultById } from "../lib";

export const useTechMapTemplateResult = (
    tmtResults: (TTechMapTemplateResult | TTechMapTemplateResultCreating)[],
    onChange: (
        tmtResults: (TTechMapTemplateResult | TTechMapTemplateResultCreating)[]
    ) => void
) => {
    const onChangeItem = (
        item: TTechMapTemplateResult | TTechMapTemplateResultCreating
    ) => {
        onChange(
            tmtResults.map((result) => {
                return compareResultById(item, result) ? item : result;
            })
        );
    };

    const onCreate = () => {
        onChange([...tmtResults, techMapTemplateLib.getTechMapTemplateResultMock()]);
    };
    const onDelete = (item: TTechMapTemplateResult | TTechMapTemplateResultCreating) => {
        onChange(
            tmtResults.filter((result) => {
                return !compareResultById(item, result);
            })
        );
    };

    return {
        onChangeItem,
        onCreate,
        onDelete,
    };
};
