import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import EditTemplateBar from "../EditTemplateBar";

interface IApproveBranchAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const ApproveBranchAction = ({ onDeleteItem, templateItem }: IApproveBranchAction) => {
    return (
        <CardWithHead
            backgroundColor="var(--success)"
            noColor
            title={
                <EditTemplateBar
                    textColor="white"
                    title={templateItem?.name ?? "Согласовать"}
                />
            }
        />
    );
};

export default ApproveBranchAction;
