import { LoadingOutlined, MenuOutlined, PauseOutlined } from "@ant-design/icons";
import { useScroll } from "@shared/hooks";
import { Popover, Spin, Typography } from "antd";
import { ReactNode, useEffect } from "react";
import { operationBgColor } from "../constants";
import "./TMOperationsList.scss";
interface ITMOperationItem {
    templateOperation: TTechMapTemplateOperation;
    operation: TTechMapOperation | undefined | null;
    small?: boolean;
    operationPopoverNode?: ReactNode;
}

const TMOperationItem = ({
    templateOperation,
    operation,
    small,
    operationPopoverNode,
}: ITMOperationItem) => {
    const bgColor = operation ? operationBgColor[operation.status] : "#eaeaea";
    const { executeScroll, elRef } = useScroll();

    useEffect(() => {
        if (operation?.status === "in_progress") {
            executeScroll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operation?.status]);

    return (
        <div className="tm-operation" ref={elRef}>
            <div
                className={
                    !small ? "tm-operation__content" : "tm-operation__content--small"
                }
                style={{
                    backgroundColor: bgColor,
                    width: "98%",
                }}
            >
                <Typography.Text ellipsis>
                    {templateOperation.name}
                    {Boolean(operationPopoverNode) && (
                        <Popover
                            content={operationPopoverNode}
                            placement={"bottom"}
                            arrow={{ pointAtCenter: true }}
                        >
                            <MenuOutlined
                                style={{
                                    cursor: "pointer",
                                    borderRadius: 8,
                                    padding: 4,
                                    marginLeft: 10,
                                    backgroundColor: "white",
                                }}
                            />
                        </Popover>
                    )}
                    {operation?.status === "in_progress" && (
                        <Spin
                            indicator={<LoadingOutlined />}
                            style={{ marginLeft: 15 }}
                        />
                    )}
                </Typography.Text>
                {operation?.status === "paused" && (
                    <span style={{ textWrap: "nowrap", marginLeft: 4 }}>
                        Пауза <PauseOutlined />
                    </span>
                )}
            </div>
            <div
                className={!small ? "tm-operation__arrow" : "tm-operation__arrow--small"}
                style={{ backgroundColor: bgColor }}
            />
        </div>
    );
};

export default TMOperationItem;
