import { userApi } from "@entities/Portal/User";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useRecoverPasswordForm = (code: string) => {
    const [passwords, setPasswords] = useState({
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState<null | string>(null);
    const [checkError, setCheckError] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const [checkLoading, setCheckLoading] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const checkCode = () => {
        setCheckLoading(true);
        userApi
            .checkRecoverPassword(code)
            .then(() => {
                setIsChecked(true);
            })
            .catch((error) => {
                setCheckError(error?.response?.data?.message ?? "Неизвестная ошибка");
            })
            .finally(() => {
                setCheckLoading(false);
            });
    };
    useEffect(() => {
        checkCode();
    }, []);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError(null);
        setPasswords({
            ...passwords,
            [event.target.name]: event.target.value,
        });
    };
    const submitPassword = () => {
        setLoading(true);
        userApi
            .recoverPassword(code, passwords.password)
            .then(() => {
                setSuccess(true);
                setTimeout(() => {
                    navigate("/login");
                }, 1500);
            })
            .catch((error) => {
                notificationEmit({
                    type: "error",
                    title: error?.response?.data?.message ?? "Неизвестная ошибка!",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onSubmit = () => {
        if (passwords.password.length < 8) {
            setError("Пароль должен быть минимум 8 символов");
            return;
        }
        if (passwords.password != passwords.confirmPassword) {
            setError("Пароли не совпадают");
            return;
        }
        submitPassword();
    };

    const navigateToStartRecoverPassword = () => {
        navigate("/start-recover-password");
    };
    return {
        error,
        isChecked,
        checkLoading,
        checkError,
        passwords,
        loading,
        success,
        onChange,
        onSubmit,
        navigateToStartRecoverPassword,
    };
};
