import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { TFiltersTechMap } from "@widgets/Production/TechMapTemplatesFilters/types";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useTechMapTemplateList = () => {
    const {
        techMapTemplates,
        loading,
        setFilters,
        filters,
        loadTechMapTemplatesWithPagination,
        isPaginationLoading,
        paginationIsEnded,
        perPage,
    } = techMapTemplateModel.useTechMapTemplatesLoad();
    const dispatch = useDispatch<AppDispatch>();
    const onDelete = (techMapTemplate: TTechMapTemplate) => {
        dispatch(
            techMapTemplateModel.deleteTechMapTemplateThunk({ id: techMapTemplate.id })
        );
    };
    const [filters_, setFilters_] = useState<TFiltersTechMap>(filters);

    const onChange = (name: keyof TFiltersTechMap, value: any) => {
        setFilters_({ ...filters_, [name]: value });
    };

    const resetFilters = () => {
        //TODO: вынести в либу отдельную для компонента фильтров isActiveFilters
        if (
            filters.entity_row_id ||
            filters.entity_uuid ||
            filters.is_active ||
            filters_.name ||
            (filters.executor_roles && filters.executor_roles.length > 0) ||
            (filters.reviewer_roles && filters.reviewer_roles.length > 0)
        )
            setFilters({
                name: null,
                entity_uuid: null,
                entity_row_id: null,
                is_active: undefined,
                executor_roles: [],
                reviewer_roles: [],
            });
    };

    const submitFilters = () => {
        setFilters(filters_);
    };
    return {
        onChange,
        resetFilters,
        submitFilters,
        filters: filters_,
        onDelete,
        techMapTemplates,
        loading,
        loadTechMapTemplatesWithPagination,
        isPaginationLoading,
        paginationIsEnded,
        perPage,
    };
};
