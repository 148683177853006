import { CheckOutlined, ReloadOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import { getStageBackgroundColor } from "../lib";
import StageEntityRowItem from "./StageEntityRowItem";

interface IStagesListEntityRow {
    stages: (
        | TStage
        | {
              id: number;
              uuid: string;
              category_uuid: string;
              type: TStageType;
              label: string;
              prev_stage_uuid: string;
              background_color: string;
          }
    )[];
    onlyRead?: boolean;
    onEnter: (stage: TStage, index: number) => void;
    onLeave: () => void;
    submitLoading: number | null;
    onClickEndStage: (item: number) => void;
    onClickStage: (item: number) => void;
    hoveredIndex: number | null;
    hoveredStage:
        | (TStage & {
              index: number;
          })
        | null;
    currentStage: TStage | null;
    currentStageIndex: number | null;
    showOnlCurrentStage?: boolean;
    listDirection: "row" | "column";
}

const StagesListEntityRow = ({
    stages,
    onlyRead,
    onEnter,
    onLeave,
    submitLoading,
    onClickEndStage,
    onClickStage,
    hoveredIndex,
    hoveredStage,
    currentStage,
    currentStageIndex,
    showOnlCurrentStage,
    listDirection,
}: IStagesListEntityRow) => {
    const indexOfCurrentStageInArrayStages = stages
        .map((stage) => stage.id === currentStage?.id)
        .indexOf(true);

    if (listDirection === "column")
        return (
            <Timeline className="stages-timeline">
                {stages.map((stage, index, fullArr) => {
                    return (
                        <Timeline.Item
                            dot={
                                stage.id === currentStage?.id ? (
                                    <ReloadOutlined spin />
                                ) : index >
                                  indexOfCurrentStageInArrayStages ? undefined : (
                                    <CheckOutlined />
                                )
                            }
                            color={
                                index > indexOfCurrentStageInArrayStages
                                    ? "gray"
                                    : stage.id === currentStage?.id
                                      ? "blue"
                                      : "green"
                            }
                        >
                            <div
                                onMouseEnter={() => !onlyRead && onEnter(stage, index)}
                                onMouseLeave={() => onLeave()}
                                key={stage.id}
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                    display:
                                        showOnlCurrentStage &&
                                        stage.id !== currentStage?.id
                                            ? "none"
                                            : undefined,
                                }}
                            >
                                <StageEntityRowItem
                                    borderAround={true}
                                    loading={submitLoading === stage.id}
                                    onClickStage={(stage) =>
                                        fullArr.length === index + 1
                                            ? onClickEndStage(stage.id)
                                            : onClickStage(stage.id)
                                    }
                                    stage={stage}
                                    specificColor={getStageBackgroundColor(
                                        index,
                                        stage,
                                        hoveredIndex,
                                        hoveredStage,
                                        currentStage,
                                        currentStageIndex
                                    )}
                                />
                            </div>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        );

    return (
        <div
            style={{
                gap: "8px 8px",
                display: "flex",
                flexDirection: listDirection,
                width: "100%",
            }}
        >
            {stages.map((stage, index, fullArr) => {
                return (
                    <div
                        onMouseEnter={() => !onlyRead && onEnter(stage, index)}
                        onMouseLeave={() => onLeave()}
                        key={stage.id}
                        style={{
                            textAlign: "center",
                            width: "100%",
                            display:
                                showOnlCurrentStage && stage.id !== currentStage?.id
                                    ? "none"
                                    : undefined,
                        }}
                    >
                        <StageEntityRowItem
                            loading={
                                showOnlCurrentStage
                                    ? !!submitLoading
                                    : submitLoading === stage.id
                            }
                            onClickStage={(stage) =>
                                showOnlCurrentStage
                                    ? undefined
                                    : fullArr.length === index + 1
                                      ? onClickEndStage(stage.id)
                                      : onClickStage(stage.id)
                            }
                            stage={stage}
                            specificColor={getStageBackgroundColor(
                                index,
                                stage,
                                hoveredIndex,
                                hoveredStage,
                                currentStage,
                                currentStageIndex
                            )}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default StagesListEntityRow;
