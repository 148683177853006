import { LockOutlined } from "@ant-design/icons";
import { UserProfileInfo } from "@entities/Portal/User";
import { QrCodeList } from "@features/Portal/QrCodeList";
import { SelectUserTimezone } from "@features/Portal/User/SelectUserTimezone";
import { UserPassword } from "@features/Portal/User/UserPassword";
import { UserTelegramLinkButton } from "@features/Portal/User/UserTelegramLink";
import TelegramIcon from "@shared/assets/TelegramIcon";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Card, Spin, Typography } from "antd";
import { getUserAvatarName } from "../../lib";
import { useUserProfile } from "../../model";
import "./UserProfile.scss";
import UserProfileHeader from "./UserProfileHeader";
import { SignOutButton } from "@features/Portal/SignOutButton";
interface IUserProfile {
    userId: number;
}

const UserProfile = ({ userId }: IUserProfile) => {
    const { user, isLoading, isMe } = useUserProfile(userId);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                    }}
                >
                    <Spin />
                </div>
            ) : (
                <>
                    {user && (
                        <div className="user">
                            <IndentContainer>
                                <UserProfileHeader name={user.name} isMe={isMe} />
                            </IndentContainer>
                            <div className="user__info">
                                <Card
                                    style={{ border: "none" }}
                                    className="user__info__avatar-card"
                                >
                                    <div className="user__info__avatar-card__label">
                                        {getUserAvatarName(user?.name)}
                                    </div>
                                    {isMe && window.innerWidth < 800 && (
                                        <div style={{ position: "absolute", right: 10 }}>
                                            <SignOutButton
                                                size={
                                                    window.innerWidth <= 800
                                                        ? 16
                                                        : undefined
                                                }
                                                color="red"
                                            />
                                        </div>
                                    )}
                                </Card>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Card
                                        bordered={false}
                                        style={{ width: "100%" }}
                                        bodyStyle={{ width: "100%", height: "100%" }}
                                        title={
                                            <Typography.Title level={5}>
                                                Контактная информация
                                            </Typography.Title>
                                        }
                                    >
                                        <UserProfileInfo
                                            user={user}
                                            containerStyle={{
                                                width: "100%",
                                                maxWidth: 600,
                                            }}
                                            noAvatar
                                            additionalUserData={[
                                                ...[
                                                    {
                                                        label: "Временная зона",
                                                        value: (
                                                            <div
                                                                style={{
                                                                    marginTop: 10,
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <SelectUserTimezone
                                                                    onlyRead={!isMe}
                                                                    userId={user.id}
                                                                    timezoneId={
                                                                        user.timezone_id
                                                                    }
                                                                />
                                                            </div>
                                                        ),
                                                    },
                                                ],
                                                ...(isMe
                                                    ? [
                                                          {
                                                              label: "Телеграм",
                                                              value: (
                                                                  <UserTelegramLinkButton
                                                                      onlyReadTg={false}
                                                                  />
                                                              ),
                                                              icon: (
                                                                  style: React.CSSProperties
                                                              ) => (
                                                                  <TelegramIcon
                                                                      style={style}
                                                                  />
                                                              ),
                                                          },
                                                          {
                                                              label: "Пароль",
                                                              value: (
                                                                  <UserPassword
                                                                      userId={user.id}
                                                                  />
                                                              ),
                                                              icon: (
                                                                  style: React.CSSProperties
                                                              ) => (
                                                                  <LockOutlined
                                                                      style={style}
                                                                  />
                                                              ),
                                                          },
                                                      ]
                                                    : []),
                                            ]}
                                            // actions={
                                            //     <SignOutButton
                                            //         size={window.innerWidth <= 768 ? 16 : undefined}
                                            //         color="red"
                                            //     />
                                            // }
                                        />
                                    </Card>
                                    {isMe && (
                                        <IndentContainer type={["marginTop"]}>
                                            {" "}
                                            <Card
                                                bordered={false}
                                                title={
                                                    <Typography.Title level={5}>
                                                        QR-коды
                                                    </Typography.Title>
                                                }
                                            >
                                                <QrCodeList userId={userId} />
                                            </Card>
                                        </IndentContainer>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UserProfile;
