import { useState } from "react";
import { TEditQueryActionValue } from "./types";

export const useEditQueryActionBody = (
    value: string | Record<string | number, number | string | null | boolean> | undefined,
    onChange: (
        name: keyof TEditQueryActionValue,
        value: string | null | Record<string, string>[]
    ) => void
) => {
    const [isValidJsonBody, setIsValidJsonBody] = useState<boolean>(true);

    const stringifiedBody = (
        typeof value !== "string"
            ? JSON.stringify(
                  value
                      ? value.length === 0 || Object.keys(value).length === 0
                          ? undefined
                          : value
                      : value,
                  null,
                  4
              )
            : value
    ) as string | undefined;
    const isDisabledFormatButton =
        stringifiedBody === undefined || stringifiedBody.length === 0 || !isValidJsonBody;
    const countOfRows = stringifiedBody ? stringifiedBody.split("\n").length : undefined;
    const onChangeBody = (_: string, value: string) => {
        try {
            JSON.parse(value.length > 0 ? value : "{}");
            setIsValidJsonBody(true);
        } catch (error) {
            setIsValidJsonBody(false);
        }
        onChange("body", value);
    };
    const formatJson = () => {
        onChange(
            "body",
            JSON.stringify(
                typeof value === "object" ? value : JSON.parse(value as string),
                null,
                4
            )
        );
    };
    const minimizeJson = () =>
        onChange(
            "body",
            JSON.stringify(
                typeof value === "object" ? value : JSON.parse(value as string),
                null,
                0
            )
        );
    return {
        stringifiedBody,
        onChangeBody,
        countOfRows,
        isDisabledFormatButton,
        isValidJsonBody,
        formatJson,
        minimizeJson,
    };
};
