export const selectTechMapTemplates = (state: RootState): TTechMapTemplate[] =>
    state.techMapTemplate.techMapTemplates;
export const selectTechMapTemplate = (
    state: RootState
): TTechMapTemplate | TTechMapTemplateCreating | null =>
    state.techMapTemplate.techMapTemplate;
export const selectGetLoading = (state: RootState): boolean =>
    state.techMapTemplate.getLoading;
export const selectUpdateLoading = (
    state: RootState,
    techMapTemplateId: number
): boolean => state.techMapTemplate.updateLoading.includes(techMapTemplateId);
export const selectGetOneLoading = (
    state: RootState,
    techMapTemplateId: number | null
): boolean => state.techMapTemplate.getOneLoading.includes(techMapTemplateId);
export const selectCreateLoading = (state: RootState): boolean =>
    state.techMapTemplate.createLoading;
export const selectDeleteLoading = (
    state: RootState,
    techMapTemplateId: number
): boolean => state.techMapTemplate.deleteLoading.includes(techMapTemplateId);
