import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { TFiltersTechMap } from "@widgets/Production/TechMapTemplatesFilters/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTechMapTemplatesWithFilters } from "../../api";
import { setTechMapTemplates } from "../TechMapTemplateSlice";
import { selectGetLoading, selectTechMapTemplates } from "./TechMapTemplateSelectors";
import { loadTechMapTemplatesThunk } from "./TechMapTemplateThunk";
import { isNumber } from "lodash";

export const useTechMapTemplatesLoad = () => {
    const loading = useSelector(selectGetLoading);
    const techMapTemplates = useSelector(selectTechMapTemplates);
    const dispatch = useDispatch<AppDispatch>();
    const [isPaginationLoading, setIsPaginationLoading] = useState<boolean>(false);
    const [paginationIsEnded, setPaginationIsEnded] = useState<boolean>(false);
    const [filters, setFilters] = useState<TFiltersTechMap>({
        name: null,
        entity_uuid: null,
        entity_row_id: null,
        is_active: undefined,
        executor_roles: [],
        reviewer_roles: [],
    });
    const perPage = 15;
    const executorRoles = filters.executor_roles;
    const reviewerRoles = filters.reviewer_roles;
    const resultExecutorRoles =
        executorRoles === null || executorRoles.length === 0 ? null : executorRoles;
    const resultReviewerRoles =
        reviewerRoles === null || reviewerRoles.length === 0 ? null : reviewerRoles;
    useEffect(() => {
        setPaginationIsEnded(false);
        let result = null;
        if (filters.entity_uuid) {
            result = {
                entity_uuid: filters.entity_uuid,
                entity_row_id: filters.entity_row_id,
            };
        }
        const filter =
            filters.name || filters.is_active
                ? {
                      condition_type: "AND" as TConditionType,
                      conditions: [
                          ...(filters.name
                              ? [
                                    {
                                        key: "name",
                                        value: `%${filters.name}%`,
                                        condition: "like",
                                    },
                                ]
                              : []),
                          ...(isNumber(filters.is_active)
                              ? [
                                    {
                                        key: "is_active",
                                        value: filters.is_active,
                                        condition: "=",
                                    },
                                ]
                              : []),
                      ],
                  }
                : null;

        dispatch(
            loadTechMapTemplatesThunk({
                result,
                filter,
                perPage,
                executor_roles: resultExecutorRoles,
                reviewer_roles: resultReviewerRoles,
            })
        );
        return () => {
            dispatch(setTechMapTemplates([]));
        };
    }, [dispatch, filters, perPage]);

    const loadTechMapTemplatesWithPagination = (lastTemplateId: number) => {
        setIsPaginationLoading(true);
        const newFilter = {
            condition_type: "AND" as TConditionType,
            conditions: [
                ...(filters.name
                    ? [
                          {
                              key: "name",
                              value: `%${filters.name}%`,
                              condition: "like",
                          },
                      ]
                    : []),
                ...(isNumber(filters.is_active)
                    ? [
                          {
                              key: "is_active",
                              value: filters.is_active,
                              condition: "=",
                          },
                      ]
                    : []),
                { key: "id", value: lastTemplateId, condition: ">" },
            ],
        };
        getTechMapTemplatesWithFilters(
            newFilter,
            filters.entity_uuid
                ? {
                      entity_uuid: filters.entity_uuid,
                      entity_row_id: filters.entity_row_id,
                  }
                : undefined,
            resultExecutorRoles,
            resultReviewerRoles,
            perPage
        )
            .then((response) => {
                const {
                    data: {
                        data: { data },
                    },
                } = response;
                dispatch(setTechMapTemplates([...techMapTemplates, ...data]));
                if (response.data.data.total < perPage) setPaginationIsEnded(true);
            })
            .catch((e) => {
                console.log(e);
                notificationEmit({ type: "error", title: "Ошибка загрузки тех. карт" });
            })
            .finally(() => setIsPaginationLoading(false));
    };
    return {
        filters,
        setFilters,
        techMapTemplates,
        loading,
        loadTechMapTemplatesWithPagination,
        isPaginationLoading,
        paginationIsEnded,
        perPage,
    };
};
