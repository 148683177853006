import { useStageCreateUpdateForm } from "../model";
import { FormItem } from "@shared/ui/Form";
import { Button, ColorPicker, Input, Select, Space } from "antd";
import { stageTypesOptions } from "../constants";
interface IStageCreateUpdateForm {
    stage: TStage | TStageCreating;
    entityId: number;
    categoryId: number;
    onSave: () => void;
    onCancel: () => void;
}

const StageCreateUpdateForm = ({
    stage,
    entityId,
    categoryId,
    onSave,
    onCancel,
}: IStageCreateUpdateForm) => {
    const {
        loading,
        isCreated,
        editingStage,
        onChangeStageLabel,
        onChangeStageColor,
        onChangeStageType,
        onSubmit,
    } = useStageCreateUpdateForm(stage, entityId, categoryId, onSave);
    return (
        <div>
            <FormItem label="Имя">
                <Input value={editingStage.label} onChange={onChangeStageLabel} />
            </FormItem>
            <FormItem label="Тип">
                <Select
                    style={{ width: "100%" }}
                    options={stageTypesOptions}
                    onChange={onChangeStageType}
                    value={editingStage.type}
                    disabled
                />
            </FormItem>
            <FormItem label="Цвет">
                {/* <Input
                    prefix={
                        <div
                            style={{
                                width: 30,
                                height: 30,
                                backgroundColor: editingStage.background_color,
                            }}
                        ></div>
                    }
                    disabled={
                        editingStage.type === "successful" ||
                        editingStage.type === "unsuccessful"
                    }
                    value={editingStage.background_color}
                    onChange={onChangeStageColor}
                /> */}
                <ColorPicker
                    value={editingStage.background_color}
                    onChangeComplete={onChangeStageColor}
                    size="large"
                    showText
                />
            </FormItem>
            {isCreated && (
                <FormItem label="UUID">
                    <Input
                        disabled
                        value={(editingStage as TCategory).uuid}
                        onChange={onChangeStageLabel}
                    />
                </FormItem>
            )}

            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <Space>
                    {onCancel && <Button onClick={onCancel}>Закрыть</Button>}
                    <Button type="primary" onClick={onSubmit} loading={loading}>
                        Сохранить
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default StageCreateUpdateForm;
