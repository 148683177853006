import { getUserAgreementString } from "@entities/Portal/BizProc/lib";
import UserAssygnment from "./UserAssygnment";
import UserAssygnmentDropdownList from "./UserAssygnmentDropdownList";

interface IUsersAssygnment {
    users: TUser[];
    status: TBizProcStatus;
    onUserClick?: (user: TUser) => void;
    customLabel?: string;
}

const UsersAssygnment = ({
    users,
    status,
    customLabel,
    onUserClick,
}: IUsersAssygnment) => {
    return (
        <div
            className={`${users.length > 3 ? "user-assygnment-list--dropdown" : "user-assygnment-list"}`}
        >
            {customLabel !== undefined ? customLabel : getUserAgreementString(status)}
            {users.length > 3 ? (
                <div style={{ marginLeft: 8 }}>
                    <UserAssygnmentDropdownList users={users} onUserClick={onUserClick} />
                </div>
            ) : (
                <>
                    {users.map((user) => {
                        return (
                            <UserAssygnment
                                key={user.id}
                                onUserClick={onUserClick}
                                user={user}
                                status={status}
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default UsersAssygnment;
