import { Drawer } from "antd";
import { ReactNode } from "react";

interface IDrawerDefault {
    open: boolean;
    children: ReactNode;
    maskClosable?: boolean;
    onClose: () => void;
}

const DrawerDefault = ({ open, children, maskClosable, onClose }: IDrawerDefault) => {
    const windowWidth = window.innerWidth;

    const shortVariant = windowWidth <= 900;

    return (
        <Drawer
            destroyOnClose={true}
            onClose={onClose}
            maskClosable={maskClosable} // TODO
            width={!shortVariant ? "fit-content" : "100vw"}
            styles={{
                body: {
                    background: "rgb(143,133,232, 0.1)",
                    padding: 0,
                    overflow: "visible",
                },
                content: {
                    overflow: "visible",
                },
                mask: {
                    backdropFilter: "blur(1px)",
                },
            }}
            closeIcon={shortVariant}
            zIndex={1000}
            open={open}
        >
            {children}
        </Drawer>
    );
};

export default DrawerDefault;
