import { userModel } from "@entities/Portal/User";
import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useTechMapTmplOperationForm = (
    operationId: number | null | undefined,
    operationUuid: string | null | undefined,
    tmtUuid: string | null,
    parentUuid: string | null,
    tmtId: number,
    onCancel?: () => void
) => {
    const { operation, loading } = techMapTemplateModel.useTechMapTmplOperationLoadorMock(
        { operationUuid, operationId, tmtUuid, parentUuid }
    );
    const [editingOperation, setEditingOperation] = useState<
        TTechMapTemplateOperationFull | TTechMapTemplateOperationCreating | null
    >();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [editingMode, setEditingMode] = useState(false);
    const isAdmin = useSelector(userModel.selectUserIsAdmin);
    const onlyRead = !isAdmin;
    const dispatch = useDispatch<AppDispatch>();
    const isCreating = operation !== null && !("id" in operation);
    useEffect(() => {
        if (!loading) {
            setEditingOperation(operation);
        }
    }, [loading]);

    const onSubmit = () => {
        if (!editingOperation) return;
        setUpdateLoading(true);
        dispatch(
            techMapTemplateModel.updateTechMapTemplateOperationsThunk(
                editingOperation,
                tmtId
            )
        )
            .then(() => {
                onCancel && onCancel();
            })
            .catch(() => {
                console.log("ERROR");
            })
            .finally(() => {
                setUpdateLoading(false);
            });
    };

    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        if (editingOperation)
            setEditingOperation({
                ...editingOperation,
                name: event.target.value,
            });
    };

    const onClickEditing = () => {
        setEditingMode(true);
    };
    const onClickCancelEditing = () => {
        setEditingMode(false);
        setEditingOperation(operation);
    };

    const onUpdateNecessatyItems = (
        items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[]
    ) => {
        setEditingOperation({
            ...editingOperation!,
            necessary_items: items,
        });
    };
    const onUpdateTemplateCodes = (items: TMTOCodeTemplateItem[]) => {
        setEditingOperation({
            ...editingOperation!,
            code_templates: items,
        });
    };

    const onDelete = () => {
        if (operation && "id" in operation) {
            setDeleteLoading(true);
            dispatch(
                techMapTemplateModel.deleteTechMapTemplateOperationThunk(operation, tmtId)
            )
                .then(() => {
                    onCancel && onCancel();
                })
                .catch(() => {
                    console.log("ERROR");
                })
                .finally(() => {
                    setDeleteLoading(false);
                });
        }
    };
    return {
        operation: editingOperation,
        operationName: operation?.name,
        onlyRead,
        editingMode: editingMode || isCreating,
        isCreating,
        deleteLoading,
        updateLoading,
        onSubmit,
        onClickEditing,
        onClickCancelEditing,
        onChangeName,
        onUpdateNecessatyItems,
        onUpdateTemplateCodes,
        onDelete,
    };
};
