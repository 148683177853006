import { categoryModel } from "@entities/Portal/Category";
import { entityRowApi } from "@entities/Portal/EntityRow";
import { useStagesLoad } from "@entities/Portal/Stage/model";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useMemo, useState } from "react";
import { getSpecialOptionsForStageSelect } from "../lib";

export const useEntityRowsActionsChangeStage = (
    entityId: number,
    selectedRows: any[],
    onSuccess?: () => void
) => {
    const { categories: allCategories, loading: allCategoriesLoading } =
        categoryModel.useCategoryLoad(entityId);
    const { stages, loading: allStagesLoading } = useStagesLoad(entityId, null);
    const [stage, setStage] = useState<string | number | null>(null);
    const [category, setCategory] = useState<string | number | null>(null);
    const [loading, setLoading] = useState(false);
    const disabled = selectedRows.length === 0;
    const onChangeStage = (stage: string | number | null) => {
        setStage(stage);
    };

    const options = useMemo(() => {
        return stages && stages.length > 0 && allCategories && allCategories.length > 0
            ? getSpecialOptionsForStageSelect(allCategories, stages)
            : [];
    }, [stages, allCategories]);

    const loadingSelect = allCategoriesLoading || allStagesLoading;

    const onChangeCategory = (category: string | number | null) => {
        setStage(null);
        setCategory(category);
    };

    const onChange = (value: string | number | null, { option }: any) => {
        const { category, stage } = option;
        setCategory(category.id);
        setStage(stage.id);
    };

    const onSubmit = () => {
        const newCategory = category;
        if (!category) {
            notificationEmit({
                type: "error",
                title: "Выберите категорию!",
            });
            return;
        }
        if (!stage) {
            notificationEmit({
                type: "error",
                title: "Выберите стадию!",
            });
            return;
        }
        setLoading(true);
        entityRowApi
            .saveEntityRows({
                entityId,
                items: selectedRows.map((row) => ({
                    ...row,
                    category: newCategory,
                    stage,
                })),
            })
            .then(() => {
                onSuccess && onSuccess();
                setStage(null);
                setCategory(null);
            })
            .catch(() => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось перевести в стадию!",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        stage,
        category,
        disabled,
        loading,
        onChangeStage,
        onChangeCategory,
        onSubmit,
        options,
        loadingSelect,
        onChange,
    };
};
