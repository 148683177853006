import { ReleaseItemsTable } from "@features/Production/ReleaseItemFeatures/ReleaseItemsTable";
import SelectReleaseItemsGroups from "./SelectReleaseItemsGroups";
import { useReleaseItems } from "../model";
import "./ReleaseItems.scss";
import { ReleaseItemsFilters } from "@features/Production/ReleaseItemFeatures/ReleaseItemsFilters";
interface IReleaseItems {}

const ReleaseItems = ({}: IReleaseItems) => {
    const { groups, filters, setGroups, setFilters } = useReleaseItems();
    return (
        <>
            <div className="release-items-header">
                <div style={{ marginRight: 10 }}>
                    <ReleaseItemsFilters filters={filters} setFilters={setFilters} />
                </div>
                <SelectReleaseItemsGroups groups={groups} setGroups={setGroups} />
            </div>
            <ReleaseItemsTable additionalFilters={filters} groups={groups} level={0} />
        </>
    );
};

export default ReleaseItems;
