import { Flex, Result } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { maxWidth, PDFoptions } from "../constants";
import { usePdfViewerContent } from "../model";
import PDFViewerSidebar from "./PDFViewerSidebar";
import { getPercents } from "../lib";

interface IPDFViewerContent {
    setLoaded: (item: number) => void;
    isLoading: boolean;
    link: string;
    scale: number;
    isOpen: boolean;
    rescale: (type?: "decrease" | "increase", value?: number) => void;
    numPages?: number;
    currentNumPage: number;
    onDocumentLoadSuccess: ({ numPages }: pdfjs.PDFDocumentProxy) => void;
    setCurrentNumPage: React.Dispatch<React.SetStateAction<number>>;
    containerRef: React.MutableRefObject<HTMLElement | null>;
    containerWidth: number | undefined;
    onLoadStart: () => void;
    onLoadError: (error: any) => void;
}

const PDFViewerContent = ({
    setLoaded,
    scale,
    link,
    isOpen,
    rescale,
    numPages,
    currentNumPage,
    onDocumentLoadSuccess,
    containerRef,
    containerWidth,
    setCurrentNumPage,
    onLoadStart,
    onLoadError,
}: IPDFViewerContent) => {
    const { onWheelContainer, onPageListItemClick } = usePdfViewerContent(
        scale,
        rescale,
        currentNumPage,
        setCurrentNumPage,
        containerRef,
        containerWidth
    );
    return (
        <Flex className="PDF__container" onWheel={onWheelContainer}>
            <PDFViewerSidebar
                link={link}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                isOpen={isOpen}
                numPages={numPages}
                currentNumPage={currentNumPage}
                onPageListItemClick={onPageListItemClick}
            />
            <Flex className="PDF__document-container" ref={containerRef}>
                <Flex className="PDF__document">
                    <Document
                        file={`${process.env.REACT_APP_API_URL}/api/file?link=${link}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onLoadError}
                        onLoadProgress={(object) =>
                            setLoaded(getPercents(object.loaded / object.total))
                        }
                        error={<Result status="error" title="Ошибка чтения PDF файла" />}
                        loading={<></>}
                        options={PDFoptions}
                    >
                        {Array.from(new Array(numPages), (_el, index) => (
                            <Page
                                scale={scale}
                                className="PDF__page"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={
                                    containerWidth
                                        ? Math.min(containerWidth, maxWidth)
                                        : maxWidth
                                }
                            />
                        ))}
                    </Document>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default PDFViewerContent;
