import { TechMapTemplateList } from "@features/Production/TechMapTemplateFeatures/TechMapTemplateList";
import { useHeaderLayout } from "@shared/ui/Header";
import TechMapTemplatesFilters from "@widgets/Production/TechMapTemplatesFilters/TechMapTemplatesFilters";

interface ITechMapsEditorPage {}

const TechMapsEditorPage = ({}: ITechMapsEditorPage) => {
    useHeaderLayout({
        title: "Редактор технологических карт",
    });

    return (
        <div style={{ height: "100%" }}>
            <TechMapTemplateList
                filtersWidget={(props) => <TechMapTemplatesFilters {...props} />}
            />
        </div>
    );
};

export default TechMapsEditorPage;
