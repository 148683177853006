import { FormReadValue } from "@shared/ui/Form";
import { Input } from "antd";

interface ITextAreaInput {
    value: string;
    onChangeValue: (value: string) => void;
    additionalParameters?: TLongTextAdditionalParameters;
    onlyRead?: boolean;
    disabled?: boolean;
    isReduceValue?: boolean;
}

const TextAreaInput = ({
    value,
    onlyRead,
    additionalParameters,
    onChangeValue,
    disabled,
    isReduceValue,
}: ITextAreaInput) => {
    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value;
        onChangeValue(value);
    };

    if (onlyRead)
        return <FormReadValue isReduceValue={isReduceValue} value={value ?? "-"} />;
    if (additionalParameters?.isInFilterInput)
        return (
            <Input
                disabled={onlyRead || disabled}
                onChange={onChange}
                value={value ?? ""}
            />
        );

    return (
        <Input.TextArea
            autoSize
            style={{ maxHeight: 400, minHeight: 60 }}
            disabled={onlyRead || disabled}
            onChange={onChange}
            value={value ?? ""}
        />
    );
};

export default TextAreaInput;
