import { entityFieldModel } from "@entities/Portal/EntityField";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEntityFieldEditCreate = () => {
    const dispatch = useDispatch();
    const editingEntityField = useSelector(entityFieldModel.selectEditingEntityField);
    const onClose = () => {
        dispatch(entityFieldModel.setEditingEntityField(null));
    };

    useEffect(() => {
        return () => {
            dispatch(entityFieldModel.setEditingEntityField(null));
        };
    }, [dispatch]);

    return {
        onClose,
        editingEntityField,
    };
};
