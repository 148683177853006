import { userModel } from "@entities/Portal/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGetStartedTmsLoading, selectStartedTm } from "./TechMapSelectors";
import { loadStartedTms } from "./Thunks";

export const useLoadStartedTms = () => {
    const loading = useSelector(selectGetStartedTmsLoading);
    const startedTm = useSelector(selectStartedTm);
    const dispatch = useDispatch<AppDispatch>();
    const userId = useSelector(userModel.selectUserId);
    useEffect(() => {
        dispatch(loadStartedTms(userId!));
    }, [dispatch, userId]);
    return {
        startedTm,
        loading,
    };
};
