import { Modal } from "antd";
import DeviceEditForm from "./DeviceEditForm";

interface IDeviceEditModal {
    device: TDevice | null;
    isOpen: boolean;
    onClose: () => void;
}

const DeviceEditModal = ({ device, isOpen, onClose }: IDeviceEditModal) => {
    return (
        <Modal open={isOpen} onCancel={onClose} destroyOnClose footer={[]}>
            {device && <DeviceEditForm initialDevice={device} onClose={onClose} />}
        </Modal>
    );
};

export default DeviceEditModal;
