import { userModel } from "@entities/Portal/User";
import { useSelector } from "react-redux";
import { getApiAuthedFrameUrl } from "@shared/api/apiFrame";
import { LOGS_IFRAME_ENDPOINT } from "@shared/constants";

export const useLogViewerFrame = () => {
    const token = useSelector(userModel.selectAccessToken) ?? "";

    return {
        url: getApiAuthedFrameUrl(LOGS_IFRAME_ENDPOINT, token),
    };
};
