import { $api } from "@shared/api/api";
const routeTemplate = "/api/production/tm-template";
const route = "/api/production/technical-map";
const routeOperation = "/api/production/tm-operation";
const routeTmo = "/api/production/tmo-item";
export const getExecutableTmts = () => {
    return $api.get<TResponseList<TTechMapTemplateExecuble>>(
        `${routeTemplate}/executable`
    );
};
export const getExecutableTmtsWithFilters = (
    filter: TFilterValue | TMultipleCondition | undefined,
    result: { entity_uuid: string; entity_row_id: number | null } | undefined,
    executor_roles: number[]| null,
    reviewer_roles: number[]| null,
    per_page: number
) => {
    return $api.post(`${routeTemplate}/executable`, {
        filter,
        result,
        executor_roles,
        reviewer_roles,
        per_page,
    });
};

export const getOperation = (tmoId: number) => {
    return $api.get<TResponse<TTechMapOperationFull>>(`${routeOperation}/${tmoId}`);
};

export const getTechMap = (tmId: number) => {
    return $api.get<TResponse<TTechMapStarted>>(`${route}/${tmId}`);
};
export const getTechMaps = (params?: TTechMapPaginatedReq) => {
    if (params?.group_by)
        return $api.get<TResponseListWithPage<TTechMapStarted>>(`${route}/grouped`, {
            params,
        });

    return $api.get<TResponseListWithPage<TTechMapStarted>>(`${route}`, { params });
};

export const getTechMapsFoReview = () => {
    return $api.get<TResponseList<TTechMapStarted>>(`${route}/reviewable`);
};
export const getStartedTms = (userId: number) => {
    return $api.get<TResponseListWithPage<TTechMapStarted>>(`${route}`, {
        params: {
            filter: {
                condition_type: "AND",
                conditions: [
                    {
                        key: "user_id",
                        condition: "=",
                        value: userId,
                    },
                    {
                        key: "status",
                        condition: "=",
                        value: `in_progress`,
                    },
                ],
            },
        },
    });
};
export const getTm = (tmId: number) => {
    return $api.get<TResponse<TTechMapStarted>>(`${route}/${tmId}`);
};
export const getTmtOperations = (tmtUuid: string) => {
    return $api.get<TResponseList<TTechMapStarted>>(
        `/api/production/tmt-operation/by-uuid/${tmtUuid}`
    );
};
export const startTmt = (tmtId: number) => {
    return $api.post<TResponse<TTechMapStarted>>(`${routeTemplate}/${tmtId}/start`);
};
export const attachItem = (oId: number, value: string) => {
    return $api.post<TResponse<TTechMapOperationItem>>(
        `${routeOperation}/${oId}/item/attach`,
        {
            value,
        }
    );
};
export const detachItem = (tmoiId: number) => {
    return $api.delete<TResponse<TTechMapOperationItem>>(`${routeTmo}/${tmoiId}`);
};

export const nextOperation = (tmoId: number) => {
    return $api.post<TResponseList<TTechMapOperationFull>>(
        `${routeOperation}/${tmoId}/next`
    );
};
export const finishTm = (tmId: number, count?: number) => {
    return $api.post<TResponse<TTechMapStarted>>(
        `${route}/${tmId}/finish`,
        count ? { count } : undefined
    );
};
export const approveTm = (
    tmId: number,
    decision: TDecision,
    results?: TTechMapPartlyRejectedResult[],
    releaseItemsIds?: number[]
) => {
    return $api.post<TResponse<TTechMapStarted>>(`${route}/${tmId}/review`, {
        decision,
        results,
        release_items: releaseItemsIds,
    });
};

export const stopTm = (tmId: number) => {
    return $api.post<TResponse<TTechMapStarted>>(`${route}/${tmId}/stop`);
};
export const pauseOperation = (oId: number) => {
    return $api.post<TResponse<TTechMapOperationPlayRes>>(
        `${routeOperation}/${oId}/pause`
    );
};
export const resumeOperation = (oId: number) => {
    return $api.post<TResponse<TTechMapOperationPlayRes>>(
        `${routeOperation}/${oId}/resume`
    );
};
