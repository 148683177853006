import { CardWithActions } from "@shared/ui/Cards/CardWithActions";
import TechMapRolesList from "./TechMapRolesList";
import "./TechMapTemplateListItem.scss";
import { UseIsMobile } from "@shared/hooks";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
interface ITechMapTemplateListItem {
    techMapTemplate: TTechMapTemplate;
    onSubmitDelete?: (techMapTemplate: TTechMapTemplate) => void;
}

const TechMapTemplateListItem = ({
    techMapTemplate,
    onSubmitDelete,
}: ITechMapTemplateListItem) => {
    const isMobile = UseIsMobile();
    const navigate = useNavigate();
    const onClick = () => {
        navigate(`/tech-map-editor/${techMapTemplate.id}`);
    };

    const onDelete = () => {
        onSubmitDelete!(techMapTemplate);
    };
    return (
        <div className="item-list-card clickable max-w-4" onClick={onClick}>
            <CardWithActions onSubmitDelete={onSubmitDelete ? onDelete : undefined}>
                <div>
                    <Typography.Text className="card-label">
                        {techMapTemplate.name}
                    </Typography.Text>
                    <div className="display-inline space-between indent-top">
                        <div className={`procent-w-${isMobile ? 4 : 2}`}>
                            <TechMapRolesList
                                roles={techMapTemplate.executor_roles}
                                label={"Исполнители"}
                            />
                        </div>
                        <div className={`procent-w-${isMobile ? 4 : 2}`}>
                            <TechMapRolesList
                                roles={techMapTemplate.reviewer_roles}
                                label={"Согласующие"}
                            />
                        </div>
                    </div>
                </div>
            </CardWithActions>
        </div>
    );
};

export default TechMapTemplateListItem;
