export const checkValueIsEqual = (
    original: any,
    updated: any,
    field: TBizProcFormField
) => {
    if (field.datatype === "entity") {
        return true;
    }
    /* eslint eqeqeq: "off" */
    return original == updated;
};
export const checkEntityRowEqual = (
    original: any,
    updated: any,
    fields: TBizProcFormField[]
) => {
    let isEqual = true;
    fields.forEach((field) => {
        if (field.is_multiple && field.datatype === "entity") {
            // const result = checkValueIsEqual(
            //     original[field.key],
            //     updated[field.key],
            //     field
            // );
            // if (!result) isEqual = false;
        } else {
            const result = checkValueIsEqual(
                original[field.key],
                updated[field.key],
                field
            );
            if (!result) isEqual = false;
        }
    });
    return isEqual;
};
