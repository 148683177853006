import { CloseOutlined } from "@ant-design/icons";
import { UserAccess } from "@entities/Portal/User";
import { Button } from "antd";
import { useToggleEditingMode } from "./model";

interface IToggleNavigationEditingMode {
    collapsed: boolean;
}

const ToggleNavigationEditingModeButton = ({
    collapsed,
}: IToggleNavigationEditingMode) => {
    const { editingNavigationMode, onToggle } = useToggleEditingMode();
    return (
        <UserAccess.AdminAccess>
            {collapsed ? undefined : (
                <Button
                    style={{
                        marginLeft: 10,
                    }}
                    type="link"
                    danger={editingNavigationMode}
                    onClick={onToggle}
                    icon={editingNavigationMode ? <CloseOutlined /> : undefined}
                >
                    {editingNavigationMode ? "Выйти из настройки" : "Настройка меню"}
                </Button>
            )}
        </UserAccess.AdminAccess>
    );
};

export default ToggleNavigationEditingModeButton;
