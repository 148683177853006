import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface ISelectListInput {}

interface ISelectListInput {
    value: string;
    onChangeValue: (value: string) => void;
    onlyRead?: boolean;
    additionalParameters: TSelectAdditionalParameters;
    field: TEntityField | TVariable;
}

const SelectListInput = ({
    value,
    onChangeValue,
    additionalParameters,
    onlyRead,
    field,
}: ISelectListInput) => {
    const isIncludeFilter = additionalParameters?.isIncludeFilter;
    return (
        <PaginatedSelect
            onlyRead={onlyRead}
            multiple={field.is_multiple || isIncludeFilter}
            requestUrl={`${apiUrl}/api/${field.variable_type === "variable" ? "variable" : "entity-field"}/${field.id}/field-option/select`}
            value={value}
            valueKey="value"
            labelKey="label"
            onChangeValue={onChangeValue}
            initialOptions={additionalParameters?.options ?? []}
        />
    );
};

export default SelectListInput;
