import { entityFieldConstants } from "@entities/Portal/EntityField";
import { entityRowConstants } from "@entities/Portal/EntityRow";
import { techMapConstant } from "@entities/Production/TechMap";
import { TechMapTmplOperationForm } from "@features/Production/TechMapTemplateFeatures/TechMapTmplOperationForm";
import { DrawerDefault, DrawerManager } from "@shared/ui/DrawerManager";
import { FileViewer } from "@shared/ui/FileViewer";
import { BizProcHistoryListWithLoad } from "@widgets/Portal/BizProcWidgets/BizProcHistory";
import { CreateEditEntityRowDrawer } from "@widgets/Portal/CreateEditEntityRowDrawer";
import { TMDrawer } from "@widgets/Production/TMDrawer";
import { patterns } from "../constants";
import { IFrameViewer } from "@widgets/Portal/IFrameViewerDrawer";
import { navigationConstants } from "@entities/Portal/NavigationRoute";

// interface IDrawerManagerWidget {}
// TODO REFACTOR
const DrawerManagerWidget = () => {
    return (
        <>
            <DrawerManager
                patterns={patterns}
                drawer={(
                    drawerItem: TDrawerManagerItemPrepared,
                    closeDrawer: (item: TDrawerManagerItemPrepared) => void,
                    level?: number
                ) => (
                    <>
                        {drawerItem.pattern ===
                        entityRowConstants.ERP_LOCATION_PATTERN ? (
                            <CreateEditEntityRowDrawer
                                level={level}
                                entityTableName={drawerItem.name}
                                isOpenDrawer={drawerItem.isOpen}
                                entityRowId={drawerItem.id}
                                hideHistory={false}
                                history={(props) => (
                                    <BizProcHistoryListWithLoad
                                        {...props}
                                        isActionsDisabled={true}
                                    />
                                )}
                                initialValues={drawerItem.additional?.initialValues}
                                openCloseDrawerWithNavigate={() =>
                                    closeDrawer(drawerItem)
                                }
                            />
                        ) : drawerItem.pattern ===
                          entityFieldConstants.LOCATION_FILE_VIEWER_PATTERN ? (
                            <DrawerDefault
                                maskClosable={true}
                                open={drawerItem.isOpen}
                                onClose={() => closeDrawer(drawerItem)}
                            >
                                <FileViewer
                                    linkToFile={drawerItem.name}
                                    closeDrawer={() => closeDrawer(drawerItem)}
                                />
                            </DrawerDefault>
                        ) : drawerItem.pattern === techMapConstant.LOCATION_TM_PATTERN ? (
                            <TMDrawer
                                level={level}
                                openCloseDrawer={() => closeDrawer(drawerItem)}
                                isOpenDrawer={drawerItem.isOpen}
                                tmId={drawerItem.id!}
                            />
                        ) : drawerItem.pattern ===
                          navigationConstants.LOCATION_IFRAME_PATTERN ? (
                            <IFrameViewer
                                isOpen={drawerItem.isOpen}
                                onClose={() => closeDrawer(drawerItem)}
                                routeKey={drawerItem.name}
                            />
                        ) : (
                            <DrawerDefault
                                maskClosable={false}
                                open={drawerItem.isOpen}
                                onClose={() => closeDrawer(drawerItem)}
                            >
                                <TechMapTmplOperationForm
                                    operationUuid={drawerItem.additional?.uuid}
                                    parentUuid={drawerItem.additional?.parentUuid ?? null}
                                    tmtUuid={drawerItem.additional?.tmtUuid ?? null}
                                    operationId={drawerItem.id}
                                    tmtId={drawerItem.additional?.tmtId}
                                    level={level}
                                    onCancel={() => closeDrawer(drawerItem)}
                                />
                            </DrawerDefault>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default DrawerManagerWidget;
