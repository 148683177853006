import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Flex } from "antd";
import { ICommonPropsEditQueryActionInterface } from "../model/types";
import { useEditQueryActionBody } from "../model";
import { maxCountOfRows, minCountOfRows } from "../constants";

interface IEditQueryActionBody extends ICommonPropsEditQueryActionInterface {
    value: string | Record<string | number, number | string | null | boolean> | undefined;
    disabled: boolean;
}

const EditQueryActionBody = ({
    itemId,
    value,
    onChange,
    disabled,
}: IEditQueryActionBody) => {
    const {
        stringifiedBody,
        countOfRows,
        onChangeBody,
        isDisabledFormatButton,
        isValidJsonBody,
        formatJson,
        minimizeJson,
    } = useEditQueryActionBody(value, onChange);

    return (
        <FormItem label="Тело запроса">
            <Flex vertical gap={8} style={{ position: "relative", paddingBottom: 8 }}>
                <InputWithVariables
                    disabled={disabled}
                    itemId={itemId}
                    isTextArea
                    rows={
                        countOfRows &&
                        Math.max(minCountOfRows, Math.min(countOfRows, maxCountOfRows))
                    }
                    name={`value`}
                    value={stringifiedBody}
                    onChange={onChangeBody}
                    placeholder="Тело запроса"
                />
                <Flex justify={!isValidJsonBody ? "space-between" : "flex-end"}>
                    <Flex style={{ marginRight: "auto" }}>
                        {!isValidJsonBody ? (
                            <ErrorText text="Тело запроса не является JSON и при сохранении будет удалено" />
                        ) : undefined}
                    </Flex>
                    <Flex gap={4}>
                        <Button
                            style={{ width: "fit-content", justifySelf: "flex-end" }}
                            disabled={isDisabledFormatButton}
                            onClick={minimizeJson}
                        >
                            Минимизировать JSON
                        </Button>
                        <Button
                            style={{ width: "fit-content", justifySelf: "flex-end" }}
                            disabled={isDisabledFormatButton}
                            onClick={formatJson}
                        >
                            Форматировать JSON
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </FormItem>
    );
};

export default EditQueryActionBody;
