export const creatingVariable: TVariable = {
    id: 0,
    name: "",
    key: "",
    datatype: "",
    is_system: false,
    type: "global",
    parent: null,
    is_calculated: false,
    is_precalculated: false,
    variable_type: "variable",
    value: undefined,
    is_creating: true,
};

export const variableParentsOptions = [
    { label: "Глобальный тип", value: "global" },
    { label: "Сущность", value: "entity" },
    {
        label: "Шаблон бизнес-процесса",
        value: "biz_proc_template",
    },
    // {
    //     label: "Бизнес-процесс",
    //     value: "biz_proc",
    // }, <---- Временно не используется
];

export const excludedDataTypes = [
    "childEntity",
    "parentEntity",
    "externalKey",
    "entity",
    "stage",
    "category",
];
