import { messageEmit } from "@shared/ui/NotificationAndMessage/model/emits";

export const useCreateCapabilitiesRolesInput = (
    newCapabilityEntityRowByRole: TCapabilitiesRolesRow,
    type: TCapType,
    isMultiSelect: boolean | undefined,
    onChangeCapability: (
        value: any,
        type: TCapType,
        isMultiSelect: boolean | undefined
    ) => void
) => {
    const copyValue = async (value: string) => {
        const copiedDataPrepared = JSON.stringify({
            value,
            entity_uuid: newCapabilityEntityRowByRole.entity_uuid,
        });
        try {
            await navigator.clipboard.writeText(copiedDataPrepared);
        } catch (error) {
            console.error("Не удалось скопировать значение", error);
            messageEmit({ type: "error", content: "Не удалось скопировать значение" });
        }
    };

    const pasteValue = async () => {
        try {
            const copiedValue = await navigator.clipboard.readText();
            const parsedCopiedValue = JSON.parse(copiedValue);
            if (
                newCapabilityEntityRowByRole.entity_uuid === parsedCopiedValue.entity_uuid
            ) {
                const comparableValue = parsedCopiedValue.value;
                const correctCopiedValue =
                    comparableValue !== "null" && comparableValue
                        ? comparableValue.split(",")
                        : "";
                if (correctCopiedValue || correctCopiedValue === "")
                    onChangeCapability(correctCopiedValue, type, isMultiSelect);
            } else {
                messageEmit({
                    type: "info",
                    content: "Не удалось вставить скопированное значение",
                });
            }
        } catch (error) {
            console.error("Не удалось вставить данные", error);
        }
    };

    return { copyValue, pasteValue };
};
