import { bizProcModel } from "@entities/Portal/BizProc";
import { navigationRouteModel } from "@entities/Portal/NavigationRoute";
import { portalSettingModel } from "@entities/Portal/PortalSetting";
import { userModel } from "@entities/Portal/User";
import { MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "../sideBarItems";
import { SidebarItem } from "../ui/SidebarItem";
import { useEffect } from "react";
import { setDocumentTitle } from "@shared/lib/document";
import { openDrawer } from "@shared/ui/DrawerManager";

export const useSidebar = (collapsed: boolean) => {
    const { navigationRoutes } = navigationRouteModel.useLoadNavigationRoutes();
    const logo = useSelector(portalSettingModel.selectPortalSettingsPrepared)?.logoPortal;

    const navigate = useNavigate();
    const userIsAdmin = useSelector(userModel.selectUserIsAdmin);
    const editingMode = useSelector(navigationRouteModel.selectIsInEditingMode);
    const agreementsCount = useSelector(bizProcModel.selectAgreementsCounter);
    const formsCount = useSelector(bizProcModel.selectFormsCounter);
    const badges: any = {
        "/dashboard": formsCount + agreementsCount,
        agreement: agreementsCount,
        "my-forms": formsCount,
        dashboard: formsCount + agreementsCount,
    };
    useEffect(() => {
        const location = window.location.href;
        navigationRoutes.forEach((navigationRoute) => {
            if (location.includes(navigationRoute.route)) {
                setDocumentTitle(navigationRoute.name);
            }
        });
    }, [navigationRoutes]);
    const getMenuItem = (
        navigationRoute: TNavigationRoute
    ): ItemType & {
        children: any;
        navigationroute: any;
    } => {
        return {
            className: "sidebar__menu-item sidebar__text",
            label: SidebarItem({
                collapsed,
                navigationRoute,
                badge: badges?.[navigationRoute.route],
            }),
            key: navigationRoute.key,
            navigationroute: navigationRoute,
            onClick: () => {
                if (
                    navigationRoute.subroutes?.length === 0 ||
                    !navigationRoute.subroutes
                ) {
                    if (
                        navigationRoute.type === "route" &&
                        (navigationRoute.open_type === "currentTab" ||
                            navigationRoute.open_type === undefined)
                    ) {
                        navigate(navigationRoute.route);
                        setDocumentTitle(navigationRoute.name);
                        return;
                    } else if (navigationRoute.open_type === "drawer") {
                        openDrawer({
                            id: navigationRoute.id,
                            name: navigationRoute.key,
                            pattern: "/iframe-viewer",
                            additional: navigationRoute.route,
                        });
                        return;
                    } else {
                        window.open(
                            navigationRoute.route,
                            navigationRoute.open_type === "newTab" ? "_blank" : undefined
                        );
                    }
                }
            },

            children:
                navigationRoute.subroutes?.length === 0
                    ? undefined
                    : navigationRoute.subroutes?.map((innerRoute: TNavigationRoute) => {
                          return getMenuItem(innerRoute);
                      }),
        };
    };

    const menuItems: MenuProps["items"] = [
        ...navigationRoutes,
        ...(userIsAdmin ? adminRoutes : []),
    ].map((navigationRoute) => {
        return getMenuItem(navigationRoute);
    });
    return { badges, logo, menuItems, editingMode };
};
