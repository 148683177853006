export const hidedActionsToSelect: TBizProcActionType[] = [
    "IfElseActionBranch",
    "RejectBranchAction",
    "ApproveBranchAction",
    "InitialAction",
    "ParallelExecutionActionBranch",
    "QueryActionSuccessBranch",
    "QueryActionErrorBranch",
];
export const withFixedAction: TBizProcActionType[] = [
    "IfElseAction",
    "ParallelExecutionAction",
];
