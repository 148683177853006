import { Button, Popconfirm } from "antd";

export const getDeleteColumn = (
    onDelete: (id: number) => void,
    deletingIds: number[]
) => {
    return {
        title: "",
        dataIndex: "id",
        render: (value: number, record: any) => {
            if (record.is_sent) return <></>;

            return (
                <div onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                        title="Удалить выпуск"
                        okText="Удалить"
                        okButtonProps={{ danger: true }}
                        onConfirm={() => onDelete(value)}
                    >
                        <Button loading={deletingIds.includes(value)} danger type="link">
                            Удалить
                        </Button>
                    </Popconfirm>
                </div>
            );
        },
    };
};
