import { useState } from "react";
import { getNecessaryItemMock, isCreatedNeccetyItem, isSameNecceryItem } from "../lib";

export const useTMTONecessaryItems = (
    tmtoUuid: string,
    items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[],
    onChange: (
        items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[]
    ) => void
) => {
    const [isOpen, setIsOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<
        TOperationNecessaryItem | TOperationNecessaryItemCreating | null
    >(null);

    const onClose = () => {
        setIsOpen(false);
    };
    const onOpen = (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => {
        setIsOpen(true);
        setEditingItem(item);
    };

    const onUpdateItem = (
        updatedItem: TOperationNecessaryItem | TOperationNecessaryItemCreating
    ) => {
        if (!isCreatedNeccetyItem(items, updatedItem)) {
            onChange([...items, updatedItem]);
        } else {
            onChange(
                items.map((item) => {
                    return isSameNecceryItem(item, updatedItem) ? updatedItem : item;
                })
            );
        }
        onClose();
    };

    const onDelete = (
        deletingItem: TOperationNecessaryItem | TOperationNecessaryItemCreating
    ) => {
        onChange(
            items.filter((item) => {
                return !isSameNecceryItem(item, deletingItem);
            })
        );
    };
    const onCreate = () => {
        onOpen(getNecessaryItemMock(tmtoUuid));
    };

    return {
        isOpen,
        editingItem,
        onClose,
        onUpdateItem,
        onDelete,
        onCreate,
        onOpen,
    };
};
