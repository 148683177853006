import { checkIsValidParsingKeyWithVariables } from "@shared/lib/object-helper";
import { useRef } from "react";
import { TParseRule, TTargetOfParseRule } from "../model/types";

export const useEditQueryActionResultParseItem = (
    parseRule: TParseRule,
    parseRules: TParseRule[],
    onChangeParseRulesCommonProps: (
        name: keyof TParseRule,
        value: string | TTargetOfParseRule[] | boolean | null,
        index: number
    ) => void,
    onDelete: (index: number) => void,
    index: number
) => {
    const firstRender = useRef(true);

    const { targets } = parseRule;
    const isValidKey =
        checkIsValidParsingKeyWithVariables(parseRule.key) &&
        !parseRules.find(({ key }) => parseRule.key === key);
    const isValidParseRule = isValidKey && parseRule.targets.length > 0;
    const entityFieldTargets = targets.filter(
        ({ variable_type }) => variable_type === "entity"
    );
    const variableTargets = targets.filter(
        ({ variable_type }) => variable_type === "variable"
    );
    const entityFieldKeys = entityFieldTargets.map(
        (item) => (item as TTargetOfParseRule & { variable_type: "entity" }).key
    );
    const variableIds = variableTargets.map(
        (item) => (item as TTargetOfParseRule & { variable_type: "variable" }).id
    );
    const onChangeTargets = (newTargets: TTargetOfParseRule[]) => {
        onChangeParseRulesCommonProps("targets", newTargets, index);
    };

    const onChangeKey = (name: string, value: string) => {
        firstRender.current = false;
        onChangeParseRulesCommonProps(name as keyof TParseRule, value, index);
    };
    const onDelete_ = () => onDelete(index);
    return {
        firstRender,
        onDelete_,
        isValidKey,
        isValidParseRule,
        entityFieldKeys,
        variableIds,
        onChangeTargets,
        onChangeKey,
    };
};
