import { TMFilters } from "../../TMFilters";
import { TMGroupSelect } from "../../TMGroupSelect";
import { useTMTableWs } from "../model/UseTMTableWs";
import TMTable from "./TMTable";

interface ITMTableGrouped {}

const TMTableGrouped = ({}: ITMTableGrouped) => {
    useTMTableWs();
    return (
        <>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: 10,
                    alignItems: "flex-start",
                }}
            >
                <TMFilters />
                <TMGroupSelect />
            </div>
            <TMTable level={0} />
        </>
    );
};

export default TMTableGrouped;
