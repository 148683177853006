import React from "react";
import QrBarCodesPrintButton from "./QrBarCodesPrintButton";
import { Flex } from "antd";

interface IQrBarCodesWrapper {
    children: React.ReactNode;
    value: string | undefined;
    type: TPrinterCodeItemType;
}

const QrBarCodesWrapper = ({ children, value, type }: IQrBarCodesWrapper) => {
    return (
        <Flex gap={4} align="start" style={{ position: "relative" }}>
            {children}
            <div style={{ paddingTop: 4 }}>
                {value && <QrBarCodesPrintButton type={type} value={value} />}
            </div>
        </Flex>
    );
};

export default QrBarCodesWrapper;
