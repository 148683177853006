import { ReactNode } from "react";
import "./StageCardItem.scss";
import { contrastColor } from "@shared/lib/colors";
import { Flex } from "antd";
interface IStageCardItem {
    categoryId: number;
    entityId: number;
    dragHandleProps?: any;
    stage: TStage;
    actions?: (stage: TStage, entityId: number, categoryId: number) => ReactNode;
}

const StageCardItem = ({
    categoryId,
    entityId,
    dragHandleProps,
    stage,
    actions,
}: IStageCardItem) => {
    return (
        <Flex style={{ width: "100%" }}>
            <Flex
                {...(dragHandleProps ?? {})}
                className="stage-card-item"
                style={{
                    flex: "1 1 100%",
                    backgroundColor: stage.background_color,
                }}
            >
                <div style={{ color: contrastColor(stage.background_color) }}>
                    {stage.label}
                </div>
            </Flex>
            {actions && (
                <Flex
                    style={{
                        color: `${contrastColor(stage.background_color)} !important`,
                    }}
                >
                    {actions(stage, entityId, categoryId)}
                </Flex>
            )}
        </Flex>
    );
};

export default StageCardItem;
