import { useLogViewerFrame } from "../model";
import "./LogViewerFrameWrapper.scss";

export const LogViewerFrame = () => {
    const { url } = useLogViewerFrame();

    return (
        <div className={"log-viewer-frame-wrapper"}>
            <iframe
                title={"Log Viewer"}
                style={{
                    outline: "none",
                    border: "none",
                    borderRadius: 8,
                    boxShadow: "0px 0px 4px 0px rgba(34, 60, 80, 0.2)",
                }}
                width="100%"
                height="100%"
                src={url}
            ></iframe>
        </div>
    );
};

export default LogViewerFrame;
