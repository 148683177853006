import { EstimationTemplateEditModal } from "@features/Portal/EstimationFeatures/EstimationTemplateEdit";
import { EstimationTemplatesList } from "@features/Portal/EstimationFeatures/EstimationTemplatesList";

interface IEstimationTemplatesWithEdit {
    entityId: number;
    entityUuid: string;
    onClickItem?: () => void;
}

const EstimationTemplatesWithEdit = ({
    entityId,
    entityUuid,
    onClickItem,
}: IEstimationTemplatesWithEdit) => {
    return (
        <>
            <EstimationTemplateEditModal />
            <EstimationTemplatesList
                entityId={entityId}
                entityUuid={entityUuid}
                onClickItem={onClickItem}
            />
        </>
    );
};

export default EstimationTemplatesWithEdit;
