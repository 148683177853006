import { Flex, Typography } from "antd";
import React from "react";
import "./DraggableUserRoleListCard.scss";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { isNumber } from "lodash";

type TItemAddonNodes = {
    type: "edit" | "delete";
    node: React.ReactNode;
    handler: (...args: any) => void;
}[];

interface IDraggableUserRoleListCard {
    dragHandleProps: any;
    item: TUserRole;
    onClickUserRole: (item: TUserRole) => void;
    itemAddonNodes: (item: TUserRole) => TItemAddonNodes;
    comparableValue: number | null | undefined;
    isDragging: boolean | undefined;
    currentIndex: number | null | undefined;
    sourceIndex: number | null | undefined;
}

const DraggableUserRoleListCard = ({
    dragHandleProps,
    item,
    onClickUserRole,
    itemAddonNodes,
    comparableValue,
    isDragging,
    currentIndex,
    sourceIndex,
}: IDraggableUserRoleListCard) => {
    return (
        <Flex
            className="user-role-card"
            align="center"
            justify="space-between"
            onClick={() => onClickUserRole(item)}
            {...dragHandleProps}
        >
            <Flex className="user-role-card__container" wrap="wrap">
                <Typography.Text className="user-role-card__text">
                    {item.name}
                    <Typography.Text className="user-role-card__priority-value">
                        {item.priority}
                    </Typography.Text>
                    <IndentContainer customStyle={{ display: "inline" }}>
                        {isNumber(comparableValue) &&
                        isDragging &&
                        isNumber(sourceIndex) &&
                        isNumber(currentIndex) ? (
                            <React.Fragment>
                                {sourceIndex === currentIndex ? (
                                    <></>
                                ) : sourceIndex > currentIndex ? (
                                    <React.Fragment>
                                        <ArrowUpOutlined
                                            style={{ color: "lightgreen" }}
                                        />
                                        <Typography.Text style={{ color: "lightgreen" }}>
                                            {comparableValue + 1}
                                        </Typography.Text>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <ArrowDownOutlined style={{ color: "red" }} />
                                        <Typography.Text style={{ color: "red" }}>
                                            {comparableValue - 1}
                                        </Typography.Text>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <></>
                        )}
                    </IndentContainer>
                </Typography.Text>
            </Flex>
            <Flex>
                {itemAddonNodes(item)?.map((action, index) => (
                    <div
                        key={index}
                        onClick={(event) => {
                            event.stopPropagation();
                            action.handler(item);
                        }}
                    >
                        {action.node}
                    </div>
                ))}
            </Flex>
        </Flex>
    );
};

export default DraggableUserRoleListCard;
