import { variableModel } from "@entities/Portal/Variables";
import { useDispatch } from "react-redux";
import { creatingVariable } from "../constants";

export const useCreateButton = () => {
    const dispatch = useDispatch<AppDispatch>();

    const onClick = () => {
        dispatch(variableModel.setEditingVariable(creatingVariable));
    };
    return {
        onClick,
    };
};
