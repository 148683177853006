import { FormItem } from "@shared/ui/Form";
import { Button, Divider, Input } from "antd";
import { useDeviceEditForm } from "../model";
import { BizProcTemplateSelect } from "@entities/Portal/BizProcTemplate";
import { SelectEntityRow } from "@entities/Portal/EntityRow";

interface IDeviceEditForm {
    initialDevice: TDevice;
    onClose: () => void;
}

const DeviceEditForm = ({ initialDevice, onClose }: IDeviceEditForm) => {
    const {
        device,
        saveLoading,
        userId,
        writeLoading,
        onChangeBizProcTemplate,
        onSaveDevice,
        writeToCard,
        onChangeName,
        onChangeUserId,
    } = useDeviceEditForm(initialDevice);

    return (
        <div>
            <FormItem label="Имя устройства">
                <Input value={device.name} onChange={onChangeName} />
            </FormItem>
            <FormItem label="Бизнес-процесс">
                <BizProcTemplateSelect
                    value={device.biz_proc_template_id}
                    onChange={onChangeBizProcTemplate}
                />
            </FormItem>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    onClick={onSaveDevice}
                    style={{ marginLeft: 10 }}
                    loading={saveLoading}
                >
                    Сохранить
                </Button>
            </div>
            <Divider />
            <FormItem
                labelStyle={{ textWrap: "nowrap" }}
                label="Записать карту"
                style={{ marginRight: 16, flex: "1 1 120px" }}
            >
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <SelectEntityRow
                        entityInfo="users"
                        value={userId}
                        onChange={onChangeUserId}
                    />
                    <Button
                        onClick={writeToCard}
                        style={{ marginLeft: 10 }}
                        loading={writeLoading}
                    >
                        Записать
                    </Button>
                </div>
            </FormItem>
        </div>
    );
};

export default DeviceEditForm;
