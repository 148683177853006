import ActionCard from "./ActionCard";
interface IParallelExecutionActionBranch {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}
const ParallelExecutionActionBranch = ({
    onDeleteItem,
    templateItem,
}: IParallelExecutionActionBranch) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="RoyalBlue"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Ветвь параллельного выполнения"}
        />
    );
};

export default ParallelExecutionActionBranch;
