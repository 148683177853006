import { TInputField } from "../FilterKitTypes";
import {
    FilterInputItem,
    FilterNumberInputItem,
    FilterRequestedSelectItem,
    FilterSelectItem,
    FilterTextAreaItem,
} from "./FilterInputs";
import FilterRadioButtonItem from "./FilterInputs/FIlterRadioButtonItem";

interface IFilterInputItems {
    field: TInputField;
    onChange: (name: string, value: any) => void;
    hideLabel?: boolean;
}

const FilterInputItems = ({ field, onChange, hideLabel }: IFilterInputItems) => {
    const { type } = field;

    if (type === "input") {
        return (
            <FilterInputItem field={field} onChange={onChange} hideLabel={hideLabel} />
        );
    }
    if (type === "numberInput") {
        return (
            <FilterNumberInputItem
                field={field}
                onChange={onChange}
                hideLabel={hideLabel}
            />
        );
    }
    if (type === "textArea") {
        return (
            <FilterTextAreaItem field={field} onChange={onChange} hideLabel={hideLabel} />
        );
    }
    if (type === "select") {
        return (
            <FilterSelectItem field={field} onChange={onChange} hideLabel={hideLabel} />
        );
    }
    if (type === "radioButton") {
        return (
            <FilterRadioButtonItem
                field={field}
                onChange={onChange}
                hideLabel={hideLabel}
            />
        );
    }
    if (type === "requestedSelect") {
        return (
            <FilterRequestedSelectItem
                field={field}
                onChange={onChange}
                hideLabel={hideLabel}
            />
        );
    } else return <></>;
};

export default FilterInputItems;
