import { useDispatch, useSelector } from "react-redux";
import { selectActions } from "./ActionSelectors";
import { useEffect } from "react";
import { loadActionsThunk } from "./ActionThunk";

export const useLoadActions = (preload?: boolean) => {
    const actions = useSelector(selectActions);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (preload) {
            loadActions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, preload]);

    const loadActions = () => {
        dispatch(loadActionsThunk());
    };
    return {
        actions,
        loadActions,
    };
};
