import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createTechMapTemplate,
    deleteTechMapTemplate,
    getTechMapTemplate,
    // getTechMapTemplates,
    getTechMapTemplatesWithFilters,
    updateTechMapTemplate,
} from "../../api/api";

export const loadTechMapTemplatesThunk = createAsyncThunk(
    "TechMapTemplateSlice/loadTechMapTemplatesThunk",
    // async (props: { params?: TParams }, { rejectWithValue }) => {
    async (
        props: {
            result: { entity_uuid: string; entity_row_id: number | null } | null;
            filter: TFilterValue | TMultipleCondition | null;
            executor_roles: number[] | null;
            reviewer_roles: number[] | null;
            perPage: number;
        },
        { rejectWithValue }
    ) => {
        try {
            // const response = await getTechMapTemplates(props.params);
            const response = await getTechMapTemplatesWithFilters(
                props.filter ?? undefined,
                props.result ?? undefined,
                props.executor_roles,
                props.reviewer_roles,
                props.perPage
            );
            // TODO: добавить пагинацию
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/loadTechMapTemplateThunk",
    async ({ techMapTemplateId }: { techMapTemplateId: number }, { rejectWithValue }) => {
        try {
            const response = await getTechMapTemplate(techMapTemplateId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/updateTechMapTemplateThunk",
    async (props: { techMapTemplate: TTechMapTemplateReq }, { rejectWithValue }) => {
        try {
            const response = await updateTechMapTemplate(props.techMapTemplate);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const createTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/createTechMapTemplateThunk",
    async (props: { techMapTemplate: TTechMapTemplateReq }, { rejectWithValue }) => {
        try {
            const response = await createTechMapTemplate(props.techMapTemplate);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/deleteTechMapTemplateThunk",
    async (props: { id: number }, { rejectWithValue }) => {
        try {
            const response = await deleteTechMapTemplate(props.id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
