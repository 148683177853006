import { useEffect, useState } from "react";
import { getEntityFields, getEntityFieldsByEntityUuid } from "../api";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

interface IUseEntityFieldSelect {
    entityUuid?: string;
    entityId?: number;
    fieldKey: string | null;
    fieldDatatypeTypes?: TFieldDatatype[];
    onChange: (fieldKey: string, entityField: TEntityField) => void;
}
export const useEntityFieldSelect = ({
    entityUuid,
    entityId,
    fieldKey,
    fieldDatatypeTypes,
    onChange,
}: IUseEntityFieldSelect) => {
    const [fieldsOptions, setFieldsOptions] = useState<TOptionWithItem<TEntityField>[]>(
        []
    );

    useEffect(() => {
        (entityId ? getEntityFields(entityId) : getEntityFieldsByEntityUuid(entityUuid))
            .then((response) => {
                const field = response.data.data.find((item) => item.key === fieldKey);
                if (fieldKey && field) {
                    onChange(fieldKey, field);
                }
                setFieldsOptions(
                    response.data.data
                        .filter(
                            (field) =>
                                !fieldDatatypeTypes ||
                                fieldDatatypeTypes.includes(field.datatype)
                        )
                        .map((field) => ({
                            label: field.name,
                            value: field.key,
                            item: field,
                        }))
                );
            })
            .catch(() => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось загрузить поля",
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityUuid, entityId]);
    const _onChange = (value: string, option: TOptionWithItem<TEntityField>) => {
        onChange(value, option.item);
    };
    return {
        fieldsOptions,
        _onChange,
    };
};
