export const timeSplitter = (seconds: number) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor(((seconds % (3600 * 24)) % 3600) / 60);
    return [minutes, hours, days];
};

const getIfSetted = (value: number, postfix: string) => {
    return value > 0 ? `${value} ${postfix}` : "";
};
export const secondsToDhms = (seconds: number) => {
    let valueOfSeconds = seconds;
    const days = Math.floor(valueOfSeconds / (24 * 60 * 60));
    valueOfSeconds -= days * (24 * 60 * 60);
    const hours = Math.floor(valueOfSeconds / (60 * 60));
    valueOfSeconds -= hours * (60 * 60);
    const minutes = Math.floor(valueOfSeconds / 60);
    valueOfSeconds -= minutes * 60;
    return `${getIfSetted(days, "д. ")}${getIfSetted(hours, "ч. ")}${getIfSetted(minutes, "м. ")}${getIfSetted(valueOfSeconds, "с.")} `;
};

export const getSeconds = ({
    minutes,
    hours,
    days,
}: {
    minutes: number;
    hours: number;
    days: number;
}) => {
    return minutes * 60 + hours * 3600 + days * 3600 * 24;
};
