export const routeTypeOptions = [
    {
        label: "Портальная ссылка",
        value: "route",
    },
    {
        label: "Внешняя ссылка",
        value: "link",
    },
];
export const routeBlankOptions = [
    {
        label: "На новой странице",
        value: "newTab",
    },
    {
        label: "На той же странице",
        value: "currentTab",
    },
    {
        label: "В всплывающем окне",
        value: "drawer",
    },
];
