import { secondsToDhms } from "@shared/lib/time";
import "./TMFullInfo.scss";
import dayjs from "dayjs";
import { openDrawer } from "@shared/ui/DrawerManager";
import { techMapTemplateConstant } from "@entities/Production/TechMapTemplate";
interface ITMFullInfoTime {
    techMap: TTechMapStarted;
}

const TMFullInfoTime = ({ techMap }: ITMFullInfoTime) => {
    const timeIntervals = techMap.time_intervals;
    const dataFormat = "HH:mm D MMM YYYY";
    return (
        <div className="tm-time-intervals">
            <span className="tm-time-intervals__label">Временные интервалы</span>
            <div className="tm-time-intervals__items">
                {timeIntervals.map((interval) => {
                    const operation = techMap.operations.find(
                        (o) => o.id === interval.technical_map_operation_id
                    );
                    const oUuid = operation?.technical_map_template_operation_uuid;
                    const tmto = techMap.template.operations.find(
                        (to) => to.uuid === oUuid
                    );
                    const name = tmto?.name ?? "-";

                    const onClickOperation = () => {
                        if (!tmto) return;
                        openDrawer({
                            pattern:
                                techMapTemplateConstant.LOCATION_TMT_OPERATION_PATTERN +
                                "/" +
                                techMap.template.id,

                            id: tmto.id,
                            name: "operation",
                            additional: {
                                tmtId: techMap.template.id,
                            },
                        });
                    };

                    return (
                        <div className="tm-time-intervals__items__item" key={interval.id}>
                            <span
                                onClick={onClickOperation}
                                className="tm-time-intervals__items__item__name link"
                            >
                                {name}
                            </span>
                            <span>
                                Время начала:{" "}
                                <span style={{ fontWeight: 500 }}>
                                    {dayjs(interval.start_time).format(dataFormat)}
                                </span>
                            </span>
                            <span>
                                Время завершения:{" "}
                                <span style={{ fontWeight: 500 }}>
                                    {interval.end_time
                                        ? dayjs(interval.end_time).format(dataFormat)
                                        : "-"}
                                </span>
                            </span>
                            {interval.end_time && (
                                <span>
                                    Итого:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                        {secondsToDhms(
                                            dayjs(interval.end_time).diff(
                                                dayjs(interval.start_time)
                                            ) / 1000
                                        )}
                                    </span>
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TMFullInfoTime;
