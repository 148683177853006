import { TMOperationsList } from "@entities/Production/TechMap";
import { useTMOperations } from "../model";
import "./TMOperations.scss";
import { Skeleton } from "antd";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import TechMapSpentTime from "./TechMapSpentTime";
import { ReactNode } from "react";

interface ITMOperations {
    operationPopoverNode?: (
        templateOperationsWithMapOperation: TMapOperationWithTemplateO
    ) => ReactNode;
}

const TMOperations = ({ operationPopoverNode }: ITMOperations) => {
    const { templateOperationsWithMapOperation, loading, startedTm } = useTMOperations();
    return (
        <div className="tm-operations">
            <div className="tm-operations__header">
                <div>
                    <FlexContainer type="row-start">
                        <div>Выполняется:</div>
                        <div className="tm-operations__header__name">
                            {loading ? (
                                <Skeleton
                                    active
                                    rootClassName="tm-skeleton"
                                    title={false}
                                    paragraph={{ rows: 1, width: [200] }}
                                />
                            ) : (
                                <span>{startedTm?.template.name}</span>
                            )}
                        </div>
                    </FlexContainer>
                </div>
                <div>
                    <FlexContainer type="row-start">
                        <div>Время:</div>
                        <div className="tm-operations__header__name">
                            {loading ? (
                                <Skeleton
                                    active
                                    rootClassName="tm-skeleton"
                                    title={false}
                                    paragraph={{ rows: 1, width: [70] }}
                                />
                            ) : startedTm ? (
                                <span>
                                    {
                                        <TechMapSpentTime
                                            isPaused={
                                                startedTm.status !== "in_progress" ||
                                                startedTm.is_paused
                                            }
                                            spentTime={startedTm.spent_time}
                                        />
                                    }
                                </span>
                            ) : (
                                "-"
                            )}
                        </div>
                    </FlexContainer>
                </div>
            </div>
            <TMOperationsList
                operationPopoverNode={operationPopoverNode}
                templateOperationsWithMapOperation={templateOperationsWithMapOperation}
                loading={loading}
            />
        </div>
    );
};

export default TMOperations;
