import { userModel } from "@entities/Portal/User";
import { viewPatternModel } from "@entities/Portal/ViewPattern";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tabModeIds } from "../constants";
export const useViewPatternSelect = (entityId: number, tabMode: string) => {
    const { loading, viewPatterns } = viewPatternModel.useViewPatternsLoad(entityId);
    const dispatch = useDispatch<AppDispatch>();
    const userId = useSelector(userModel.selectUserId);
    const userIsAdmin = useSelector(userModel.selectUserIsAdmin);
    const currentViewPatternId = useSelector((state) =>
        viewPatternModel.selectCurrentPatternId(state, tabMode)
    );
    // TODO
    useEffect(() => {
        if (!loading) {
            const viewPatternId = localStorage.getItem(
                `${entityId}_view_pattern_${tabMode}`
            );
            if (viewPatternId) {
                dispatch(
                    viewPatternModel.setCurrentViewPatternId({
                        tabMode,
                        id: Number(viewPatternId),
                    })
                );
            } else {
                const defaultViewPattern = viewPatterns.find(
                    (pattern) =>
                        pattern.type === "default" &&
                        pattern.view_type ===
                            tabModeIds[tabMode as keyof typeof tabModeIds]
                );
                if (defaultViewPattern) {
                    onClickPattern({ key: defaultViewPattern.id.toString() });
                }
            }
        }
    }, [loading, tabMode]);

    const onClickPattern = ({ key }: { key: string }) => {
        if (key) {
            localStorage.setItem(`${entityId}_view_pattern_${tabMode}`, key);
            dispatch(
                viewPatternModel.setCurrentViewPatternId({
                    tabMode,
                    id: Number(key),
                })
            );
        }
    };

    return {
        currentViewPatternId,
        loading,
        viewPatterns,
        userId,
        userIsAdmin,
        onClickPattern,
    };
};
