import { CloseOutlined } from "@ant-design/icons";
import {
    capabilitiesRolesConstants,
    capabilitiesRolesModel,
} from "@entities/Portal/CapabilitiesRoles";
import { EntitySelect } from "@entities/Portal/Entity";
import { useLoadEntities } from "@entities/Portal/Entity/model";
import TMTResultItemEntityRow from "@features/Production/TechMapTemplateFeatures/TechMapTmplResult/ui/TMTResultItemEntityRow";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Input, Select } from "antd";
import { isActiveSelectOptions } from "./constants";
import { TFiltersTechMap } from "./types";

interface ITechMapTemplatesFiltersInputs {
    filters: TFiltersTechMap;
    onChange: (name: keyof TFiltersTechMap, value: any) => void;
    resetFilters: () => void;
    isVertical?: boolean;
}

const TechMapTemplatesFiltersInputs = ({
    isVertical,
    filters,
    onChange,
    resetFilters,
}: ITechMapTemplatesFiltersInputs) => {
    const { entities } = useLoadEntities(true);
    const entityUserRoles = entities.find(
        (entity) => entity.uuid === capabilitiesRolesConstants.entityRolesUuid
    );
    const { userRoles, loading } = capabilitiesRolesModel.useLoadUserRoles(
        entityUserRoles?.id
    );

    const userRolesOptions = (userRoles ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    return (
        <Flex vertical={isVertical} style={{ width: "100%" }} align="start" gap={8}>
            <Flex gap={8} style={{ flex: "1 1 100%" }} align="center" wrap="wrap">
                <FormItem
                    label={<>Название</>}
                    style={{ flex: isVertical ? "1 1 100%" : "0 1 240px" }}
                >
                    <Input
                        placeholder="Название"
                        onChange={({ target: { value } }) => onChange("name", value)}
                        value={filters.name ?? ""}
                    />
                </FormItem>
                <FormItem
                    label={<>Фильтр по активности</>}
                    style={{ flex: isVertical ? "1 1 100%" : "0 1 240px" }}
                >
                    <Select
                        allowClear
                        placeholder="Активность"
                        style={{ width: "100%" }}
                        options={isActiveSelectOptions}
                        onChange={(value) => onChange("is_active", value)}
                        value={filters.is_active}
                    />
                </FormItem>

                <FormItem
                    label={<>Компонент</>}
                    style={{ flex: isVertical ? "1 1 100%" : "0 1 240px" }}
                >
                    <EntitySelect
                        value={filters.entity_uuid}
                        placeholder="Компонент"
                        onChangeSelect={(value) => onChange("entity_uuid", value)}
                    />
                </FormItem>
                <FormItem
                    label={<>Исполнитель</>}
                    style={{ flex: isVertical ? "1 1 100%" : "0 1 240px" }}
                >
                    <Select
                        loading={loading}
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        options={userRolesOptions}
                        placeholder="Исполнитель"
                        maxTagCount={1}
                        onChange={(value) => onChange("executor_roles", value)}
                    />
                </FormItem>
                <FormItem
                    label={<>Согласующий</>}
                    style={{ flex: isVertical ? "1 1 100%" : "0 1 240px" }}
                >
                    <Select
                        loading={loading}
                        mode="multiple"
                        allowClear
                        maxTagCount={1}
                        style={{ width: "100%" }}
                        options={userRolesOptions}
                        placeholder="Согласующий"
                        onChange={(value) => onChange("reviewer_roles", value)}
                    />
                </FormItem>
                <Flex
                    gap={8}
                    align="center"
                    style={{ flex: isVertical ? "1 1 100%" : "0 1 240px" }}
                >
                    <>
                        {filters.entity_uuid ? (
                            <FormItem
                                style={{
                                    width: "100%",
                                }}
                                label={<>Запись</>}
                            >
                                <TMTResultItemEntityRow
                                    entityUuid={filters.entity_uuid}
                                    entityRowId={filters.entity_row_id}
                                    onChangeEntityRowId={(value) =>
                                        onChange("entity_row_id", value)
                                    }
                                />
                            </FormItem>
                        ) : undefined}
                        {isVertical ? undefined : (
                            <Flex>
                                <Button
                                    style={{
                                        marginTop: 6,
                                        minHeight: 0,
                                        height: "fit-content",
                                    }}
                                    onClick={resetFilters}
                                    type="text"
                                    icon={<CloseOutlined />}
                                />
                            </Flex>
                        )}
                    </>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default TechMapTemplatesFiltersInputs;
