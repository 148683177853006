import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm, Tag } from "antd";
import { useEntityFieldItem } from "../model/UseEntityFieldItem";
import "./EntityFieldItem.scss";

interface IEntityFieldListItem {
    dragHandleProps?: any;
    labelDataType?: string;
    deleteLoading?: boolean;
    entityField: TEntityField;
    error?: TValidationError;
    onEdit: (field: TEntityField) => void;
    onDelete: (field: TEntityField) => void;
    isColored?: (key: string) => boolean;
}
const EntityFieldListItem = ({
    labelDataType,
    entityField,
    dragHandleProps,
    deleteLoading,
    onEdit,
    onDelete,
    isColored,
}: IEntityFieldListItem) => {
    const {
        onDeleteClick,
        onClickEdit,
        name,
        key,
        datatype,
        isDescription,
        isName,
        rules,
    } = useEntityFieldItem({
        entityField,
        onEdit,
        onDelete,
    });

    return (
        <div
            {...dragHandleProps}
            className={`entity-field-list-item ${isColored && isColored(key) ? "border-color" : ""}`}
            onDoubleClick={onClickEdit}
            style={{ display: "flex", flexDirection: "column" }}
        >
            <Flex vertical gap={8} className="entity-field-list-item__text-labels">
                <Flex align="center" gap={16} className="entity-field-list-item__label">
                    {name}
                </Flex>
                <Flex gap="4px 24px" wrap="wrap">
                    <Flex gap={2} className="entity-field-list-item__info" vertical>
                        <span className="entity-field-list-item__info-key">
                            Ключ поля:
                        </span>
                        <span className="entity-field-list-item__info-value">{key}</span>
                    </Flex>
                    <Flex gap={2} className="entity-field-list-item__info" vertical>
                        <span className="entity-field-list-item__info-key">
                            Тип поля:
                        </span>
                        <span className="entity-field-list-item__info-value">
                            {datatype}
                            {labelDataType && ` / ${labelDataType}`}
                        </span>
                    </Flex>
                    {rules.length > 0 && (
                        <Flex gap={2} className="entity-field-list-item__info" vertical>
                            <span className="entity-field-list-item__info-key">
                                По умолчанию:
                            </span>
                            <span className="entity-field-list-item__info-value">
                                {rules.join(", ")}
                            </span>
                        </Flex>
                    )}
                </Flex>
                {/* 
                {isName && (
                    <div className="entity-field-list-item__text-labels__info">
                        <span className="entity-field-list-item__text-labels__info__is-name">
                            Имя
                        </span>
                    </div>
                )}
                {isDescription && (
                    <div className="entity-field-list-item__text-labels__info">
                        <span className="entity-field-list-item__text-labels__info__is-desc">
                            Описание
                        </span>
                    </div>
                )} */}
            </Flex>
            <Flex
                justify="end"
                align="center"
                className="entity-field-list-item__buttons"
            >
                {isName && (
                    <Tag
                        style={{ width: "fit-content", margin: "0px 5px" }}
                        bordered={false}
                        color="purple"
                    >
                        Имя
                    </Tag>
                )}
                {isDescription && (
                    <Tag
                        style={{ width: "fit-content", margin: "0px 5px" }}
                        bordered={false}
                        color="volcano"
                    >
                        Описание
                    </Tag>
                )}

                {!!entityField.is_system && (
                    <Tag
                        style={{ width: "fit-content", margin: "0px 5px" }}
                        bordered={false}
                    >
                        Системное поле
                    </Tag>
                )}
                <Button
                    key={"edit"}
                    onClick={onClickEdit}
                    type="link"
                    icon={<EditOutlined />}
                />
                {!entityField.is_system && (
                    <Popconfirm
                        title="Удалить элемент?"
                        okText="Удалить"
                        onConfirm={onDeleteClick}
                        cancelText="Отмена"
                    >
                        <Button
                            loading={deleteLoading}
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                )}
            </Flex>
        </div>
    );
};

export default EntityFieldListItem;
