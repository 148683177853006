import { getUnixTimestamp } from "@shared/lib/date";
import {
    getNewAgreementAction,
    getNewDefaultAction,
    getNewEmailMessageAction,
    getNewIfElseAction,
    getNewIfElseBranchAction,
    getNewNotificationAction,
    getNewParallelExecutionAction,
    getNewParallelExecutionActionBranch,
    getNewQueryAction,
    getNotFoundedAction,
} from "./CreateBizProcActionLib";

export const getBizProcNewAction = (
    action: TBizProcActionType,
    name: string,
    parameters?: any
): TBizProcTemplateAction => {
    const key = `key-${getUnixTimestamp()}`;
    switch (action) {
        case "IfElseAction":
            return getNewIfElseAction(name, key);
        case "NotificationAction":
            return getNewNotificationAction(name, key);
        case "ChangeValuesAction":
            return getNewDefaultAction("ChangeValuesAction", name, key);
        case "FormAction":
            return getNewDefaultAction("FormAction", name, key);
        case "EmailMessageAction":
            return getNewEmailMessageAction(name, key);
        case "AgreementAction":
            return getNewAgreementAction(name, key);
        case "ParallelExecutionAction":
            return getNewParallelExecutionAction(name, key);
        case "QueryAction":
            return getNewQueryAction(name, key);
        default:
            return getNewDefaultAction(action, name, key, parameters);
    }
    //return getNotFoundedAction(action, key)
};
export const getBizProcNewFixedAction = (
    action: TBizProcActionType
): TBizProcTemplateAction => {
    const key = `key-${getUnixTimestamp()}`;

    switch (action) {
        case "IfElseAction":
            return getNewIfElseBranchAction("Ветвь Условие", key);
        case "ParallelExecutionAction":
            return getNewParallelExecutionActionBranch(
                "Ветвь параллельного выполнения",
                key
            );
    }
    return getNotFoundedAction(action, key);
};
