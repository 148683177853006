import { Flex, Tabs } from "antd";
import { useEditQueryAction } from "../model";
import { TEditQueryActionValue } from "../model/types";
import "./EditQueryAction.scss";
import EditQueryActionBody from "./EditQueryActionBody";
import EditQueryActionHeader from "./EditQueryActionHeader";
import EditQueryActionHeaders from "./EditQueryActionHeaders";
import EditQueryActionParams from "./EditQueryActionParams";
import EditQueryActionResultParse from "./EditQueryActionResultParse";

interface IEditQueryAction {
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    setItem: (
        templateItem: Omit<TBizProcTemplateAction, "parameters"> & {
            parameters: TEditQueryActionValue;
        }
    ) => void;
}
const EditQueryAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditQueryAction) => {
    const { parametersValue, itemId, onChange, onChangeParseRules } = useEditQueryAction(
        bizProcTemplate,
        templateItem,
        setItem
    );
    return (
        <Flex gap={16} vertical style={{ width: "100%" }} className="edit-query-action">
            <EditQueryActionHeader
                method={parametersValue.method}
                url={parametersValue.url}
                itemId={itemId}
                onChange={onChange}
            />
            <Tabs
                destroyInactiveTabPane={false}
                size="small"
                items={[
                    {
                        key: "headers",
                        label: "Заголовки",
                        children: (
                            <EditQueryActionHeaders
                                itemId={itemId}
                                onChange={onChange}
                                headers={parametersValue.headers ?? []}
                            />
                        ),
                        style: { height: "fit-content" },
                    },
                    {
                        key: "params",
                        label: "Параметры",
                        children: (
                            <EditQueryActionParams
                                itemId={itemId}
                                onChange={onChange}
                                url={parametersValue.url ?? ""}
                            />
                        ),
                        style: { height: "fit-content" },
                    },
                ]}
            />
            <EditQueryActionBody
                disabled={parametersValue?.method === "get"}
                itemId={itemId}
                onChange={onChange}
                value={parametersValue.body}
            />
            <EditQueryActionResultParse
                bizProcFields={bizProcTemplate.fields ?? []}
                itemId={itemId}
                onChangeParseRules={onChangeParseRules}
                parseRules={parametersValue.parse_rules ?? []}
            />
        </Flex>
    );
};

export default EditQueryAction;
