import { Input } from "antd";

import { useBizProcTemplateInputVariables } from "../model";
import InputDropdown from "./InputDropdown";

interface IInputWithVariables {
    itemId: number | string;
    type?: TVariableType;
    onChange: (name: string, value: string) => void;
    value?: string;
    name: string;
    disabled?: boolean;
    isTextArea: boolean;
    placeholder?: string;
    width?: number | string;
    rows?: number;
}

const InputWithVariables = ({
    value,
    itemId,
    type = "biz_proc_template",
    name,
    isTextArea,
    disabled,
    onChange,
    placeholder,
    width,
    rows,
}: IInputWithVariables) => {
    const { inputRef, selectMode, itemsGroups, isLoading, onSelectItem, onChangeInput } =
        useBizProcTemplateInputVariables(itemId, type, name, value, isTextArea, onChange);

    const width_ =
        width ??
        (!isTextArea
            ? inputRef?.current?.input?.offsetWidth
            : inputRef?.current?.resizableTextArea?.textArea?.offsetWidth) ??
        0;
    return (
        <div style={{ width: "100%" }}>
            {isTextArea ? (
                <Input.TextArea
                    name={name}
                    placeholder={placeholder ?? undefined}
                    style={{
                        textWrap: "wrap",
                    }}
                    ref={inputRef}
                    onChange={onChangeInput}
                    value={value}
                    rows={rows}
                    disabled={disabled}
                />
            ) : (
                <Input
                    disabled={disabled}
                    name={name}
                    placeholder={placeholder ?? undefined}
                    ref={inputRef}
                    onChange={onChangeInput}
                    value={value}
                />
            )}
            <InputDropdown
                onSelectItem={onSelectItem}
                loading={isLoading}
                items={itemsGroups}
                isOpen={selectMode}
                width={width_}
            />
        </div>
    );
};

export default InputWithVariables;
