import { DeleteOutlined } from "@ant-design/icons";
import { timeSplitter } from "@shared/lib/time";
import { Button } from "antd";
import { ReactNode } from "react";
import "./ActionCard.scss";
interface IActionCard {
    title?: string;
    templateItem?: TBizProcTemplateAction;
    onDeleteItem?: () => void;
    textColor?: string;
    borderColor?: string;
    borderColorLeft?: string;
    borderColorRight?: string;
    children?: ReactNode;
}

const ActionCard = ({
    title,
    templateItem,
    onDeleteItem,
    borderColor,
    borderColorLeft,
    borderColorRight,
    children,
}: IActionCard) => {
    const [minutes, hours, days] = timeSplitter(
        templateItem ? templateItem.deadline_delta ?? 0 : 0
    );
    const borderLeft =
        borderColorLeft || borderColor
            ? `3px solid ${borderColorLeft ?? borderColor}`
            : undefined;
    const borderRight =
        borderColorRight || borderColor
            ? `3px solid ${borderColorRight ?? borderColor}`
            : undefined;
    return (
        <div
            className="action-card"
            style={{ borderLeft: borderLeft, borderRight: borderRight }}
        >
            <div className="action-card__actions">
                {onDeleteItem && (
                    <Button
                        danger
                        type="link"
                        onClick={onDeleteItem}
                        icon={<DeleteOutlined />}
                    />
                )}
            </div>
            <div className="action-card__title">{title}</div>
            {children && <div className="action-card__children">{children}</div>}
            {templateItem && Boolean(templateItem.deadline_delta_enabled) === true && (
                <div style={{}}>
                    {`Время на выполнение ${days ? `${days} д.` : ""} ${hours ? `${hours} ч.` : ""} ${minutes ? `${minutes} м.` : ""}`}
                </div>
            )}
        </div>
    );
};

export default ActionCard;
