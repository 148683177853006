import { MDXEditorMethods } from "@mdxeditor/editor";
import React, { useRef } from "react";

export const useEditorMarkdownInput = (
    value: string,
    onChangeValue: (value: string) => void
) => {
    const maxValueLength = 100;

    const ref = useRef<MDXEditorMethods>(null);
    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value;
        onChangeValue(value);
    };

    return {
        maxValueLength,
        ref,
        onChange,
    };
};
