export const getGroupLevelFilterKey = (groupLevel: string | number) => {
    switch (groupLevel) {
        case "user":
            return "user_id";
        case "template":
            return "technical_map_template_uuid";
        default:
            return groupLevel;
    }
};
