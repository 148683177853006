import { techMapModel } from "@entities/Production/TechMap";
import { TFiltersTechMap } from "@widgets/Production/TechMapTemplatesFilters/types";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMTBar = () => {
    const {
        executableTmts,
        loading,
        loadTechMapTemplatesWithPagination,
        paginationIsEnded,
        isPaginationLoading,
        filters,
        setFilters,
    } = techMapModel.useLoadExecutableTmts();
    const startingLoading = useSelector(techMapModel.selectStartingLoading);
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const dispatch = useDispatch<AppDispatch>();
    const startingLoadingItemsIds = startingLoading ? [startingLoading] : [];
    const activeItemsIds = startedTm?.template?.id ? [startedTm.template.id] : [];
    const [searchValue, setSearchValue] = useState<string>("");
    const options = useMemo(() => {
        return executableTmts.map((item) => ({
            value: item.id,
            label: item.name,
            item,
        }));
    }, [executableTmts]);
    const onSearch = (value: string) => {
        setSearchValue(value ?? "");
    };
    const onClickItem = (item: TTechMapTemplateExecuble) => {
        if (startingLoading) return;
        dispatch(techMapModel.startTmtThunk({ tmtId: item.id }));
    };

    const [filters_, setFilters_] = useState(filters);
    const onChange = (name: keyof TFiltersTechMap, value: any) => {
        setFilters_({ ...filters_, [name]: value });
    };

    const resetFilters = () => {
        const resetedFilters = {
            name: null,
            entity_uuid: null,
            entity_row_id: null,
            is_active: undefined,
            executor_roles: [],
            reviewer_roles: [],
        };
        //TODO: вынести в либу отдельную для компонента фильтров isActiveFilters
        if (
            filters.entity_row_id ||
            filters.entity_uuid ||
            filters.is_active ||
            filters_.name ||
            (filters.executor_roles && filters.executor_roles.length > 0) ||
            (filters.reviewer_roles && filters.reviewer_roles.length > 0)
        )
            setFilters(resetedFilters);
    };

    const submitFilters = () => {
        setFilters(filters_);
    };

    return {
        loadTechMapTemplatesWithPagination,
        paginationIsEnded,
        isPaginationLoading,
        onChange,
        resetFilters,
        submitFilters,
        filters: filters_,
        options: options.filter((option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        ),
        loading,
        startingLoadingItemsIds,
        activeItemsIds,
        searchValue,
        onClickItem,
        onSearch,
    };
};
