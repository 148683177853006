import { Modal } from "antd";
import ReleaseForm from "./ReleaseForm";

interface IReleaseModal {
    releasedItems: TPreparingRelease;
    isOpen: boolean;
    onClose: TEmptyFunction;
}

const ReleaseModal = ({ releasedItems, isOpen, onClose }: IReleaseModal) => {
    return (
        <Modal open={isOpen} onCancel={onClose} footer={[]}>
            <ReleaseForm releasedItems={releasedItems} onClose={onClose} />
        </Modal>
    );
};

export default ReleaseModal;
