import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createTechMapTemplateResult,
    deleteTechMapTemplateResult,
    getTechMapTemplateResult,
    getTechMapTemplateResults,
    updateTechMapTemplateResult,
} from "../../api/api-tm-result";

export const loadTechMapTemplateResultsThunk = createAsyncThunk(
    "TechMapTemplateResultSlice/loadTechMapTemplateResultsThunk",
    async (
        props: { techMapTemplateId: number; params?: TParams },
        { rejectWithValue }
    ) => {
        try {
            const response = await getTechMapTemplateResults(
                props.techMapTemplateId,
                props.params
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadTechMapTemplateResultThunk = createAsyncThunk(
    "TechMapTemplateResultSlice/loadTechMapTemplateResultThunk",
    async (
        { techMapTemplateResultId }: { techMapTemplateResultId: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await getTechMapTemplateResult(techMapTemplateResultId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateTechMapTemplateResultThunk = createAsyncThunk(
    "TechMapTemplateResultSlice/updateTechMapTemplateResultThunk",
    async (
        props: { techMapTemplateResult: TTechMapTemplateResult },
        { rejectWithValue }
    ) => {
        try {
            const response = await updateTechMapTemplateResult(
                props.techMapTemplateResult
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const createTechMapTemplateResultThunk = createAsyncThunk(
    "TechMapTemplateResultSlice/createTechMapTemplateResultThunk",
    async (
        props: {
            techMapTemplateId: number;
            techMapTemplateResult: TTechMapTemplateResultCreating;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await createTechMapTemplateResult(
                props.techMapTemplateId,
                props.techMapTemplateResult
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteTechMapTemplateResultThunk = createAsyncThunk(
    "TechMapTemplateResultSlice/deleteTechMapTemplateResultThunk",
    async (props: { id: number }, { rejectWithValue }) => {
        try {
            const response = await deleteTechMapTemplateResult(props.id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
