import { bizProcModel } from "@entities/Portal/BizProc";
import { userModel } from "@entities/Portal/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useBizProcAgreementWS = (isMy?: boolean) => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();
    useEffect(() => {
        window.Echo.channel(`User.${user.id}.BizProcAction`)
            .listen(
                ".bizProcAction.create",
                ({
                    bizProcAction,
                }: {
                    bizProcAction: TBizProcAction<TBizProcAgreementParameters>;
                }) => {
                    if (
                        isMy &&
                        bizProcAction.action === "AgreementAction" &&
                        bizProcAction?.biz_proc?.assigned_user.id === user.id
                    ) {
                        dispatch(bizProcModel.addMyAgreement(bizProcAction));
                    }
                    if (
                        !isMy &&
                        bizProcAction.action === "AgreementAction" &&
                        bizProcAction.collected_parameters.users_ids.includes(user.id)
                    ) {
                        dispatch(bizProcModel.addAgreement(bizProcAction));
                    }
                }
            )
            .listen(
                ".bizProcAction.update",
                ({
                    bizProcAction,
                }: {
                    bizProcAction: TBizProcAction<TBizProcAgreementParameters>;
                }) => {
                    if (
                        isMy &&
                        bizProcAction.action === "AgreementAction" &&
                        bizProcAction?.biz_proc?.assigned_user.id === user.id
                    ) {
                        dispatch(bizProcModel.updateMyAgreement(bizProcAction));
                    }
                    if (
                        !isMy &&
                        bizProcAction.action === "AgreementAction" &&
                        bizProcAction.collected_parameters.users_ids.includes(user.id)
                    ) {
                        dispatch(bizProcModel.updateAgreement(bizProcAction));
                    }
                }
            );

        return () => {
            window.Echo.leave(`User.${user.id}.BizProcAction`);
        };
    }, []);
};
