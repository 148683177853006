import { techMapModel } from "@entities/Production/TechMap";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TMTableApprovePartialModal from "./TMTableApprovePartialModal";
import { useState } from "react";

interface ITMTableApprovePartial {
    techMap: TTechMapStarted;
    levelKey?: string | number;
}

const TMTableApprovePartial = ({ techMap, levelKey }: ITMTableApprovePartial) => {
    const [isOpen, setIsOpen] = useState(false);
    const approveLoading = useSelector((state: RootState) =>
        techMapModel.selectIsPartlyRejectLoading(state, techMap.id)
    );
    const rejectLoading = useSelector((state: RootState) =>
        techMapModel.selectIsRejectLoading(state, techMap.id)
    );
    const dispatch = useDispatch<AppDispatch>();
    const results = techMap.results;
    const onApprove = () => {
        setIsOpen(true);
    };
    const onCancel = () => {
        setIsOpen(false);
    };

    return (
        <>
            {results.length > 0 && (
                <Button
                    loading={approveLoading}
                    disabled={rejectLoading}
                    style={{ margin: 0, padding: 0, minHeight: 10 }}
                    type="link"
                    size="small"
                    onClick={onApprove}
                >
                    Согласовать частично
                </Button>
            )}
            <TMTableApprovePartialModal
                techMap={techMap}
                isOpen={isOpen}
                levelKey={levelKey}
                onCancel={onCancel}
            />
        </>
    );
};

export default TMTableApprovePartial;
