import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Pagination, Table } from "antd";
import { memo, ReactNode } from "react";
import { groupFilterKey } from "../constants";
import { useReleasesTable } from "../model";
import { getDeleteColumn } from "./DeleteColumn";
import { getReleasesColumn } from "./ReleasesColumns";
import { getPublishColumn } from "./PublishColumn";

interface IReleaseTable {
    level: number;
    releaseItemsTable?: (release: TRelease) => ReactNode;
    groups?: TReleaseGroup[];
    additionalFilters?: TFilterValue[];
}

const ReleaseTable = ({
    level,
    groups,
    additionalFilters,
    releaseItemsTable,
}: IReleaseTable) => {
    const {
        loading,
        page,
        perPage,
        lastPage,
        currentGroup,
        releases,
        deletingIds,
        publishIds,
        onShowSizeChange,
        onDelete,
        onPublish,
    } = useReleasesTable(level, groups, additionalFilters);

    const columns = getReleasesColumn(currentGroup);

    if (!currentGroup) {
        columns.push(getDeleteColumn(onDelete, deletingIds));
        columns.push(getPublishColumn(onPublish, publishIds));
    }

    return (
        <>
            <Table
                size="small"
                rowKey={currentGroup ? "value" : "id"}
                key={currentGroup ? "value" : "id"}
                pagination={false}
                style={{ width: "100%" }}
                loading={loading}
                columns={columns}
                dataSource={releases}
                // rowSelection={{
                //     type: "checkbox",
                //     onChange: (_: any, rows: any[]) => onSelectRows(rows),
                //     // selectedRowKeys: selectedRowIds.map(
                //     //     (row) => (row as any).id
                //     // ),
                // }}
                expandable={
                    currentGroup
                        ? {
                              expandRowByClick: true,
                              expandedRowRender: (
                                  record: TReleaseGrouped,
                                  index,
                                  indent,
                                  expanded
                              ) => {
                                  if (!expanded) return <></>;
                                  return (
                                      <ReleaseTable
                                          releaseItemsTable={releaseItemsTable}
                                          additionalFilters={[
                                              ...(additionalFilters ?? []),
                                              {
                                                  condition: "=",
                                                  value: record.value,
                                                  key: groupFilterKey[
                                                      currentGroup as TReleaseGroup
                                                  ],
                                              },
                                          ]}
                                          groups={groups}
                                          level={level + 1}
                                      />
                                  );
                              },
                          }
                        : releaseItemsTable
                          ? {
                                expandRowByClick: true,
                                expandedRowRender: (record, index, indent, expanded) => {
                                    if (!expanded) return <></>;
                                    return releaseItemsTable(record);
                                },
                            }
                          : undefined
                }
            />
            <IndentContainer type={["marginTop"]}>
                <FlexContainer type="row-end">
                    <Pagination
                        hideOnSinglePage={currentGroup != undefined}
                        showSizeChanger
                        onChange={onShowSizeChange}
                        total={lastPage * perPage}
                        pageSize={perPage}
                        current={page}
                    />
                </FlexContainer>
            </IndentContainer>
        </>
    );
};

export default memo(ReleaseTable, (prev, next) => {
    const prevGroups = prev.groups ?? [];
    const nextGroups = next.groups ?? [];
    if (prevGroups.length !== nextGroups.length) {
        return false;
    } else {
        prevGroups.forEach((group) => {
            if (!nextGroups.includes(group)) {
                return false;
            }
        });
    }

    const prevLevel = prev.level;
    const nextLevel = next.level;
    if (prevLevel !== nextLevel) return false;
    const prevFilters = prev.additionalFilters ?? [];
    const nextFilters = prev.additionalFilters ?? [];
    if (prevFilters.length !== nextFilters.length) {
        return false;
    } else {
        prevFilters.forEach((filter) => {
            const isHave = nextFilters.find(
                (nextFilter) =>
                    nextFilter.condition === filter.condition &&
                    nextFilter.value === filter.value &&
                    nextFilter.key === filter.key
            );
            if (!isHave) {
                return false;
            }
        });
    }

    return true;
});
