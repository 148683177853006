import { $api } from "@shared/api/api";
const route = "/api/production/tm-template";
const routeOperation = "/api/production/tmt-operation";

export const getTechMapTemplateOperations = (
    techMapTemplateId: number,
    params?: TParams
) => {
    return $api.get<TResponseList<TTechMapTemplateOperation>>(
        `${route}/${techMapTemplateId}/operation`,
        { params: params }
    );
};
export const getTechMapTemplateOperation = (techMapTemplateOperationId: number) => {
    return $api.get<TResponse<TTechMapTemplateOperationFull>>(
        `${routeOperation}/${techMapTemplateOperationId}`
    );
};
export const getTechMapTemplateOperationByUuid = (
    techMapTemplateOperationUuid: string
) => {
    return $api.get<TResponse<TTechMapTemplateOperationFull>>(
        `${routeOperation}/by-uuid/${techMapTemplateOperationUuid}`
    );
};
export const updateTechMapTemplateOperation = (
    techMapTemplateOperation: TTechMapTemplateOperation
) => {
    return $api.put<TResponse<TTechMapTemplateOperation>>(
        `${routeOperation}/${techMapTemplateOperation.id}`,
        techMapTemplateOperation
    );
};
export const updateMassTechMapTemplateOperations = (
    techMapTemplateId: number,
    techMapTemplateOperations: (
        | TTechMapTemplateOperation
        | TTechMapTemplateOperationCreating
    )[]
) => {
    return $api.put<TResponseList<TTechMapTemplateOperation>>(
        `${route}/${techMapTemplateId}/operation`,
        techMapTemplateOperations
    );
};
export const createTechMapTemplateOperation = (
    techMapTemplateId: number,
    techMapTemplateOperation: TTechMapTemplateOperationCreating
) => {
    return $api.post<TResponse<TTechMapTemplateOperation>>(
        `${route}/${techMapTemplateId}/operation`,
        techMapTemplateOperation
    );
};
export const deleteTechMapTemplateOperation = (id: number) => {
    return $api.delete<TResponse<TTechMapTemplateOperation>>(`${routeOperation}/${id}`);
};
