import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { ErrorText } from "@shared/ui/Text";
import { Flex, Select } from "antd";
import { methodsOptions } from "../constants";
import { useEditQueryActionHeader } from "../model";
import { ICommonPropsEditQueryActionInterface } from "../model/types";

interface IEditQueryActionHeader extends ICommonPropsEditQueryActionInterface {
    url: string | undefined;
    method: string | undefined;
}

const EditQueryActionHeader = ({
    url,
    itemId,
    method,
    onChange,
}: IEditQueryActionHeader) => {
    const { firstRender, isValidUrl, onChageMethod, onChangeUrl, commonCustomStyle } =
        useEditQueryActionHeader(url, onChange);

    return (
        <Flex gap={8} style={{ height: "fit-content" }}>
            <Flex
                vertical
                style={{
                    flex: "1 1 30%",
                    paddingBottom: 8,
                    position: "relative",
                    maxWidth: 100,
                }}
            >
                <Select
                    placeholder="Метод"
                    value={method}
                    onChange={onChageMethod}
                    style={{ width: "100%" }}
                    options={methodsOptions}
                />
            </Flex>
            <Flex
                style={{ flex: "1 1 70%", paddingBottom: 8, position: "relative" }}
                vertical
            >
                <InputWithVariables
                    placeholder="URL"
                    name="url"
                    value={url}
                    isTextArea={false}
                    onChange={onChangeUrl}
                    itemId={itemId}
                />
                {(!firstRender.current || (url && url?.length > 0)) && !isValidUrl ? (
                    <ErrorText
                        customStyle={commonCustomStyle}
                        absolute
                        text="Формат ссылки некорректен"
                    />
                ) : (
                    <></>
                )}
            </Flex>
        </Flex>
    );
};

export default EditQueryActionHeader;
