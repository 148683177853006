import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Button, Input, Space } from "antd";
import { useTMNecessaryItemAttach } from "../model";

interface ITMNecessaryItemAttach {}

const TMNecessaryItemAttach = ({}: ITMNecessaryItemAttach) => {
    const { code, loading, onAttach, onChangeCode } = useTMNecessaryItemAttach();
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <span>Ручной ввод</span>
            <FlexContainer>
                <Space>
                    <Input
                        value={code}
                        onChange={onChangeCode}
                        style={{ maxWidth: 130, minHeight: 15 }}
                        size="small"
                    />
                    <Button
                        loading={loading}
                        size="small"
                        style={{ minHeight: 15 }}
                        onClick={onAttach}
                    >
                        Добавить
                    </Button>
                </Space>
            </FlexContainer>
        </div>
    );
};

export default TMNecessaryItemAttach;
