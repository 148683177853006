import { userModel } from "@entities/Portal/User";
import { TMFilters } from "@features/Production/TechMapFeatures/TMFilters";
import { TMGroupSelect } from "@features/Production/TechMapFeatures/TMGroupSelect";
import { TMTableGrouped } from "@features/Production/TechMapFeatures/TMTable";
import { ReleaseItemsTable } from "@widgets/Production/ReleaseItemsTable";
import { Releases } from "@widgets/Production/ReleasesTable";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
interface IProductionTabs {}

const ProductionTabs = ({}: IProductionTabs) => {
    const isAdmin = useSelector(userModel.selectUserIsAdmin);

    if (!isAdmin) return <TMTableGrouped />;

    return (
        <Tabs
            defaultActiveKey="1"
            type="line"
            destroyInactiveTabPane
            size="middle"
            items={[
                {
                    label: <>Согласование</>,
                    key: "1",
                    children: (
                        <>
                            <TMTableGrouped />
                        </>
                    ),
                },
                {
                    label: <>Элементы для выпуска</>,
                    key: "2",
                    children: (
                        <>
                            <ReleaseItemsTable />
                        </>
                    ),
                },
                {
                    label: <>Выпуски</>,
                    key: "3",
                    children: (
                        <>
                            <Releases />
                        </>
                    ),
                },
            ]}
        />
    );
};

export default ProductionTabs;
