export const getCodesFromTM = (techMap: TTechMapStarted) => {
    const codes: any[] = [];

    techMap.template.operations.forEach((tmto) => {
        const tmo = techMap.operations.find(
            (o) => o.technical_map_template_operation_uuid === tmto.uuid
        );

        if (tmo?.status === "successful") {
            const tmoCodes = tmo.codes;
            const tmtoCodes = tmto.code_templates as TMTOCodeTemplate[];
            tmoCodes.forEach((code) => {
                const tmtoCode = tmtoCodes.find(
                    (tmtoCode) => tmtoCode.id === code.tmt_operation_code_id
                );

                codes.push({
                    ...code,
                    code_template: tmtoCode,
                });
            });
        }
    });
    return codes;
};
