export const hiddenTimelineItemActions = [
    "IfElseAction",
    "IfElseActionBranch",
    "ApproveBranchAction",
    "RejectBranchAction",
    "QueryActionSuccessBranch",
    "QueryActionErrorBranch",
    "ParallelExecutionAction",
    "ParallelExecutionActionBranch",
];
