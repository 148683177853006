import {
    DeleteOutlined,
    EditOutlined,
    GroupOutlined
} from "@ant-design/icons";
import { UserAccess } from "@entities/Portal/User";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { TextWithLabel } from "@shared/ui/Text";
import { Button, Card, Popconfirm } from "antd";
import React from "react";
import { useEntityListItem } from "../model";
import "./EntitiesList.scss";
interface IEntityNormalItem {
    entity: TEntity;
    additionalActions: (item: TEntity) => React.ReactNode;
}
const EntityNormalItem = ({ entity, additionalActions }: IEntityNormalItem) => {
    const { onSubmitDelete, onEdit, moveToEntityTable, deleteLoading } =
        useEntityListItem(entity);

    return (
        <Card className="entity-item">
            <div className="entity-item__buttons" onClick={(e) => e.stopPropagation()}>
                {additionalActions && additionalActions(entity)}
                <UserAccess.AdminAccess>
                    <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
                </UserAccess.AdminAccess>

                <UserAccess.AdminAccess>
                    <Popconfirm
                        title="Удалить элемент?"
                        okText="Удалить"
                        onConfirm={onSubmitDelete}
                        cancelText="Отмена"
                    >
                        <Button
                            loading={deleteLoading}
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </UserAccess.AdminAccess>
            </div>
            <IndentContainer>
                <TextWithLabel label={"Наименование"} text={entity.name} />
            </IndentContainer>
            <FlexContainer type="row-end">
                <div>
                    <Button
                        onClick={moveToEntityTable}
                        type="link"
                        icon={<GroupOutlined />}
                    >
                        Список
                    </Button>
                </div>
            </FlexContainer>
        </Card>
    );
};

export default EntityNormalItem;
