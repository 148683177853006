import { datatypeModel } from "@entities/Portal/Datatype";
import { useDispatch } from "react-redux";
import { createdDatatype } from "../constant";

export const useCreateButton = () => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(datatypeModel.setEditingDatatype(createdDatatype));
    };
    return {
        onClick,
    };
};
