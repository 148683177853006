import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadBizProcTemplateItemThunk } from "./BizProcTemplateThunk";

export const useLoadBizProcTemplateItem = (
    bizProcId: number,
    withTemplateActions: boolean,
    preload?: boolean
) => {
    //const bizProcTemplateItem = useSelector(selectEditingBizProcTemplateItem);
    const [bizProcTemplateItem, setBizProcTemplateItem] =
        useState<TBizProcTemplateItem | null>(null);
    const dispatch = useDispatch<any>();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (preload) {
            loadBizProcTemplateItem();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preload]);

    const loadBizProcTemplateItem = () => {
        setLoading(true);
        dispatch(
            loadBizProcTemplateItemThunk({ id: bizProcId, withTemplateActions })
        ).then(({ payload }: { payload: TResponse<TBizProcTemplateItem> }) => {
            setLoading(false);
            setBizProcTemplateItem(payload.data);
        });
    };
    return {
        bizProcTemplateItem,
        setBizProcTemplateItem,
        loading,
        loadBizProcTemplateItem,
    };
};
