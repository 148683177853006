import { calculatedValueModel } from "@entities/Portal/CalculatedValues";

import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { useSelector } from "react-redux";
import { CalculatedValuesList } from "@features/Portal/CalculatedValues/CalculatedValuesList";
import {
    CalculatedValueCreateButton,
    CalculatedValueModifyModal,
} from "@features/Portal/CalculatedValues/CreateUpdateCalculatedValues";
import { ExportButton } from "@features/Portal/Action/Export";
import { Space } from "antd";
import { ImportButton } from "@features/Portal/Action/Import";

const CalculatedValuePage = () => {
    useHeaderLayout({
        title: "Вычисляемые значения",
    });
    const { calculatedValues } = calculatedValueModel.useLoadCalculatedValues(true);

    const editingCalculatedValue = useSelector(
        calculatedValueModel.selectEditingCalculatedValue
    );

    return (
        <div>
            <IndentContainer>
                <Space>
                    {calculatedValues.length > 0 && (
                        <>
                            <CalculatedValueCreateButton />
                            <ExportButton
                                url="calculated-value/export"
                                filename="calculated values"
                            />
                        </>
                    )}
                    <ImportButton />
                </Space>
            </IndentContainer>
            <CalculatedValuesList />
            <CalculatedValueModifyModal editingCalculatedValue={editingCalculatedValue} />
        </div>
    );
};

export default CalculatedValuePage;
