import { BizProcTemplateListItem } from "@entities/Portal/BizProcTemplate";
import { ReactNode } from "react";
import { useBizProcTemplateListItem } from "../model";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
interface IBizProcTemplateNormalItem {
    entityId: number;
    bizProcTemplateItem: TBizProcTemplateItem;
    bottomActions?: ReactNode;
    onDeleted?: (id: number) => void;
}
const BizProcTemplateNormalItem = ({
    entityId,
    bizProcTemplateItem,
    bottomActions,
    onDeleted,
}: IBizProcTemplateNormalItem) => {
    const { onClick, onDelete, deleteLoading } = useBizProcTemplateListItem(
        entityId,
        bizProcTemplateItem,
        onDeleted
    );

    return (
        <div
            style={{
                cursor: "pointer",
            }}
        >
            <BizProcTemplateListItem
                onClick={onClick}
                topActions={
                    <>
                        <Popconfirm
                            title="Удалить шаблон?"
                            okText="Удалить"
                            okButtonProps={{
                                danger: true,
                                loading: deleteLoading,
                            }}
                            onConfirm={onDelete}
                            cancelText="Отмена"
                        >
                            <Button danger type="link" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </>
                }
                bottomActions={bottomActions}
                bizProcTemplateItem={bizProcTemplateItem}
            />
        </div>
    );
};

export default BizProcTemplateNormalItem;
