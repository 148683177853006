import { Button } from "antd";
import { useTechMapTmlEditorSubmit } from "../model";

interface ITechMapTmlEditorSubmit {
    setInitialTMT?: (tmt: TTechMapTemplate) => void;
}

const TechMapTmlEditorSubmit = ({ setInitialTMT }: ITechMapTmlEditorSubmit) => {
    const { submitLoading, isUpdating, onSubmit } =
        useTechMapTmlEditorSubmit(setInitialTMT);

    return (
        <Button type="primary" loading={submitLoading} onClick={onSubmit}>
            {isUpdating ? "Сохранить" : "Создать"}
        </Button>
    );
};

export default TechMapTmlEditorSubmit;
