import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { entityRowBuilder } from "./EntityRowBuilder";

export interface IEntityRowSlice {
    entityRows: any[];
    entityRowsWithPage: TEntityRowsWithPageItems;
    currentPage: number;
    lastPage: number;
    currentCategory: number | null;
    entityFields: TEntityField[];
    entityUserCapabilities: TUserCapabilities | null;
    isLoading: boolean;
    groupingKeys: string[];
    filters: TFilterValues;
    textFilterValue: string | undefined;
    isDisabledCurrentCategorySelect: boolean;
}

const initialState: IEntityRowSlice = {
    entityRows: [],
    entityRowsWithPage: {},
    entityFields: [],
    groupingKeys: [],
    currentPage: 1,
    lastPage: 1,
    entityUserCapabilities: null,
    currentCategory: null,
    isLoading: false,
    isDisabledCurrentCategorySelect: false,
    filters: undefined,
    textFilterValue: undefined,
};
const addCategoryFilter = (state: IEntityRowSlice, entityId: any) => {
    const { filters = [], currentCategory, isDisabledCurrentCategorySelect } = state;
    const filterCategoryIndex = filters.findIndex((item) => item.key === "category");
    if (filterCategoryIndex !== -1) {
        if (isDisabledCurrentCategorySelect) {
            state.filters = [...filters];
        } else {
            state.filters = currentCategory
                ? filters.map((item) =>
                      item.key === "category" ? { ...item, value: currentCategory } : item
                  )
                : filters.filter((item) => item.key !== "category");
        }
    } else {
        if (currentCategory) {
            state.filters = [
                ...filters,
                {
                    key: "category",
                    value: currentCategory,
                    condition: "=",
                },
            ];
        } else {
            state.filters = filters.filter((item) => item.key !== "category");
        }
    }
};

export const entityRowSlice = createSlice({
    name: "EntityRowsSlice",
    initialState,
    reducers: {
        setEntityRows: (state, action: PayloadAction<any[]>) => {
            state.entityRows = action.payload;
        },
        setCurrentCategory: (
            state,
            action: PayloadAction<{
                entityId: number;
                categoryId: number | null;
            }>
        ) => {
            const categoryValue = action.payload.categoryId;
            if (state.filters && categoryValue)
                state.filters = state.filters.filter((item) => item.key !== "stage");
            state.currentCategory = categoryValue;
            const entityId = action.payload.entityId;
            state.currentPage = 1;
            addCategoryFilter(state, entityId);
        },
        setEntityFields: (state, action: PayloadAction<TEntityField[]>) => {
            state.entityFields = action.payload;
        },
        addEntityRow: (state, action: PayloadAction<any>) => {
            state.entityRows = [action.payload, ...state.entityRows];
        },

        addEntityRowByKey: (
            state,
            action: PayloadAction<{
                entityRow: any;
                key: number | string;
            }>
        ) => {
            if (state.entityRowsWithPage[action.payload.key]) {
                state.entityRowsWithPage[action.payload.key].entityRows = [
                    action.payload.entityRow,
                    ...state.entityRowsWithPage[action.payload.key].entityRows,
                ];
            }
        },
        updateEntityRowByKey: (
            state,
            action: PayloadAction<{
                entityRow: any;
                key: number | string;
            }>
        ) => {
            if (state.entityRowsWithPage[action.payload.key]) {
                state.entityRowsWithPage[action.payload.key].entityRows =
                    updateArrByItemId(
                        state.entityRowsWithPage[action.payload.key].entityRows,
                        action.payload.entityRow
                    );
            }
        },
        deleteEntityRowByKey: (
            state,
            action: PayloadAction<{
                entityRowId: number;
                key: number | string;
            }>
        ) => {
            if (state.entityRowsWithPage[action.payload.key]) {
                state.entityRowsWithPage[action.payload.key].entityRows =
                    state.entityRowsWithPage[action.payload.key].entityRows.filter(
                        ({ id }) => id !== action.payload.entityRowId
                    );
            }
        },
        updateEntityRow: (state, action: PayloadAction<any>) => {
            state.entityRows = updateArrByItemId(state.entityRows, action.payload);
        },
        deleteEntityRow: (state, action: PayloadAction<any>) => {
            const deletedId = action.payload;
            state.entityRows = state.entityRows.filter(({ id }) => id !== deletedId);
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setCurrentPageEntityRowsWithPage: (
            state,
            action: PayloadAction<{
                key: number | string;
                page: number;
            }>
        ) => {
            if (state.entityRowsWithPage[action.payload.key]) {
                state.entityRowsWithPage[action.payload.key].currentPage =
                    action.payload.page;
            }
        },
        deleteEntityRowsWithPage: (state, action: PayloadAction<number | string>) => {
            if (state.entityRowsWithPage[action.payload])
                delete state.entityRowsWithPage[action.payload];
        },
        setIsDisabledCurrentCategorySelect: (state, action: PayloadAction<boolean>) => {
            state.isDisabledCurrentCategorySelect = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setGroupingKeys: (state, action: PayloadAction<string[]>) => {
            state.groupingKeys = action.payload;
        },
        setLastPage: (state, action: PayloadAction<number>) => {
            state.lastPage = action.payload;
        },
        setFilters: (
            state,
            action: PayloadAction<{
                filters: TFilterValues | undefined;
                entityId: number;
            }>
        ) => {
            const entityId = action.payload.entityId;
            const filters = action.payload.filters;
            state.currentPage = 1;
            state.filters = filters;
            if (filters) {
                localStorage.setItem(`${entityId}_filters`, JSON.stringify(filters));
            } else {
                localStorage.removeItem(`${entityId}_filters`);
            }
            addCategoryFilter(state, entityId);
        },
        setTextFilterValue: (
            state,
            action: PayloadAction<{
                textFilterValue: string | undefined;
                entityId: number;
            }>
        ) => {
            const entityId = action.payload.entityId;
            state.textFilterValue = action.payload.textFilterValue;
            state.currentPage = 1;
            if (state.textFilterValue)
                localStorage.setItem(`${entityId}__special-key`, state.textFilterValue);
            else localStorage.removeItem(`${entityId}__special-key`);
        },
        setEntityUserCapabilities: (state, action: PayloadAction<any>) => {
            state.entityUserCapabilities = action.payload;
        },
    },
    extraReducers: (builder) => {
        entityRowBuilder(builder);
    },
});

export const {
    setEntityRows,
    setCurrentCategory,
    setLastPage,
    setEntityFields,
    deleteEntityRowsWithPage,
    setCurrentPage,
    addEntityRow,
    setCurrentPageEntityRowsWithPage,
    updateEntityRow,
    deleteEntityRow,
    setIsLoading,
    setIsDisabledCurrentCategorySelect,
    setGroupingKeys,
    addEntityRowByKey,
    updateEntityRowByKey,
    deleteEntityRowByKey,
    setFilters,
    setTextFilterValue,
    setEntityUserCapabilities,
} = entityRowSlice.actions;
export default entityRowSlice.reducer;
