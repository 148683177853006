import { DrawerDefault } from "@shared/ui/DrawerManager";
import TMFullInfo from "./TMFullInfo";

interface ITMDrawer {
    isOpenDrawer: boolean;
    tmId: number;
    level?: number;
    openCloseDrawer: () => void;
}

const TMDrawer = ({ isOpenDrawer, tmId, level, openCloseDrawer }: ITMDrawer) => {
    const windowWidth = window.innerWidth;
    const shortVariant = windowWidth <= 900;
    const drawerLevel = level ?? 0;
    return (
        <DrawerDefault maskClosable={true} open={isOpenDrawer} onClose={openCloseDrawer}>
            <div
                style={{
                    // TODO анимация
                    height: "100%",
                    width: `calc(${
                        !shortVariant ? "60vw" : "100vw"
                    } + ${drawerLevel * 30}px)`,
                }}
            >
                <TMFullInfo tmId={tmId} />
            </div>
        </DrawerDefault>
    );
};

export default TMDrawer;
