import { datatypeModel } from "@entities/Portal/Datatype";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCreateUpdateDatatype = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isOpen, setIsOpen] = useState(false);
    const [datatype, setDatatype] = useState<TDatatype | TCreatingDatatype | null>(null);
    const [error, setError] = useState<TValidationError>({});
    const editigDatatype = useSelector(datatypeModel.selectEditingDatatype);

    useEffect(() => {
        setDatatype(editigDatatype);
        if (editigDatatype) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [editigDatatype]);

    const onChangeNamedValue = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (datatype)
            setDatatype({
                ...datatype,
                [event.target.name]: event.target.value,
            });
    };
    const onChangeDatatype = (datatype: TDatatype | TCreatingDatatype) => {
        setDatatype(datatype);
    };
    const onOk = () => {
        setError({});

        if (datatype) {
            dispatch(
                !datatype.id
                    ? datatypeModel.createDatatypesThunk(datatype as TCreatingDatatype)
                    : datatypeModel.updateDatatypesThunk(datatype)
            )
                .then(parseFormResponse)
                .then((data: TResponse<TAction>) => {
                    onClose();
                })
                .catch((error: TValidationError) => {
                    console.log("error", error);
                    setError(error);
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Ошибка",
                        type: "error",
                    });
                });
        }
    };

    const onClose = () => {
        setIsOpen(false);
        setError({});
        dispatch(datatypeModel.setEditingDatatype(null));
    };

    return {
        isOpen,
        datatype,
        error,
        onOk,
        onClose,
        onChangeDatatype,
        onChangeNamedValue,
    };
};
