import { entityFieldApi } from "@entities/Portal/EntityField";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { messageEmit } from "@shared/ui/NotificationAndMessage/model/emits";
import { FocusEvent, useEffect, useRef, useState } from "react";

export const useCapabilitiesRolesCell = (
    entityCapabilitiesRolesId: number,
    type: TCapType,
    entityId: number | undefined,
    entity_uuid: string,
    onChange: (key: TCapType, value: any) => void,
    isMultiSelectType: boolean | undefined
) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entityFieldsOptions, setEntityFieldsOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const timer = useRef<any>();

    const onFocus = () => {
        setIsFocused(true);
    };

    const onMouseEnterCell = () => {
        if (timer.current) clearTimeout(timer.current);
    };

    const onMouseLeaveCell = () => {
        timer.current = setTimeout(() => {
            setIsFocused(false);
        }, 600);
    };

    useEffect(() => {
        if (isFocused && entityFieldsOptions.length === 0) {
            setIsLoading(true);
            entityFieldApi
                .getEntityFieldsByEntityUuid(entity_uuid)
                .then((response) => {
                    setEntityFieldsOptions([
                        { value: "", label: type === "hidden_fields" ? "-" : "Все" },
                        ...response.data.data.map((entityField) => ({
                            label: entityField.name,
                            value: entityField.key,
                        })),
                    ]);
                })
                .catch((e) => {
                    console.log(e);
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: "Не удалось загрузить поля сущности",
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [isFocused]);

    const openSelect = () => {
        setIsOpen(true);
    };
    const closeSelect = () => {
        setIsOpen(false);
    };

    const onBlurHandle = (e: FocusEvent<HTMLInputElement>) => {
        if (!e.relatedTarget?.className.includes("ant-input-select")) {
            closeSelect();
        }
    };

    const onChangeValue = (value: any) => {
        const newValue = isMultiSelectType
            ? value.length > 0
                ? value.join(",")
                : null
            : !!value || value === 0
              ? value
              : null;

        onChange(type, newValue);
    };

    const copyValue = async (value: string) => {
        const copiedDataPrepared = JSON.stringify({
            value,
            entity_uuid,
        });
        try {
            await navigator.clipboard.writeText(copiedDataPrepared);
        } catch (error) {
            messageEmit({ type: "error", content: "Не удалось скопировать значение" });
            console.error("Не удалось вставить", error);
        }
    };

    const pasteValue = async () => {
        try {
            const copiedValue = await navigator.clipboard.readText();
            const parsedCopiedValue = JSON.parse(copiedValue);
            if (entity_uuid === parsedCopiedValue.entity_uuid) {
                const comparableValue = parsedCopiedValue.value;
                const correctCopiedValue =
                    comparableValue !== "null" && comparableValue
                        ? comparableValue.split(",")
                        : "";
                if (correctCopiedValue || correctCopiedValue === "")
                    onChangeValue(correctCopiedValue);
            } else {
                messageEmit({
                    type: "info",
                    content: "Не удалось вставить скопированное значение",
                });
            }
        } catch (error) {
            console.error("Не удалось вставить данные", error);
        }
    };

    return {
        isOpen,
        isLoading,
        isFocused,
        entityFieldsOptions,
        openSelect,
        closeSelect,
        onBlurHandle,
        onChangeValue,
        onFocus,
        onMouseLeaveCell,
        onMouseEnterCell,
        copyValue,
        pasteValue,
    };
};
