import ActionCard from "./ActionCard";

interface IParallelExecutionAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const ParallelExecutionAction = ({
    onDeleteItem,
    templateItem,
}: IParallelExecutionAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="silver"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Параллельное выполнение"}
        >
            {templateItem && templateItem.parameters?.is_wait_for_all && (
                <div style={{ color: "silver", fontSize: 11, textAlign: "center" }}>
                    Ожидание всех
                </div>
            )}
        </ActionCard>
    );
};

export default ParallelExecutionAction;
