import { CloseOutlined } from "@ant-design/icons";
import { SignOutButton } from "@features/Portal/SignOutButton";
import { useQueryParams } from "@shared/hooks/UseQueryParams";
import { Button, Card } from "antd";
import "./UserProfileHeader.scss";

interface IUserProfileHeader {
    name: string;
    isMe: boolean;
}

const UserProfileHeader = ({ name, isMe }: IUserProfileHeader) => {
    const { deleteParam } = useQueryParams("user");
    const onClose = () => {
        deleteParam();
    };

    return (
        <Card bodyStyle={{ padding: "10px 20px" }}>
            <div className="user-header-card">
                <div className="user-header-card__name">
                    {name}
                    {isMe && window.innerWidth >= 800 && (
                        <SignOutButton
                            size={window.innerWidth <= 800 ? 16 : undefined}
                            color="red"
                        />
                    )}
                </div>
                <Button
                    onClick={onClose}
                    type="text"
                    shape="circle"
                    size="large"
                    icon={<CloseOutlined />}
                />
            </div>
        </Card>
    );
};

export default UserProfileHeader;
