import {
    getQueryParamsObjectFromString,
    getQueryParamsStringFromObjectsArray,
    getUrlRouteAndQueryParamString,
} from "../lib";
import { TEditQueryActionValue } from "./types";

export const useEditQueryActionParams = (
    url: string | undefined,
    onChange: (
        name: keyof TEditQueryActionValue,
        value: string | null | Record<string, string>[]
    ) => void
) => {
    const [urlRoute, queryParamsString] = getUrlRouteAndQueryParamString(url ?? "");
    const queryParamsKeyValues = getQueryParamsObjectFromString(queryParamsString);
    const lastParam = queryParamsKeyValues[0] ?? { key: "", value: "" };
    const isEmptyLastParam = lastParam.key?.length === 0;
    const isDisabledAddButton = queryParamsKeyValues.length !== 0 && isEmptyLastParam;

    const onDelete = (index: number) => {
        const newParams = queryParamsKeyValues.filter((_, idx) => index !== idx);
        onChange(
            "url",
            urlRoute +
                (newParams.length > 0 ? "?" : "") +
                getQueryParamsStringFromObjectsArray(newParams)
        );
    };
    const onChangeQueryParamsKeyValue = (
        name: string,
        newValue: string,
        newIndex: number
    ) => {
        const newParams = queryParamsKeyValues.map((item, index) => {
            if (index === newIndex) {
                return {
                    ...item,
                    [name]: newValue,
                };
            }
            return item;
        });
        onChange("url", urlRoute + `?` + getQueryParamsStringFromObjectsArray(newParams));
    };

    const onAdd = () => {
        onChange(
            "url",
            urlRoute +
                "?" +
                getQueryParamsStringFromObjectsArray([
                    { key: "", value: "" },
                    ...queryParamsKeyValues,
                ])
        );
    };

    return {
        onAdd,
        isDisabledAddButton,
        queryParamsKeyValues,
        onChangeQueryParamsKeyValue,
        onDelete,
    };
};
