import { techMapModel } from "@entities/Production/TechMap";
import { TMTResultSelectOption } from "@entities/Production/TechMapTemplate";
import { printCode } from "@shared/StickerPrinter/emits";
import { Code } from "@shared/ui/Code";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Button } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./TMOCodes.scss";
import { getCodeType } from "@shared/lib/GetCodeTypeForPrint";
interface ITMOCodesItem {
    code: TMOCode;
    result: TTechMapTemplateResult | undefined;
    fieldKey?: string | null;
}

const TMOCodesItem = ({ code, result, fieldKey }: ITMOCodesItem) => {
    const printerIsConnected = useSelector(techMapModel.selectPrinterIsConnected);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const printCodeClick = () => {
        if (result?.entity_uuid && fieldKey) {
            setIsLoading(true);
            getCodeType(result.entity_uuid, fieldKey)
                .then((strokeCodeType) => {
                    printCode(code.value, strokeCodeType);
                })
                .catch((e) => {
                    notificationEmit({
                        type: "error",
                        title: "Не удалось получить тип кода",
                    });
                })
                .finally(() => setIsLoading(false));
        }
    };
    return (
        <div className="tmtoc-item">
            <Code
                codeType="qrcode"
                bgColor="white"
                color="black"
                value={code.value}
                showValue
                size={100}
            />

            {result && (
                <TMTResultSelectOption
                    entityUuid={result.entity_uuid}
                    entityRowId={result.entity_row_id}
                />
            )}
            <Button
                disabled={!printerIsConnected}
                loading={isLoading}
                onClick={printCodeClick}
            >
                Печать
            </Button>
        </div>
    );
};

export default TMOCodesItem;
