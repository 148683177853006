import { Modal } from "antd";
import { ReactNode } from "react";
import { getModalWidthByUsedAction } from "../lib";
import BizProcItemEditForm from "./BizProcItemEditForm";

interface IBizProcItemEditModal {
    additionalNode?: ReactNode;
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;

    isOpen: boolean;
    onSubmit: (templateItem: TBizProcTemplateAction) => void;
    disabled?: boolean;
    submitLoading?: boolean;
    okText?: ReactNode;
    setIsOpen: (isOpen: boolean) => void;
}

const BizProcItemEditModal = ({
    templateItem,
    disabled,
    isOpen,
    okText,
    bizProcTemplate,
    additionalNode,
    onSubmit,
    setIsOpen,
}: IBizProcItemEditModal) => {
    const onCancel = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            title="Редактирование"
            open={isOpen}
            destroyOnClose
            okText={okText}
            onCancel={onCancel}
            // okButtonProps={{
            //     loading: submitLoading,
            // }}
            width={getModalWidthByUsedAction(templateItem.action)}
            footer={[]}
        >
            <BizProcItemEditForm
                onCancel={onCancel}
                onSubmit={onSubmit}
                additionalNode={additionalNode}
                templateItem={templateItem}
                bizProcTemplate={bizProcTemplate}
                disabled={disabled}
            />
        </Modal>
    );
};

export default BizProcItemEditModal;
