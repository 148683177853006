import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { ITechMapTemplate } from "../TechMapTemplateSlice";
import {
    createTechMapTemplateResultThunk,
    deleteTechMapTemplateResultThunk,
    loadTechMapTemplateResultsThunk,
    updateTechMapTemplateResultThunk,
} from "./TechMapTemplateResultThunk";

const techMapTemplateResultsLoadBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(loadTechMapTemplateResultsThunk.fulfilled, (state, action) => {
        state.techMapTemplateResults = action.payload.data;
        state.getResultLoading = false;
    });
    builder.addCase(loadTechMapTemplateResultsThunk.pending, (state, action) => {
        state.getResultLoading = true;
    });
    builder.addCase(loadTechMapTemplateResultsThunk.rejected, (state, rejectedValue) => {
        state.getResultLoading = false;
        notificationEmit({
            title: "Не удалось загрузить элемент",
            type: "error",
        });
    });
};

const techMapTemplateResultCreateBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(createTechMapTemplateResultThunk.fulfilled, (state, action) => {
        state.techMapTemplateResults = [
            action.payload.data,
            ...state.techMapTemplateResults,
        ];
        state.createResultLoading = false;
    });
    builder.addCase(createTechMapTemplateResultThunk.pending, (state, action) => {
        state.createResultLoading = true;
    });
    builder.addCase(createTechMapTemplateResultThunk.rejected, (state, rejectedValue) => {
        state.createResultLoading = false;
        notificationEmit({
            title: "Не удалось создать элемент",
            type: "error",
        });
    });
};

const techMapTemplateResultUpdateBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(updateTechMapTemplateResultThunk.fulfilled, (state, action) => {
        const id = action.meta.arg.techMapTemplateResult.id;
        state.techMapTemplateResults = updateArrByItemId(
            state.techMapTemplateResults,
            action.payload.data
        );
        state.updateResultLoading = state.updateResultLoading.filter(
            (item) => item !== id
        );
    });
    builder.addCase(updateTechMapTemplateResultThunk.pending, (state, action) => {
        state.updateResultLoading.push(action.meta.arg.techMapTemplateResult.id);
    });
    builder.addCase(updateTechMapTemplateResultThunk.rejected, (state, rejectedValue) => {
        state.updateResultLoading = state.updateResultLoading.filter(
            (item) => item !== rejectedValue.meta.arg.techMapTemplateResult.id
        );
        notificationEmit({
            title: "Не удалось обновить элемент",
            type: "error",
        });
    });
};

const techMapTemplateResultDeleteBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(deleteTechMapTemplateResultThunk.fulfilled, (state, action) => {
        const id = action.meta.arg.id;
        state.techMapTemplateResults = state.techMapTemplateResults.filter(
            (item) => item.id !== id
        );

        state.deleteResultLoading = state.deleteResultLoading.filter(
            (item) => item !== id
        );
    });
    builder.addCase(deleteTechMapTemplateResultThunk.pending, (state, action) => {
        state.deleteResultLoading.push(action.meta.arg.id);
    });
    builder.addCase(deleteTechMapTemplateResultThunk.rejected, (state, rejectedValue) => {
        state.deleteResultLoading = state.deleteResultLoading.filter(
            (item) => item !== rejectedValue.meta.arg.id
        );
        notificationEmit({
            title: "Не удалось удалить элемент",
            type: "error",
        });
    });
};

export const techMapTemplateResultBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    techMapTemplateResultsLoadBuilder(builder);
    techMapTemplateResultCreateBuilder(builder);
    techMapTemplateResultUpdateBuilder(builder);
    techMapTemplateResultDeleteBuilder(builder);
};
