import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Tooltip } from "antd";
import { ICommonPropsEditQueryActionInterface } from "../model/types";
import EditQueryActionParamsOrHeadersItem from "./EditQueryActionParamsOrHeadersItem";
import { useEditQueryActionParams } from "../model";

interface IEditQueryActionParams extends ICommonPropsEditQueryActionInterface {
    itemId: number;
    url: string;
}

const EditQueryActionParams = ({ itemId, onChange, url }: IEditQueryActionParams) => {
    const {
        onAdd,
        isDisabledAddButton,
        queryParamsKeyValues,
        onChangeQueryParamsKeyValue,
        onDelete,
    } = useEditQueryActionParams(url, onChange);

    return (
        <FormItem
            label={
                <Flex gap={4}>
                    <Button
                        icon={<PlusOutlined />}
                        type="link"
                        onClick={onAdd}
                        disabled={isDisabledAddButton}
                        style={{
                            minHeight: 0,
                            height: "fit-content",
                            padding: 0,
                            position: "sticky",
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        Добавить параметр
                    </Button>
                    <Tooltip
                        color="white"
                        overlayInnerStyle={{ color: "#838383" }}
                        title={"Некорректные параметры при сохранении будут удалены"}
                    >
                        <InfoCircleOutlined style={{ color: "var(--warning)" }} />
                    </Tooltip>
                </Flex>
            }
        >
            <Flex
                gap={"16px 8px"}
                vertical
                style={{
                    padding: 8,
                    maxHeight: 300,
                    overflow: "auto",
                    scrollbarGutter: "stable both-edges",
                }}
            >
                {queryParamsKeyValues?.map(({ key, value }, index, { length }) => (
                    <EditQueryActionParamsOrHeadersItem
                        key={length - 1 - index}
                        index={index}
                        onChangeQueryParamsKeyValue={onChangeQueryParamsKeyValue}
                        itemId={itemId}
                        onDelete={onDelete}
                        paramKey={key}
                        paramValue={value}
                        keyPlaceholder="Ключ"
                        ValuePlaceholder="Значение"
                    />
                ))}
            </Flex>
        </FormItem>
    );
};

export default EditQueryActionParams;
