import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { releaseApi } from "@entities/Production/Release";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { FormItem } from "@shared/ui/Form";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Button, Input, Space } from "antd";
import { ChangeEvent, useState } from "react";

interface IReleaseForm {
    releasedItems: TPreparingRelease;
    onClose: TEmptyFunction;
}

const ReleaseForm = ({ releasedItems, onClose }: IReleaseForm) => {
    const [loading, setLoading] = useState(false);
    const [organizationRef, setOrganizationRef] = useState(null);
    const [warehouseRef, setWarehouseRef] = useState(null);
    const [comment, setComment] = useState("");

    const onRelease = () => {
        setLoading(true);
        (releasedItems.type === "ids"
            ? releaseApi.createReleaseByItemsIds(
                  releasedItems.items_ids,
                  warehouseRef!,
                  organizationRef!,
                  comment
              )
            : releaseApi.createReleaseByFilter(
                  releasedItems.filter,
                  warehouseRef!,
                  organizationRef!,
                  comment
              )
        )
            .then((response) => {
                notificationEmit({
                    type: "success",
                    title: "Выпуск создан",
                });
                onClose();
            })
            .catch((err) => {
                notificationEmit({
                    type: "error",
                    title: "Ошибка при создании выпуска",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangeOrgRef = (value: any) => {
        setOrganizationRef(value);
    };
    const onChangeWarehouseRef = (value: any) => {
        setWarehouseRef(value);
    };
    const onChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };
    return (
        <div>
            <FormItem label="Организация">
                <SelectEntityRow
                    value={organizationRef}
                    entityInfo={`organizations?filter[condition_type]=AND&filter[conditions][0][key]=type&filter[conditions][0][condition]=%3D&filter[conditions][0][value]=organization`}
                    onChange={onChangeOrgRef}
                    idKey="ref"
                />
            </FormItem>
            <FormItem label="Склад">
                <SelectEntityRow
                    idKey="ref"
                    value={warehouseRef}
                    entityInfo={`warehouses`}
                    onChange={onChangeWarehouseRef}
                />
            </FormItem>
            <FormItem label="Комментарий">
                <Input.TextArea value={comment} onChange={onChangeComment} />
            </FormItem>
            <FlexContainer type={"row-end"}>
                <Space>
                    <Button onClick={onClose}>Отмена</Button>
                    <Button
                        disabled={!warehouseRef || !organizationRef}
                        onClick={onRelease}
                        type="primary"
                        loading={loading}
                    >
                        Выпуск
                    </Button>
                </Space>
            </FlexContainer>
        </div>
    );
};

export default ReleaseForm;
