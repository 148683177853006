import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { getCodeType } from "@shared/lib/GetCodeTypeForPrint";
import { checkConnectionAndPrintCode } from "@shared/StickerPrinter/emits";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Button } from "antd";
import { useState } from "react";
import { getCodesFromTM } from "../lib";

interface ITMFullInfoMainResults {
    techMap: TTechMapStarted;
}

const TMFullInfoMainResults = ({ techMap }: ITMFullInfoMainResults) => {
    const results = techMap.results;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const printCodeClick = (
        value: string,
        field_key: TPrinterCodeItemType | undefined,
        entity_uuid: string | undefined | null
    ) => {
        if (field_key && entity_uuid) {
            setIsLoading(true);
            getCodeType(entity_uuid, field_key)
                .then((strokeCodeType) => {
                    checkConnectionAndPrintCode(value, strokeCodeType);
                })
                .catch((e) => {
                    notificationEmit({
                        type: "error",
                        title: "Не удалось получить тип кода",
                    });
                })
                .finally(() => setIsLoading(false));
        }
    };
    return (
        <div>
            {results.length > 0 ? (
                results.map((result) => {
                    const codes = getCodesFromTM(techMap);
                    const resultCode = codes.find(
                        (code) =>
                            code.code_template.technical_map_template_result_id ===
                            result.technical_map_template_result_id
                    );
                    return (
                        <IndentContainer key={result.id}>
                            <div
                                style={{
                                    padding: 10,
                                    borderRadius: 8,
                                    backgroundColor: "var(--primary-card)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    {result.template_result && result.entity_row_id ? (
                                        <SelectEntityRow
                                            onlyRead
                                            value={result.entity_row_id}
                                            entityInfo={
                                                result.template_result.entity_uuid!
                                            }
                                            byUuid
                                            onChange={() => {}}
                                        />
                                    ) : (
                                        <>Результат не найден</>
                                    )}
                                    <span style={{ padding: "0px 3px" }}>
                                        Количество: {result.count}
                                    </span>
                                </div>
                                {resultCode && (
                                    <div>
                                        <Button
                                            loading={isLoading}
                                            onClick={() => {
                                                printCodeClick(
                                                    resultCode.value,
                                                    resultCode?.code_template?.field_key,
                                                    result?.template_result?.entity_uuid
                                                );
                                            }}
                                        >
                                            Печать кода
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </IndentContainer>
                    );
                })
            ) : (
                <>Результата нет</>
            )}
        </div>
    );
};
export default TMFullInfoMainResults;
