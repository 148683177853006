import FilterDate from "./FilterDate";

interface IReleasesFilters {
    filters: TFilterValue[];
    setFilters: (filters: TFilterValue[]) => void;
}

const ReleasesFilters = ({ filters, setFilters }: IReleasesFilters) => {
    return (
        <div>
            <FilterDate filters={filters} setFilters={setFilters} />
        </div>
    );
};

export default ReleasesFilters;
