import { Component, ErrorInfo, ReactNode } from "react";
import FallbackComponent from "./FallbackComponent";
import { notificationEmit } from "../NotificationAndMessage";
import { $api } from "@shared/api/api";
const route = "/api/log";
interface Props {
    children?: ReactNode;
    additionalInfo?: any;
    errorNode?: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error | null;
    errorInfo?: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    static getDerivedStateFromError(error: Error): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const reducedTrace = error.stack?.split("\n").slice(0, 5).join("\n");
        this.setState({ ...this.state, error, errorInfo });
        $api.post(route, {
            status: "error",
            type: error.name,
            message: error.message,
            trace: reducedTrace,
        }).then(() => {
            notificationEmit({ type: "info", title: "Сообщение об ошибке отправлено" });
        });
    }

    resetError = () => {
        this.setState({ hasError: false, error: null, errorInfo: null });
    };

    render() {
        if (this.state.hasError) {
            return (
                <FallbackComponent
                    error={this.state.error}
                    resetErrorBoundary={this.resetError}
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
