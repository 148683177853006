import { entityApi } from "@entities/Portal/Entity";
import { useEffect, useState } from "react";

export const useLoadEntity = (
    entityId: number,
    entityLoadedAdditionalAction?: (entity: TEntity) => void
) => {
    const [entity, setEntity] = useState<TEntity | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        entityApi
            .getEntity(entityId)
            .then((response) => {
                const entity = response.data.data;
                setEntity(entity);
                entityLoadedAdditionalAction && entityLoadedAdditionalAction(entity);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [entityId]);

    return { entity, loading, setEntity };
};
