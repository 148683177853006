import { actionModel } from "@entities/Portal/Action";
import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { BizProcTemplateEditor } from "@features/Portal/BizProcTemplate/BizProcTemplateEditor";
import { setDocumentTitle } from "@shared/lib/document";
import { useHeaderLayout } from "@shared/ui/Header";
import { Spin } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

interface IBizProcEditorPage {}

const BizProcEditorPage = (props: IBizProcEditorPage) => {
    const params = useParams();
    const bizprocId = params.bizprocId as string;
    const entityId = params.entityId as string;
    const { bizProcTemplateItem, loading } =
        bizProcTemplateModel.useLoadBizProcTemplateItem(+bizprocId, true, true);
    useHeaderLayout({
        title: "Редактор бизнес-процессов",
    });
    useEffect(() => {
        if (bizProcTemplateItem)
            setDocumentTitle(`Редактор БП (${bizProcTemplateItem?.name})`);
    }, [bizProcTemplateItem?.name]);

    actionModel.useLoadActions(true);
    if (loading)
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                }}
            >
                <Spin />
            </div>
        );
    if (bizProcTemplateItem === null) return <></>;

    return (
        <div style={{ width: "100%", height: "90%" }}>
            <BizProcTemplateEditor
                entityId={+entityId}
                initialBizProcTemplateItem={bizProcTemplateItem}
            />
        </div>
    );
};

export default BizProcEditorPage;
