import { SignInFormLogo } from "@features/Portal/Auth/SignInForm";
import { Typography } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./RecoverPasswordPage.scss";
import { RecoverPasswordForm } from "@features/Portal/Auth/RecoverPasswordForm";

interface IRecoverPasswordPage {}

const RecoverPasswordPage = ({}: IRecoverPasswordPage) => {
    const navigate = useNavigate();
    const currentURL = useLocation();
    const queryParams = new URLSearchParams(currentURL.search);
    const code = queryParams.get("code");

    useEffect(() => {
        if (!code) {
            navigate("/start-recover-password");
        }
    }, []);

    return (
        <div className="recover-page">
            <Typography.Title level={4}>Восстановление пароля</Typography.Title>
            <div className="recover-page__form">
                <SignInFormLogo />
                {code && <RecoverPasswordForm code={code} />}
            </div>
        </div>
    );
};

export default RecoverPasswordPage;
