import { ActionListItem } from "@entities/Portal/Action";
import React, { ReactNode } from "react";
import { useActionListItem } from "../model";
interface IActionNormalItem {
    action: TAction;
    exampleRender?: ReactNode;
}
const ActionNormalItem = ({ action, exampleRender }: IActionNormalItem) => {
    const {
        onSave,
        onDelete,
        isEditing,
        deleteLoading,
        error,
        onCloseCustom,
        setIsEditing,
    } = useActionListItem(action);

    return (
        <div>
            <ActionListItem
                error={error}
                onCloseCustom={onCloseCustom}
                deleteLoading={deleteLoading}
                onSave={onSave}
                exampleRender={exampleRender}
                onDelete={onDelete}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                action={action}
            />
        </div>
    );
};

export default ActionNormalItem;
