export const LOGS_IFRAME_ENDPOINT = "sys/logs";
export const EXTERNAL_LINK_VALIDATION_REGEXP =
    /^https?:\/\/([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.\D-]*)*$/;
export const PORTAL_URL_VALIDATION_REGEXP =
    /^\/([\da-zA-Z-][^\.]*|[\da-zA-Z-])(\/[^\.]*)*$/;

export const VALID_PARSING_OBJECTS_KEY_WITH_VARIABLES =
    /^(?!.*\.\.)(?!.*\.$)(?![^{]*}})(?!.*\{\{[^}]*$)[a-zA-Z0-9\-_.:*$&]+({\{[a-zA-Z0-9\-_.:*$&]*\}\})?[a-zA-Z0-9\-_.:*$&]*$/;
export const VALID_EXTERNAL_LINK_WITH_VARIABLES =
    /^(https?:\/\/)(?!.*\.\.)(?!.*\.$)(?![^{]*}})(?!.*\{\{[^}]*$)[a-zA-Z0-9\-_.:%?=&/]*({\{[a-zA-Z0-9:%\-_.?=&/]*\}\})?[a-zA-Z0-9\-_.:%?=&/]*$/;

export const VALUE_WITH_VARIABLES_CHECK_REGEX = /{{.*?}}/;
