import { dbClearTableInAllStores, dbDropStores } from "@shared/lib/indexDb";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { notDropingStores } from "./constants";

export const useDropStores = () => {
    const location = useLocation();
    const lastLocation = useRef("");
    useEffect(() => {
        return () => {
            if (lastLocation.current !== location.pathname)
                dbClearTableInAllStores("data", notDropingStores);
            lastLocation.current = location.pathname;
        };
    }, [location.pathname]);
};
