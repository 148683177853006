import { getTimePickerFormat } from "@shared/lib/date";
import { FormReadValue } from "@shared/ui/Form";
import { DatePicker } from "antd";
interface ITimeInput {
    value: string;
    onChangeValue: (value: string) => void;
    onlyRead?: boolean;
    disabled?: boolean;
}

const TimeInput = ({ value, onlyRead, onChangeValue, disabled }: ITimeInput) => {
    const onChange = (value: any | null, dateString: string) => {
        onChangeValue(dateString);
    };
    if (onlyRead) return <FormReadValue value={value ?? "-"} />;
    return (
        <DatePicker
            disabled={disabled}
            style={{ width: 150 }}
            format={"HH:mm:ss"}
            value={getTimePickerFormat(value)}
            picker="time"
            showSecond
            changeOnBlur
            // placeholder={field.name}
            onChange={onChange}
        />
    );
};

export default TimeInput;
