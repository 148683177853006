import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "./lib";
import { userApi } from "@entities/Portal/User";

export const useStartRecoverPasswordForm = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setError(null);
    };
    const onClickBack = () => {
        navigate("/login");
    };
    const onSubmit = () => {
        setError(null);
        const isValid = validateEmail(email);
        if (!isValid) {
            setError("Необходимо указать корректный E-mail");
            return;
        }
        setLoading(true);
        userApi
            .startRecoverPassword(email)
            .then((response) => {
                setIsSuccess(true);
            })
            .catch((error) => {
                console.log("error", error);

                setError(error?.response?.data?.message ?? "Неизвестная ошибка");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return {
        email,
        loading,
        error,
        isSuccess,
        onChangeEmail,
        onClickBack,
        onSubmit,
    };
};
