import { Button } from "antd";
import { useState } from "react";
import ReleaseModal from "./ReleaseModal";

interface IReleaseItemsSendButton {
    releasedItems: TPreparingRelease;
    buttonText?: string;
}

const ReleaseItemsSendButton = ({
    releasedItems,
    buttonText = "Отправить",
}: IReleaseItemsSendButton) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => {
        setIsOpen(false);
    };
    const onClick = () => {
        setIsOpen(true);
    };

    return (
        <>
            <Button type="link" onClick={onClick}>
                {buttonText}
            </Button>
            <ReleaseModal
                releasedItems={releasedItems}
                isOpen={isOpen}
                onClose={onClose}
            />
        </>
    );
};

export default ReleaseItemsSendButton;
