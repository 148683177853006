import SelectReleasesGroups from "./SelectReleasesGroups";
import { useReleases } from "../model";
import "./Releases.scss";
import { ReleasesTable } from "@features/Production/ReleaseFeatures/ReleasesTable";
import { ReleasesFilters } from "@features/Production/ReleaseFeatures/ReleasesFilters";
import { ReleaseItemsTable } from "@features/Production/ReleaseItemFeatures/ReleaseItemsTable";
interface IRelease {}

const Releases = ({}: IRelease) => {
    const { groups, filters, setGroups, setFilters } = useReleases();
    return (
        <>
            <div className="release-items-header">
                <div style={{ marginRight: 10 }}>
                    <ReleasesFilters filters={filters} setFilters={setFilters} />
                </div>
                <SelectReleasesGroups groups={groups} setGroups={setGroups} />
            </div>
            <ReleasesTable
                releaseItemsTable={(release: TRelease) => {
                    return (
                        <ReleaseItemsTable
                            hideReleaseButton
                            level={0}
                            additionalFilters={[
                                {
                                    condition: "=",
                                    value: release.id,
                                    key: "production_release_id",
                                },
                            ]}
                        />
                    );
                }}
                additionalFilters={filters}
                groups={groups}
                level={0}
            />
        </>
    );
};

export default Releases;
