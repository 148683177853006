import { TEditQueryActionValue, TParseRules } from "../model/types";

export const useEditQueryAction = (
    bizProcTemplate: TBizProcTemplateItem,
    templateItem: TBizProcTemplateAction,
    setItem: (
        templateItem: Omit<TBizProcTemplateAction, "parameters"> & {
            parameters: TEditQueryActionValue;
        }
    ) => void
) => {
    const parametersValue = templateItem.parameters as TEditQueryActionValue;

    const itemId = bizProcTemplate.id!;

    const onChange = (
        name: keyof TEditQueryActionValue,
        value: string | null | Record<string, string>[]
    ) => {
        setItem({
            ...templateItem,
            parameters: {
                ...parametersValue,
                [name]: value,
            },
        });
    };
    const onChangeParseRules = (newParseRules: TParseRules) => {
        setItem({
            ...templateItem,
            parameters: { ...parametersValue, parse_rules: newParseRules },
        });
    };

    return {
        parametersValue,
        itemId,
        onChange,
        onChangeParseRules,
    };
};
