import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { FormItem } from "@shared/ui/Form";
import { Button, Checkbox, Input, Select } from "antd";
import { tmtoUseActionsOptions } from "../../constants";
import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { useTMTONItemUseAction } from "../../model";
import { EntityFieldSelect } from "@entities/Portal/EntityField";
import { FormInputItem } from "@entities/Portal/Datatype";
import "./TMTONItemUseActions.scss";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { EntitySelect } from "@entities/Portal/Entity";
interface ITMTONItemUseAction {
    action: TONItemUseAction | TONItemUseActionCreating;
    onDelete: (id: string | number) => void;
    onChange: (action: TONItemUseAction | TONItemUseActionCreating) => void;
}

// id: number;
// tmto_necessary_item_uuid: string;
// entity_uuid: string | null;
// entity_field_key: string | null;
// entity_row_id: number | null;
// is_calculation: boolean;
// value: string | number | null;

const TMTONItemUseAction = ({ action, onDelete, onChange }: ITMTONItemUseAction) => {
    const {
        entityField,
        onClickDelete,
        onChangeType,
        onChangeEntityRowId,
        onChangeEntityFieldKey,
        onChangeValue,
        toggleIsCalculation,
        onChangeAction,
        onChangeEntityUuid,
    } = useTMTONItemUseAction({
        action,
        onDelete,
        onChange,
    });

    return (
        <div className="tmtoni-use-action">
            <FlexContainer type="row-end">
                <Button onClick={onClickDelete} danger type="link">
                    Удалить
                </Button>
            </FlexContainer>
            <FormItem label="Время действия">
                <Select
                    onChange={onChangeType}
                    allowClear={false}
                    style={{ width: "100%" }}
                    options={tmtoUseActionsOptions}
                    value={action.type}
                />
            </FormItem>
            {action.entity_uuid && (
                <>
                    <FormItem label="Какой элемент изменить">
                        <EntitySelect
                            multiple={false}
                            value={action.entity_uuid}
                            onChangeSelect={onChangeEntityUuid}
                        />
                    </FormItem>
                    <FormItem label="Какой элемент изменить">
                        <SelectEntityRow
                            byUuid
                            value={action.entity_row_id}
                            entityInfo={action.entity_uuid}
                            onChange={onChangeEntityRowId}
                        />
                    </FormItem>
                    <FormItem label="Какой поле изменить">
                        <EntityFieldSelect
                            fieldKey={action.entity_field_key}
                            entityUuid={action.entity_uuid}
                            onChange={onChangeEntityFieldKey}
                        />
                    </FormItem>
                    {!action.is_calculation ? (
                        entityField && (
                            <FormInputItem
                                field={entityField}
                                additionalParameters={{}}
                                datatype={entityField.datatype as unknown as string}
                                value={action.value}
                                onlyRead={false}
                                onChange={onChangeValue}
                            />
                        )
                    ) : (
                        <div style={{ position: "relative" }}>
                            <InputWithVariables
                                itemId={action.entity_uuid}
                                disabled={false}
                                type="entity"
                                isTextArea={true}
                                onChange={onChangeAction}
                                name="value"
                                value={(action.value ?? "").toString()}
                            />
                        </div>
                    )}

                    <Checkbox
                        onClick={toggleIsCalculation}
                        checked={Boolean(action.is_calculation)}
                    >
                        Вычисляемое значение
                    </Checkbox>
                </>
            )}
        </div>
    );
};

export default TMTONItemUseAction;
