import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { messageEmit } from "@shared/ui/NotificationAndMessage/model/emits";
import { useSelector } from "react-redux";
import { isValuePartOfBizProcTemplate } from "../lib";

export const useBizProcTemplateItemCopyPasteButtons = (
    type: "copy" | "paste",
    templateItem: TBizProcTemplateAction,
    pasteCopiedBlockOfActions?: (
        value: TBizProcTemplateAction,
        passParentKey?: boolean
    ) => void,
    passParentKey?: boolean,
    onDeleteTemplateItem?: (deletedTemplateItem: TBizProcTemplateAction) => void
) => {
    const isCopyingMovingMode = useSelector(
        bizProcTemplateModel.selectIsCopyingMovingMode
    );
    const copyValue = async () => {
        const copiedDataPrepared = JSON.stringify(templateItem);
        try {
            await navigator.clipboard.writeText(copiedDataPrepared);
            messageEmit({
                type: "info",
                content: type === "copy" ? "Блок скопирован" : "Блок вставлен",
            });
        } catch (error) {
            console.error("Не удалось скопировать значение", error);
            messageEmit({ type: "error", content: "Не удалось скопировать значение" });
        }
    };

    const pasteValue = async () => {
        try {
            const copiedValue = await navigator.clipboard.readText();
            const parsedCopiedValue = JSON.parse(copiedValue);
            if (isValuePartOfBizProcTemplate(parsedCopiedValue)) {
                pasteCopiedBlockOfActions &&
                    pasteCopiedBlockOfActions(parsedCopiedValue, passParentKey);
            } else {
                messageEmit({
                    type: "error",
                    content: "Скопированное значение не является частью бизнес-процесса",
                });
            }
        } catch (error) {
            messageEmit({
                type: "error",
                content: "Не удалось вставить скопированное значение",
            });
            console.error("Не удалось вставить данные", error);
        }
    };

    const cutValue = async () => {
        try {
            await copyValue();
            onDeleteTemplateItem && onDeleteTemplateItem(templateItem);
        } catch (error) {
            console.log("Не удалось вырезать блок:", error);
        }
    };

    const preventDefaultAndStopPropagation = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onPasteClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        preventDefaultAndStopPropagation(e);
        pasteValue();
    };
    const onCopyClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        preventDefaultAndStopPropagation(e);
        copyValue();
    };
    const onCutClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        preventDefaultAndStopPropagation(e);
        cutValue();
    };

    return {
        isCopyingMovingMode,
        onPasteClick,
        onCopyClick,
        onCutClick,
    };
};
