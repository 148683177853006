import { Bar } from "@shared/ui/Bar";
import { ITechMapTemplatesFilters } from "@widgets/Production/TechMapTemplatesFilters/TechMapTemplatesFilters";
import { Flex, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTMTBar } from "../model";

interface ITMTBar {
    filtersWidget: (props: ITechMapTemplatesFilters) => React.ReactNode;
}

const TMTBar = ({ filtersWidget }: ITMTBar) => {
    const {
        loadTechMapTemplatesWithPagination,
        paginationIsEnded,
        isPaginationLoading,
        onChange,
        resetFilters,
        submitFilters,
        filters,
        options,
        loading,
        startingLoadingItemsIds,
        activeItemsIds,
        searchValue,
        onClickItem,
    } = useTMTBar();
    const [isScrollToBottom, setIsScrollToBottom] = useState<boolean>(false);
    const containerOfTMT = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isPaginationLoading && isScrollToBottom) {
            if (containerOfTMT.current)
                containerOfTMT.current!.scrollTop = containerOfTMT.current!.scrollHeight;
            setIsScrollToBottom(false);
        }
    }, [isPaginationLoading]);

    const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        setIsScrollToBottom(true);
        //@ts-ignore
        const { scrollHeight, scrollTop, clientHeight } = event.nativeEvent.target;
        const isBottomReached = scrollHeight - Math.round(scrollTop) === clientHeight;
        if (isBottomReached && !paginationIsEnded) {
            loadTechMapTemplatesWithPagination(options[options.length - 1].value);
        }
    };

    return (
        <>
            <Bar
                searchValue={searchValue}
                label={
                    <Flex align="center" vertical gap={2}>
                        Доступные процессы
                        {filtersWidget({
                            onChange,
                            resetFilters,
                            submitFilters,
                            filters,
                            isModal: true,
                        })}
                    </Flex>
                }
                onClick={onClickItem}
                containerOfOptionsRef={containerOfTMT}
                onScrollContainerOfOptions={onScroll}
                loading={loading}
                isBottomReachedNode={
                    isPaginationLoading && (
                        <div className="column-spinner">
                            <Spin />
                        </div>
                    )
                }
                loadingValues={startingLoadingItemsIds}
                activeValues={activeItemsIds}
                options={options}
            />
        </>
    );
};

export default TMTBar;
