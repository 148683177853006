import { emitter } from "@shared/emitter";
import { useEffect, useRef, useState } from "react";
import { generateLocation, getDrawerManagerItem, parseOriginalLocation } from "../lib";
import { DRAWER_MANAGER_EMIT } from "../constants";
import { useLocation } from "react-router-dom";
//TODO Refactor (BP remove)
export const useDrawerManager = (patterns: TDrawerPattern[]) => {
    const [openedDrawers, setOpenedDrawers] = useState<TDrawerManagerItemPrepared[]>([]);
    const location = useLocation();

    const originalLocation = useRef(parseOriginalLocation(patterns, location.pathname));
    const addOpenedDrawerItem = (item: TDrawerManagerItemPrepared) => {
        setOpenedDrawers((prev) => {
            return [...prev, item];
        });
    };

    const handleEvent = (drawerManagerItem: TDrawerManagerItem) => {
        const newLocation = generateLocation(drawerManagerItem);

        const newItem = {
            ...drawerManagerItem,
            isOpen: true,
            location: newLocation,
        };

        const addedLocation = openedDrawers.find(
            (item) => item.location === newItem?.location
        );

        if (
            addedLocation &&
            openedDrawers.length > 0 &&
            openedDrawers?.[openedDrawers.length - 1]?.location === newLocation
        )
            return;
        if (addedLocation) {
            setOpenedDrawers((drawers) => {
                return drawers.filter((item) => item.location !== newItem.location);
            });
        }

        window.history.replaceState(null, "", newLocation);
        setTimeout(() => {
            addOpenedDrawerItem(newItem as any as TDrawerManagerItemPrepared);
        });
    };

    const closeDrawer = (drawerItem: TDrawerManagerItemPrepared) => {
        setOpenedDrawers((prev) => {
            const newDrawerItems = prev.map((item) => {
                if (item.location === drawerItem.location) {
                    setTimeout(() => {
                        setOpenedDrawers((prev) =>
                            prev.filter((item) => item.location !== drawerItem.location)
                        );
                    }, 300);
                    return {
                        ...item,
                        isOpen: false,
                    };
                }
                return item;
            });
            const activeDrawerItems = newDrawerItems.filter((item) => item.isOpen);
            if (activeDrawerItems.length > 0) {
                const newItem = activeDrawerItems[activeDrawerItems.length - 1];
                const newLocation = generateLocation(newItem);

                window.history.replaceState(null, "", newLocation);
            } else {
                window.history.replaceState(null, "", originalLocation.current);
            }

            return newDrawerItems;
        });
    };

    useEffect(() => {
        const pattern = patterns.find((pattern) =>
            location.pathname.includes(`${pattern.pattern}/`)
        );
        if (pattern) {
            const patternLocation = getDrawerManagerItem(patterns, location.pathname);
            if (!patternLocation) {
                originalLocation.current = parseOriginalLocation(
                    patterns,
                    location.pathname
                );
                return;
            }

            const addedLocation = openedDrawers.find(
                (item) => item.location === patternLocation?.location
            );

            if (addedLocation) return;
            if (openedDrawers.length === 0) {
                originalLocation.current = parseOriginalLocation(
                    patterns,
                    location.pathname
                );
            }

            addOpenedDrawerItem(patternLocation);
        } else {
            originalLocation.current = parseOriginalLocation(patterns, location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        emitter.on(DRAWER_MANAGER_EMIT, handleEvent);
        return () => {
            emitter.removeListener(DRAWER_MANAGER_EMIT, handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedDrawers.length]);

    return {
        openedDrawers,
        closeDrawer,
    };
};
