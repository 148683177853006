import {
    bizProcTemplateApi,
    bizProcTemplateModel,
} from "@entities/Portal/BizProcTemplate";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useXarrow } from "react-xarrows";
import { checkDraggingItemIsChildOfTargetTemplateItem } from "../lib";
import { backgroundOfDraggingItem } from "../constants";
import { messageEmit } from "@shared/ui/NotificationAndMessage/model/emits";

export const useBizProcEditor = (
    entityId: number,
    initialBizProcTemplateItem: TBizProcTemplateItem
) => {
    const [bizProcTemplate, setBizProcTemplate] = useState<TBizProcTemplate>(
        JSON.parse(JSON.stringify(initialBizProcTemplateItem.template ?? []))
    );
    const [name, setName] = useState(initialBizProcTemplateItem.name);
    const [version, setVersion] = useState(initialBizProcTemplateItem.version);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();
    const updateXArrows = useXarrow();
    const dispatch = useDispatch();
    const isCopyingMovingMode = useRef<boolean>(false);
    useEffect(() => {
        document.body.addEventListener("keydown", pressCtrl);
        document.body.addEventListener("keyup", unpressCtrl);
        return () => {
            document.body.removeEventListener("keydown", pressCtrl);
            document.body.removeEventListener("keyup", unpressCtrl);
        };
    }, []);
    const onChangeVersion = (version: string) => {
        setVersion(version);
        bizProcTemplateApi
            .getBizProcTemplate(initialBizProcTemplateItem.id!, true, version)
            .then((response) => {
                setBizProcTemplate(response.data.data.template!);
            })
            .catch((error: any) => {});
    };
    const onSave = () => {
        setLoading(true);
        if (initialBizProcTemplateItem.id) {
            bizProcTemplateApi
                .updateBizProcEntityTemplateItem({
                    ...initialBizProcTemplateItem,
                    name: name,
                    template: bizProcTemplate,
                })
                .then((response) => {
                    navigation(`/entity/${entityId}?tab=templates`);
                })
                .catch((e) => {
                    notificationEmit({
                        title:
                            e.response?.data?.message ?? "Не удалось сохранить изменения",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            bizProcTemplateApi
                .createBizProcTemplateItem({
                    category_uuid: initialBizProcTemplateItem.category_uuid,
                    entity_uuid: initialBizProcTemplateItem.entity_uuid,
                    name: name,
                    template: bizProcTemplate,
                })
                .then((response) => {
                    navigation(`/entity/${entityId}?tab=templates`);
                })
                .catch(() => {
                    notificationEmit({
                        title: "Не удалось создать бизнес-процесс",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleScroll = (event: any) => {
        updateXArrows();
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeTemplate = (bizProcTemplate: TBizProcTemplate) => {
        setTimeout(() => {
            updateXArrows();
        });
        setBizProcTemplate(bizProcTemplate);
    };
    const archerRef = useRef() as any;

    const onChangeTemplateItem = (templateItem: TBizProcTemplateAction) => {
        const newTemplate = bizProcTemplate.map((item) =>
            item.key === templateItem.key ? templateItem : item
        );
        updateXArrows();
        onChangeTemplate(newTemplate);
        // archerRef.current.refreshScreen();
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const disabled = version !== initialBizProcTemplateItem.version;

    const pressCtrl = (event: KeyboardEvent) => {
        if (event.ctrlKey) {
            if (!isCopyingMovingMode.current) {
                isCopyingMovingMode.current = true;
                dispatch(bizProcTemplateModel.setIsCopyingMovingMode(true));
            }
        }
    };

    const unpressCtrl = (event: KeyboardEvent) => {
        if (event.key === "Control") {
            isCopyingMovingMode.current = false;
            dispatch(bizProcTemplateModel.setIsCopyingMovingMode(false));
        }
    };

    const isDeleteDraggedItem = useRef<boolean>(false);
    const onDrop = (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction,
        pasteCopiedBlockOfActions: (
            item: TBizProcTemplateAction,
            passParentKey?: boolean
        ) => void,
        isCopyingMovingMode: boolean,
        passParentKey?: boolean
    ) => {
        event.stopPropagation();
        isDeleteDraggedItem.current = true;
        const data = event.dataTransfer.getData("text/plain");
        const draggingItem = JSON.parse(data);
        if (!isCopyingMovingMode) {
            if (
                checkDraggingItemIsChildOfTargetTemplateItem(draggingItem, templateItem)
            ) {
                isDeleteDraggedItem.current = false;
                messageEmit({
                    type: "error",
                    content: "Родительский блок невозможно перенести в дочерний",
                });
                return;
            } else {
                isDeleteDraggedItem.current = true;
            }
        }
        pasteCopiedBlockOfActions(draggingItem, passParentKey);
    };

    const onDragStart = (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction
    ) => {
        event.stopPropagation();
        const element = event.target as HTMLElement;
        element.style.borderRadius = "20px";
        element.style.backgroundImage = backgroundOfDraggingItem;
        let img = new Image();
        event.dataTransfer.setDragImage(img, 0, 0);
        event.dataTransfer.setData("text/plain", JSON.stringify(templateItem));
    };
    const onDragEnd = (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction,
        deleteFunction: (item: TBizProcTemplateAction) => void,
        isCopyingMovingMode_?: boolean
    ) => {
        event.stopPropagation();
        const element = event.target as HTMLElement;
        element.style.borderRadius = "0";
        element.style.backgroundImage = "none";
        dispatch(bizProcTemplateModel.setIsCopyingMovingMode(false));
        isCopyingMovingMode.current = false;
        if (!["copy", "move"].includes(event.dataTransfer.dropEffect)) {
            return;
        }
        if (!isCopyingMovingMode_) {
            isDeleteDraggedItem.current && deleteFunction(templateItem);
        }
    };

    return {
        name,
        loading,
        archerRef,
        bizProcTemplate,
        version,
        disabled,
        onSave,
        onChangeName,
        onChangeVersion,
        onChangeTemplateItem,
        onChangeTemplate,
        onDrop,
        onDragStart,
        onDragEnd,
    };
};
