import { Checkbox } from "antd";

interface IFilterRelease {
    filters: TFilterValue[];
    setFilters: (filters: TFilterValue[]) => void;
}

const FilterRelease = ({ filters, setFilters }: IFilterRelease) => {
    const condition = filters.find((filter) => filter.key === "production_release_id");

    const onClick = () => {
        const isHave = Boolean(condition);
        const newFilters = filters.filter(
            (filter) => filter.key !== "production_release_id"
        );

        if (!isHave)
            newFilters.push({
                value: "null",
                condition: "null",
                key: "production_release_id",
            });

        setFilters(newFilters);
    };
    return (
        <Checkbox
            style={{ textWrap: "nowrap" }}
            checked={Boolean(condition)}
            onClick={onClick}
        >
            Только без выпуска
        </Checkbox>
    );
};

export default FilterRelease;
