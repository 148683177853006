import { LOCATION_FILE_VIEWER_PATTERN } from "@entities/Portal/EntityField";
import { apiUrl } from "@shared/api/api";
import { useDebounceTooltipOpenEffect } from "@shared/hooks/DebounceTooltipOpenEffect";
import { openDrawer } from "@shared/ui/DrawerManager";
import { FileUpload } from "@shared/ui/FileUpload";
import { IFileUpload } from "@shared/ui/FileUpload/FileUpload";
import { ReducedDataEffect } from "@shared/ui/ReducedDataEffect";
import { Tooltip, Upload, message } from "antd";

type FileType = any;

const beforeUpload = (file: FileType) => {
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
        message.error("Файл должен быть менее 50MB!");
    }
    return isLt2M || Upload.LIST_IGNORE;
};

interface IFilesInput {
    value: any;
    onChangeValue: (value: string) => void;
    onlyRead?: boolean;
    field: TEntityField | TVariable;
    additionalParameters: TFileAdditionalParameters;
    isReduceValue?: boolean;
}

const FilesInput = ({
    value,
    onChangeValue,
    onlyRead,
    field,
    additionalParameters,
    isReduceValue,
}: IFilesInput) => {
    const maxFilesCount = 2;
    const fileList =
        (field.is_multiple
            ? value
            : value
              ? Array.isArray(value)
                  ? value
                  : [value]
              : []
        )?.map((item: any) => {
            return {
                url: apiUrl + "/api/file?link=" + item.link,
                xhr: {
                    headers: {
                        authorization: "sdfsadsfd",
                    },
                },
                name: item.filename,
                status: item.status ?? "done",
                ...item,
            };
        }) ?? [];
    const { tooltipInnerStyle, isOpen, handleClick, handleDoubleClick } =
        useDebounceTooltipOpenEffect(fileList?.length, maxFilesCount, isReduceValue);

    const onPreviewFile = (file: any) => {
        openDrawer({
            //@ts-ignore
            id: file.id,
            //@ts-ignore
            name: file.link,
            pattern: LOCATION_FILE_VIEWER_PATTERN,
            additional: { fileName: file.name },
        });
    };

    const onChangeFileList = (fileList: any) => {
        const fileListFiltered = fileList?.filter(
            (file: any) => file.status !== "removed"
        );

        onChangeValue(
            field.is_multiple ? fileListFiltered : fileListFiltered?.[0] ?? null
        );
    };

    if (!fileList) return <>-</>;

    const baseProps: IFileUpload = {
        uploadUrl: `${apiUrl}/api/file/${field.variable_type}/${field.id}`,
        smallFormat: additionalParameters?.fileSmallFormat,
        beforeUpload,
        onPreviewFile,
        maxFiles: field.is_multiple ? 10 : 1,
        onChangeFileList,
        onlyRead,
        initialFileList:
            isReduceValue && fileList.length > 3 ? fileList.slice(0, 3) : fileList ?? [],
    };
    return (
        <Tooltip
            color="white"
            overlayInnerStyle={tooltipInnerStyle}
            zIndex={900}
            getPopupContainer={(trigger) => trigger.parentElement!}
            open={!!isReduceValue && fileList.length > maxFilesCount && isOpen}
            title={
                <div
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    <FileUpload
                        {...baseProps}
                        onlyRead={true}
                        initialFileList={fileList}
                        multiple={field.is_multiple}
                    />
                </div>
            }
        >
            <div
                className="reduced"
                style={
                    isReduceValue && fileList.length > maxFilesCount
                        ? { position: "relative", height: 88, overflow: "hidden" }
                        : {}
                }
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
            >
                {isReduceValue && fileList.length > maxFilesCount && (
                    <ReducedDataEffect />
                )}
                <FileUpload
                    multiple={field.is_multiple}
                    {...baseProps}
                    isDisabledTableListHoverActions={
                        !!isReduceValue && fileList.length > maxFilesCount
                    }
                    initialFileList={
                        isReduceValue && fileList.length > 3
                            ? fileList.slice(0, 3)
                            : fileList ?? []
                    }
                />
            </div>
        </Tooltip>
    );
};

export default FilesInput;
