import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";
import { Button, Tag } from "antd";
import { MouseEvent } from "react";
import "./TMTONecessaryItems.scss";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
interface ITMTONecessaryItem {
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating;
    editingMode?: boolean;
    onEdit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
    onDelete: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
}

const TMTONecessaryItem = ({
    item,
    editingMode,
    onEdit,
    onDelete,
}: ITMTONecessaryItem) => {
    const onClickEdit = () => {
        onEdit(item);
    };
    const onClickDelete = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onDelete(item);
    };
    return (
        <CardWithAvatar
            hideAvatar
            onClick={editingMode ? onClickEdit : undefined}
            header={
                <FlexContainer type="row-start">
                    <IndentContainer type={["marginRight"]}>{item.label}</IndentContainer>
                </FlexContainer>
            }
            description={
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <span>Количество:</span>
                            <span style={{ marginLeft: 5 }}>{item.count ?? 1}</span>
                        </div>
                        {item.type === "filling" && (
                            <div style={{ marginLeft: 10 }}>
                                <Tag color="gold">Учитываемый</Tag>
                            </div>
                        )}
                    </div>
                    {editingMode && (
                        <div>
                            <Button
                                size="small"
                                type="link"
                                danger
                                style={{ padding: 0, minHeight: 20, marginRight: 15 }}
                                icon={<DeleteOutlined />}
                                onClick={onClickDelete}
                            >
                                Удалить
                            </Button>

                            <Button
                                style={{ padding: 0, minHeight: 20 }}
                                size="small"
                                type="link"
                                icon={<EditOutlined />}
                                onClick={onClickEdit}
                            >
                                Редактировать
                            </Button>
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default TMTONecessaryItem;
