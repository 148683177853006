import { useDispatch } from "react-redux";
import ProductionTabs from "./ProductionTabs";

interface IProducationStatsPage {}

const ProducationStatsPage = ({}: IProducationStatsPage) => {
    const dispatch = useDispatch();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
            }}
        >
            <ProductionTabs />
        </div>
    );
};

export default ProducationStatsPage;
