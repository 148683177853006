import { Input, Skeleton } from "antd";
import { IndentContainer } from "../Containers/IndentContainer";
import "./Bar.scss";
import BarItem from "./BarItem";
import { ChangeEvent, ReactNode } from "react";
interface IBar<T> {
    label?: ReactNode | string;
    loading?: boolean;
    options: TOptionWithItem<T>[];
    activeValues?: (number | string)[];
    loadingValues?: (number | string)[];
    searchValue?: string;
    onSearch?: (searchValue: string) => void;
    onClick: (item: T) => void;
    containerOfOptionsRef?: React.RefObject<HTMLDivElement>;
    onScrollContainerOfOptions?: (item: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    isBottomReachedNode?: React.ReactNode;
}

const Bar = <T,>({
    label,
    loading,
    options,
    activeValues,
    loadingValues,
    searchValue,
    onSearch,
    onClick,
    containerOfOptionsRef,
    onScrollContainerOfOptions,
    isBottomReachedNode,
}: IBar<T>) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onSearch && onSearch(event.target.value);
    };
    return (
        <div className="bar">
            {label && (
                <div className="bar__header">
                    <span className="bar__header__label">{label}</span>
                </div>
            )}
            <div
                className="bar__content"
                onScroll={onScrollContainerOfOptions}
                ref={containerOfOptionsRef}
            >
                {loading ? (
                    <div className="bar__content__skeleton">
                        <Skeleton
                            active
                            title={false}
                            paragraph={{ rows: 5, width: "100%" }}
                        />
                    </div>
                ) : (
                    <div className="bar__content__items">
                        {onSearch && (
                            <div
                                style={{
                                    padding: "5px 5px",
                                    position: "sticky",
                                    top: 0,
                                    left: 0,
                                    background: "white",
                                }}
                            >
                                <Input
                                    value={searchValue}
                                    onChange={onChange}
                                    bordered={false}
                                    placeholder="Поиск..."
                                    style={{ width: "100%" }}
                                />
                            </div>
                        )}
                        {options.map((option) => {
                            const isActive = activeValues?.includes(option.value);
                            const isLoading = loadingValues?.includes(option.value);
                            return (
                                <IndentContainer key={option.value}>
                                    <BarItem
                                        active={isActive}
                                        loading={isLoading}
                                        option={option}
                                        onClick={onClick}
                                    />
                                </IndentContainer>
                            );
                        })}
                        {isBottomReachedNode && isBottomReachedNode}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Bar;
