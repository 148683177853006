import { entityRowModel } from "@entities/Portal/EntityRow";
import { theme } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEntityFieldWithFilter } from "../lib";
import { useWindowResize } from "@shared/lib/UseWindowResize";

export const useCurrentActiveFiltersValues = () => {
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const filters = useSelector(entityRowModel.selectFilters);
    const mainTheme = theme.useToken();
    const currentCategory = useSelector(entityRowModel.selectCurrentCategory);
    const isDisabledCurrentCategorySelect = useSelector(
        entityRowModel.selectIsDisabledCurrentCategorySelect
    );
    const [activeFiltersValues, setActiveFiltersValues] = useState<
        (TBizProcFormField & {
            condition?: TComparisonType | null;
        })[]
    >([]);

    useEffect(() => {
        setActiveFiltersValues(
            getEntityFieldWithFilter(filters ?? [], entityFields).filter((item) =>
                currentCategory !== null && !isDisabledCurrentCategorySelect
                    ? item.key !== "category"
                    : true
            )
        );
    }, [filters, entityFields]);

    const widthForCollapseListToBadge = 484;
    const widthForNormalSize = 1160;
    const [isOver1160px, isOverWidthForCollapseListToBadge] = useWindowResize([
        widthForNormalSize,
        widthForCollapseListToBadge,
    ]);

    const maxFiltersCount = isOver1160px ? 3 : 1;

    return {
        activeFiltersValues,
        mainTheme,
        isOverWidthForCollapseListToBadge,
        maxFiltersCount,
    };
};
