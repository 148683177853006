import { entityRowModel } from "@entities/Portal/EntityRow";
import { useDispatch } from "react-redux";
import { addUserRole, deleteUserRole, updateUserRoles } from "./CapabilitiesRolesSlice";

export const useUserRolesWS = (user: TUser, userRolesEntityId: number | undefined) => {
    const dispatch = useDispatch();

    const onCreate = (entityRow: any) => {
        dispatch(addUserRole(entityRow));
    };
    const onUpdate = (entityRow: any) => {
        dispatch(updateUserRoles(entityRow));
    };
    const onDelete = (entityRowId: number) => {
        dispatch(deleteUserRole(entityRowId));
    };

    entityRowModel.useEntityRowsWS(userRolesEntityId, user, onCreate, onUpdate, onDelete);
};
