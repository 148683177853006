import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useSidebarItemEditing } from "../model";
import { NavigationRouteEditUpdateModal } from "@features/Portal/NavigationRoute/NavigationRouteEditUpdate";

interface ISidebarItemEditing {
    dragHandleProps?: any;
    navigationRoute: TNavigationRoute;
}

export const SidebarItemEditing = ({
    dragHandleProps,
    navigationRoute,
}: ISidebarItemEditing) => {
    const { deleteLoading, isOpen, onCancel, onDelete, onEdit } =
        useSidebarItemEditing(navigationRoute);

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                fontWeight: 600,
                fontFamily: "Golos Text",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 14,
                paddingLeft: 20,
            }}
            onClick={(e) => e.stopPropagation()}
            {...(dragHandleProps ?? {})}
        >
            {navigationRoute.name}
            <div>
                <Popconfirm
                    title="Добавить элемент?"
                    okText="Удалить"
                    okType="danger"
                    onConfirm={onDelete}
                    cancelText="Отмена"
                >
                    <Button
                        danger
                        type="link"
                        icon={<DeleteOutlined />}
                        loading={deleteLoading}
                    />
                </Popconfirm>
                <Button
                    style={{}}
                    onClick={onEdit}
                    type="link"
                    icon={<EditOutlined />}
                    disabled={deleteLoading}
                />
                <NavigationRouteEditUpdateModal
                    onCancel={onCancel}
                    isOpen={isOpen}
                    navigationRoute={navigationRoute}
                />
            </div>
        </div>
    );
};
