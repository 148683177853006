import { viewPatternItemModel } from "@entities/Portal/ViewPatternItem";
import { useSelector } from "react-redux";
import { getViewPatternItemCreateSelectOptions } from "../lib";
import { useDispatch } from "react-redux";
import { useState } from "react";

export const useViewPatternItemCreateSelect = (
    viewPattern: TViewPattern,
    entityFields: TEntityField[]
) => {
    const viewPatternItems = useSelector((state) =>
        viewPatternItemModel.selectorViewPatternItems(state, viewPattern?.id)
    );
    const selectOptions = getViewPatternItemCreateSelectOptions(
        entityFields,
        viewPatternItems
    );
    const [createLoading, setCreateLoading] = useState<{
        [key: string | number]: boolean;
    }>({});
    const [deleteLoading, setDeleteLoading] = useState<{
        [key: string | number]: boolean;
    }>({});
    const dispatch = useDispatch<AppDispatch>();

    const values = viewPatternItems
        .filter((item) => item.type === "entity-field" && item.parent_id === null)
        .map((item) => item.type_value as string);

    const onSelect = (
        fieldId: string,
        { entityField }: { entityField: TEntityField }
    ) => {
        onCreate(entityField.name, "entity-field", fieldId);
    };

    const onDeselect = (
        fieldId: string,
        { entityField }: { entityField: TEntityField }
    ) => {
        const viewPatternItem = viewPatternItems.find(
            (item) => item.type === "entity-field" && item.type_value == fieldId
        );
        if (!viewPatternItem) return;

        setDeleteLoading((deleteLoading) => ({
            ...deleteLoading,
            [viewPatternItem.type_value as string]: true,
        }));

        dispatch(
            viewPatternItemModel.deleteViewPatternItemThunk({
                viewPatternId: viewPattern.id,
                id: viewPatternItem.id,
            })
        ).then(() => {
            setDeleteLoading((deleteLoading) => ({
                ...deleteLoading,
                [viewPatternItem.type_value as string]: false,
            }));
        });
        //  onCreate(entityField.name, "entity-field", fieldId.toString());
    };

    const onCreate = (
        label: string,
        type: TViewPatternItemType,
        type_value: string | number | null
    ) => {
        const newViewPatternItem: TViewPatternItemCreating = {
            label,
            type,
            parent_id: null,
            view_pattern_id: viewPattern.id,
            type_value,
            order: viewPatternItems.filter((item) => item.parent_id === null).length,
        };
        if (type_value) {
            setCreateLoading((createLoading) => ({
                ...createLoading,
                [type_value]: true,
            }));
        }

        dispatch(
            viewPatternItemModel.createViewPatternItemThunk(newViewPatternItem)
        ).then(() => {
            if (type_value) {
                setCreateLoading((createLoading) => ({
                    ...createLoading,
                    [type_value]: false,
                }));
            }
        });
    };

    return {
        selectOptions,
        values,
        deleteLoading,
        createLoading,
        onSelect,
        onDeselect,
        onCreate,
    };
};
