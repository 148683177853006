import { useSelector } from "react-redux";
import {
    selectAllStages,
    selectGetLoading,
    selectGettingAllStagesLoading,
    selectStages,
} from "./StageSelectors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadStagesThunk } from "./StageThunk";

export const useStagesLoad = (entityId: number, categoryId: number | null) => {
    const loading = useSelector((state: RootState) =>
        categoryId
            ? selectGetLoading(state, categoryId)
            : selectGettingAllStagesLoading(state)
    );
    const stages = useSelector((state: RootState) =>
        categoryId ? selectStages(state, categoryId) ?? [] : selectAllStages(state)
    );
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(
            loadStagesThunk({
                entityId,
                categoryId,
            })
        );
    }, [dispatch, entityId, categoryId]);

    return {
        stages,
        loading,
    };
};
