import { ActionSkeletonListItem } from "@entities/Portal/Action";

const ActionsSkeletonList = () => {
    const actionsCount = Number(localStorage.getItem("actionsLength"));
    const multiply = Array.from({ length: actionsCount > 0 ? actionsCount : 1 });

    return (
        <div className="actions-list">
            {multiply.map((_, index) => {
                return (
                    <div className="actions-list__item" key={index}>
                        <ActionSkeletonListItem />
                    </div>
                );
            })}
        </div>
    );
};

export default ActionsSkeletonList;
