import { stageModel } from "@entities/Portal/Stage";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { Color } from "antd/es/color-picker";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useStageCreateUpdateForm = (
    stage: TStage | TStageCreating,
    entityId: number,
    categoryId: number,
    onSave?: (stage: TStage | TStageCreating) => void
) => {
    const [editingStage, setEditingStage] = useState(stage);
    const createLoading = useSelector((state) =>
        stageModel.selectCreateLoading(state, categoryId)
    );

    const updateLoading = useSelector((state) =>
        stageModel.selectUpdateLoading(state, "id" in stage ? stage.id : 0)
    );
    const dispatch = useDispatch<AppDispatch>();
    const isCreated = "id" in stage;
    const onChangeStageLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setEditingStage({
            ...editingStage,
            label: event.target.value,
        });
    };
    const onChangeStageColor = (value: Color) => {
        setEditingStage({
            ...editingStage,
            background_color: "#" + value.toHex(),
        });
    };
    const onChangeStageType = (type: string) => {
        setEditingStage({
            ...editingStage,
            type: type as TStageType,
            background_color:
                type === "successful"
                    ? "#7bd500"
                    : type === "unsuccessful"
                      ? "#ff115e"
                      : editingStage.background_color,
        });
    };
    const onSubmit = () => {
        dispatch(
            isCreated
                ? stageModel.updateStageThunk({
                      entityId,
                      categoryId,
                      stage: editingStage as TStage,
                  })
                : stageModel.createStageThunk({
                      entityId,
                      categoryId,
                      stage: editingStage as TStageCreating,
                  })
        )
            .then(parseFormResponse)
            .then(() => {
                onSave && onSave(editingStage);
            })
            .catch(() => {});
    };
    return {
        loading: createLoading || updateLoading,
        isCreated,
        editingStage,
        onChangeStageLabel,
        onChangeStageType,
        onChangeStageColor,
        onSubmit,
    };
};
