import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Pagination, Table } from "antd";
import { useReleaseItemsTable } from "../model";
import { getColumns } from "./ReleaseItemsColumns";
import { groupFilterKey } from "../constants";
import ReleaseItemsSendButton from "./ReleaseItemsSendButton";

interface IReleaseItemsTable {
    level: number;
    hideReleaseButton?: boolean;
    groups?: string[];
    additionalFilters?: TFilterValue[];
}

const ReleaseItemsTable = ({
    level,
    groups,
    additionalFilters,
    hideReleaseButton,
}: IReleaseItemsTable) => {
    const {
        loading,
        page,
        perPage,
        lastPage,
        currentGroup,
        releaseItems,
        onShowSizeChange,
        onSelectRows,
    } = useReleaseItemsTable(level, groups, additionalFilters);
    return (
        <>
            <Table
                size="small"
                rowKey={currentGroup ? "value" : "id"}
                pagination={false}
                style={{ width: "100%" }}
                loading={loading}
                columns={getColumns(currentGroup as "nomenclature" | "release")}
                dataSource={releaseItems}
                // rowSelection={{
                //     type: "checkbox",
                //     onChange: (_: any, rows: any[]) => onSelectRows(rows),
                //     // selectedRowKeys: selectedRowIds.map(
                //     //     (row) => (row as any).id
                //     // ),
                // }}
                expandable={
                    currentGroup
                        ? {
                              expandRowByClick: true,
                              expandedRowRender: (record, index, indent, expanded) => {
                                  if (!expanded) return <></>;
                                  return (
                                      <ReleaseItemsTable
                                          additionalFilters={[
                                              ...(additionalFilters ?? []),
                                              {
                                                  condition: "=",
                                                  value: record.value,
                                                  key: groupFilterKey[
                                                      currentGroup as
                                                          | "nomenclature"
                                                          | "release"
                                                  ],
                                              },
                                          ]}
                                          groups={groups}
                                          level={level + 1}
                                      />
                                  );
                              },
                          }
                        : undefined
                }
            />
            <IndentContainer type={["marginTop"]}>
                <FlexContainer type={hideReleaseButton ? "row-end" : "row-space-between"}>
                    {!hideReleaseButton && (
                        <ReleaseItemsSendButton
                            buttonText="Выпустить всё"
                            releasedItems={{
                                type: "filter",
                                filter: [],
                            }}
                        />
                    )}
                    <Pagination
                        hideOnSinglePage={!currentGroup}
                        showSizeChanger
                        onChange={onShowSizeChange}
                        total={lastPage * perPage}
                        pageSize={perPage}
                        current={page}
                    />
                </FlexContainer>
            </IndentContainer>
        </>
    );
};

export default ReleaseItemsTable;
