import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectMyForms } from "./BizProcSelectors";
import { loadBizProcsFormsThunk } from "./BizProcThunk";
import { usePostLoadForms } from "./UsePostLoadForms";

export const useLoadMyForms = (preload?: boolean) => {
    const forms = useSelector(selectMyForms);
    const dispatch = useDispatch<AppDispatch>();
    usePostLoadForms();

    useEffect(() => {
        if (preload) {
            loadForms();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preload]);

    const loadForms = () => {
        dispatch(
            loadBizProcsFormsThunk({
                isMy: true,
            })
        );
    };
    return {
        forms,
        loadForms,
    };
};
