import { StageCardItem } from "@entities/Portal/Stage";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { DragAndDropList } from "@shared/ui/DragAndDropList";
import { Card, Typography } from "antd";
import { CSSProperties, Fragment, ReactNode } from "react";

interface IStagesGroupCard {
    stages: TStage[];
    latestStage?: TStage | undefined;
    label: string;
    categoryId: number;
    style?: CSSProperties;
    entityId: number;
    type: TStageType;
    actions?: (stage: TStage, entityId: number, categoryId: number) => ReactNode;
    allowDragAndDrop?: boolean;
    stageCreateAction?: (
        stage: TStageCreating,
        entityId: number,
        categoryId: number
    ) => ReactNode;
    onChangeItems?: (items: TStage[], moveIndex: number, targetIndex: number) => void;
}

const StagesGroupCard = ({
    stages,
    label,
    categoryId,
    type,
    allowDragAndDrop,
    entityId,
    style,
    latestStage,
    actions,
    stageCreateAction,
    onChangeItems,
}: IStagesGroupCard) => {
    const prevStage =
        type === "in_progress" && stages.length > 0
            ? stages[stages.length - 1]
            : latestStage;
    return (
        <Card style={style} title={<Typography.Text>{label}</Typography.Text>}>
            <div>
                {onChangeItems && allowDragAndDrop ? (
                    <DragAndDropList
                        disabled={false}
                        keyName="id"
                        items={stages}
                        onChangeItems={onChangeItems}
                        draggableItem={(dragHandleProps, stage, index) => (
                            <IndentContainer customStyle={{ width: "100%" }}>
                                <StageCardItem
                                    dragHandleProps={dragHandleProps}
                                    stage={stage}
                                    actions={actions}
                                    entityId={entityId}
                                    categoryId={categoryId}
                                />
                            </IndentContainer>
                        )}
                    />
                ) : (
                    stages.map((stage) => {
                        return (
                            <Fragment key={stage.id}>
                                <IndentContainer>
                                    <StageCardItem
                                        stage={stage}
                                        actions={actions}
                                        entityId={entityId}
                                        categoryId={categoryId}
                                    />
                                </IndentContainer>
                            </Fragment>
                        );
                    })
                )}
            </div>
            {stageCreateAction &&
                stageCreateAction(
                    {
                        type,
                        label: "Новая стадия",
                        background_color:
                            type === "successful"
                                ? "var(--success)"
                                : type === "unsuccessful"
                                  ? "var(--danger)"
                                  : prevStage?.background_color ?? "#fefefe",
                        prev_stage_uuid: prevStage?.uuid ?? null,
                    },
                    entityId,
                    categoryId
                )}
        </Card>
    );
};

export default StagesGroupCard;
