import { Fragment } from "react";
import { useTechMapTmplOperations } from "../model";
import TechMapTmplOperationItem from "./TechMapTmplOperationItem";
import "./TechMapTmplOperations.scss";
import { DragAndDropList } from "@shared/ui/DragAndDropList";
interface ITechMapTmplOperations {
    tmtId: number | null;
}

const TechMapTmplOperations = ({ tmtId }: ITechMapTmplOperations) => {
    const { tmtOperations, onReorderItems } = useTechMapTmplOperations(tmtId);

    return (
        <div className="tmt-operations">
            <DragAndDropList
                disabled={false}
                direction="horizontal"
                keyName="uuid"
                className="view-pattern-edit-form__items"
                items={tmtOperations}
                onChangeItems={onReorderItems}
                draggableItem={(dragHandleProps, operation, index) => (
                    <div
                        key={operation.uuid}
                        style={{ width: 250, flexGrow: 1, marginRight: 8 }}
                        {...dragHandleProps}
                    >
                        <TechMapTmplOperationItem tmtId={tmtId} operation={operation} />
                    </div>
                )}
            />
            {tmtOperations.map((operation) => {
                return <Fragment key={operation.id}></Fragment>;
            })}
        </div>
    );
};

export default TechMapTmplOperations;
