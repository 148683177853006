import ActionCard from "./ActionCard";
interface IQueryAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}
const QueryAction = ({ onDeleteItem, templateItem }: IQueryAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColorLeft="var(--danger)"
            borderColorRight="var(--success)"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Сетевой запрос"}
        ></ActionCard>
    );
};

export default QueryAction;
