import { actionModel } from "@entities/Portal/Action";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useActionListItem = (initialAction?: TAction) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState<TActionError>({});

    const [deleteLoading, setDeleteLoading] = useState(false);
    const onDelete = (action: TAction) => {
        if (initialAction?.is_creating) {
            dispatch(actionModel.deleteAction(initialAction));
            return;
        }
        setDeleteLoading(true);
        dispatch(actionModel.deleteActionThunk(action.id)).finally(() => {
            setDeleteLoading(false);
        });
    };
    const onSave = (action: TAction) => {
        dispatch(actionModel.updateActionThunk(action))
            .then(parseFormResponse)
            .then((data: TResponse<TAction>) => {
                setIsEditing(false);
            })
            .catch((e: TActionError) => {
                setError(e);
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка",
                    type: "error",
                });
            });
    };
    const onCloseCustom = () => {
        setError({});
        setIsEditing(false);
    };
    const onCreate = (action: TAction) => {
        dispatch(
            actionModel.createActionThunk({
                name: action.name,
                type: action.type,
                parameters: action.parameters,
            })
        )
            .then(parseFormResponse)
            .then((data: TResponse<TAction>) => {
                dispatch(actionModel.deleteAction(action));
                setIsEditing(false);
            })
            .catch((e: TActionError) => {
                setError(e);
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка",
                    type: "error",
                });
            });
    };
    return {
        onSave,
        isEditing,
        setIsEditing,
        onCloseCustom,
        error,
        deleteLoading,
        onCreate,
        onDelete,
    };
};
