import { userApi, userModel } from "@entities/Portal/User";
import { linkUserTelegram } from "@entities/Portal/User/api";
import { FormInstance } from "antd";
import { createRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useSignInForm = () => {
    const formRef = createRef<FormInstance>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (formRef.current) {
            const username = localStorage.getItem("username");
            const password = localStorage.getItem("password");
            if (username !== null && password !== null) {
                formRef.current.setFieldsValue({
                    username,
                    password,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef.current]);

    const onSubmit = (values: any) => {
        setLoading(true);
        setError(false);
        userApi
            .signIn(values.username, values.password)
            .then((response) => {
                dispatch(userModel.setToken(response.data.access_token));
                userApi
                    .getUserInfo()
                    .then((response) => {
                        dispatch(userModel.setUser(response.data.data));
                        if (values.remember) {
                            localStorage.setItem("username", values.username);
                            localStorage.setItem("password", values.password);
                            navigate("/dashboard");
                        } else {
                            localStorage.removeItem("username");
                            localStorage.removeItem("password");
                        }
                    })
                    .then(() => {
                        linkUserTelegram();
                    })
                    .catch((error) => {
                        setError(true);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((error) => {
                setError(true);
                setLoading(false);
            })
            .finally(() => {});
    };
    return {
        onSubmit,
        formRef,
        error,
        loading,
    };
};
