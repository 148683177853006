import { portalSettingModel } from "@entities/Portal/PortalSetting";
import { userModel } from "@entities/Portal/User";
import { useCheckAuth } from "@features/Portal/Auth/ChechAuth";
import { LoginPage } from "@pages/Portal/LoginPage";
import { RecoverPasswordPage } from "@pages/Portal/RecoverPasswordPage";
import { StartRecoverPasswordPage } from "@pages/Portal/StartRecoverPasswordPage";
import { Message, Notification } from "@shared/ui/NotificationAndMessage";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../api";
import AuthorizedRouter from "./AuthorizedRouter";
const AppRouter = () => {
    const isAuthorizated = useSelector(userModel.selectIsAuthorizated);
    const { loading } = useCheckAuth();
    const { loading: portalSettingLoading } = portalSettingModel.usePortalSettingsLoad();
    if (loading || portalSettingLoading) {
        return (
            <div>
                <Spin />
            </div>
        );
    }

    return (
        <BrowserRouter>
            {!isAuthorizated ? (
                <Routes>
                    <Route
                        path="/start-recover-password"
                        element={<StartRecoverPasswordPage />}
                    />
                    <Route path="/recover-password" element={<RecoverPasswordPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="*" element={<LoginPage />} />
                </Routes>
            ) : (
                <AuthorizedRouter />
            )}
            <Notification />
            <Message />
        </BrowserRouter>
    );
};

export default AppRouter;
