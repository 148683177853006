import { createAsyncThunk } from "@reduxjs/toolkit";
import { createStage, deleteStage, getStages, updateStage } from "../api";

export const loadStagesThunk = createAsyncThunk(
    "StageSlice/loadStagesThunk",
    async (
        props: { entityId: number; categoryId: number | null; params?: TParams },
        { rejectWithValue }
    ) => {
        try {
            const response = await getStages(
                props.entityId,
                props.categoryId,
                props.params
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateStageThunk = createAsyncThunk(
    "StageSlice/updateStagesThunk",
    async (
        props: { entityId: number; categoryId: number; stage: TStage },
        { rejectWithValue }
    ) => {
        try {
            const response = await updateStage(
                props.entityId,
                props.categoryId,
                props.stage
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const updateStagePrevStageUuidThunk = createAsyncThunk(
    "StageSlice/updateStagePrevStageUuidThunk",
    async (
        props: { entityId: number; categoryId: number; stage: TStage },
        { rejectWithValue }
    ) => {
        try {
            const response = await updateStage(
                props.entityId,
                props.categoryId,
                props.stage,
                true
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const createStageThunk = createAsyncThunk(
    "StageSlice/createStagesThunk",
    async (
        props: { entityId: number; categoryId: number; stage: TStageCreating },
        { rejectWithValue }
    ) => {
        try {
            const response = await createStage(
                props.entityId,
                props.categoryId,
                props.stage
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteStageThunk = createAsyncThunk(
    "StageSlice/deleteStagesThunk",
    async (
        props: { entityId: number; categoryId: number; stageId: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await deleteStage(
                props.entityId,
                props.categoryId,
                props.stageId
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
