import { categoryModel } from "@entities/Portal/Category";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useCategoryItemActions = (entityId: number, category: TCategory) => {
    const dispatch = useDispatch<AppDispatch>();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const onEdit = () => {
        setIsOpen(true);
    };
    const onCancel = () => {
        setIsOpen(false);
    };
    const onDelete = () => {
        setDeleteLoading(true);
        dispatch(
            categoryModel.deleteCategoryThunk({
                entityId,
                id: category.id,
            })
        )
            .then(parseFormResponse)
            .finally(() => {
                setDeleteLoading(false);
            });
    };
    return { deleteLoading, isOpen, onEdit, onCancel, onDelete };
};
