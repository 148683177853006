// Новые блоки добавляются в children
export const actionsAllowChildren: TBizProcActionType[] = [
    "IfElseAction",
    "AgreementAction",
    "ApproveBranchAction",
    "RejectBranchAction",
    "IfElseActionBranch",
    "QueryAction",
    "QueryActionSuccessBranch",
    "QueryActionErrorBranch",
    "InitialAction",
    "ParallelExecutionAction",
    "ParallelExecutionActionBranch",
];
// Не делать кнопку ДОБАВИТЬ после экшена
export const actionNotAllowAddAfter: TBizProcActionType[] = [
    "AgreementAction",
    "QueryAction",
];
// Имеет внутри себя детей расположенных в строку
export const bizProcRowChild: TBizProcActionType[] = [
    "IfElseAction",
    "AgreementAction",
    "ParallelExecutionAction",
    "QueryAction",
];
