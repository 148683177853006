import { Button, Flex, Modal } from "antd";
import { useCheckConnectionModal } from "../model/UseCheckConnectionModal";
import PrinterModule from "./PrinterModule";
import { Typography } from "antd/lib";

const CheckConnectionModal = () => {
    const { isOpen, printerIsConnected, onCloseModal, onChangeConnectedState, onOk } =
        useCheckConnectionModal();

    return (
        <Modal
            zIndex={1001}
            styles={{
                body: {
                    height: 90,
                },
            }}
            onCancel={onCloseModal}
            footer={
                <Flex justify="end">
                    <Button onClick={onCloseModal}>Отмена</Button>
                    <Button disabled={!printerIsConnected} type="primary" onClick={onOk}>
                        Печать
                    </Button>
                </Flex>
            }
            open={isOpen}
        >
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
                Принтер не подключён
            </Typography.Title>
            <PrinterModule onChangeConnectedState={onChangeConnectedState} />
        </Modal>
    );
};

export default CheckConnectionModal;
