import { AgreementSkeletonItem } from "@entities/Portal/BizProc";

interface IBizProcAgreementsSkeletonList {
    isMyAgreements?: boolean;
}

const BizProcAgreementsSkeletonList = ({
    isMyAgreements,
}: IBizProcAgreementsSkeletonList) => {
    const bizProcsAgreementsCount = localStorage.getItem(
        isMyAgreements ? "MyAgreementsCount" : "AgreementsCount"
    );

    const parsedCounts =
        bizProcsAgreementsCount && bizProcsAgreementsCount !== "{}"
            ? JSON.parse(bizProcsAgreementsCount)
            : { "--": 1 };

    return (
        <>
            {Object.entries(parsedCounts).map(([name, count]: any, index) => {
                return (
                    <div key={index}>
                        <div className="agreement-header">{name}</div>
                        <div className="agreements-list">
                            {Array.from({ length: count }).map((_, index) => {
                                return (
                                    <div className="agreements-list__item" key={index}>
                                        <AgreementSkeletonItem />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};
export default BizProcAgreementsSkeletonList;
