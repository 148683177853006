import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Input, Modal } from "antd";
import { useCreateUpdateCalculatedValue } from "../model/UseCreateUpdateCalculatedValue";
import CalculatedValueComparisonSelect from "./CalculatedValueComparisonSelect";
interface ICalculatedValueModifyModal {
    editingCalculatedValue: TCalculatedValue | null;

    onSuccess?: (calculatedValue: TCalculatedValue, isCreating?: boolean) => void;
}
const CalculatedValueModifyModal = ({
    onSuccess,
    editingCalculatedValue,
}: ICalculatedValueModifyModal) => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const {
        onClose,
        onChangeName,
        onOk,
        loading,
        error,
        isOpen,
        onChangeFieldComparisonTypes,
        calculatedValue,
    } = useCreateUpdateCalculatedValue(editingCalculatedValue, onSuccess);
    return (
        <Modal
            confirmLoading={loading}
            title={`${calculatedValue?.is_creating ? "Создание" : "Редактирование"} переменной`}
            open={isOpen}
            onOk={onOk}
            onCancel={onClose}
            width={"600px"}
            style={{
                top: windowHeight <= 700 ? "10%" : undefined,
                padding: windowWidth <= 700 ? 0 : undefined,
            }}
        >
            <FormItem label="Наименование">
                <>
                    <Input
                        name="name"
                        status={error.data?.["name"] ? "error" : undefined}
                        value={calculatedValue?.name}
                        onChange={onChangeName}
                        placeholder="Наименование"
                    />
                    {error.data?.["name"] && <ErrorText text={error.data?.["name"][0]} />}
                </>
            </FormItem>

            <FormItem label="Тип переменной">
                <>
                    <Input
                        name="type"
                        value={calculatedValue?.type}
                        onChange={onChangeName}
                        style={{ width: "100%" }}
                    />
                    {error.data?.["type"] && <ErrorText text={error.data?.["type"][0]} />}
                </>
            </FormItem>
            <FormItem label="Тип группы">
                <>
                    <Input
                        name="group_type"
                        value={calculatedValue?.group_type}
                        onChange={onChangeName}
                        style={{ width: "100%" }}
                    />
                    {error.data?.["group_type"] && (
                        <ErrorText text={error.data?.["group_type"][0]} />
                    )}
                </>
            </FormItem>
            <FormItem label="Виды сравнения">
                <>
                    <CalculatedValueComparisonSelect
                        onChange={onChangeFieldComparisonTypes}
                        values={
                            calculatedValue?.comparison_types?.map(
                                (comparison) => comparison.id
                            ) ?? []
                        }
                    />
                </>
            </FormItem>
        </Modal>
    );
};

export default CalculatedValueModifyModal;
