import { userApi, userModel } from "@entities/Portal/User";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const useCheckAuth = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const authorize = (user: TUser, access_token: string) => {
        dispatch(userModel.setToken(access_token));
        dispatch(userModel.setUser(user));
        userApi
            .getUserInfo()
            .then((response) => {
                dispatch(userModel.setUser(response.data.data));
            })
            .catch((error) => {
                dispatch(userModel.signOut());
            });
    };
    useEffect(() => {
        // const access_token = cookies.get("accessToken");
        // const user = cookies.get("user");
        // if (access_token && user) {
        //     authorize(user, access_token);
        // } else {
        //     const access_token = localStorage.getItem("access_token");
        //     const user = localStorage.getItem("user");
        //     if (user && access_token) authorize(JSON.parse(user), access_token);
        // }
        const access_token = localStorage.getItem("access_token");
        const user = localStorage.getItem("user");
        if (user && access_token) authorize(JSON.parse(user), access_token);
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    return {
        loading,
    };
};
