import { Flex } from "antd";
import FieldSelect from "../../../FormFieldsListCreator/FieldSelect";
import { TTargetOfParseRule } from "../model/types";

interface IEditQueryActionVariableType {
    bizProcFields: TBizProcTemplateField[];
    entityFieldKeys: string[];
    variableIds: number[];
    onChange: (newTargets: TTargetOfParseRule[]) => void;
}
const EditQueryActionVariableType = ({
    bizProcFields,
    variableIds,
    onChange,
    entityFieldKeys,
}: IEditQueryActionVariableType) => {
    const onChange_ = (variables: TBizProcTemplateField[]) => {
        const newTargets = variables.map((variable) => {
            const { variable_type, id } = variable;
            if (variable_type === "entity") {
                const { entity_uuid, key } = variable;
                return {
                    entity_uuid,
                    key,
                    variable_type,
                };
            } else {
                return {
                    id,
                    variable_type,
                };
            }
        }) as TTargetOfParseRule[];

        onChange(newTargets);
    };
    const choosenEntityFieldIdsByTheirKeys = bizProcFields
        .filter(({ key }) => entityFieldKeys.includes(key))
        .map(({ id }) => id);
    const choosenVariablesIds = [...variableIds, ...choosenEntityFieldIdsByTheirKeys];
    const selectedFields = bizProcFields.filter(({ id }) =>
        choosenVariablesIds.includes(id)
    );

    return (
        <Flex style={{ width: "100%" }}>
            <FieldSelect
                maxTagCount={5}
                allowClear
                placeholder="Переменные"
                selectOnly
                disabled={false}
                onChangeFields={onChange_}
                selectedFields={selectedFields}
                fields={bizProcFields}
            />
        </Flex>
    );
};

export default EditQueryActionVariableType;
