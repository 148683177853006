import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm } from "antd";
import { ReactNode } from "react";
import "./CardWithActions.scss";
interface ICardWithActions {
    children: ReactNode;
    onClick?: () => void;
    onSubmitDelete?: () => void;
    onEdit?: () => void;
    deleteLoading?: boolean;
    extraNode?: React.ReactNode;
}

const CardWithActions = ({
    children,
    onClick,
    onSubmitDelete,
    deleteLoading,
    onEdit,
    extraNode,
}: ICardWithActions) => {
    return (
        <Flex className={`actions-card ${onClick ? "clickable" : ""}`} onClick={onClick}>
            <Flex style={{ flex: "1 1 80%" }} className="actions-card__content" vertical>
                {children}
            </Flex>
            <Flex
                style={{ flex: "1 1 fit-content" }}
                className="actions-card__buttons"
                onClick={(e) => e.stopPropagation()}
            >
                {extraNode && extraNode}
                {onEdit && (
                    <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
                )}
                {onSubmitDelete && (
                    <Popconfirm
                        title="Удалить элемент?"
                        okText="Удалить"
                        onConfirm={onSubmitDelete}
                        cancelText="Отмена"
                    >
                        <Button
                            loading={deleteLoading}
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                )}
            </Flex>
        </Flex>
    );
};

export default CardWithActions;
