export const options = [
    {
        label: "Номенклатура",
        value: "nomenclature",
    },
    {
        label: "Выпуск",
        value: "release",
    },
];
