import { entityModel } from "@entities/Portal/Entity";
import React from "react";
import { useSelector } from "react-redux";
import "./EntitiesList.scss";
import EntityNormalItem from "./EntityNormalItem";
import EntitySkeletonItemsList from "./EntitySkeletonItemsList";

interface IEntitiesList {
    entities: TEntity[];
    additionalActions: (item: TEntity) => React.ReactNode;
}

const EntitiesList = ({ entities, additionalActions }: IEntitiesList) => {
    const isLoading = useSelector(entityModel.selectIsLoading);

    if (entities.length > 0) {
        return (
            <>
                <div className="entities-list">
                    {entities.map((entity) => {
                        return (
                            <div key={entity.id} className="entities-list__item">
                                <EntityNormalItem
                                    entity={entity}
                                    additionalActions={additionalActions}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
    if (isLoading && entities.length < 1) {
        return <EntitySkeletonItemsList />;
    }
    return <></>;
};

export default EntitiesList;
