import { FilterOutlined } from "@ant-design/icons";
import { useDebounceEffect } from "@shared/hooks";
import { Badge, Button, Flex, Input, Modal } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import TechMapTemplatesFiltersInputs from "./TechMapTemplatesFiltersInputs";
import { TFiltersTechMap } from "./types";

export interface ITechMapTemplatesFilters {
    filters: TFiltersTechMap;
    onChange: (name: keyof TFiltersTechMap, value: any) => void;
    resetFilters: () => void;
    submitFilters: () => void;
    isModal?: boolean;
    top?: number;
    left?: number;
}

const TechMapTemplatesFilters = ({
    filters,
    onChange,
    submitFilters,
    resetFilters,
    isModal,
}: ITechMapTemplatesFilters) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const onOk = () => {
        submitFilters();
        closeModal();
    };
    const onResetClick = () => {
        resetFilters();
        closeModal();
    };
    const [fastFilterName, setFastFilterName] = useState<string | null>(filters.name);
    const onChangeNameFastFilter = (event: ChangeEvent<HTMLInputElement>) => {
        setFastFilterName(event.target.value);
        onChange("name", event.target.value);
    };
    //TODO: избавиться от useEffect
    useEffect(() => {
        setFastFilterName(filters.name);
    }, [filters]);

    useDebounceEffect(
        () => {
            submitFilters();
        },
        () => {},
        [isModal ? fastFilterName : filters],
        500
    );

    return isModal ? (
        <>
            <Flex align="center" gap={4} style={{ width: "100%" }}>
                <Badge
                    dot={
                        !!filters.name || !!filters.entity_row_id || !!filters.entity_uuid
                    }
                >
                    <Button
                        onClick={openModal}
                        icon={<FilterOutlined />}
                        style={{ minHeight: 0, height: "fit-content" }}
                        type="primary"
                    />
                </Badge>
                <Input
                    style={{ width: "100%" }}
                    size="small"
                    placeholder="Название"
                    onChange={onChangeNameFastFilter}
                    value={fastFilterName ?? ""}
                />
            </Flex>
            <Modal
                open={isOpen}
                destroyOnClose
                onCancel={closeModal}
                footer={
                    <Flex gap={4} justify="end">
                        <Button onClick={onResetClick}>Сбросить</Button>
                        <Button onClick={closeModal}>Отмена</Button>
                        <Button onClick={onOk} type="primary">
                            ОК
                        </Button>
                    </Flex>
                }
            >
                <TechMapTemplatesFiltersInputs
                    filters={filters}
                    onChange={onChange}
                    resetFilters={resetFilters}
                    isVertical={true}
                />
            </Modal>
        </>
    ) : (
        <TechMapTemplatesFiltersInputs
            filters={filters}
            onChange={onChange}
            resetFilters={resetFilters}
        />
    );
};

export default TechMapTemplatesFilters;
