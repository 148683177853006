import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";

interface IUseTMTOCodesItem {
    code: TMTOCodeTemplateItem;
    onChange: (code: TMTOCodeTemplateItem) => void;
    onDelete: (code: TMTOCodeTemplateItem) => void;
}

export const useTMTOCodesItem = ({ code, onChange, onDelete }: IUseTMTOCodesItem) => {
    const tmt = useSelector(techMapTemplateModel.selectTechMapTemplate);
    const results = tmt?.results ?? [];
    const selectedResult = results.find(
        (result) => "id" in result && result.id === code.technical_map_template_result_id
    );
    const onChangeResult = (value: number) => {
        onChange({
            ...code,
            technical_map_template_result_id: value,
            value: null,
            field_key: null,
        });
    };
    const onChangeFieldKey = (fieldKey: string) => {
        onChange({
            ...code,
            field_key: fieldKey,
        });
    };
    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...code,
            value: event.target.value === "" ? null : event.target.value,
        });
    };
    const onDeleteItem = () => {
        onDelete(code);
    };
    return {
        onChangeResult,
        onChangeFieldKey,
        onChangeValue,
        onDeleteItem,
        selectedResult,
    };
};
