import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { getStages } from "../api";

export const useStagesLoadStatefull = (categoryId: number, entityId: number) => {
    const [loading, setLoading] = useState(true);
    const [stages, setStages] = useState<TStage[]>([]);
    const [notAllowed, setNotAllowed] = useState(false);
    const loadStages = () => {
        setLoading(true);

        getStages(entityId, categoryId)
            .then((response) => {
                const responseData = response.data.data;
                if (!responseData) {
                    setNotAllowed(true);
                    return;
                }
                setStages(responseData);
            })
            .catch((e) => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось загрузить стадии",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        loadStages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    return {
        notAllowed,
        stages,
        loading,
    };
};
