import dayjs from "dayjs";

export const getReleasesColumn = (currentGroup?: TReleaseGroup) => {
    if (currentGroup) {
        return [
            {
                title: "Наименование",
                dataIndex: "name",
            },
            {
                title: "Количество",
                dataIndex: "count",
            },
        ];
    }
    return [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Номер",
            dataIndex: "number",
        },
        {
            title: "Синхронизация",
            dataIndex: "is_sent",
            render: (value: any) => {
                return value == 0 ? "Ожидает синхронизации" : "Синхронизирован";
            },
        },
        {
            title: "Склад",
            dataIndex: "warehouse",
            render: (value: any) => {
                return value?.name;
            },
        },
        {
            title: "Организация",
            dataIndex: "organization",
            render: (value: any) => {
                return value?.name;
            },
        },
        {
            title: "Комментарий",
            dataIndex: "comment",
        },
        {
            title: "Дата создания",
            dataIndex: "created_at",
            render: (value: any) => {
                return value ? dayjs(value).format("HH:mm | DD.MM.YYYY") : "-";
            },
        },
    ] as any;
};
