import { EntityFieldListItem } from "@entities/Portal/Entity";
import { entityFieldLib } from "@entities/Portal/EntityField";
import { DragAndDropList } from "@shared/ui/DragAndDropList";
import { Empty, Flex, Spin } from "antd";
import { useEntityFieldItemList } from "../model";
import "./EntityFieldItemList.scss";
interface IEntityFieldItemList {
    entityId?: number | undefined;
    entityUuid?: string | undefined;
    isColored?: (key: string) => boolean;
    getCoincidences?: (item: TEntity, key: string) => any[];
    view?: "card" | "list";
    datatypes: TDatatype[];
    isCreatingEntity?: boolean;
}

const EntityFieldItemList = ({
    entityId,
    entityUuid,
    isColored,
    view = "card",
    datatypes,
}: IEntityFieldItemList) => {
    const { fields, loading, onEdit, onDelete, reorderFields } = useEntityFieldItemList(
        entityId,
        entityUuid
    );

    const fullFieldsList = entityFieldLib.getOrderedEntityFields(fields);
    const onChangeFields = (items: TEntityField[]) => {
        reorderFields(items);
    };
    return (
        <div
            className="entities-row"
            style={
                view === "card"
                    ? {
                          display: "flex",
                          flexWrap: "wrap",
                          marginTop: "20px",
                          justifyContent: "stretch",
                      }
                    : {}
            }
        >
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: 300,
                    }}
                >
                    <Spin />
                </div>
            ) : (
                <Flex vertical gap={4} style={{ width: "100%" }}>
                    {view === "card" ? (
                        fullFieldsList.map((entityField: TEntityField, index: number) => {
                            return (
                                <EntityFieldListItem
                                    key={index}
                                    isColored={isColored}
                                    entityField={entityField}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                />
                            );
                        })
                    ) : fullFieldsList.length === 0 ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: 300,
                            }}
                        >
                            <Empty description={"Поля не созданы"} />
                        </div>
                    ) : (
                        <DragAndDropList
                            className="entity-fields-list"
                            disabled={false}
                            keyName="id"
                            items={fullFieldsList}
                            customDndListStyle={{
                                padding: "0 8px 0 8px",
                                gap: 8,
                                display: "flex",
                                flexDirection: "column",
                            }}
                            onChangeItems={onChangeFields}
                            draggableItem={(dragHandleProps, entityField, index) => (
                                <EntityFieldListItem
                                    labelDataType={
                                        datatypes.find(
                                            (item) =>
                                                item.datatype === entityField.datatype
                                        )?.name
                                    }
                                    dragHandleProps={dragHandleProps}
                                    key={entityField.id ?? index}
                                    isColored={isColored}
                                    entityField={entityField}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                />
                            )}
                        />
                    )}
                </Flex>
            )}
        </div>
    );
};

export default EntityFieldItemList;
