import { userApi } from "@entities/Portal/User";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { ChangeEvent, useState } from "react";

export const useUserPasswordModal = (
    userId: number,
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
) => {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const onCancel = () => {
        setIsOpen(false);
        setConfirmPassword("");
        setPassword("");
    };

    const updatePassword = (password: string) => {
        setLoading(true);
        return userApi
            .updateUserPassword(userId, password)
            .then((response) => {
                notificationEmit({
                    type: "success",
                    title: "Новый пароль установлен!",
                });
                onCancel();
            })
            .catch((error) => {
                notificationEmit({
                    type: "error",
                    title: error.response.data.message ?? "Не удалось обновить пароль",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSubmit = () => {
        if (password.length < 8) {
            setPasswordError("Пароль должен быть 8 или более символов");
            return;
        }
        if (confirmPassword != password) {
            setPasswordError("Пароль должен совпадать");
            return;
        }
        setPasswordError(null);
        updatePassword(password);
    };

    const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setPasswordError(null);
    };
    const onChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        setPasswordError(null);
    };
    return {
        password,
        confirmPassword,
        loading,
        passwordError,
        onCancel,
        onSubmit,
        onChangePassword,
        onChangeConfirmPassword,
    };
};
