import { FormInputItem } from "@entities/Portal/Datatype";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { Checkbox } from "antd";
import SelectVariable from "./SelectVariable";
import { InputWithVariables } from "@entities/Portal/BizProcTemplate";

interface IEditChangeValuesAction {
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}
const EditChangeValuesAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditChangeValuesAction) => {
    const variables = bizProcTemplate.variables ?? [];

    const templateItemVariables = (templateItem.parameters.variables ??
        []) as TChangeActionVariable[];
    const onChangeTemplateItem = (
        value: any,
        key: string,
        inputId?: string | number | undefined
    ) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                variables: templateItemVariables.map((item) =>
                    item.unique_id === inputId
                        ? {
                              ...item,
                              [key]: value,
                          }
                        : item
                ),
            },
        });
    };
    const onChangeField = (value: any, inputId?: string | number | undefined) => {
        onChangeTemplateItem(value, "value", inputId);
    };
    const onChangeCalculation = (value: any, inputId: string) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                variables: templateItemVariables.map((item) =>
                    item.unique_id === inputId
                        ? {
                              ...item,
                              is_calculation: value,
                          }
                        : item
                ),
            },
        });
    };

    const onChangeCalculationInput = (value: any, inputId: string) => {
        onChangeTemplateItem(value, "input", inputId);
    };

    return (
        <div>
            <IndentContainer>
                <SelectVariable
                    disabled={disabled}
                    variables={variables}
                    setItem={setItem}
                    templateItem={templateItem}
                />
            </IndentContainer>

            {templateItemVariables?.map((field) => {
                return (
                    <IndentContainer key={field.unique_id}>
                        <FormItem label={field?.name}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {field.is_calculation ? (
                                    <InputWithVariables
                                        disabled={disabled}
                                        itemId={bizProcTemplate.id!}
                                        isTextArea={true}
                                        onChange={(name, value) =>
                                            onChangeCalculationInput(
                                                value,
                                                field.unique_id
                                            )
                                        }
                                        name="input"
                                        value={field.input as string}
                                    />
                                ) : (
                                    <FormInputItem
                                        field={field}
                                        inputId={field.unique_id}
                                        additionalParameters={{
                                            params: { withCalcValues: 1 },
                                        }}
                                        //additionalParameters={field.additional_parameters}
                                        datatype={field.datatype as unknown as string}
                                        value={field.value}
                                        onlyRead={
                                            disabled ||
                                            (field.variable_type === "entity" &&
                                                field.is_unwritable)
                                        }
                                        onChange={onChangeField}
                                    />
                                )}
                                <Checkbox
                                    disabled={disabled}
                                    checked={field.is_calculation}
                                    onChange={() =>
                                        onChangeCalculation(
                                            !field.is_calculation,
                                            field.unique_id
                                        )
                                    }
                                >
                                    Вычисляемое значение по формуле
                                </Checkbox>
                            </div>
                        </FormItem>
                    </IndentContainer>
                );
            })}
        </div>
    );
};

export default EditChangeValuesAction;
