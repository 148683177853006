import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { getTMTOCodeTemplateMock, isSameItems } from "../lib";
import { useSelector } from "react-redux";

export const useTMTOCodes = (
    codes: TMTOCodeTemplateItem[],
    onChange: (codes: TMTOCodeTemplateItem[]) => void
) => {
    const tmt = useSelector(techMapTemplateModel.selectTechMapTemplate);
    const results = tmt?.results ?? [];

    const onAddCodeTemplate = () => {
        onChange([getTMTOCodeTemplateMock(), ...codes]);
    };
    const onChangeCode = (updatedCode: TMTOCodeTemplateItem) => {
        onChange(
            codes.map((code) => {
                return isSameItems(updatedCode, code) ? updatedCode : code;
            })
        );
    };
    const onDeleteCode = (deletedCode: TMTOCodeTemplateItem) => {
        onChange(
            codes.filter((code) => {
                return !isSameItems(deletedCode, code);
            })
        );
    };
    const getCodeResult = (resultId: number | null) => {
        if (!resultId) return undefined;
        return results.find((result) => "id" in result && result.id === resultId);
    };
    return {
        onAddCodeTemplate,
        getCodeResult,
        onChangeCode,
        onDeleteCode,
    };
};
