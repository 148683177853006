import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import "./EmptyPage.scss";
interface IEmptyPage {
    text: string;
    button?: React.ReactNode;
}

const EmptyPage = (props: IEmptyPage) => {
    const { text, button } = props;
    return (
        <div className="empty-page">
            <div className="empty-page__header">
                <SearchOutlined style={{ fontSize: 55, color: "#4f4f4f" }} />
                {text}
            </div>
            {button && <div className="empty-page__button">{button}</div>}
        </div>
    );
};

export default EmptyPage;
