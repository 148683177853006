import { useEffect, useRef, useState } from "react";

export const useDebounceTooltipOpenEffect = (
    currentValue: number | undefined,
    maxValue: number,
    isReduceValue?: boolean
) => {
    const timerRef = useRef<any | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const tooltipInnerStyle = {
        maxWidth: 300,
        width: "fit-content",
        minWidth: 200,
        maxHeight: 400,
        overflow: "auto",
        color: "black",
    };

    const closeTooltip = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.addEventListener("click", closeTooltip);
        }
        return () => {
            document.body.removeEventListener("click", closeTooltip);
        };
    }, [isOpen]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        timerRef.current && clearTimeout(timerRef.current);
        event.preventDefault();
        if (isReduceValue && currentValue && currentValue > maxValue) {
            timerRef.current = setTimeout(() => {
                setIsOpen(true);
            }, 200);
        }
    };
    const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        timerRef.current && clearTimeout(timerRef.current);
    };

    return {
        isOpen,
        handleClick,
        handleDoubleClick,
        tooltipInnerStyle,
    };
};
