import { userModel } from "@entities/Portal/User";
import { viewPatternModel } from "@entities/Portal/ViewPattern";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { useDispatch, useSelector } from "react-redux";

export const useViewPatternCreate = (entityId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const userId = useSelector(userModel.selectUserId);
    const loading = useSelector(viewPatternModel.selectCreateLoading);
    const onClick = () => {
        dispatch(
            viewPatternModel.createViewPatternThunk({
                name: "Шаблон",
                creator_id: userId!,
                type: "view",
                is_public: false,
                view_type: 1,
                entity_id: entityId,
                roles_ids: [],
                users_ids: [],
                departments_ids: [],
            })
        )
            .then(parseFormResponse)
            .then(({ data }: { data: TViewPattern }) => {
                dispatch(viewPatternModel.setEditingViewPattern(data));
            })
            .catch(() => {});
    };
    return {
        loading,
        onClick,
    };
};
