import { useWindowResize } from "@shared/lib/UseWindowResize";
import { Flex, Pagination } from "antd";
import React from "react";

interface IEntityRowsTablePaginationWithActions {
    groupingKey: string;
    rowsActions:
        | ((
              selectedRows: any[],
              onSelectRows: (rows: any[]) => void,
              levelKey: number | string
          ) => React.ReactNode)
        | undefined;
    onShowSizeChange: (current: number, size: number) => void;
    lastPage: number;
    perPage: number;
    currentPage: number;
    selectedRowIds: number[];
    onSelectRows: (selectedRows: any[]) => void;
    levelKey: string | number;
}

const EntityRowsTablePaginationWithActions = ({
    groupingKey,
    rowsActions,
    onShowSizeChange,
    lastPage,
    perPage,
    currentPage,
    selectedRowIds,
    onSelectRows,
    levelKey,
}: IEntityRowsTablePaginationWithActions) => {
    const justifyContentChangerWidth = 940;
    const paginationSizeChangerWidth = 968;
    const [isOverJustifyContentChangerWidth, isOverPaginationSizeChangerWidth] =
        useWindowResize([justifyContentChangerWidth, paginationSizeChangerWidth]);

    const onChangePaginationPageWithResetSelectedRows = (
        page: number,
        pageSize: number
    ) => {
        onShowSizeChange(page, pageSize);
        onSelectRows([]);
    };

    return (
        <Flex
            className="entity-rows-table__pagination-container"
            gap={8}
            style={{
                justifyContent:
                    !groupingKey && rowsActions
                        ? isOverJustifyContentChangerWidth
                            ? "space-between"
                            : "center"
                        : "flex-end",
            }}
        >
            {!groupingKey &&
                rowsActions &&
                rowsActions(selectedRowIds, onSelectRows, levelKey)}
            <Pagination
                size={!isOverPaginationSizeChangerWidth ? "small" : "default"}
                showSizeChanger
                onChange={onChangePaginationPageWithResetSelectedRows}
                total={lastPage * perPage}
                pageSize={perPage}
                current={currentPage}
            />
        </Flex>
    );
};

export default EntityRowsTablePaginationWithActions;
