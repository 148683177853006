import { QrcodeOutlined } from "@ant-design/icons";
import { Button, ConfigProvider } from "antd";
import "./GenerateQRCodeButton.scss";
import { useDispatch } from "react-redux";
import { entityModel } from "@entities/Portal/Entity";
const GenerateQRCodeButton = ({ entity }: { entity: TEntity }) => {
    const dispatch = useDispatch();

    const onQrCodeClick = () => {
        dispatch(entityModel.setClickedEntityForQRCode(entity));
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorLinkHover: "gray",
                    },
                },
            }}
        >
            <Button
                className="button"
                type="link"
                icon={<QrcodeOutlined />}
                onClick={onQrCodeClick}
            />
        </ConfigProvider>
    );
};

export default GenerateQRCodeButton;
