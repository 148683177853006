import { FormInputItem } from "@entities/Portal/Datatype";
import { FormItem } from "@shared/ui/Form";
import "./KanbanItem.scss";
interface IKanbanItem {
    choosenVisibleFieldsOptions: TEntityField[] | null;
    choosenEntityField: TEntityField | null;
    pureItem: any;
    groupColors: { [key: string]: string };
    openCloseDrawerWithNavigate: (isOpenDrawer: boolean, entityRowId?: number) => void;
}

const KanbanItem = ({
    choosenVisibleFieldsOptions,
    choosenEntityField,
    pureItem,
    groupColors,
    openCloseDrawerWithNavigate,
}: IKanbanItem) => {
    const isVisibleCard =
        choosenVisibleFieldsOptions &&
        choosenVisibleFieldsOptions.length > 0 &&
        choosenEntityField;

    return (
        <>
            {isVisibleCard && (
                <div
                    className="kanban-item"
                    onClick={() => openCloseDrawerWithNavigate(true, pureItem.id)}
                    style={{}}
                >
                    {choosenVisibleFieldsOptions.map((entityField, index) => {
                        return (
                            <FormItem
                                key={index}
                                label={entityField.name}
                                style={{ marginBottom: 0, minHeight: "24px !important" }}
                            >
                                <FormInputItem
                                    additionalParameters={
                                        entityField.additional_parameters
                                    }
                                    key={pureItem.id}
                                    onlyRead={true}
                                    field={entityField}
                                    datatype={entityField.datatype!}
                                    value={pureItem[entityField.key]}
                                    onChange={() => {}}
                                />
                            </FormItem>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default KanbanItem;
