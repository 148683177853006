import { TEditQueryActionValue, TParseRule } from "./model/types";

export const getQueryParamsObjectFromString = (queryParamsString: string | undefined) => {
    const queryParamsKeyValues = queryParamsString
        ? queryParamsString.split("&").map((queryKeyValue) => {
              const [key, value] = queryKeyValue.split("=");
              return {
                  key,
                  value,
              };
          })
        : [];
    return queryParamsKeyValues;
};

export const getQueryParamsStringFromObjectsArray = (
    queryParamsObjectsArray: { [key: string]: string }[]
) => queryParamsObjectsArray.map(({ key, value }) => `${key}=${value}`).join("&");

export const getUrlRouteAndQueryParamString = (url: string) => {
    const [urlRoute, queryParamsString] = url.split("?");
    return [urlRoute, queryParamsString];
};

export const getNotEmptyQueryParams = (queryParamsString: string) =>
    getQueryParamsObjectFromString(queryParamsString).filter(
        ({ key, value }) => key.length > 0 && value.length > 0
    );

export const getUrlWithQueryParams = (
    urlRoute: string,
    queryParams: { key: string; value: string }[]
) =>
    urlRoute +
    (queryParams.length > 0 ? "?" : "") +
    getQueryParamsStringFromObjectsArray(queryParams);

export const getNotEmptyParseRules = (parseRules: TParseRule[]) =>
    (parseRules ?? []).filter(
        (item) => item.key && item.key.length > 0 && item.targets.length > 0
    );

export const getPreparedEditQueryAction = (templateItem: TBizProcTemplateAction) => {
    const parametersValue = templateItem.parameters as TEditQueryActionValue;
    const [urlRoute, queryParamsString] = getUrlRouteAndQueryParamString(
        parametersValue.url ?? ""
    );
    const notEmptyQueryParams = getNotEmptyQueryParams(queryParamsString);

    const urlWithoutEmptyQueryParams = getUrlWithQueryParams(
        urlRoute,
        notEmptyQueryParams
    );

    const notEmptyParseRules = getNotEmptyParseRules(parametersValue.parse_rules ?? []);
    const body = parametersValue?.body;
    let parsedBody;
    try {
        parsedBody =
            typeof body === "object"
                ? body
                : JSON.parse(body && body.length > 0 ? body : "{}");
    } catch (error) {
        parsedBody = {};
    }
    return {
        ...templateItem,
        parameters: {
            ...parametersValue,
            url: urlWithoutEmptyQueryParams,
            body: parsedBody,
            parse_rules: notEmptyParseRules,
        },
    };
};
