import { NumberInput } from "@entities/Portal/Datatype";
import { EntityFieldSelect } from "@entities/Portal/EntityField";
import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { techMapModel } from "@entities/Production/TechMap";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { LabelText } from "@shared/ui/Text";
import { Checkbox, Modal, Space } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ITMTableApprovePartialModal {
    techMap: TTechMapStarted;
    levelKey?: string | number;
    isOpen: boolean;
    onCancel: () => void;
}

const TMTableApprovePartialModal = ({
    techMap,
    isOpen,
    levelKey,
    onCancel,
}: ITMTableApprovePartialModal) => {
    const results = techMap.results;
    const approveLoading = useSelector((state: RootState) =>
        techMapModel.selectIsPartlyRejectLoading(state, techMap.id)
    );
    const [rejectedResult, setRejectedResult] = useState<TTechMapResultRejected[]>(
        results.map((item) => ({
            id: item.id,
            defect: 0,
            reset: 0,
            result: item,
        }))
    );
    const [releaseItems, setReleaseItems] = useState<number[]>(
        results.length === 1 ? [results[0].id] : []
    );

    const dispatch = useDispatch<AppDispatch>();

    const onChange = (id: number, key: keyof TTechMapResultRejected, value: any) => {
        setRejectedResult(
            rejectedResult.map((result) =>
                result.id === id
                    ? {
                          ...result,
                          [key]: value,
                      }
                    : result
            )
        );
    };

    const onApprove = () => {
        dispatch(
            techMapModel.approveTmThunk({
                tmtId: techMap.id,
                decision: "partly_reject",
                results: rejectedResult.map((result) => ({
                    id: result.id,
                    defect: result.defect,
                    reset: result.reset,
                })),
                releaseItemsIds: releaseItems,
                level: levelKey,
            })
        );
    };

    return (
        <Modal
            title={"Согласование результатов"}
            onCancel={onCancel}
            open={isOpen}
            confirmLoading={approveLoading}
            onOk={onApprove}
            okText={"Согласовать"}
        >
            {rejectedResult.map((result) => {
                const onChangeToDefect = (value: number) => {
                    const numValue = Number(value) ?? 0;
                    const maxValue = result.result.count - result.reset;
                    console.log("numValue", numValue, maxValue);

                    onChange(
                        result.id,
                        "defect",
                        numValue > maxValue ? maxValue : numValue
                    );
                };
                const onChangeToReset = (value: number) => {
                    const numValue = Number(value) ?? 0;
                    const maxValue = result.result.count - result.defect;
                    onChange(
                        result.id,
                        "reset",
                        numValue > maxValue ? maxValue : numValue
                    );
                };

                const release = releaseItems.find((item) => item === result.id);

                const onChangeRelease = () => {
                    if (release) {
                        setReleaseItems((items) =>
                            items.filter((item) => item !== release)
                        );
                    } else {
                        setReleaseItems((items) => {
                            return [...items, result.id];
                        });
                    }
                };

                return (
                    <IndentContainer key={result.id}>
                        <div
                            style={{
                                padding: 15,
                                borderRadius: 8,
                                border: "1px solid #dfdfdfaa",
                            }}
                        >
                            <FormItem label="Результат">
                                {result.result.template_result.entity_uuid &&
                                result.result.entity_row_id ? (
                                    <SelectEntityRow
                                        byUuid
                                        onlyRead
                                        entityInfo={
                                            result.result.template_result.entity_uuid
                                        }
                                        value={result.result.entity_row_id}
                                        onChange={() => {}}
                                    />
                                ) : (
                                    <>Не указан</>
                                )}
                            </FormItem>

                            <FormItem label="Перевести в брак">
                                <NumberInput
                                    onChangeValue={onChangeToDefect}
                                    value={result.defect}
                                />
                            </FormItem>
                            <FormItem label="Отменить результат">
                                <NumberInput
                                    onChangeValue={onChangeToReset}
                                    value={result.reset}
                                />
                            </FormItem>
                            <Space>
                                <span>
                                    <LabelText text={`Всего: `} />
                                    {result.result.count}
                                </span>
                                <span>
                                    <LabelText text={`Одобрено: `} />
                                    {result.result.count - result.defect - result.reset}
                                </span>
                                <span>
                                    <LabelText text={`Брак: `} />
                                    {result.defect}
                                </span>
                                <span>
                                    <LabelText text={`Отменено: `} />
                                    {result.reset}
                                </span>
                            </Space>
                            <div>
                                <div onClick={onChangeRelease}>
                                    <Checkbox checked={!!release}>
                                        Использовать для выпуска
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                    </IndentContainer>
                );
            })}
        </Modal>
    );
};

export default TMTableApprovePartialModal;
