import { getNecessaryItemUseActionMock } from "../lib";

interface UseTMTONItemUseActionsI {
    onChange: (actions: (TONItemUseAction | TONItemUseActionCreating)[]) => void;
    actions: (TONItemUseAction | TONItemUseActionCreating)[];
    entityUuid?: string | null;
    entityRowId?: number | null;
}
export const useTMTONItemUseActions = ({
    actions,
    entityUuid,
    entityRowId,
    onChange,
}: UseTMTONItemUseActionsI) => {
    const createNew = () => {
        onChange([
            ...actions,
            getNecessaryItemUseActionMock({
                entityUuid,
                entityRowId,
            }),
        ]);
    };

    const onDelete = (id: number | string) => {
        onChange(
            actions.filter((action) => {
                const isEqual =
                    ("id" in action && action.id === id) ||
                    ("tmp_id" in action && action.tmp_id === id);
                return !isEqual;
            })
        );
    };
    const onChangeAction = (
        updatedAction: TONItemUseAction | TONItemUseActionCreating
    ) => {
        onChange(
            actions.map((action) => {
                const isEqual =
                    ("id" in updatedAction &&
                        "id" in action &&
                        action.id === updatedAction.id) ||
                    ("tmp_id" in updatedAction &&
                        "tmp_id" in action &&
                        action.tmp_id === updatedAction.tmp_id);
                if (isEqual) return updatedAction;
                return action;
            })
        );
    };

    return { createNew, onDelete, onChangeAction };
};
